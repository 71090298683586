import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  View,
  StyleSheet,
  Image,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';

import { Text, Button, TextInput } from '../../core-ui';
import {
  BrandDropdown,
  Option,
  ConfirmationModal,
  ErrorMessage,
  ErrorModal,
  Modal,
} from '../../components';
import { colors, theme } from '../../constants/theme';
import { emailIcon, deleteIcon } from '../../../assets';
import { EDIT_BU_CONTACT } from '../../graphql/mutations/BUContact';
import {
  EditDataBUContact,
  EditDataBUContactVariables,
} from '../../generated/EditDataBUContact';
import { Brands, BrandsVariables, Brands_brands } from '../../generated/Brands';
import { BRANDS, EMAIL_ASSIGNED_BU } from '../../graphql/queries';
import {
  BUContactInput,
  BUContactInputWithId,
} from '../../generated/globalTypes';
import { IS_BU_CONNECT } from '../../graphql/queries/BUContact';
import {
  BUContactConnect,
  BUContactConnectVariables,
} from '../../generated/BUContactConnect';
import {
  EmailAssignedBU,
  EmailAssignedBUVariables,
} from '../../generated/EmailAssignedBU';

type Props = { setPrincipalIncomplete: (value: boolean) => void };

const blankData = {
  idBrand: null as string | null,
  name: '',
  phoneNumber: '',
  email: '',
};

export default function BusinessUnitContacts({
  setPrincipalIncomplete,
}: Props) {
  let [editable, setEditable] = useState(false);
  let [deleteIndex, setDeleteIndex] = useState(-1);
  let [showDeleteUsedModal, setShowDeleteUsedModal] = useState(false);
  let [showEditModal, setShowEditModal] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState<Array<number>>([]);
  let [name, setName] = useState<Array<string>>([]);
  let [phoneNumber, setPhoneNumber] = useState<Array<string>>([]);
  let [email, setEmail] = useState<Array<string>>([]);
  let [id, setId] = useState<Array<string>>([]);
  let [brands, setBrands] = useState<Array<Option>>([]);
  let [newData, setNewData] = useState<Array<BUContactInput>>([]);
  let [sendLoading, setSendLoading] = useState<boolean>(false);
  let [sendEmailModal, setSendEmailModal] = useState<boolean>(false);
  let [pressedEmailIndex, setPressedEmailIndex] = useState<number>();
  const [errorOpen, setErrorOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const duplicateEmailErrorMessage = t([
    'Setiap merek harus memiliki email yang berbeda',
    'Each brand must have different email',
  ]);

  const {
    data: brandData,
    loading: brandLoading,
    error: brandError,
    refetch: brandRefetch,
  } = useQuery<Brands, BrandsVariables>(BRANDS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      const brandsWithoutPrincipal = result.brands.filter(
        ({ businessUnitContact }) =>
          !businessUnitContact || !businessUnitContact.email,
      );
      if (brandsWithoutPrincipal.length > 0) {
        setPrincipalIncomplete(true);
      } else {
        setPrincipalIncomplete(false);
      }
      setDuplicateEmail(new Array(result.brands.length).fill(-1));
    },
  });

  const refetchBrands = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await brandRefetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [brandRefetch]);

  const filteredBrands = useMemo(() => {
    let newBrands: Brands['brands'] = [];
    if (brandData) {
      for (let item of brandData.brands) {
        if (item.businessUnitContact != null) {
          newBrands.push(item);
        }
      }
    }
    return newBrands;
  }, [brandData]);

  useEffect(() => {
    if (editable) {
      let initialBrands: Array<Option> = [];
      let initialPhoneNumber: Array<string> = [];
      let initialName: Array<string> = [];
      let initialEmail: Array<string> = [];
      let initialId: Array<string> = [];
      for (let { id, name, businessUnitContact } of filteredBrands) {
        initialBrands.push({ label: name, value: id });
        initialPhoneNumber.push(businessUnitContact?.phoneNumber ?? '');
        initialName.push(businessUnitContact?.name ?? '');
        initialEmail.push(businessUnitContact?.email ?? '');
        initialId.push(businessUnitContact?.id ?? '');
      }
      setBrands(initialBrands || []);
      setPhoneNumber(initialPhoneNumber || []);
      setName(initialName || []);
      setEmail(initialEmail || []);
      setId(initialId || []);
    }
  }, [
    filteredBrands,
    setBrands,
    setEmail,
    setName,
    setPhoneNumber,
    setId,
    editable,
  ]);

  const queryErrorAction = useMemo(
    () => t(['mengambil data Principal', 'retrieve the Principal data']),
    [],
  );
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorInstance(error);
      setErrorAction(action);
    },
    [],
  );
  const onEditError = useCallback(
    (error: ApolloError) => {
      let message = t(['mengubah data Principal', 'edit Principal data']);
      let errorMessage = error.graphQLErrors[0]?.message;

      if (
        errorMessage.includes(
          'Business contact or admin with same email already exist',
        )
      ) {
        setErrorModalMessage(duplicateEmailErrorMessage);
      } else if (
        errorMessage.includes(
          'Data input update email or brand is not unique from each other',
        )
      ) {
        setErrorModalMessage(
          t([
            'Data email dan brand tidak unik',
            'Email or brand data is not unique from each other',
          ]),
        );
      } else if (
        errorMessage.includes(
          'There are double data between input and database',
        )
      ) {
        setErrorModalMessage(
          t([
            'Terdapat data ganda antara input dan database',
            'There are double data between input and database',
          ]),
        );
      } else {
        setErrorModalMessage('');
      }
      openErrorModal(message)(error);
    },
    [duplicateEmailErrorMessage, openErrorModal],
  );
  const onDeleteError = useMemo(() => {
    return openErrorModal(
      t(['menghapus data Principal', 'delete Principal data']),
    );
  }, [openErrorModal]);
  const onSendEmailError = useMemo(() => {
    return openErrorModal(
      t(['mengirim email ke principal', 'send email to principal']),
    );
  }, [openErrorModal]);

  const onEditCompleted = useCallback(() => {
    refetchBrands();
    setEditable(false);
    setNewData([]);
  }, [refetchBrands]);
  const onDeleteCompleted = useCallback(() => {
    setDeleteIndex(-1);
    setShowDeleteUsedModal(false);
    setShowDeleteModal(false);
    refetchBrands();
  }, [refetchBrands]);
  const [editBUContact, { loading: editLoading }] = useMutation<
    EditDataBUContact,
    EditDataBUContactVariables
  >(EDIT_BU_CONTACT, {
    onCompleted: onEditCompleted,
    onError: onEditError,
  });
  const [deleteBUContact, { loading: deleteLoading }] = useMutation<
    EditDataBUContact,
    EditDataBUContactVariables
  >(EDIT_BU_CONTACT, {
    onCompleted: onDeleteCompleted,
    onError: onDeleteError,
  });
  const [emailAssignment] = useLazyQuery<
    EmailAssignedBU,
    EmailAssignedBUVariables
  >(EMAIL_ASSIGNED_BU, {
    onError: onSendEmailError,
    fetchPolicy: 'network-only',
  });
  const [isBUConnect] = useLazyQuery<
    BUContactConnect,
    BUContactConnectVariables
  >(IS_BU_CONNECT, {
    onCompleted: (data) => {
      if (data.BUContactConnect.isConnect) {
        setShowDeleteUsedModal(true);
      } else {
        setShowDeleteModal(true);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const checkDuplicateEmail = useCallback(
    (index: number, value: string) => {
      let newDuplicateValue = -1;
      for (let loopIndex = 0; loopIndex < email.length; loopIndex++) {
        if (loopIndex === index) {
          continue;
        }
        if (value === email[loopIndex]) {
          newDuplicateValue = loopIndex;
        }
      }
      // NOTE: need to add email.length because this list is a continuation from the existing email list
      for (
        let loopIndex = email.length;
        loopIndex < email.length + newData.length;
        loopIndex++
      ) {
        if (loopIndex === index) {
          continue;
        }
        if (value === newData[index]?.email) {
          newDuplicateValue = loopIndex;
        }
      }
      setDuplicateEmail((previous) =>
        previous.map((previousValue, loopIndex) =>
          loopIndex === index
            ? newDuplicateValue
            : previousValue === index
            ? -1
            : previousValue,
        ),
      );
    },
    [email, newData],
  );

  const createSetValue = useCallback(
    <T extends unknown>(setter: Dispatch<SetStateAction<Array<T>>>) => (
      index: number,
    ) => (value: T) => {
      setter((prev) =>
        prev.map((prevValue, loopIndex) =>
          loopIndex === index ? value : prevValue,
        ),
      );
    },
    [],
  );
  const setNameValue = useMemo(() => createSetValue(setName), [createSetValue]);
  const setBrandsValue = useMemo(() => createSetValue(setBrands), [
    createSetValue,
  ]);
  const setPhoneNumberValue = useMemo(() => createSetValue(setPhoneNumber), [
    createSetValue,
  ]);
  const setEmailValue = useCallback(
    (index: number) => (value: string) => {
      checkDuplicateEmail(index, value);
      createSetValue(setEmail)(index)(value);
    },
    [createSetValue, checkDuplicateEmail],
  );

  let selectedBrands = useMemo(() => brands.map((brand) => brand?.value), [
    brands,
  ]);
  let brandsOptionLength = useMemo(
    () => (brandData && brandData.brands.length) || 0,
    [brandData],
  );

  const addNewRow = useCallback(() => {
    setNewData([...newData, blankData as BUContactInput]);
  }, [newData]);

  let onSelectNewBrand = useCallback(
    (index: number) => (selected: Option) => {
      let newDataUpdated = newData.map((previous, i) => {
        return i === index
          ? {
              ...previous,
              idBrand: selected.value,
            }
          : previous;
      });
      if (
        editable &&
        (index === newData.length - 1 ||
          newData[newData.length - 1].idBrand != null) &&
        newData.length + selectedBrands.length < brandsOptionLength
      ) {
        newDataUpdated = [...newDataUpdated, blankData as BUContactInput];
      }
      setNewData(newDataUpdated);
    },
    [editable, newData, brandsOptionLength, selectedBrands],
  );
  let onAddName = useCallback(
    (index: number) => (value: string) => {
      setNewData(
        newData.map((previous, i) =>
          i === index
            ? {
                ...previous,
                name: value,
              }
            : previous,
        ),
      );
    },
    [newData],
  );
  let onAddEmail = useCallback(
    (index: number) => (value: string) => {
      const duplicateEmailIndex = email.length + index;
      checkDuplicateEmail(duplicateEmailIndex, value);
      setNewData(
        newData.map((previous, i) =>
          i === index
            ? {
                ...previous,
                email: value,
              }
            : previous,
        ),
      );
    },
    [email, newData, checkDuplicateEmail],
  );
  let onAddPhoneNumber = useCallback(
    (index: number) => (value: string) => {
      setNewData(
        newData.map((previous, i) =>
          i === index
            ? {
                ...previous,
                phoneNumber: value,
              }
            : previous,
        ),
      );
    },
    [newData],
  );

  const onSave = useCallback(async () => {
    let updatedData: Array<BUContactInputWithId> = [];
    for (let i = 0; i < name.length; i++) {
      updatedData.push({
        name: name[i] || '',
        phoneNumber: phoneNumber[i] || '',
        email: email[i] || '',
        idBrand: brands[i]?.value || '',
        id: id[i] || '',
      });
    }
    let createNewBU = [];
    for (let newBUData of newData) {
      if (newBUData.idBrand) {
        createNewBU.push(newBUData);
      }
    }
    editBUContact({
      variables: {
        buContact: {
          update: {
            buContacts: updatedData,
          },
          create: {
            buContacts: createNewBU,
          },
        },
      },
    });
  }, [editBUContact, newData, name, phoneNumber, email, brands, id]);

  const onEdit = useCallback(() => {
    addNewRow();
    setEditable(true);
    setDuplicateEmail(new Array(brandData?.brands.length ?? 0).fill(-1));
  }, [brandData, addNewRow]);
  const onCancel = useCallback(() => {
    setName([]);
    setEmail([]);
    setPhoneNumber([]);
    setBrands([]);
    setEditable(false);
    setNewData([]);
  }, []);
  const onDelete = useCallback(
    (id) => {
      deleteBUContact({
        variables: {
          buContact: {
            delete: { buContacts: { id: [id] } },
          },
        },
      });
    },
    [deleteBUContact],
  );

  const onPressDelete = useCallback(
    (index) => {
      setDeleteIndex(index);
      isBUConnect({
        variables: {
          id: id[index],
        },
      });
    },
    [id, isBUConnect],
  );

  let onContactPress = useCallback(
    (buContactId?: string, index?: number) => {
      if (buContactId) {
        emailAssignment({ variables: { buContactId } });
        setSendLoading(true);
        setPressedEmailIndex(index);
        setTimeout(() => {
          setPressedEmailIndex(undefined);
          setSendLoading(false);
          setSendEmailModal(true);
        }, [3000]);
      }
    },
    [emailAssignment],
  );

  const checkNonExistence = useCallback(
    (value) => value == null || value === '',
    [],
  );
  const hasIncompleteExistingData = useMemo(() => {
    if (brands.filter(checkNonExistence).length) {
      return true;
    } else if (name.filter(checkNonExistence).length) {
      return true;
    } else if (phoneNumber.filter(checkNonExistence).length) {
      return true;
    } else if (email.filter(checkNonExistence).length) {
      return true;
    } else {
      return false;
    }
  }, [brands, name, phoneNumber, email, checkNonExistence]);

  const hasIncompleteNewData = useMemo(() => {
    for (let { idBrand, name, phoneNumber, email } of newData) {
      if (!idBrand && !name && !phoneNumber && !email) {
        continue;
      } else if (!!idBrand && !!name && !!phoneNumber && !!email) {
        continue;
      } else {
        return true;
      }
    }
    return false;
  }, [newData]);

  const hasDuplicateEmail = useMemo(() => {
    for (let value of duplicateEmail) {
      if (value !== -1) {
        return true;
      }
    }
    return false;
  }, [duplicateEmail]);

  const submitDisabled =
    hasIncompleteExistingData || hasIncompleteNewData || hasDuplicateEmail;

  return (
    <View data-cy="bu-contact-list-item" style={styles.flex}>
      <View style={styles.header}>
        <Text bold>{t(['KONTAK PRINCIPAL', 'PRINCIPAL CONTACTS'])}</Text>
        {editable ? (
          <View style={styles.row}>
            <Button
              data-cy="bu-contact-save"
              preset="transparent"
              title={t(['Simpan', 'Save'])}
              isLoading={editLoading}
              onPress={() => setShowEditModal(true)}
              disabled={submitDisabled}
            />
            <View style={styles.separator} />
            <Button
              data-cy="bu-contact-cancel"
              preset="transparent"
              title={t(['Batal', 'Cancel'])}
              onPress={onCancel}
            />
          </View>
        ) : (
          <Button
            data-cy="bu-contact-edit-add"
            preset="transparent"
            title={t(['Ubah/Tambah', 'Edit/Add'])}
            onPress={onEdit}
          />
        )}
      </View>
      {brandLoading ? (
        <ActivityIndicator />
      ) : editable ? (
        filteredBrands?.map((_: Brands_brands, index: number) => {
          return (
            <View key={index} style={styles.editable}>
              <View style={styles.brand}>
                <BrandDropdown
                  data-cy={`bu-contact-brand-dropdown`}
                  title={t(['Cari Merek', 'Search by Brands'])}
                  disabledOptionsById={[
                    ...selectedBrands,
                    ...newData.map(({ idBrand }) => idBrand),
                  ]}
                  selectedOption={brands[index]?.value}
                  onSelect={setBrandsValue(index)}
                />
              </View>
              <TextInput
                data-cy="bu-contact-name"
                value={name[index] || ''}
                onChangeText={setNameValue(index)}
                disabled={!editable}
                containerStyle={styles.input}
              />
              <TextInput
                data-cy="bu-contact-phone"
                value={phoneNumber[index] || ''}
                onChangeText={setPhoneNumberValue(index)}
                disabled={!editable}
                containerStyle={styles.input}
                type="number"
              />
              <TextInput
                data-cy="bu-contact-email"
                error={duplicateEmail[index] !== -1}
                errorMessage={duplicateEmailErrorMessage}
                value={email[index] || ''}
                onChangeText={setEmailValue(index)}
                disabled={!editable}
                containerStyle={styles.input}
              />
              <Button
                data-cy="bu-contact-delete"
                title={t(['hapus', 'delete'])}
                preset="transparent"
                isLoading={index === deleteIndex && deleteLoading}
                icon={<Image source={deleteIcon} style={styles.delete} />}
                onPress={() => onPressDelete(index)}
              />
            </View>
          );
        })
      ) : (
        filteredBrands?.map((item: Brands_brands, index: number) => {
          return (
            <View key={index} style={styles.contact}>
              <View style={styles.textWrapper}>
                <Text size="small" style={styles.text}>
                  {item.name}
                </Text>
              </View>
              <View style={styles.textWrapper}>
                <Text
                  data-cy="bu-contact-edited-name"
                  size="small"
                  style={styles.text}
                >
                  {item.businessUnitContact?.name}
                </Text>
              </View>
              <View style={styles.textWrapper}>
                <Text size="small" style={styles.text}>
                  {item.businessUnitContact?.phoneNumber}
                </Text>
              </View>
              <View style={styles.textWrapper}>
                <Text size="small" style={styles.text}>
                  {item.businessUnitContact?.email}
                </Text>
              </View>
              {pressedEmailIndex === index ? (
                <ActivityIndicator style={styles.activityIndicatorEmail} />
              ) : (
                <Button
                  data-cy="bu-contact-send-email"
                  title={t(['email', 'email'])}
                  preset="transparent"
                  icon={<Image source={emailIcon} style={styles.email} />}
                  onPress={() =>
                    onContactPress(item.businessUnitContact?.id, index)
                  }
                  disabled={sendLoading}
                />
              )}
            </View>
          );
        })
      )}
      {newData.map((data, index) => {
        return editable &&
          newData[newData.length - 1].idBrand !== '' &&
          selectedBrands.length < brandsOptionLength ? (
          <View
            data-cy="bu-contact-blank-data"
            key={index}
            style={styles.editable}
          >
            <View style={styles.brand}>
              <BrandDropdown
                data-cy="bu-contact-blank-dropdown"
                title={t(['Cari Merek', 'Search by Brands'])}
                disabledOptionsById={[
                  ...selectedBrands,
                  ...newData.map(({ idBrand }) => idBrand),
                ]}
                selectedOption={data.idBrand || undefined}
                onSelect={onSelectNewBrand(index)}
              />
            </View>
            <TextInput
              data-cy="bu-contact-blank-name"
              value={data.name}
              onChangeText={onAddName(index)}
              disabled={!editable}
              containerStyle={styles.input}
            />
            <TextInput
              data-cy="bu-contact-blank-phone"
              value={data.phoneNumber}
              onChangeText={onAddPhoneNumber(index)}
              disabled={!editable}
              containerStyle={styles.input}
            />
            <TextInput
              data-cy="bu-contact-blank-email"
              error={duplicateEmail[index + email.length] !== -1}
              errorMessage={duplicateEmailErrorMessage}
              value={data.email}
              onChangeText={onAddEmail(index)}
              disabled={!editable}
              containerStyle={styles.input}
            />
            <Button
              data-cy="bu-contact-blank-delete"
              title={t(['hapus', 'delete'])}
              preset="transparent"
              icon={<Image source={deleteIcon} style={styles.delete} />}
              onPress={() => onPressDelete(index)}
            />
          </View>
        ) : null;
      })}
      <ConfirmationModal
        data-cy="delete-modal"
        keepOpenAfterConfirm
        loading={deleteLoading}
        open={showDeleteModal}
        onClose={() => setDeleteIndex(-1)}
        onConfirm={() => onDelete(id[deleteIndex])}
        title={t([
          'Apakah anda yakin untuk menghapus? Data yang telah di hapus tidak dapat dikembalikan.',
          'Are you sure you want to delete? This action is irreversible.',
        ])}
      />
      <ConfirmationModal
        data-cy="delete-modal"
        keepOpenAfterConfirm
        loading={deleteLoading}
        open={showDeleteUsedModal}
        onClose={() => setShowDeleteUsedModal(false)}
        onConfirm={() => onDelete(id[deleteIndex])}
        title={t([
          'Anda akan menghapus kontak Principal yang telah di gunakan di halaman lain. Apakah anda yakin ingin menghapus?',
          'You are going to delete Principal contact which is used in other pages. Are you sure you want to continue?',
        ])}
      />
      <ConfirmationModal
        data-cy="bu-contact-edit-modal"
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        onConfirm={onSave}
        title={t([
          'Kontak ini sudah terdaftar pada beberapa fitur lain. Apakah anda yakin akan mengubah data kontak Principal? Perubahan kontak Principal akan merubah kontak Principal yang sudah ditetapkan pada fitur lain.',
          'This contact is already registered with other features. Are you sure you want to change Principal contact data? Changes to Principal contact data will change Principal contact data that has been assigned to another feature.',
        ])}
      />
      <Modal
        open={sendEmailModal}
        onClose={() => {
          setSendEmailModal(false);
        }}
      >
        <TouchableOpacity
          style={styles.modalCloseButton}
          onPress={() => {
            setSendEmailModal(false);
          }}
        >
          <IconButton>
            <CloseIcon fontSize="small" />
          </IconButton>
        </TouchableOpacity>
        <View>
          <IconButton>
            <CheckIcon fontSize="large" htmlColor={colors.icon.primary} />
          </IconButton>
          <Text>{t(['Email sudah terkirim', 'Email has been sent'])}</Text>
        </View>
      </Modal>
      {!!brandError && (
        <View style={styles.errorRoot}>
          <ErrorMessage
            action={queryErrorAction}
            error={brandError}
            onPress={refetchBrands}
          />
        </View>
      )}
      <ErrorModal
        action={errorAction}
        open={errorOpen}
        error={errorInstance}
        message={errorModalMessage}
        onClose={closeErrorModal}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  errorRoot: { alignItems: 'center', paddingTop: theme.spacing.medium },
  contact: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  editable: {
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    marginBottom: 6,
  },
  separator: {
    height: 12,
    width: 2,
    backgroundColor: colors.button.primary.background,
    alignSelf: 'center',
    marginRight: 4,
  },
  textWrapper: { flex: 1 },
  input: { flex: 1, alignItems: 'center', marginRight: 8 },
  text: { width: '100%' },
  brand: { flex: 1, marginRight: 8 },
  row: { flexDirection: 'row', justifyContent: 'space-between' },
  email: { width: 22, height: 12 },
  delete: { width: 24, height: 24 },
  activityIndicatorEmail: { paddingHorizontal: 22, marginVertical: 8 },
  modalCloseButton: { position: 'absolute', top: 0, right: 0 },
});
