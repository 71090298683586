import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_PROVINCES } from '../../../../graphql/queries';
import { Provinces } from '../../../../generated/Provinces';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultPlaceholder = t(['- Pilih Provinsi -', '- Select Province -']);

type Props = Omit<
  DropdownProps<string>,
  'options' | 'multiple' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (value: string, label: string) => void;
};

const ProvinceDropdown = (props: Props) => {
  let { placeholder = defaultPlaceholder, ...otherProps } = props;

  //TODO: add error handling
  const { data } = useQuery<Provinces>(GET_PROVINCES);

  const options = useMemo(
    () =>
      data
        ? data.provinces.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          }))
        : [],
    [data],
  );

  return (
    <Dropdown options={options} placeholder={placeholder} {...otherProps} />
  );
};

export default ProvinceDropdown;
