import gql from 'graphql-tag';

import { SLIDER_FRAGMENT } from '../fragments';

export const CREATE_SLIDER = gql`
  mutation CreateCardSlider(
    $brandId: ID
    $promotionName: String!
    $appUser: [String!]!
    $region: [String!]!
    $city: [String!]!
    $promotionLinkId: ID
    $externalLink: String
    $image: String
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $active: Boolean!
    $timeActive: Time!
    $numberOrder: Int!
  ) {
    createCardSliderPromotion(
      brandId: $brandId
      promotionName: $promotionName
      appUser: $appUser
      region: $region
      city: $city
      promotionLinkId: $promotionLinkId
      externalLink: $externalLink
      image: $image
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      active: $active
      timeActive: $timeActive
      numberOrder: $numberOrder
    ) {
      ...SliderFragment
    }
  }
  ${SLIDER_FRAGMENT}
`;

export const UPDATE_SLIDER = gql`
  mutation UpdateCardSlider(
    $cardSliderId: ID!
    $brandId: ID
    $promotionName: String!
    $appUser: [String!]!
    $region: [String!]!
    $city: [String!]!
    $promotionLinkId: ID
    $externalLink: String
    $image: String
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $timeActive: Time!
    $numberOrder: Int!
  ) {
    updateCardSliderPromotion(
      cardSliderId: $cardSliderId
      brandId: $brandId
      promotionName: $promotionName
      appUser: $appUser
      region: $region
      city: $city
      promotionLinkId: $promotionLinkId
      externalLink: $externalLink
      image: $image
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      timeActive: $timeActive
      numberOrder: $numberOrder
    ) {
      ...SliderFragment
    }
  }
  ${SLIDER_FRAGMENT}
`;

export const ARCHIVE_SLIDER = gql`
  mutation ArchiveCardSlider($cardSliderId: ID!) {
    archiveCardSliderPromotion(cardSliderId: $cardSliderId) {
      success
    }
  }
`;

export const TOGGLE_ACTIVE_SLIDER = gql`
  mutation ToggleActiveSlider($allDayActive: Boolean!) {
    toggleActiveSlider(allDayActive: $allDayActive) {
      success
    }
  }
`;
