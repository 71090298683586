import React from 'react';
import { View, StyleSheet } from 'react-native';

import { StepHeader } from '../../components';
import { Text, TextInput, Button } from '../../core-ui';
import { spacing } from '../../constants/theme';
import { CompetitionFragment_winners } from '../../generated/CompetitionFragment';

export type CompetitionWinner = Pick<
  CompetitionFragment_winners,
  'id' | 'name' | 'email' | 'notified' | 'storeName'
>;

type Props = {
  winners: Array<CompetitionWinner>;
  editable: boolean;
  onNameChange: (text: string, index: number) => void;
  onStoreNameChange: (text: string, index: number) => void;
  onAddNewPress: () => void;
  onDeletePress: (index: number) => void;
};

export default function StepSevenCompetition(props: Props) {
  let {
    winners,
    editable,
    onNameChange,
    onStoreNameChange,
    onAddNewPress,
    onDeletePress,
  } = props;

  return (
    <>
      <StepHeader step={7} title={t(['Pilih Pemenang', 'Select Winner/s'])} />
      <View style={styles.winnerRow}>
        <Text style={{ marginVertical: spacing.small }} bold>
          {t(['Pemenang', 'Winner/s'])}
        </Text>
        <Button
          preset="transparent"
          title={t(['+ Tambah', '+ Add New'])}
          onPress={onAddNewPress}
          disabled={!editable}
        />
      </View>
      <View style={styles.row}>
        <View>
          {winners[0] ? <Text bold>{t(['Nama', 'Name'])}</Text> : null}
          {winners.map(({ name }, index) => (
            <View key={index} style={styles.winnerRow}>
              <TextInput
                value={name}
                onChangeText={(text) => onNameChange(text, index)}
                containerStyle={styles.winner}
                disabled={!editable}
              />
            </View>
          ))}
        </View>
        <View>
          {winners[0] ? (
            <Text bold>{t(['Nama Toko', 'Store Name'])}</Text>
          ) : null}
          {winners.map(({ storeName }, index) => (
            <View key={index} style={styles.winnerRow}>
              <TextInput
                value={storeName}
                onChangeText={(text) => onStoreNameChange(text, index)}
                containerStyle={styles.winner}
                disabled={!editable}
              />
              <Button
                preset="transparent"
                title={t(['Hapus', 'Delete'])}
                onPress={() => onDeletePress(index)}
                disabled={!editable}
              />
            </View>
          ))}
        </View>
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  winnerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: { flexDirection: 'row', justifyContent: 'space-between' },
  winner: { marginVertical: spacing.xsmall },
});
