import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { HeaderNavigationBar } from '../../components';
import { spacing } from '../../constants/theme';
import { SliderFragment } from '../../generated/SliderFragment';
import { pageTitle } from '../../constants/pageTitle';

import HomepageSliderForm from './HomepageSliderForm';
import HomepageSliderSearch from './HomepageSliderSearch';
import ShowAllUserGroup from './ShowAllUserGroups';

const HomepageSliderScene = () => {
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [editSlider, setEditSlider] = useState<SliderFragment | null>(null);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.HomepageSliderScene,
      });
    };
    eventLog();
  }, []);

  const onSubmit = useCallback(
    (id?: string | null) => {
      setRefetchToggle(!refetchToggle);
      if (!id || editSlider?.id === id) {
        setEditSlider(null);
      }
    },
    [editSlider, refetchToggle, setRefetchToggle],
  );
  const onCancel = useCallback(() => setEditSlider(null), [setEditSlider]);

  return (
    <View style={styles.root}>
      <View style={styles.section}>
        <HeaderNavigationBar />
        <HomepageSliderForm
          editSliderData={editSlider}
          refetchToggle={refetchToggle}
          setEditSlider={setEditSlider}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </View>
      <View style={styles.section}>
        <HomepageSliderSearch
          refetchToggle={refetchToggle}
          setEditSlider={setEditSlider}
          onArchive={onSubmit}
        />
      </View>
      <View style={styles.section}>
        <ShowAllUserGroup refetchToggle={refetchToggle} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  section: { paddingBottom: spacing.xlarge },
});

export default HomepageSliderScene;
