import React, { useMemo, useCallback, memo } from 'react';
import { View, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/react-hooks';

import { spacing } from '../../constants/theme';
import { GET_SCREEN_NEWS_LIST } from '../../graphql/queries';
import { screenNews } from '../../generated/screenNews';

import Dropdown, { Option } from './Dropdown';
import BrandDropdown from './BrandDropdown';
import RangeDropdown from './RangeDropdown';

type Props = {
  selectedScreen: string | null;
  selectedSubScreenOne: string | null;
  selectedSubScreenTwo: string | null;
  getValidInput?: (valid: boolean) => void;
  onScreenSelect: (selected: Option) => void;
  onSubScreenOneSelect: (selected: Option) => void;
  onSubScreenTwoSelect: (selected: Option) => void;
  disabled?: boolean;
};

const ScreenDropdown = memo((props: Props) => {
  let {
    selectedScreen,
    selectedSubScreenOne,
    selectedSubScreenTwo,
    getValidInput,
    onScreenSelect,
    onSubScreenOneSelect,
    onSubScreenTwoSelect,
    disabled,
  } = props;

  const { data, loading, error, refetch } = useQuery<screenNews>(
    GET_SCREEN_NEWS_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const subScreenOneTitle = useMemo(
    () => t(['Pilih Sub Halaman 1', 'Select Sub Screen 1']),
    [],
  );
  const subScreenTwoTitle = useMemo(
    () => t(['Pilih Sub Halaman 2', 'Select Sub Screen 2']),
    [],
  );

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const screenOptions = useMemo(() => {
    let options: Array<Option> = [];
    if (data?.screenNewsList) {
      for (let { id, name, parent } of data.screenNewsList) {
        if (parent) {
          let found = options.find(({ value }) => value === parent);
          if (!found) {
            options.push({ label: parent, value: parent });
          }
        } else {
          options.push({ label: name, value: id });
        }
      }
    }
    return options;
  }, [data]);

  const createOnSelect = useMemo(
    () => (callback: (selected: Option) => void, valid: boolean) => (
      selected: Option,
    ) => {
      callback(selected);
      getValidInput?.(valid);
    },
    [getValidInput],
  );

  const subScreenDropdowns = () => {
    if (data && selectedScreen) {
      // check if selectedScreen is My Order
      let selectedScreenOption = screenOptions.find(
        ({ value }) => value === selectedScreen,
      );
      if (selectedScreenOption?.label.toLowerCase() === 'my order') {
        // selected screen is My Order, use Brand and Range dropdowns
        return (
          <>
            <View style={styles.bottomSpacing}>
              <BrandDropdown
                data-cy="popup-form-brand"
                type="basic"
                title={subScreenOneTitle}
                selectedOption={selectedSubScreenOne ?? undefined}
                onSelect={createOnSelect(onSubScreenOneSelect, false)}
                disabled={disabled}
              />
            </View>
            <RangeDropdown
              data-cy="popup-form-range"
              type="basic"
              title={subScreenTwoTitle}
              brandID={selectedSubScreenOne ?? undefined}
              selectedOption={selectedSubScreenTwo ?? undefined}
              onSelect={createOnSelect(onSubScreenTwoSelect, true)}
              disabled={disabled}
            />
          </>
        );
      }

      // otherwise, check if we need to render sub screen dropdown
      let sameParents = data.screenNewsList?.filter(
        ({ parent }) => selectedScreen === parent,
      );
      if (sameParents?.length) {
        // selectedScreen is a parent and has sub options
        let subScreenOptions = sameParents.map(({ id, name }) => ({
          label: name,
          value: id,
        }));
        return (
          <Dropdown
            data-cy="popup-form-sub-screen"
            type="basic"
            title={subScreenOneTitle}
            options={subScreenOptions}
            selectedOption={selectedSubScreenOne ?? undefined}
            onSelect={createOnSelect(onSubScreenOneSelect, true)}
          />
        );
      } else {
        getValidInput?.(true);
      }
    }
    return null;
  };

  return (
    <View>
      <View style={styles.bottomSpacing}>
        <Dropdown
          data-cy="popup-form-screen"
          error={error}
          errorAction={t([
            'mengambil data halaman toko app',
            'retrieve the toko app page data',
          ])}
          onRetryPress={refetchData}
          type="basic"
          title={t(['Pilih Halaman', 'Select Screen'])}
          loading={loading}
          options={screenOptions}
          selectedOption={selectedScreen ?? undefined}
          onSelect={createOnSelect(onScreenSelect, false)}
          disabled={disabled}
        />
      </View>
      {subScreenDropdowns()}
    </View>
  );
});

const styles = StyleSheet.create({
  bottomSpacing: { paddingBottom: spacing.xxsmall },
});

export default ScreenDropdown;
