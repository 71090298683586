import gql from 'graphql-tag';

export const GET_STORE_CATEGORIES = gql`
  query StoreCategories {
    storeCategory {
      id
      name
    }
  }
`;

export const VERIFY_STORE_ID = gql`
  query CheckManagerStoreId($managerStoreCode: String!) {
    checkManagerStoreCode(managerStoreCode: $managerStoreCode) {
      valid
      storeName
      message
    }
  }
`;
