// NOTE: a workaround from apollo-upload-client issue thread (https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261)
import { getToken } from './auth';

const parseHeaders = (rawHeaders: string, token: string | null) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift()?.trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  token && headers.append('Authorization', token);
  return headers;
};

type Options = Omit<RequestInit, 'headers'> & {
  method: string;
  headers: Record<string, string>;
  useUpload?: boolean;
  onLoadStart: () => void;
  onProgress: () => void;
  onAbortPossible?: (abort: () => void) => void;
};

export const uploadFetch = (url: string, options: Options) =>
  new Promise<Response>(async (resolve, reject) => {
    const token = await getToken();
    const xhr = new XMLHttpRequest();
    const headers = parseHeaders(xhr.getAllResponseHeaders() || '', token);
    xhr.onloadstart = options.onLoadStart;
    xhr.onload = () => {
      const opts = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers,
        url:
          'responseURL' in xhr ? xhr.responseURL : headers.get('X-Request-URL'),
      };
      const body =
        'response' in xhr ? xhr.response : (xhr as XMLHttpRequest).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    // NOTE: if needed, pass onAbortPossible to context.fetchOptions and use to abort request
    options.onAbortPossible?.(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

export const customFetch = (uri: string, options: Options) => {
  // NOTE: only uploads use the uploadFetch function otherwise use the default fetch function
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};
