import React, { ReactNode, useCallback, useState } from 'react';
import {
  View,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  StyleSheet,
} from 'react-native';

import { Text, Button } from '../core-ui';
import { colors, theme } from '../constants/theme';

import Header from './Header';

type SearchProps = {
  'data-cy'?: string;
  children?: ReactNode;
  disabled?: boolean;
  title?: string;
  containerStyle: StyleProp<ViewStyle>;
  onClearPress: () => void;
  onSearchPress: () => void;
  onChangeTab: (tab: ActiveContent) => void;
};

export enum ActiveContent {
  POINT = 'point',
  ORDER = 'order',
  VERIF = 'verif',
  PHOTO = 'photo',
}

export default function SearchHeaderReferral(props: SearchProps) {
  let {
    children,
    title,
    containerStyle,
    onClearPress,
    onSearchPress,
    disabled,
    onChangeTab,
  } = props;
  let leftSideCount = 1;
  let leftSide = children;
  let rightSide = null as ReactNode;
  if (Array.isArray(children)) {
    let filteredChildren = children.filter((item) => item != null);
    leftSideCount = Math.ceil(filteredChildren.length / 2);
    leftSide = filteredChildren.slice(0, leftSideCount);
    rightSide = filteredChildren.slice(leftSideCount);
  }

  const [activeContent, setActiveContent] = useState<ActiveContent>(
    ActiveContent.POINT,
  );

  const onPressHeaderButton = useCallback(
    (activeContent: ActiveContent) => {
      setActiveContent(activeContent);
      onChangeTab(activeContent);
    },
    [onChangeTab],
  );

  return (
    <View style={containerStyle}>
      {title && <Header title={t(['Cari', 'Search'])} subtitle={title} />}
      <View
        style={{
          flexDirection: 'row',
          borderBottomWidth: 1,
          borderBottomColor: colors.border.primary,
          marginTop: 20,
        }}
      >
        <HeaderButton
          text="Loyalty Point"
          onPress={() => onPressHeaderButton(ActiveContent.POINT)}
          isActive={activeContent === ActiveContent.POINT}
        />
        <HeaderButton
          text="Order"
          onPress={() => onPressHeaderButton(ActiveContent.ORDER)}
          isActive={activeContent === ActiveContent.ORDER}
        />
        <HeaderButton
          text="Verifikasi Pemilik Toko"
          onPress={() => onPressHeaderButton(ActiveContent.VERIF)}
          isActive={activeContent === ActiveContent.VERIF}
        />
        <HeaderButton
          text="Verifikasi Foto Toko"
          onPress={() => onPressHeaderButton(ActiveContent.PHOTO)}
          isActive={activeContent === ActiveContent.PHOTO}
        />
      </View>
      <View style={styles.row}>
        <View style={styles.flex} testID="leftSide">
          {leftSide}
        </View>
        <View style={styles.mediumHorizontalSpacing} />
        <View style={styles.flex} testID="rightSide">
          {rightSide}
          <View style={styles.row}>
            <View style={[styles.row, styles.flex]}>
              <Button
                disabled={disabled}
                data-cy={`${props['data-cy']}-submit`}
                title={t(['Cari', 'Search'])}
                style={styles.flex}
                onPress={onSearchPress}
              />
              <View style={styles.smallHorizontalSpacing} />
              <Button
                data-cy={`${props['data-cy']}-clear`}
                preset="secondary"
                title={t(['Hapus', 'Clear'])}
                style={styles.flex}
                onPress={onClearPress}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

type HeaderButtonProps = {
  text: string;
  isActive: boolean;
  onPress: () => void;
};

const HeaderButton = ({ text, isActive, onPress }: HeaderButtonProps) => (
  <TouchableOpacity onPress={() => onPress()}>
    <View
      style={[
        styles.headButton,
        isActive && {
          borderBottomColor: colors.button.primary.background,
        },
      ]}
    >
      <Text bold={isActive} size="mediumLarge">
        {text}
      </Text>
    </View>
  </TouchableOpacity>
);

const styles = StyleSheet.create({
  headButton: {
    paddingBottom: 16,
    marginRight: 36,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  row: { flexDirection: 'row' },
  flex: { flex: 1 },
  smallHorizontalSpacing: { width: theme.spacing.small },
  mediumHorizontalSpacing: { width: theme.spacing.medium },
});
