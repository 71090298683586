import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router-dom';

import { Text, Button } from '../core-ui';
import { spacing } from '../constants/theme';

// TODO: implement proper Not Found scene
const NotFoundScene = () => {
  const history = useHistory();
  const toMainPage = () => history.push('/');
  return (
    <View style={styles.root}>
      <Text size="large" style={styles.textSpacing}>
        {t(['Halaman tidak ditemukan!', 'Page not found!'])}
      </Text>
      <Button
        data-cy="unauthenticate-button"
        preset="transparent"
        title={t(['Kembali ke Halaman Utama', 'Go to Main Page'])}
        onPress={toMainPage}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    padding: spacing.xlarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textSpacing: { paddingBottom: spacing.medium },
});

export default NotFoundScene;
