import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_PROMOTION } from '../../graphql/queries';
import { Promotions, PromotionsVariables } from '../../generated/Promotions';
import { ArchiveStatus } from '../../generated/globalTypes';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Promosi', 'Select Promotion']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  brandId?: string;
  titleIfEmpty?: string;
};

const PromotionDropdown = memo((props: Props) => {
  let { title = defaultTitle, brandId, titleIfEmpty, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<
    Promotions,
    PromotionsVariables
  >(GET_PROMOTION, {
    notifyOnNetworkStatusChange: true,
    variables: {
      brandId,
      archived: ArchiveStatus.NORMAL,
    },
  });
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      data?.promotionAdvanceSearch.row.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [data],
  );

  const dropdownTitle = useMemo(() => {
    return options.length === 0 && !!titleIfEmpty ? titleIfEmpty : title;
  }, [options, title, titleIfEmpty]);

  return (
    <Dropdown
      disabled={!!titleIfEmpty && options.length === 0}
      error={error}
      errorAction={t(['mengambil data promosi', 'retrieve the promotion data'])}
      onRetryPress={refetchData}
      options={options}
      title={dropdownTitle}
      loading={loading}
      {...otherProps}
    />
  );
});

export default PromotionDropdown;
