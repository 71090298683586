import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet, Clipboard } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { Table, Text } from '../../core-ui';
import {
  Header,
  HeaderNavigationBar,
  PromotionDropdown,
  BrandDropdown,
  Option,
  CompetitionsDropdown,
  EventDropdown,
  NewsDropdown,
  BrandRangeDropdown,
  LoyaltyCategoryDropdown,
  LoyaltySubCategoryDropdown,
} from '../../components';
import { spacing } from '../../constants/theme';
import {
  ListOfDeepLink,
  ID_CATEGORY_EWALLET,
} from '../../constants/deepLinkList';
import { pageTitle } from '../../constants/pageTitle';
import { DEEP_LINK_URL } from '../../constants/api';

const emptyFn = () => {};

export const DeepLinkList = () => {
  const [promotion, setPromotion] = useState<string | null>(null);
  const [brand, setBrand] = useState<string | null>(null);
  const [brandId, setBrandId] = useState<string | null>(null);
  const [range, setRange] = useState<string | null>(null);
  const [news, setNews] = useState<string | null>(null);
  const [competition, setCompetition] = useState<string | null>(null);
  const [event, setEvent] = useState<string | null>(null);
  const [loyaltyCategory, setLoyaltyCategory] = useState<string | null>(null);
  const [loyaltySubCategory, setLoyaltySubCategory] = useState<string | null>(
    null,
  );

  const createSetSelected = useCallback(
    (setter: (value: string) => void) => (selected: Option) => {
      setter(selected.value);
    },
    [],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.DeepLinkList });
    };
    eventLog();
  }, []);

  const getLink = useCallback(
    (key: string, data?: string, option?: string) => {
      let link = DEEP_LINK_URL + key + (data ?? '');
      if (option === 'promotion') {
        link = link + (promotion ?? '');
      } else if (option === 'brand') {
        link = link + (brand ?? '');
      } else if (option === 'news') {
        link = link + (news ?? '');
      } else if (option === 'competition') {
        link = link + (competition ?? '');
      } else if (option === 'event') {
        link = link + (event ?? '');
      } else if (option === 'order') {
        link = `${link}${brandId ?? ''}/rangeId=${range ?? ''}`;
      } else if (option === 'loyalty') {
        link = `${link}${
          loyaltyCategory ? `/CategoryID=${loyaltyCategory}` : ''
        }${loyaltySubCategory ? `/SubCategoryID=${loyaltySubCategory}` : ''}`;
      }
      return link;
    },
    [
      brand,
      competition,
      event,
      news,
      promotion,
      range,
      brandId,
      loyaltyCategory,
      loyaltySubCategory,
    ],
  );

  useEffect(() => {
    setRange(null);
  }, [brandId]);

  const copyText = useCallback(
    ({ key, data, option }: typeof ListOfDeepLink[0]) =>
      Clipboard.setString(getLink(key, data, option)),
    [getLink],
  );

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header
        withSeparator
        title="Deep Link"
        subtitle="List"
        subtitleColor="default"
      />
      <Table
        disablePadding
        data={ListOfDeepLink}
        dataCount={0}
        onChangePage={emptyFn}
        onChangeRowsPerPage={emptyFn}
        structure={{
          pageName: {
            headerTitle: t(['halaman', 'page']),
            render: ({ pageName, option }) => {
              let DropdownComponent = null;
              let DropdownComponent2 = null;
              if (option === 'promotion') {
                DropdownComponent = (
                  <PromotionDropdown
                    type="basic"
                    selectedOption={promotion ?? undefined}
                    onSelect={createSetSelected(setPromotion)}
                    style={styles.dropdown}
                  />
                );
              } else if (option === 'brand') {
                DropdownComponent = (
                  <BrandDropdown
                    type="basic"
                    selectedOption={brand ?? undefined}
                    onSelect={createSetSelected(setBrand)}
                    style={styles.dropdown}
                  />
                );
              } else if (option === 'order') {
                DropdownComponent = (
                  <BrandDropdown
                    type="basic"
                    selectedOption={brandId ?? undefined}
                    onSelect={createSetSelected(setBrandId)}
                    style={styles.dropdown}
                  />
                );
                DropdownComponent2 = (
                  <BrandRangeDropdown
                    brandID={brandId ?? undefined}
                    type="basic"
                    selectedOption={range ?? undefined}
                    onSelect={createSetSelected(setRange)}
                    style={styles.dropdown}
                  />
                );
              } else if (option === 'news') {
                DropdownComponent = (
                  <NewsDropdown
                    type="basic"
                    selectedOption={news ?? undefined}
                    onSelect={createSetSelected(setNews)}
                    style={styles.dropdown}
                  />
                );
              } else if (option === 'competition') {
                DropdownComponent = (
                  <CompetitionsDropdown
                    type="basic"
                    selectedOption={competition ?? undefined}
                    onSelect={createSetSelected(setCompetition)}
                    style={styles.dropdown}
                  />
                );
              } else if (option === 'event') {
                DropdownComponent = (
                  <EventDropdown
                    type="basic"
                    selectedOption={event ?? undefined}
                    onSelect={createSetSelected(setEvent)}
                    style={styles.dropdown}
                  />
                );
              } else if (option === 'loyalty') {
                DropdownComponent = (
                  <LoyaltyCategoryDropdown
                    type="basic"
                    title="Pilih Kategori"
                    selectedOption={loyaltyCategory}
                    onSelect={createSetSelected(setLoyaltyCategory)}
                    style={styles.dropdown}
                  />
                );
                // NOTE: 46 Id for E-Wallet Category in Prod n Staging
                DropdownComponent2 =
                  loyaltyCategory === ID_CATEGORY_EWALLET ? (
                    <LoyaltySubCategoryDropdown
                      type="basic"
                      categoryId={loyaltyCategory}
                      title="Pilih Sub Kategori"
                      selectedOption={loyaltySubCategory}
                      onSelect={createSetSelected(setLoyaltySubCategory)}
                      style={styles.dropdown}
                    />
                  ) : null;
              }

              return (
                <View style={styles.row}>
                  <Text>{pageName}</Text>
                  {DropdownComponent}
                  {DropdownComponent2}
                </View>
              );
            },
          },
          link: {
            headerTitle: t(['link', 'link']),
            render: ({ key, data, option }) => {
              return <Text>{getLink(key, data, option)}</Text>;
            },
          },
          action: {
            headerTitle: t(['aksi', 'action']),
            headerTitleColor: 'link',
            displayText: t(['salin', 'copy']),
            disabled: ({ option }) => {
              switch (option) {
                case 'promotion': {
                  return !promotion;
                }
                case 'brand': {
                  return !brand;
                }
                case 'news': {
                  return !news;
                }
                case 'competition': {
                  return !competition;
                }
                case 'event': {
                  return !event;
                }
              }
              return false;
            },
            onPress: copyText,
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdown: {
    marginLeft: spacing.small,
    minWidth: 200,
  },
});
