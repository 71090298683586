import gql from 'graphql-tag';

import { SLIDER_FRAGMENT } from '../fragments';

export const GET_SLIDERS = gql`
  query Sliders(
    $id: ID
    $user: String
    $region: String
    $city: String
    $cardSliderName: String
    $numberOrder: Int
    $brandId: ID
    $timeActive: Time
    $date: DateFilter
    $archiveStatus: ArchiveStatus
    $skip: Int
    $first: Int
    $orderBy: CardSliderOrderBy
  ) {
    cardSliderAdvanceSearch(
      id: $id
      user: $user
      region: $region
      city: $city
      numberOrder: $numberOrder
      cardSliderName: $cardSliderName
      brandId: $brandId
      timeActive: $timeActive
      archiveStatus: $archiveStatus
      date: $date
      pagination: { skip: $skip, first: $first }
      orderBy: $orderBy
    ) {
      row {
        ...SliderFragment
      }
      total
    }
  }
  ${SLIDER_FRAGMENT}
`;
