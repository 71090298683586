import React, { memo } from 'react';

import Dropdown, { Props as DropdownProps } from './Dropdown';

type Props = DropdownProps & {
  setSelectedMultiLevelValue: (multiLevelOptions: Array<string>) => void;
  selectedMultiLevelValue?: Array<string>;
};

const MultiLevelDropdown = memo((props: Props) => {
  let {
    selectedOption,
    setSelectedMultiLevelValue,
    onSelect,
    type,
    options,
    title,
    ...otherProps
  } = props;

  return (
    <Dropdown
      {...otherProps}
      options={options}
      title={title}
      selectedOption={selectedOption}
      getMultiLevelOptions={setSelectedMultiLevelValue}
      onSelect={onSelect}
      type={type}
    />
  );
});

export default MultiLevelDropdown;
