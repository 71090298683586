import React, { ReactNode, memo, useMemo } from 'react';
import { Text as RNText, TextProps, StyleSheet } from 'react-native';

import { theme } from '../constants/theme';

export type Props = TextProps & {
  bold?: boolean;
  children?: ReactNode;
  color?: keyof typeof theme.colors.text;
  italic?: boolean;
  size?: keyof typeof theme.fonts.sizes;
};

const Text = memo((props: Props) => {
  let {
    bold,
    color = 'default',
    italic,
    size = 'default',
    style,
    ...otherProps
  } = props;

  const fontFamily = useMemo(() => {
    if (bold && italic) {
      return 'RobotoBoldItalic';
    } else if (bold) {
      return 'RobotoBold';
    } else if (italic) {
      return 'RobotoItalic';
    } else {
      return 'Roboto';
    }
  }, [bold, italic]);
  return (
    <RNText
      style={StyleSheet.flatten([
        theme.fonts.default,
        {
          fontSize: theme.fonts.sizes[size],
          lineHeight: theme.fonts.lineHeights[size],
          color: theme.colors.text[color],
          fontFamily,
        },
        style,
      ])}
      {...otherProps}
    />
  );
});

export default Text;
