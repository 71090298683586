import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { logEvent } from 'expo-firebase-analytics';

import {
  Header,
  HeaderNavigationBar,
  BrandDropdown,
  Option,
  StepHeader,
  ErrorMessage,
  UploadLogoItem,
  ErrorModal,
  ConfirmationModal,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { Text, Button } from '../../core-ui';
import { LOGO_LIBRARY } from '../../graphql/queries';
import {
  LogoLibrary,
  LogoLibrary_brands,
  LogoLibraryVariables,
} from '../../generated/LogoLibrary';
import { FileWithPreview } from '../../core-ui/Dropzone';
import {
  UPLOAD,
  CREATE_BRAND_LOGO,
  EDIT_BRAND_LOGO,
  DELETE_BRAND_LOGO,
} from '../../graphql/mutations';
import { Upload, UploadVariables } from '../../generated/Upload';
import {
  CreateBrandLogo,
  CreateBrandLogoVariables,
} from '../../generated/CreateBrandLogo';
import {
  EditBrandLogo,
  EditBrandLogoVariables,
} from '../../generated/EditBrandLogo';
import {
  DeleteBrandLogo,
  DeleteBrandLogoVariables,
} from '../../generated/DeleteBrandLogo';
import { BrandImageFragment } from '../../generated/BrandImageFragment';
import { FolderType } from '../../generated/globalTypes';
import { pageTitle } from '../../constants/pageTitle';

export type Logo = Omit<BrandImageFragment, '__typename'> & {
  imageFile?: FileWithPreview;
  editable?: boolean;
};
type BrandLogoLibrary = Omit<LogoLibrary_brands, '__typename' | 'logos'> & {
  logos: Array<Logo>;
};
type SelectedLogoItem = {
  brandId: string;
  logoIndex: number;
};

const blankLogo = {
  id: '',
  name: '',
  imageUrl: '',
  editable: false,
};

export default function LogoLibraryScene() {
  let [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  let [logoLibrary, setLogoLibrary] = useState<Array<BrandLogoLibrary> | null>(
    null,
  );
  let [
    selectedLogoItem,
    setSelectedLogoItem,
  ] = useState<SelectedLogoItem | null>(null);
  let [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.LogoLibraryScene });
    };
    eventLog();
  }, []);

  const onUploadError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);
  const onCreateError = useMemo(() => {
    return openErrorModal(t(['membuat logo brand', 'create brand logo']));
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(t(['mengubah logo brand', 'update brand logo']));
  }, [openErrorModal]);
  const onDeleteError = useMemo(() => {
    return openErrorModal(t(['menghapus logo brand', 'delete brand logo']));
  }, [openErrorModal]);

  const removeLogoFromList = useCallback(() => {
    //NOTE: replace deleted logo with empty field
    let brand = logoLibrary?.find(
      (item) => item.id === selectedLogoItem?.brandId,
    );
    if (selectedLogoItem && brand) {
      let newLogos = brand.logos;
      newLogos.splice(selectedLogoItem.logoIndex, 1, blankLogo);
      logoLibrary &&
        setLogoLibrary(
          logoLibrary.map((item) => {
            return item.id === selectedLogoItem?.brandId
              ? {
                  ...item,
                  logos: newLogos,
                }
              : item;
          }),
        );
    }
  }, [logoLibrary, selectedLogoItem]);

  let [getLogoLibrary, { loading, error }] = useLazyQuery<
    LogoLibrary,
    LogoLibraryVariables
  >(LOGO_LIBRARY, {
    variables: { brandID: selectedBrand?.value },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setLogoLibrary(
        //NOTE: this is to always show the logo fields in the count of multiple of 4
        data.brands.map((brand) => {
          let brandLogos: Array<Logo> = brand.logos;
          while (brandLogos.length % 4 !== 0 || !brandLogos.length) {
            brandLogos.push(blankLogo);
          }
          return {
            ...brand,
            logos: [
              ...brandLogos.map((logo) => ({
                ...logo,
                editable: false,
              })),
            ],
          };
        }),
      );
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (selectedBrand) {
      getLogoLibrary();
    }
  }, [selectedBrand, getLogoLibrary]);

  let [upload, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, { onError: onUploadError });

  let [createBrandLogo, { loading: createLoading }] = useMutation<
    CreateBrandLogo,
    CreateBrandLogoVariables
  >(CREATE_BRAND_LOGO, {
    onCompleted: (data) => {
      //NOTE: change logo id once completed
      if (selectedLogoItem) {
        onChangeLogo(
          selectedLogoItem.brandId,
          selectedLogoItem.logoIndex,
          'id',
          data.createBrandLogo.id,
        );
      }
    },
    onError: onCreateError,
  });

  let [editBrandLogo, { loading: editLoading }] = useMutation<
    EditBrandLogo,
    EditBrandLogoVariables
  >(EDIT_BRAND_LOGO, { onError: onUpdateError });

  let [deleteBrandLogo, { loading: deleteLoading }] = useMutation<
    DeleteBrandLogo,
    DeleteBrandLogoVariables
  >(DELETE_BRAND_LOGO, {
    onCompleted: removeLogoFromList,
    onError: onDeleteError,
  });

  let onMorePress = useCallback(
    (id: string) => {
      logoLibrary &&
        setLogoLibrary(
          logoLibrary.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                logos: [...item.logos, ...new Array(4).fill(blankLogo)],
              };
            } else {
              return item;
            }
          }),
        );
    },
    [logoLibrary],
  );

  let onChangeLogo = useCallback(
    (
      brandId: string,
      logoIndex: number,
      key: keyof Logo,
      value: string | FileWithPreview | boolean,
    ) => {
      if (logoLibrary) {
        let newBrand = logoLibrary.find((item) => item.id === brandId);
        let newLogos = newBrand?.logos.map((item, index) =>
          index === logoIndex ? { ...item, [key]: value } : item,
        );
        setLogoLibrary(
          logoLibrary.map((brand) => {
            if (brand.id === brandId) {
              return {
                ...brand,
                logos: newLogos || [],
              };
            } else {
              return brand;
            }
          }),
        );
      }
    },
    [logoLibrary],
  );

  let onEditLogo = useCallback(
    (brandId: string, logoIndex: number) => {
      onChangeLogo(brandId, logoIndex, 'editable', true);
    },
    [onChangeLogo],
  );

  let onSaveLogo = useCallback(
    async (brandId: string, logoIndex: number, logo: Logo) => {
      //NOTE: to determine which field is loading
      setSelectedLogoItem({ brandId, logoIndex });

      let imageLink = logo.imageUrl;
      //NOTE: if user changed the image, upload it first
      if (logo.imageFile) {
        let result = await upload({
          variables: {
            file: logo.imageFile.file,
            folder: FolderType.BRAND_IMAGE,
          },
        });
        imageLink = result?.data?.upload.link || '';
      }

      if (!!imageLink) {
        if (logo.id === '') {
          await createBrandLogo({
            variables: {
              brandId,
              name: logo.name,
              logo: imageLink,
            },
          });
        } else {
          await editBrandLogo({
            variables: {
              brandImageId: logo.id,
              name: logo.name,
              logo: imageLink,
            },
          });
        }
      }
    },
    [createBrandLogo, editBrandLogo, upload],
  );

  let onDeleteLogo = useCallback(async () => {
    let brand = logoLibrary?.find(
      (item) => item.id === selectedLogoItem?.brandId,
    );
    if (brand && selectedLogoItem) {
      if (brand.logos[selectedLogoItem.logoIndex].id !== '') {
        await deleteBrandLogo({
          variables: {
            brandImageId: brand.logos[selectedLogoItem.logoIndex].id,
          },
        });
      } else {
        removeLogoFromList();
      }
    }
  }, [logoLibrary, selectedLogoItem, removeLogoFromList, deleteBrandLogo]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header
        title={t(['Logo', 'Logo'])}
        subtitle={t(['Brand', 'Brands'])}
        subtitleColor="default"
        style={styles.header}
      />
      <Text style={styles.subheader}>
        {t([
          'Logo-logo ini akan ditampilkan di aplikasi. Pastikan logo adalah kualitas terbaik.',
          'These logo’s are shown throughout the App. Be sure the logo is the best quality',
        ])}
      </Text>
      <View style={styles.row}>
        <View style={styles.dropdown}>
          <StepHeader
            step={1}
            title={t(['Pilih Brand', 'Select Brand'])}
            style={styles.stepHeader}
          />
          <BrandDropdown
            data-cy="brand-dropdown"
            selectedOption={selectedBrand?.value}
            onSelect={setSelectedBrand}
          />
        </View>
        <View style={styles.library}>
          {loading && <ActivityIndicator data-cy="data-loading" />}
          {!!error && (
            <ErrorMessage
              action={t(['mengambil data logo', 'retrieve the logo data'])}
              error={error}
              onPress={getLogoLibrary}
            />
          )}
          {!loading &&
            !error &&
            !!logoLibrary &&
            logoLibrary.map((brand) => {
              return (
                <View key={brand.id} style={styles.section}>
                  <Text bold style={styles.brandName}>
                    {brand.name}
                  </Text>
                  <View style={styles.logoRow}>
                    {!brand.logos.length && (
                      <Text>
                        {t(['Belum ada logo', 'There is no logo yet'])}
                      </Text>
                    )}
                    {brand.logos.map((logo, index) => {
                      return (
                        <UploadLogoItem
                          data-cy={`logo-${index}`}
                          key={index}
                          logo={logo}
                          style={styles.logoItem}
                          editable={logo.editable}
                          saveDisabled={
                            !logo.name || (!logo.imageUrl && !logo.imageFile)
                          }
                          saveLoading={
                            selectedLogoItem?.brandId === brand.id &&
                            selectedLogoItem.logoIndex === index &&
                            (createLoading || editLoading || uploadLoading)
                          }
                          deleteLoading={
                            selectedLogoItem?.brandId === brand.id &&
                            selectedLogoItem.logoIndex === index &&
                            deleteLoading
                          }
                          onChange={(key, value) =>
                            onChangeLogo(brand.id, index, key, value)
                          }
                          onSave={() => onSaveLogo(brand.id, index, logo)}
                          onEdit={() => onEditLogo(brand.id, index)}
                          onDelete={() => {
                            setSelectedLogoItem({
                              brandId: brand.id,
                              logoIndex: index,
                            });
                            setDeleteModalOpen(true);
                          }}
                        />
                      );
                    })}
                  </View>
                  <Button
                    data-cy="add-more-button"
                    preset="secondary"
                    title={t(['+ Tambah', '+ More'])}
                    onPress={() => onMorePress(brand.id)}
                    style={styles.moreButton}
                  />
                </View>
              );
            })}
          <ConfirmationModal
            data-cy="delete-modal"
            open={isDeleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            onConfirm={() => {
              onDeleteLogo();
            }}
            title={t([
              'Apakah anda yakin ingin menghapus logo ini?',
              'Are you sure you want to delete this logo?',
            ])}
          />
          <ErrorModal
            open={errorOpen}
            action={errorAction}
            error={errorInstance}
            onClose={closeErrorModal}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  header: {
    marginTop: spacing.xsmall,
  },
  subheader: {
    marginTop: spacing.small,
    marginBottom: spacing.xlarge,
  },
  row: {
    flexDirection: 'row',
  },
  dropdown: {
    flex: 1,
    paddingRight: spacing.xlarge,
    marginRight: spacing.xlarge,
  },
  library: {
    flex: 3,
  },
  stepHeader: {
    marginBottom: spacing.small,
  },
  brandName: {
    marginBottom: spacing.small,
  },
  logoRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  section: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    paddingBottom: spacing.medium,
    marginBottom: spacing.medium,
  },
  logoItem: {
    marginBottom: spacing.medium,
  },
  moreButton: {
    alignSelf: 'flex-end',
    maxWidth: 120,
  },
});
