import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { spacing, colors } from '../../constants/theme';
import { HeaderNavigationBar } from '../../components';
import { Text } from '../../core-ui';
import { pageTitle } from '../../constants/pageTitle';

import TokoAppContent from './TokoAppContent';

const AdminHubScene = () => {
  const [principalIncomplete, setPrincipalIncomplete] = useState(false);
  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.AdminHubScene });
    };
    eventLog();
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.headerSpacing}>
        <HeaderNavigationBar showBackButton={false} />
      </View>
      {principalIncomplete && (
        <View style={styles.headerSpacing}>
          <View style={styles.warning}>
            <Text bold color="contrast">
              {t([
                'MOHON SEGERA MELENGKAPI KONTAK PRINCIPAL UNTUK MASING-MASING BRAND',
                'PLEASE COMPLETE PRINCIPAL CONTACT FOR EACH BRAND AS SOON AS POSSIBLE',
              ])}
            </Text>
          </View>
        </View>
      )}

      <TokoAppContent setPrincipalIncomplete={setPrincipalIncomplete} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  headerSpacing: { paddingBottom: spacing.medium },
  warning: {
    backgroundColor: colors.text.pending,
    paddingVertical: spacing.small,
    paddingHorizontal: spacing.medium,
    width: '100%',
  },
});

export default AdminHubScene;
