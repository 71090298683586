import React, { ReactNode, memo, useMemo } from 'react';
import { Text as RNText, TextProps, StyleSheet } from 'react-native';

import { theme } from '../constants/theme';

export type Props = TextProps & {
  bold?: boolean;
  children?: ReactNode;
  color?: keyof typeof theme.colors.text;
  size?: keyof typeof theme.fonts.sizes;
};

const Text = memo((props: Props) => {
  let { bold, color = 'black', size = 's', style, ...otherProps } = props;

  const fontFamily = useMemo(() => {
    if (bold) {
      return 'InterBold';
    } else {
      return 'Inter';
    }
  }, [bold]);
  return (
    <RNText
      style={StyleSheet.flatten([
        {
          fontSize: theme.fonts.sizes[size],
          lineHeight: theme.fonts.lineHeights[size],
          color: theme.colors.text[color],
          fontFamily,
        },
        style,
      ])}
      {...otherProps}
    />
  );
});

export default Text;
