export type DeepLink = {
  pageName: string;
  key: string;
  option?: string;
  data?: string;
};

export const ListOfDeepLink: Array<DeepLink> = [
  { pageName: 'Register', key: 'Register' },
  { pageName: 'Login', key: 'Login' },
  { pageName: 'Beranda', key: 'Home' },
  { pageName: 'Perpustakaan', key: 'Library' },
  { pageName: 'Mission/Gamification', key: 'Mission' },
  { pageName: 'My Account (Invite Team)', key: 'Profile/invite' },
  { pageName: 'My Account (My Detail)', key: 'Profile/detail' },
  { pageName: 'My Account (My Brand)', key: 'Profile/brand' },
  { pageName: 'Loyalty Point', key: 'LoyaltyPoint', option: 'loyalty' },
  { pageName: 'Brand Information', key: 'BrandInfo' },
  { pageName: 'Promosi', key: 'Promotion' },
  { pageName: 'Promosi (bulan ini)', key: 'Promotion/month' },
  {
    pageName: 'Promosi',
    option: 'promotion',
    key: 'Promotion',
    data: '?id=',
  },
  { pageName: 'Promosi', key: 'Promotion', option: 'brand', data: '?brand=' },
  { pageName: `Berita (Berita, Kompetisi, dan Event)`, key: 'WhatsOn' },
  {
    pageName: 'Berita (Detail Berita)',
    option: 'news',
    key: 'WhatsOn',
    data: '?type=news&id=',
  },
  {
    pageName: 'Kompetisi (Detail Kompetisi)',
    option: 'competition',
    key: 'WhatsOn',
    data: '?type=competition&id=',
  },
  {
    pageName: 'Event (Detail Event)',
    option: 'event',
    key: 'WhatsOn',
    data: '?type=event&id=',
  },
  { pageName: 'Order', key: 'NewOrder/brandId=', option: 'order' },
];

export const ID_CATEGORY_EWALLET = '46';
