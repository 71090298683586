import React, { useState, useCallback, useEffect } from 'react';
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import { makeStyles } from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import { MAPS_API_KEY } from 'react-native-dotenv';
import { useDebouncedCallback } from 'use-debounce';

import { logo, googleMapsLogo } from '../../../assets';
import { Text, TextInput, Button } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { DateTimePicker, Modal, ErrorModal, Option } from '../../components';
import {
  toDdMmYyyy,
  to24Hh,
  getAddress,
  getPlaces,
  getCoordinates,
} from '../../helpers';

type Props = {
  //NOTE: common props
  editable?: boolean;

  //NOTE: admin facing props
  eventName: string;
  venue: string;
  maxParticipant: string;
  latitude: number | null;
  longitude: number | null;
  location: string;
  setEventName?: (value: string) => void;
  setVenue?: (value: string) => void;
  setMaxParticipant?: (value: string) => void;
  setLatitude?: (value: number) => void;
  setLongitude?: (value: number) => void;
  setLocation?: (value: string) => void;

  //NOTE: toko facing props
  eventDate: Date | null;
  participantName?: string;
  storeName?: string;
  phoneNumber?: string;
  totalAttendees?: string;
  submitFormLoading?: boolean;
  submitDisabled?: boolean;
  setParticipantName?: (value: string) => void;
  setStoreName?: (value: string) => void;
  setPhoneNumber?: (value: string) => void;
  setTotalAttendees?: (value: string) => void;
  onSubmitForm?: () => void;
};

const useStyles = makeStyles({
  root: { color: colors.icon.marker },
});

const emptyFn = () => {};
const zoom = 12;

const Marker = (_props: { lat: number | null; lng: number | null }) => {
  const classes = useStyles();
  return <PlaceIcon classes={classes} />;
};

export default function EventOnlineForm(props: Props) {
  let {
    editable,
    eventName,
    venue,
    eventDate,
    maxParticipant,
    participantName,
    storeName,
    phoneNumber,
    totalAttendees,
    latitude,
    longitude,
    location,
    submitFormLoading,
    submitDisabled,
    setEventName,
    setVenue,
    setMaxParticipant,
    setParticipantName,
    setStoreName,
    setPhoneNumber,
    setTotalAttendees,
    setLatitude,
    setLongitude,
    setLocation,
    onSubmitForm = emptyFn,
  } = props;
  let [isModalOpen, setModalOpen] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');
  let [searchText, setSearchText] = useState('');
  let [suggestions, setSuggestions] = useState<Array<Option>>([]);
  let [showSuggestions, setShowSuggestions] = useState(false);
  let [suggestionsLoading, setSuggestionsLoading] = useState(false);
  let [center, setCenter] = useState({ lat: -6.2, lng: 106.84 }); //NOTE: default center on map is Jakarta
  //NOTE: this is to minimize geocoding api being called.
  //      if user selected address from suggestions, we don't need to fetch address anymore
  let [canUseSearchText, setCanUseSearchText] = useState(false);

  //NOTE: these are used to store temporary latitude and longitude before user save location
  let [tempLatitude, setTempLatitude] = useState(latitude);
  let [tempLongitude, setTempLongitude] = useState(longitude);

  const formattedDate = eventDate && toDdMmYyyy(eventDate);
  const formattedTime = eventDate && to24Hh(eventDate);

  useEffect(() => {
    if (!!latitude && !!longitude) {
      setCenter({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  const closeMapModal = useCallback(() => {
    setModalOpen(false);
    setTempLatitude(latitude);
    setTempLongitude(longitude);
    setSearchText('');
    setCanUseSearchText(false);
  }, [setModalOpen, setTempLatitude, setTempLongitude, longitude, latitude]);

  const onMapClick = ({ lat, lng }: ClickEventValue) => {
    setTempLatitude(lat);
    setTempLongitude(lng);
    setCanUseSearchText(false);
  };

  const onSaveLocation = async () => {
    setModalOpen(false);
    setSearchText('');
    setLatitude && tempLatitude && setLatitude(tempLatitude);
    setLongitude && tempLongitude && setLongitude(tempLongitude);

    //NOTE: use search text as location if we can
    if (!!searchText && canUseSearchText) {
      setLocation && setLocation(searchText);
      return;
    }
    //NOTE: if not, call api to fetch complete address
    if (tempLatitude && tempLongitude && setLocation) {
      let result = await getAddress(tempLatitude, tempLongitude);
      if (result.success) {
        setLocation(result.address);
      } else {
        setErrorMessage(result.errorMessage);
      }
    }
  };

  const [getSuggestions] = useDebouncedCallback(async (query: string) => {
    setShowSuggestions(true);
    setSuggestionsLoading(true);
    let placesResult = await getPlaces(query);
    setSuggestionsLoading(false);
    if (
      placesResult.success &&
      placesResult.places &&
      placesResult.places.length > 0
    ) {
      setSuggestions(placesResult.places);
    } else {
      setShowSuggestions(false);
      setErrorMessage(placesResult.errorMessage);
    }
  }, 1000);

  const onSearchChange = useCallback(
    (text: string) => {
      setSearchText(text);
      setCanUseSearchText(false);
      if (text.length > 2) {
        getSuggestions(text);
      }
    },
    [getSuggestions],
  );

  const onSelectSuggestion = useCallback(async (option: Option) => {
    setSuggestionsLoading(true);
    let coordinatesResult = await getCoordinates(option.value);
    setSuggestionsLoading(false);
    setShowSuggestions(false);
    if (coordinatesResult.success) {
      setSearchText(option.label);
      setTempLatitude(coordinatesResult.coordinates.lat);
      setTempLongitude(coordinatesResult.coordinates.lng);
      setCenter(coordinatesResult.coordinates);
      setCanUseSearchText(true);
    } else {
      setErrorMessage(coordinatesResult.errorMessage);
    }
  }, []);

  const renderMap = () => {
    return (
      <Modal
        open={isModalOpen}
        onClose={closeMapModal}
        fullWidth
        title={t(['Pilih lokasi event', 'Select event location'])}
        contentStyle={styles.mapPadding}
        actions={
          <Button
            data-cy="form-olform-map-submit"
            title={t(['Simpan', 'Save'])}
            onPress={onSaveLocation}
          />
        }
      >
        <View style={styles.mapWrapper}>
          <TextInput
            data-cy="form-olform-map-search"
            value={searchText}
            onChangeText={onSearchChange}
            placeholder={t(['Cari alamat', 'Search address'])}
            containerStyle={[styles.field, styles.searchInput]}
            showSuggestions={showSuggestions}
            suggestions={suggestions}
            suggestionsLoading={suggestionsLoading}
            onSelectSuggestion={onSelectSuggestion}
          />
          <GoogleMapReact
            bootstrapURLKeys={{
              key: MAPS_API_KEY,
            }}
            center={center}
            defaultZoom={zoom}
            onClick={onMapClick}
          >
            <Marker lat={tempLatitude} lng={tempLongitude} />
          </GoogleMapReact>
        </View>
      </Modal>
    );
  };

  return (
    <View style={styles.formWrapper}>
      {renderMap()}
      <ErrorModal
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
      />
      <View style={styles.formPadding}>
        <View style={[styles.row, styles.header]}>
          <Image source={logo} resizeMode="contain" style={styles.logo} />
          <Text bold color="link" size="large">
            {t(['Daftar Event', 'Register for Event'])}
          </Text>
        </View>
        {setEventName ? (
          <TextInput
            data-cy="form-olform-name"
            label={t(['Nama Event', 'Event Name'])}
            value={eventName}
            onChangeText={setEventName}
            containerStyle={styles.field}
            disabled={!editable}
          />
        ) : (
          <View style={styles.field}>
            <Text bold style={styles.label}>
              {t(['Nama Event', 'Event Name'])}
            </Text>
            <Text style={styles.textBox}>{eventName}</Text>
          </View>
        )}
        {setVenue ? (
          <TextInput
            data-cy="form-olform-venue"
            label={t(['Tempat', 'Venue'])}
            value={venue}
            onChangeText={setVenue}
            containerStyle={styles.field}
            disabled={!editable}
          />
        ) : (
          <View style={styles.field}>
            <Text bold style={styles.label}>
              {t(['Tempat', 'Venue'])}
            </Text>
            <Text style={styles.textBox}>{venue}</Text>
          </View>
        )}
        <View style={[styles.row, styles.field]}>
          {eventDate ? (
            <View style={styles.datepicker}>
              <DateTimePicker
                selectedDate={eventDate}
                onChange={() => {}}
                disabled={true}
              />
            </View>
          ) : (
            <View>
              <Text bold style={styles.label}>
                {t(['Tanggal/Waktu', 'Date/Time'])}
              </Text>
              <View style={styles.row}>
                <Text style={styles.textBox}>{formattedDate}</Text>
                <Text style={styles.separator}>/</Text>
                <Text style={styles.textBox}>{formattedTime}</Text>
              </View>
            </View>
          )}
          {setMaxParticipant ? (
            <TextInput
              data-cy="form-olform-participant"
              label={t(['Peserta Maks.', 'Max. Participant'])}
              value={maxParticipant}
              onChangeText={setMaxParticipant}
              containerStyle={[styles.participantField, styles.flex]}
              disabled={!editable}
              type="number"
            />
          ) : (
            <View style={[styles.flex, styles.field, styles.participantField]}>
              <Text bold style={styles.label}>
                {t(['Peserta Maks.', 'Max. Participant'])}
              </Text>
              <Text style={styles.textBox}>{maxParticipant}</Text>
            </View>
          )}
        </View>
        <View style={[styles.row, styles.field]}>
          <Text bold style={styles.label}>
            {t(['Peta Lokasi', 'Map Location'])}
          </Text>
          <TouchableOpacity
            data-cy="form-olform-location-button"
            onPress={() => setModalOpen(true)}
            style={[styles.locationTextBox, styles.flex]}
            disabled={!editable}
          >
            {location ? (
              <Text>{location}</Text>
            ) : (
              <View style={styles.location}>
                <Text>{t(['Pilih Lokasi', 'Select Location'])}</Text>
                <Image
                  source={googleMapsLogo}
                  style={styles.mapLogo}
                  resizeMode="contain"
                />
              </View>
            )}
          </TouchableOpacity>
        </View>
        <TextInput
          data-cy="form-olform-user-name"
          label={t(['Nama Anda', 'Your Name'])}
          value={participantName ?? ''}
          onChangeText={setParticipantName ?? emptyFn}
          containerStyle={styles.field}
          disabled={!setParticipantName}
        />
        <View style={[styles.row, styles.field]}>
          <TextInput
            data-cy="form-olform-user-store"
            label={t(['TOKO Anda', 'Your TOKO'])}
            value={storeName ?? ''}
            onChangeText={setStoreName ?? emptyFn}
            containerStyle={styles.flex}
            disabled={!setStoreName}
          />
          <TextInput
            data-cy="form-olform-user-account"
            label={t(['Nomor Akun', 'Acct Number'])}
            value={phoneNumber ?? ''}
            onChangeText={setPhoneNumber ?? emptyFn}
            containerStyle={[styles.accNumberField, styles.flex]}
            disabled={!setPhoneNumber}
          />
        </View>
        <View style={styles.rowInputButton}>
          <TextInput
            type="number"
            data-cy="form-olform-user-attendees"
            label={t(['Jumlah yang hadir', 'How many attending?'])}
            value={totalAttendees ?? ''}
            onChangeText={setTotalAttendees ?? emptyFn}
            disabled={!setTotalAttendees}
          />
          <Button
            data-cy="form-olform-user-submit"
            title={t(['Daftar', 'Register'])}
            onPress={onSubmitForm}
            disabled={submitDisabled}
            isLoading={submitFormLoading}
          />
        </View>
      </View>
      <View style={styles.footer}>
        <Text size="xsmall" color="footer" style={styles.footerText}>
          {t(
            [
              'Tim layanan pelanggan DBO: {wa}',
              'DBO customer service team:  {wa}',
            ],
            { wa: '000 000 0000' }, // TODO: get DBO wa num
          )}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  formWrapper: {
    borderWidth: 1,
    borderColor: colors.border.primary,
  },
  formPadding: {
    padding: spacing.large,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    alignItems: 'center',
    marginBottom: spacing.large,
  },
  logo: {
    width: 80,
    height: 80,
    marginRight: spacing.medium,
  },
  field: {
    marginBottom: spacing.small,
  },
  rowInputButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  datepicker: {
    flex: 2,
  },
  participantField: {
    marginLeft: spacing.small,
  },
  accNumberField: {
    marginLeft: spacing.xsmall,
  },
  footer: {
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
  },
  footerText: {
    paddingVertical: spacing.small,
    textAlign: 'center',
  },
  textBox: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    borderRadius: 4,
    padding: spacing.xsmall,
    height: 36,
    justifyContent: 'center',
  },
  label: {
    marginBottom: spacing.xsmall,
    marginRight: spacing.small,
  },
  separator: {
    marginHorizontal: spacing.xxsmall,
  },
  mapLogo: {
    width: 24,
    height: 24,
  },
  location: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  locationTextBox: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    borderRadius: 4,
    padding: spacing.xsmall,
    minHeight: 36,
    justifyContent: 'center',
  },
  mapWrapper: {
    height: '65vh',
    width: '100%',
  },
  mapPadding: {
    paddingLeft: spacing.large,
    paddingRight: spacing.large,
  },
  searchInput: {
    zIndex: 99,
  },
});
