import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundScene } from '../scenes';

import { unauthenticatedRoutes } from './routes';

export default function UnauthenticatedRoute() {
  return (
    <Switch>
      {unauthenticatedRoutes.map((route, index) => {
        return <Route key={index} {...route} />;
      })}
      <Route component={NotFoundScene} />
    </Switch>
  );
}
