import React, { memo } from 'react';
import { View, StyleSheet, Image, ViewProps } from 'react-native';
import { ApolloError } from 'apollo-client';

import { errorIcon } from '../../assets';
import { Text, Button } from '../core-ui';
import { theme } from '../constants/theme';
import { spacing } from '../features/verification/constants/theme';
import { CustomNetworkError } from '../types/errorTypes';

type Props = {
  action?: string;
  error?: ApolloError;
  message?: string;
  buttonTitle?: string;
  style?: ViewProps['style'];
  onPress?: () => void;
};

const ErrorMessage = memo((props: Props) => {
  let {
    action = '',
    error,
    message,
    buttonTitle = t(['Coba lagi', 'Retry']),
    style,
    onPress,
  } = props;

  let messageToDisplay = '';
  if (error?.networkError?.name === 'TypeError' && !navigator.onLine) {
    // NOTE: "ServerError" with invalid GraphQL Request, "TypeError" with wifi off, unsure about other cases
    // NOTE: network error takes precedence
    messageToDisplay = t([
      'Oops, terjadi kesalahan jaringan. Mohon periksa jaringan Anda dan coba lagi.',
      'Oops, a network error occurred. Please check your connection and try again.',
    ]);
  } else if (
    (error?.networkError as CustomNetworkError)?.result?.errors.find(
      (item: { message: string }) =>
        item.message.includes('Phone Number must start with 08'),
    )
  ) {
    // NOTE: check if error message contains "Phone Number must start with 08"
    return <Text style={styles.empty}>Tidak ada data</Text>;
  } else if (message) {
    // NOTE: custom error message is next
    messageToDisplay = message;
  } else {
    // NOTE: otherwise use default error message with optional action information
    messageToDisplay = t(
      [
        'Oops, terjadi kesalahan{prefix}{action}. Mohon coba lagi nanti.',
        'Oops, something went wrong{prefix}{action}. Please try again later.',
      ],
      {
        prefix: !!action ? t([' saat ', ' while trying to ']) : '',
        action,
      },
    );
  }

  return (
    <View style={[styles.root, style]}>
      <View style={styles.bottomSpacing}>
        <Image source={errorIcon} style={styles.icon} />
      </View>
      <Text style={styles.message}>{messageToDisplay}</Text>
      {onPress && (
        <Button preset="transparent" title={buttonTitle} onPress={onPress} />
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  root: { alignItems: 'center' },
  bottomSpacing: { paddingBottom: theme.spacing.small },
  message: { textAlign: 'center', paddingBottom: theme.spacing.small },
  empty: { textAlign: 'center', marginTop: spacing.l },
  icon: { width: 56, height: 50 },
});

export default ErrorMessage;
