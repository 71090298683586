import gql from 'graphql-tag';

export const GET_PROVINCES = gql`
  query Provinces {
    provinces {
      id
      name
    }
  }
`;
