import React, { useState, useCallback, ReactNode, useMemo, memo } from 'react';
import { StyleSheet } from 'react-native';
import {
  ButtonBase,
  Popover,
  makeStyles,
  SvgIconProps,
  PopoverProps,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { theme, colors, fonts } from '../constants/theme';

import Text from './Text';

const useStyles = makeStyles({
  paper: {
    backgroundColor: colors.popover.primary.background,
    padding: `${theme.spacing.small}px`,
    maxWidth: 360,
  },
  smallButton: { height: 19 },
  smallIcon: { fontSize: fonts.sizes.medium, height: 19 },
});

type Props = {
  icon?: ReactNode;
  size?: SvgIconProps['fontSize'];
  popoverProps?: Partial<PopoverProps>;
  children?: ReactNode;
};

const Infotip = memo((props: Props) => {
  let { icon, size = 'default', popoverProps, children } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const onPress = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    [setAnchorEl],
  );

  const wrappedChildren = useMemo(
    () =>
      typeof children === 'string' ? (
        <Text color="contrast" style={styles.content}>
          {children}
        </Text>
      ) : (
        children
      ),
    [children],
  );

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={onPress}
        classes={{ root: size === 'small' ? classes.smallButton : undefined }}
      >
        <Text color="link">
          {icon || (
            <InfoIcon
              fontSize={size}
              color="inherit"
              classes={{ fontSizeSmall: classes.smallIcon }}
            />
          )}
        </Text>
      </ButtonBase>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        elevation={2}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        onClose={onClose}
        {...popoverProps}
      >
        {wrappedChildren}
      </Popover>
    </>
  );
});

const styles = StyleSheet.create({
  content: { lineHeight: 24 },
});

export default Infotip;
