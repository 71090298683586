import gql from 'graphql-tag';

import { LIBRARY_DOCUMENT_FRAGMENT } from '../fragments';

export const LIBRARY_DOCUMENTS = gql`
  query LibraryDocuments(
    $brandID: ID
    $archived: ArchiveStatus
    $first: Int
    $skip: Int
    $orderBy: DocumentAdvanceSearchOrderByInput
  ) {
    documentAdvanceSearch(
      brandId: $brandID
      archiveStatus: $archived
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
    ) {
      row {
        ...LibraryDocument
      }
      total
    }
  }
  ${LIBRARY_DOCUMENT_FRAGMENT}
`;

export const LIBRARY_DOCUMENT = gql`
  query LibraryDocument($id: String) {
    document(where: { id: $id }) {
      ...LibraryDocument
    }
  }
  ${LIBRARY_DOCUMENT_FRAGMENT}
`;
