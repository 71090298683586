import React, { CSSProperties, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dialog, DialogActions } from '@material-ui/core';

import { Button, Text, TextInput } from '../../core-ui';
import { fonts, spacing } from '../../constants/theme';
import { RadioGroup } from '../../components';

type Props = {
  open: boolean;
  rejectReason: string;
  onSetRejectReason: (value: string) => void;
  onClose: () => void;
  onPressSubmit: () => void;
};

const requiredOptions = [
  {
    value: 0,
    label: <Text>Toko Demo</Text>,
  },
  {
    value: 1,
    label: <Text>Toko Tidak Masuk Area Coverage Distributor</Text>,
  },
  {
    value: 2,
    label: <Text>Lainnya (Isi Alasan Dibawah)</Text>,
  },
];

const RejectReasonModal = (props: Props) => {
  let { open, rejectReason, onSetRejectReason, onClose, onPressSubmit } = props;

  const [selectedOption, setSelectedOption] = useState(0);
  const [customRejectReason, setCustomRejectReason] = useState('');

  useEffect(
    () => onSetRejectReason(requiredOptions[0].label.props.children),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <View style={styles.container}>
        <Text style={styles.title} bold>
          Alasan Reject?
        </Text>
        <RadioGroup
          options={requiredOptions}
          selectedOption={selectedOption}
          setSelectedOption={(value) => {
            setSelectedOption(value);
            value !== 2 &&
              onSetRejectReason(requiredOptions[value].label.props.children);
          }}
          containerStyle={styles.radioGroupContainerStyle}
        />
        <TextInput
          multiline
          rows={3}
          placeholder="Alasan reject"
          value={customRejectReason}
          onChangeText={(value) => {
            setCustomRejectReason(value);
            onSetRejectReason(value);
          }}
          containerStyle={styles.textInputContainerStyle}
          disabled={selectedOption !== 2}
        />
      </View>
      <DialogActions
        style={StyleSheet.flatten(styles.actions) as CSSProperties}
      >
        <Button
          preset="secondary"
          title="Batal"
          style={styles.button}
          onPress={onClose}
        />
        <Button
          title="Submit"
          style={styles.button}
          onPress={onPressSubmit}
          disabled={
            !rejectReason || (selectedOption === 2 && !customRejectReason)
          }
        />
      </DialogActions>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: spacing.medium,
  },
  title: {
    fontSize: fonts.sizes.medium,
  },
  radioGroupContainerStyle: {
    marginTop: spacing.small,
  },
  textInputContainerStyle: {
    marginLeft: 50,
    marginTop: spacing.xxsmall,
  },
  actions: {
    paddingTop: spacing.xxsmall,
    paddingRight: spacing.medium,
    paddingBottom: spacing.medium,
  },
  button: {
    width: 96,
  },
});

export default RejectReasonModal;
