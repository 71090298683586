import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { Option, Header, HeaderNavigationBar } from '../../components';
import { FormType } from '../../components/dropdowns/FormLinkDropdown';
import { spacing } from '../../constants/theme';
import { CompetitionFormFragment } from '../../generated/CompetitionFormFragment';
import { EventFormFragment } from '../../generated/EventFormFragment';
import { pageTitle } from '../../constants/pageTitle';

import CreateOnlineForm from './CreateOnlineForm';
import OnlineFormSearch from './OnlineFormSearch';
import OnlineFormTable from './OnlineFormTable';

const OnlineFormScene = () => {
  const [formType, setFormType] = useState<FormType | null>(null);
  const [formLink, setFormLink] = useState('');
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [
    editCompetition,
    setEditCompetition,
  ] = useState<CompetitionFormFragment | null>(null);
  const [editEvent, setEditEvent] = useState<EventFormFragment | null>(null);
  // TODO: edit Information Request form
  // const [editInformation, setEditInformation] = useState<InformationFormFragment | null>(null);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.OnlineFormScene });
    };
    eventLog();
  }, []);

  useEffect(() => {
    if (editCompetition) {
      setFormLink(editCompetition.link);
    } else if (editEvent) {
      setFormLink(editEvent.link);
    } else {
      setFormLink('');
    }
  }, [editCompetition, editEvent]);

  const formsHeader = useMemo(() => {
    switch (formType) {
      case 'competition':
        return t(['Kompetisi', 'Comeptitions']);
      case 'event':
        return t(['Event', 'Event']);
      case 'information':
        return t(['Permintaan Informasi', 'Information Requeset']);
    }
  }, [formType]);

  const setSelectedFormType = useCallback((option: Option | null) => {
    if (option && option.value === 'event') {
      setEditCompetition(null);
      // setEditInformation(null);
    } else if (option && option.value === 'competition') {
      setEditEvent(null);
      // setEditInformation(null);
    } else {
      setEditCompetition(null);
      setEditEvent(null);
    }
    setFormType((option?.value as FormType) ?? null);
  }, []);

  const onSubmit = useCallback(() => {
    setRefetchToggle(!refetchToggle);
  }, [refetchToggle]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header
        title={t(['Formulir', 'Forms'])}
        subtitle={t(['Formulir Online', 'Online Forms'])}
        subtitleColor="default"
        style={styles.header}
      />
      <View style={styles.section}>
        <CreateOnlineForm
          selectedFormType={formType}
          setSelectedFormType={setSelectedFormType}
          formLink={formLink}
          setFormLink={setFormLink}
          onSubmitCompleted={onSubmit}
          editCompetition={editCompetition}
          editEvent={editEvent}
          // editInformation={editInformation}
        />
      </View>
      <View style={styles.section}>
        {!!formType && formType !== 'information' && (
          <Header
            withSeparator
            title={t(['Formulir', 'Forms'])}
            subtitle={formsHeader}
          />
        )}
        <OnlineFormTable
          data-cy="olform-table"
          type={formType}
          refetchToggle={refetchToggle}
          onSubmitCompleted={onSubmit}
          setEditCompetition={setEditCompetition}
          setEditEvent={setEditEvent}
        />
      </View>
      <OnlineFormSearch
        type={formType}
        refetchToggle={refetchToggle}
        onSubmitCompleted={onSubmit}
        setEditCompetition={setEditCompetition}
        setEditEvent={setEditEvent}
        // setEditInformation={setEditInformation}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  header: {
    marginTop: spacing.xsmall,
    marginBottom: spacing.xlarge,
  },
  section: { paddingBottom: spacing.xlarge },
});

export default OnlineFormScene;
