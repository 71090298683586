import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import _ from 'lodash';
import { MaterialIcons } from '@expo/vector-icons';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlined from '@material-ui/icons/KeyboardArrowUpOutlined';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import SubjectIcon from '@material-ui/icons/Subject';

import { Button, Text, TextInput } from '../../core-ui';
import { VerificationRequests_requestVerificationAdvance_row as Data } from '../../../../generated/VerificationRequests';
import { colors, spacing } from '../../constants/theme';
import {
  storeValidation as optionsStoreValidation,
  validVisitation as optionsValidVisitation,
} from '../../constants/storeValidation';
import {
  AccessUser,
  RequestAgentVerificationUpdateInput,
  Role,
  StatusActive,
  StatusVerify,
  SubUserUpdateInput,
  StoreInputData,
  StatusType,
  PhoneCategory,
  StorePhotoCategory,
} from '../../../../generated/globalTypes';
import { UPDATE_REQUEST_VERIFICATION_STORE } from '../../graphql/mutations';
import {
  UpdateRequestVerificationStore,
  UpdateRequestVerificationStoreVariables,
} from '../../../../generated/UpdateRequestVerificationStore';
import {
  GET_UPDATED_STORE_HISTORY,
  REQUEST_MATCHMAKING,
} from '../../graphql/queries';
import {
  checkPhoneNumber,
  checkPhoneNumberFormat,
  toPascalCase,
} from '../../helpers';
import {
  CityDropdown,
  DistrictDropdown,
  Dropdown,
  ProvinceDropdown,
  StoreCategoryDropdown,
  VillageDropdown,
  RoleDropdown,
  SegmentedControl,
} from '../../components';
import { Checkbox, Switch } from '../../../../core-ui';
import {
  GetUpdatedStoreHistory,
  GetUpdatedStoreHistoryVariables,
} from '../../../../generated/GetUpdatedStoreHistory';
import {
  RequestMatchMaking,
  RequestMatchMakingVariables,
} from '../../../../generated/RequestMatchMaking';
import validateEmail from '../../../../helpers/validateEmail';
import { useSnackbar } from '../../context';
import { Modal } from '../../../../components';
import { getFormattedSentenceDate } from '../../../../helpers/formatDate';
import {
  emptyUnverifiedCategories,
  nextActions,
  phoneCategories,
  phoneNotPickedUpProblems,
  phonePickedUpProblems,
  photoCategories,
  photoFrontProblems,
  photoInsideProblems,
  typeCategories,
} from '../../constants/categoryUnverified';
import { tokoStatus } from '../../constants/tokoStatus';

import { Collapsible } from './Collapsible';
import TokoCollapsedItem from './TokoCollapsedItem';
import {
  EmployeeStoreLogChanges,
  StoreLogChangesRespond,
  UnverifiedCategory,
} from './types';

const nameRegex = /^[a-zA-Z\s]+$/;

type Props = {
  onClose: () => void;
  data: Data;
  viewMode: boolean;
  refetchData: () => void;
};

type MatchMaking = RequestAgentVerificationUpdateInput & {
  brandName: string;
  selectedDistributorName: string;
  agentSelectName?: string;
};

type FormEmployee = Omit<SubUserUpdateInput, 'status'> & {
  status: StatusActive | null;
};

const TokoSuperEditForm = memo<Props>(
  ({ viewMode, data, refetchData, onClose }) => {
    const { openSnackbar } = useSnackbar();

    const [submitError, setSubmitError] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = useCallback(() => {
      setModalOpen(true);
    }, []);
    const closeModal = useCallback(() => {
      setModalOpen(false);
    }, []);

    const generateDetailUnverified = (
      category: StorePhotoCategory | PhoneCategory | null,
    ) => {
      if (!category) {
        return '';
      }

      switch (category) {
        case StorePhotoCategory.STORE_PHOTO_FRONT:
          return photoCategories[0].value;
        case StorePhotoCategory.STORE_PHOTO_INSIDE:
          return photoCategories[1].value;
        case PhoneCategory.ANSWERED:
          return phoneCategories[0].value;
        case PhoneCategory.NOT_ANSWERED:
          return phoneCategories[1].value;
      }
    };

    const [selectedTab, setSelectedTab] = useState<'FORM' | 'RIWAYAT'>('FORM');

    const storeStatusOptions = tokoStatus;
    const storeStatusOptionTexts = storeStatusOptions.map(toPascalCase);

    const [matchMakingArray, setMatchMakingArray] = useState<
      Array<MatchMaking>
    >([]);
    const [storeName, setStoreName] = useState(
      data.store?.storeManagerBulk?.storeName,
    );
    const [storePhoneNumber, setStorePhoneNumber] = useState(
      data.store?.storeManagerBulk?.storePhoneNumber ?? '',
    );
    const [ownerPhone, setOwnerPhone] = useState(
      data.store?.User?.find((user) => user.role === Role.TOKO_OWNER)
        ?.phoneNumber ?? '',
    );
    const [storeEmail, setStoreEmail] = useState(
      data.store?.storeManagerBulk?.storeEmail,
    );
    const [storeCategoryId, setStoreCategoryId] = useState(
      data.store?.storeManagerBulk?.storeCategoryId ?? '',
    );
    const [storeAddress, setStoreAddress] = useState(
      data.store?.storeManagerBulk?.address,
    );
    const [urbanVillageId, setUrbanVillageId] = useState(
      data.store?.storeManagerBulk?.villageId ?? '',
    );
    const [districtId, setDistrictId] = useState(
      data.store?.storeManagerBulk?.districtId ?? '',
    );
    const [cityId, setCityId] = useState(
      data.store?.storeManagerBulk?.cityId ?? '',
    );
    const [provinceId, setProvinceId] = useState(
      data.store?.storeManagerBulk?.provinceId ?? '',
    );
    const [rt, setRT] = useState(data.store?.rt ?? '00');
    const [rw, setRW] = useState(data.store?.rw ?? '00');
    const [postalCode, setPostalCode] = useState(
      data.store?.storeManagerBulk?.postalCode,
    );
    const [storeStatus, setStoreStatus] = useState<boolean>(
      !!data.store?.User?.some((user) => user.status === StatusActive.ACTIVE),
    );
    const [employees, setEmployees] = useState<Array<FormEmployee>>(
      data.store?.User?.map(({ id, profile, role, accessUser, status }) => ({
        id: id,
        name: profile?.name || '',
        role: role,
        email: profile?.email || '',
        privileges: accessUser,
        status: status,
      })) ?? [],
    );

    const [unverifiedCategories, setUnverifiedCategories] = useState<
      Array<UnverifiedCategory>
    >(
      data.unverifiedReasonData?.map(
        ({
          nextAction,
          problemReason,
          otherReason,
          storePhotoCategory,
          phoneCategory,
        }) => ({
          type: !!storePhotoCategory ? 'Foto' : 'Telepon',
          detail: generateDetailUnverified(phoneCategory ?? storePhotoCategory),
          problem: otherReason ? 'Lainnya' : problemReason,
          customProblem: otherReason || '',
          action: nextAction || '',
        }),
      ) ?? [emptyUnverifiedCategories],
    );

    const onResetState = () => {
      setStoreName('');
      setStorePhoneNumber('');
      setOwnerPhone('');
      setStoreEmail('');
      setStoreCategoryId('');
      setStoreAddress('');
      setUrbanVillageId('');
      setDistrictId('');
      setCityId('');
      setProvinceId('');
      setRT('00');
      setRW('00');
      setPostalCode('');
      setStoreStatus(false);
      setEmployees([]);
      resetUnverrifiedCategories();
    };

    let isChanged = useCallback(() => {
      if (
        storeName !== '' &&
        storeName !== data.store?.storeManagerBulk?.storeName
      ) {
        return true;
      }
      if (
        storePhoneNumber !== '' &&
        storePhoneNumber !== data.store?.storeManagerBulk?.storePhoneNumber
      ) {
        return true;
      }
      if (
        storeEmail !== '' &&
        storeEmail !== data.store?.storeManagerBulk?.storeEmail
      ) {
        return true;
      }
      if (
        storeCategoryId !== '' &&
        storeCategoryId !== data.store?.storeManagerBulk?.storeCategoryId
      ) {
        return true;
      }
      if (
        storeAddress !== '' &&
        storeAddress !== data.store?.storeManagerBulk?.address
      ) {
        return true;
      }
      if (
        urbanVillageId !== '' &&
        urbanVillageId !== data.store?.storeManagerBulk?.villageId
      ) {
        return true;
      }
      if (
        districtId !== '' &&
        districtId !== data.store?.storeManagerBulk?.districtId
      ) {
        return true;
      }
      if (cityId !== '' && cityId !== data.store?.storeManagerBulk?.cityId) {
        return true;
      }
      if (
        provinceId !== '' &&
        provinceId !== data.store?.storeManagerBulk?.provinceId
      ) {
        return true;
      }
      if (rt !== '' && rt !== data.store?.rt) {
        return true;
      }
      if (rw !== '' && rw !== data.store?.rw) {
        return true;
      }
      if (
        postalCode !== '' &&
        postalCode !== data.store?.storeManagerBulk?.postalCode
      ) {
        return true;
      }
      if (
        storeStatus !==
        !!data.store?.User?.some((user) => user.status === StatusActive.ACTIVE)
      ) {
        return true;
      }

      if (
        employees.some(
          ({ name, privileges, status }, i) =>
            name !== data.store?.User?.[i].profile?.name ||
            privileges.length !== data.store.User?.[i].accessUser.length ||
            !_.isEmpty(_.xor(privileges, data.store.User?.[i].accessUser)) ||
            status !== data.store.User?.[i].status,
        )
      ) {
        return true;
      }

      if (
        unverifiedCategories.some(
          ({ type, detail, problem, customProblem, action }, i) => {
            if (data.unverifiedReasonData) {
              const {
                nextAction,
                problemReason,
                otherReason,
                storePhotoCategory,
                phoneCategory,
              } = data.unverifiedReasonData[i];

              if (
                (storePhotoCategory && type !== 'Foto') ||
                (phoneCategory && type !== 'Telepon')
              ) {
                return true;
              }
              if (
                detail !==
                generateDetailUnverified(phoneCategory ?? storePhotoCategory)
              ) {
                return true;
              }
              if (
                (otherReason &&
                  (problem !== 'Lainnya' || otherReason !== customProblem)) ||
                (!otherReason && problem !== problemReason)
              ) {
                return true;
              }
              if (action !== nextAction) {
                return true;
              }
            }
            return false;
          },
        )
      ) {
        return true;
      }

      return false;
    }, [
      data,
      storeName,
      storePhoneNumber,
      storeEmail,
      storeCategoryId,
      storeAddress,
      urbanVillageId,
      districtId,
      cityId,
      provinceId,
      rt,
      rw,
      postalCode,
      storeStatus,
      employees,
      unverifiedCategories,
    ]);

    let isDisabled = (): boolean => {
      if (isChanged()) {
        let emailValid = !!storeEmail ? validateEmail(storeEmail) : true;
        let phoneValid = !!storePhoneNumber
          ? !checkPhoneNumberFormat(storePhoneNumber)
          : !checkPhoneNumberFormat(
              data.store?.storeManagerBulk?.storePhoneNumber || '',
            );
        let nameValid = employees.every((employee) =>
          nameRegex.test(employee.name),
        );

        return !(emailValid && phoneValid && nameValid);
      }
      return true;
    };

    let disabledInput = useMemo(
      () => data.status === StatusVerify.REVALIDATION,
      [data.status],
    );

    let [getMatchMaking] = useLazyQuery<
      RequestMatchMaking,
      RequestMatchMakingVariables
    >(REQUEST_MATCHMAKING, {
      variables: { storeId: data.storeId ?? '', isVerified: viewMode },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted: (res) => {
        let matchArray = res.requestMatchMaking.map(
          ({ agentName, brandId, id, brand: { name } }) => {
            return {
              agentId: null,
              agentName: agentName || '-',
              brandId,
              requestAgentId: id,
              selectedDistributorName: '-',
              brandName: name || '-',
            };
          },
        );
        setMatchMakingArray(matchArray);
      },
    });

    let onChangeEmployeeData = (
      idx: number,
      property: keyof FormEmployee,
      value: unknown,
    ) => {
      let newEmployee = employees.map((e, index) =>
        idx === index ? { ...e, [property]: value } : e,
      );
      setEmployees(newEmployee);
    };

    let onChangeRTRW = (
      value: string,
      setState: React.Dispatch<React.SetStateAction<string>>,
    ) => {
      const regex = /^\d{0,2}$/;
      if (regex.test(value)) {
        setState(value);
      }
    };

    let resetUnverrifiedCategories = () =>
      setUnverifiedCategories([emptyUnverifiedCategories]);

    let onChangeUnverifiedCategories = (
      value: string,
      field: keyof UnverifiedCategory,
      index: number,
    ) => {
      const newUnverifCategories = unverifiedCategories.map(
        (unverifiedCategory, idx) => {
          if (idx === index) {
            if (field === 'type') {
              return {
                ...emptyUnverifiedCategories,
                type: value,
              };
            }

            if (field === 'problem' && value !== 'Lainnya') {
              return {
                ...unverifiedCategory,
                problem: value,
                action:
                  nextActions.find((item) => item.problem === value)?.action ||
                  '',
              };
            }

            return {
              ...unverifiedCategory,
              [field]: value,
            };
          }

          return unverifiedCategory;
        },
      );

      setUnverifiedCategories(newUnverifCategories);
    };

    let deleteUnverifiedCategories = (index: number) => {
      const newUnverifCategories = unverifiedCategories.filter(
        (_, idx) => idx !== index,
      );
      setUnverifiedCategories(newUnverifCategories);
    };

    let getProblemFromCategories = (category: string) => {
      switch (category) {
        case 'Foto Bagian Dalam':
          return photoInsideProblems;
        case 'Foto Tampak Depan':
          return photoFrontProblems;
        case 'Telepon diangkat':
          return phonePickedUpProblems;
        case 'Telepon tidak diangkat':
          return phoneNotPickedUpProblems;
        default:
          return [];
      }
    };

    const [updateStoreData, { loading }] = useMutation<
      UpdateRequestVerificationStore,
      UpdateRequestVerificationStoreVariables
    >(UPDATE_REQUEST_VERIFICATION_STORE, {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setSubmitError(false);
        onResetState();
        openSnackbar({
          variant: 'success',
          message: t(['Data telah diperbarui.', 'Data has been updated.']),
        });
        refetchData();
        closeModal();
        onClose();
      },
      onError: () => {
        setSubmitError(true);
      },
    });

    let updateData = () => {
      const storeData: StoreInputData = {
        storeName,
        storePhoneNumber,
        storeCategoryId,
        storeEmail,
        storeAddress,
        provinceId,
        cityId,
        districtId,
        urbanVillageId,
        rt: !!rt ? ('0' + rt).slice(-2) : '00',
        rw: !!rw ? ('0' + rw).slice(-2) : '00',
        postalCode,
        latitude: data.store?.storeManagerBulk?.latitude,
        longitude: data.store?.storeManagerBulk?.longitude,
        website: data.store?.website,
        employee: employees.map((employee) => ({
          ...employee,
          status:
            employee.status === StatusActive.ACTIVE
              ? StatusType.ACTIVE
              : StatusType.INACTIVE,
        })),
      };

      let unverifiedReason = unverifiedCategories.map(
        ({ problem, detail, action, customProblem }) => {
          return {
            nextAction: action,
            phoneType:
              detail === 'Telepon diangkat'
                ? PhoneCategory.ANSWERED
                : detail === 'Telepon tidak diangkat'
                ? PhoneCategory.NOT_ANSWERED
                : null,
            storePhotoType:
              detail === 'Foto Tampak Depan'
                ? StorePhotoCategory.STORE_PHOTO_FRONT
                : detail === 'Foto Bagian Dalam'
                ? StorePhotoCategory.STORE_PHOTO_INSIDE
                : null,
            reason: problem === 'Lainnya' ? null : problem,
            otherReason: problem === 'Lainnya' ? customProblem : null,
          };
        },
      );

      updateStoreData({
        variables: {
          verificationId: data.id,
          status: null,
          existingStoreCode:
            storeStatusOptions.findIndex((status) => status === data.status) ===
            2
              ? data.store?.storeManagerBulk?.storeCode
              : undefined,
          requestAgentData: {
            create: null,
            update: matchMakingArray.map(
              ({ agentName, brandId, requestAgentId }) => ({
                agentName,
                brandId,
                requestAgentId,
              }),
            ),
          },
          storeData,
          unverifiedReason,
        },
        refetchQueries: [
          {
            query: GET_UPDATED_STORE_HISTORY,
            variables: { verificationId: data.id },
          },
        ],
      });
    };

    const { data: historyData } = useQuery<
      GetUpdatedStoreHistory,
      GetUpdatedStoreHistoryVariables
    >(GET_UPDATED_STORE_HISTORY, {
      variables: { verificationId: data.id },
    });

    useEffect(() => {
      getMatchMaking();
    }, [data, getMatchMaking]);

    useEffect(() => {
      if (data) {
        setStoreName(data.store?.storeManagerBulk?.storeName);
        setStorePhoneNumber(
          data.store?.storeManagerBulk?.storePhoneNumber ?? '',
        );
        setOwnerPhone(
          data.store?.User?.find((user) => user.role === Role.TOKO_OWNER)
            ?.phoneNumber ?? '',
        );
        setStoreEmail(data.store?.storeManagerBulk?.storeEmail);
        setStoreCategoryId(data.store?.storeManagerBulk?.storeCategoryId ?? '');
        setStoreAddress(data.store?.storeManagerBulk?.address);
        setUrbanVillageId(data.store?.storeManagerBulk?.villageId ?? '');
        setDistrictId(data.store?.storeManagerBulk?.districtId ?? '');
        setCityId(data.store?.storeManagerBulk?.cityId ?? '');
        setProvinceId(data.store?.storeManagerBulk?.provinceId ?? '');
        setRT(data.store?.rt ?? '00');
        setRW(data.store?.rw ?? '00');
        setPostalCode(data.store?.storeManagerBulk?.postalCode);
        setStoreStatus(
          !!data.store?.User?.some((u) => u.status === StatusActive.ACTIVE),
        );
        setEmployees(
          data.store?.User?.map((u) => ({
            id: u.id,
            name: u.profile?.name || '',
            role: u.role,
            email: u.profile?.email || '',
            privileges: u.accessUser,
            status: u.status,
          })) ?? [],
        );
        setUnverifiedCategories(
          data.unverifiedReasonData?.map(
            ({
              nextAction,
              problemReason,
              otherReason,
              storePhotoCategory,
              phoneCategory,
            }) => ({
              type: !!storePhotoCategory ? 'Foto' : 'Telepon',
              detail: generateDetailUnverified(
                phoneCategory ?? storePhotoCategory,
              ),
              problem: otherReason ? 'Lainnya' : problemReason,
              customProblem: otherReason || '',
              action: nextAction || '',
            }),
          ) ?? [emptyUnverifiedCategories],
        );
      }
    }, [data]);

    useEffect(() => {
      if (
        employees.every((employee) => employee.status === StatusActive.ACTIVE)
      ) {
        setStoreStatus(true);
      } else if (
        employees.every((employee) => employee.status === StatusActive.INACTIVE)
      ) {
        setStoreStatus(false);
      }
    }, [employees]);

    return (
      <>
        <View style={styles.root}>
          <View style={styles.header}>
            <View style={styles.row}>
              <TouchableOpacity
                data-cy="header-nav-list"
                style={[
                  styles.tab,
                  selectedTab === 'FORM' ? styles.selectedTab : {},
                ]}
                onPress={() => setSelectedTab('FORM')}
              >
                <Text
                  color={selectedTab === 'FORM' ? 'black' : 'grey'}
                  bold
                  size="m"
                >
                  Form
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                data-cy="header-nav-wip"
                style={[
                  styles.tab,
                  selectedTab === 'RIWAYAT' ? styles.selectedTab : {},
                ]}
                onPress={() => setSelectedTab('RIWAYAT')}
              >
                <Text
                  color={selectedTab === 'RIWAYAT' ? 'black' : 'grey'}
                  bold
                  size="m"
                >
                  Riwayat
                </Text>
              </TouchableOpacity>
            </View>
          </View>

          {selectedTab === 'FORM' ? (
            <View>
              <SectionContainer>
                <Text bold size="m" style={styles.lBottomSpacing}>
                  Request Matchmaking
                </Text>

                <View>
                  <View style={styles.row}>
                    <View style={styles.flex}>
                      <Text bold style={styles.xxsBottomSpacing}>
                        Nama Brand
                      </Text>
                    </View>
                    <View style={styles.flex}>
                      <Text bold style={styles.xxsBottomSpacing}>
                        Agent Name (Distributor)
                      </Text>
                    </View>
                  </View>

                  {matchMakingArray.map((m, i) => (
                    <View style={styles.row} key={i}>
                      <View style={styles.flex}>
                        <Text>{m.brandName || '-'}</Text>
                      </View>
                      <View style={styles.flex}>
                        <Text>{m.agentName || '-'}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </SectionContainer>

              <SectionContainer>
                <RowContainer>
                  <Text bold size="m" style={styles.flex}>
                    Informasi Toko
                  </Text>
                  <Text
                    bold
                    size="m"
                    style={[styles.flex, styles.textAlignRight]}
                  >
                    {data.store?.storeManagerBulk?.storeCode}
                  </Text>
                </RowContainer>

                <TextInput
                  containerStyle={styles.lBottomSpacing}
                  value={storeName}
                  onChangeText={setStoreName}
                  label="Nama Toko"
                  required
                  disabled={disabledInput}
                />

                <RowContainer>
                  <TextInput
                    style={{ width: 250 }}
                    value={storePhoneNumber}
                    onChangeText={setStorePhoneNumber}
                    error={checkPhoneNumberFormat(storePhoneNumber)}
                    label="Telepon Toko"
                    helperText={
                      storePhoneNumber !== ''
                        ? !checkPhoneNumber(storePhoneNumber)
                          ? t([
                              'No. HP yang Anda masukkan tidak sesuai format (e.g. 085156263898)',
                              'Phone Number is in the wrong format (e.g. 085156263898)',
                            ])
                          : storePhoneNumber.length < 8
                          ? t(['Minimal 8 digit angka', 'Min. 8 numbers'])
                          : storePhoneNumber.length > 14
                          ? t(['Maksimal 14 angka', 'Max. 14 numbers'])
                          : ''
                        : ''
                    }
                    required
                    disabled={disabledInput}
                  />
                  <TextInput
                    style={{ width: 250 }}
                    value={ownerPhone}
                    onChangeText={setOwnerPhone}
                    label="Telepon Pengguna"
                    disabled
                  />
                </RowContainer>

                <StoreCategoryDropdown
                  containerStyle={styles.lBottomSpacing}
                  value={storeCategoryId}
                  onChange={(value) => setStoreCategoryId(value)}
                  label="Kategori Toko"
                  required
                  disabled={disabledInput}
                />

                <View style={styles.lBottomSpacing}>
                  <TextInput
                    value={storeEmail}
                    onChangeText={setStoreEmail}
                    label="Email Toko (opsional)"
                    disabled={disabledInput}
                  />
                  {!!storeEmail && !validateEmail(storeEmail) && (
                    <Text size={'xs'} color="red">
                      Tolong gunakan format email address yang benar
                    </Text>
                  )}
                </View>

                <TextInput
                  containerStyle={styles.lBottomSpacing}
                  value={data.store?.website}
                  onChangeText={() => {}}
                  label="Situs (opsional)"
                  disabled
                />

                <TextInput
                  containerStyle={styles.lBottomSpacing}
                  value={
                    data.validVisitation
                      ? `${data.validasiToko} | ${data.validVisitation}`
                      : data.validasiToko
                  }
                  onChangeText={() => {}}
                  label="Kategori Validasi (opsional)"
                  disabled
                />

                <RowContainer>
                  <Dropdown
                    containerStyle={{ width: 250 }}
                    onChange={() => {}}
                    label="Validasi Toko"
                    value={data.validasiToko}
                    options={optionsStoreValidation}
                    disabled
                  />
                  <Dropdown
                    containerStyle={{ width: 250 }}
                    onChange={() => {}}
                    label="Valid Visitation"
                    value={data.validVisitation}
                    options={optionsValidVisitation}
                    disabled
                  />
                </RowContainer>
              </SectionContainer>

              <SectionContainer>
                <Text bold size="m" style={styles.lBottomSpacing}>
                  Alamat
                </Text>

                <TextInput
                  containerStyle={styles.lBottomSpacing}
                  value={storeAddress}
                  onChangeText={setStoreAddress}
                  label="Alamat"
                  required
                  disabled={disabledInput}
                />

                <RowContainer>
                  <ProvinceDropdown
                    containerStyle={styles.w30}
                    label="Provinsi"
                    value={provinceId}
                    onChange={(value) => setProvinceId(value)}
                    required
                    disabled={disabledInput}
                  />
                  <CityDropdown
                    containerStyle={styles.w30}
                    label="Kabupaten/Kota"
                    value={cityId}
                    provinceID={provinceId}
                    onChange={(value) => setCityId(value)}
                    required
                    disabled={disabledInput}
                  />
                  <DistrictDropdown
                    containerStyle={styles.w30}
                    label="Kecamatan"
                    value={districtId}
                    cityID={cityId}
                    onChange={(value) => setDistrictId(value)}
                    required
                    disabled={disabledInput}
                  />
                </RowContainer>

                <RowContainer>
                  <VillageDropdown
                    containerStyle={styles.w30}
                    label="Kelurahan"
                    value={urbanVillageId}
                    districtID={districtId}
                    onChange={(value) => setUrbanVillageId(value)}
                    required
                    disabled={disabledInput}
                  />
                  <TextInput
                    containerStyle={styles.w30}
                    value={postalCode}
                    onChangeText={setPostalCode}
                    label="Kode Pos"
                    required
                    disabled={disabledInput}
                  />
                  <TextInput
                    containerStyle={styles.w15}
                    value={rt}
                    onChangeText={(text) => onChangeRTRW(text, setRT)}
                    label="RT"
                    disabled={disabledInput}
                  />
                  <TextInput
                    containerStyle={styles.w15}
                    value={rw}
                    onChangeText={(text) => onChangeRTRW(text, setRW)}
                    label="RW"
                    disabled={disabledInput}
                  />
                </RowContainer>

                <RowContainer style={{ alignItems: 'flex-end' }}>
                  <TextInput
                    containerStyle={styles.w30}
                    value={data.store?.storeManagerBulk?.longitude}
                    onChangeText={() => {}}
                    label="Longitude"
                    disabled
                  />

                  <TextInput
                    containerStyle={styles.w30}
                    value={data.store?.storeManagerBulk?.latitude}
                    onChangeText={() => {}}
                    label="Latitude"
                    disabled
                  />

                  <TouchableOpacity style={styles.mapsButton}>
                    <MaterialIcons
                      name="map"
                      size={24}
                      color={colors.icon.blue}
                    />
                    <Text size="s" style={styles.labelMapsButton}>
                      View on Maps
                    </Text>
                  </TouchableOpacity>
                </RowContainer>
              </SectionContainer>

              <SectionContainer>
                <Text bold size="m" style={styles.lBottomSpacing}>
                  {t(['Informasi Karyawan', 'Request Matchmaking'])}
                </Text>

                {employees.map((e, i) => (
                  <EmployeeInfoCard
                    key={i}
                    {...e}
                    onChangeProperty={(property, value) =>
                      onChangeEmployeeData(i, property, value)
                    }
                  />
                ))}
              </SectionContainer>

              <SectionContainer>
                <RowContainer>
                  <View style={{ flex: 5 }}>
                    <Text bold size="m">
                      {t(['Status Toko', 'Request Matchmaking'])}
                    </Text>
                    <Text size="s">
                      Cek status TOKO di Manager:{' '}
                      <Text
                        style={{ color: colors.text.blue }}
                        onPress={() => {}}
                      >
                        https://mngr.dbo.id/admin/stores
                      </Text>
                    </Text>
                  </View>

                  <View style={[styles.flex, styles.switchContainer]}>
                    <Text bold style={styles.switchText}>
                      {storeStatus
                        ? t(['Aktif', 'Active'])
                        : t(['Tidak Aktif', 'Non Active'])}
                    </Text>
                    <Switch
                      checked={storeStatus}
                      onPress={(value) => {
                        setStoreStatus(value);
                        const newEmployee = employees.map((e) => ({
                          ...e,
                          status: value
                            ? StatusActive.ACTIVE
                            : StatusActive.INACTIVE,
                        }));
                        setEmployees(newEmployee);
                      }}
                    />
                  </View>
                </RowContainer>

                <SegmentedControl
                  data-cy="owner-verification-status"
                  containerStyle={styles.w100}
                  selected={storeStatusOptions.findIndex(
                    (status) => status === toPascalCase(data.status),
                  )}
                  setSelected={() => {}}
                  options={storeStatusOptionTexts}
                  disabled
                />
              </SectionContainer>

              <SectionContainer>
                <Text bold size="m" style={styles.lBottomSpacing}>
                  Kategori Unverified
                  <Text bold size="s" color="red">
                    *
                  </Text>
                </Text>

                {unverifiedCategories.map(
                  ({ type, detail, problem, action, customProblem }, idx) => (
                    <>
                      <RowContainer style={styles.unverifCategoryContainer}>
                        <Dropdown
                          containerStyle={[styles.w20, styles.mRightSpacing]}
                          label="Tipe Kategori"
                          value={type}
                          onChange={(value: string) =>
                            onChangeUnverifiedCategories(value, 'type', idx)
                          }
                          options={typeCategories}
                          disabled={viewMode}
                          required
                        />
                        <Dropdown
                          containerStyle={[styles.w20, styles.mRightSpacing]}
                          label="Detail Kategori"
                          value={detail}
                          onChange={(value: string) =>
                            onChangeUnverifiedCategories(value, 'detail', idx)
                          }
                          options={
                            type === 'Telepon'
                              ? phoneCategories
                              : photoCategories
                          }
                          disabled={viewMode || !type}
                          required
                        />
                        <Dropdown
                          containerStyle={[styles.w40, styles.mRightSpacing]}
                          label="Permasalahan Ditemukan"
                          value={problem}
                          onChange={(value: string) =>
                            onChangeUnverifiedCategories(value, 'problem', idx)
                          }
                          options={getProblemFromCategories(detail)}
                          disabled={viewMode || !detail}
                          required
                        />
                        {idx > 0 ? (
                          <Button
                            large
                            preset="secondary"
                            title="-"
                            style={styles.w10}
                            onPress={() => deleteUnverifiedCategories(idx)}
                          />
                        ) : null}
                      </RowContainer>

                      {problem === 'Lainnya' ? (
                        <>
                          <TextInput
                            containerStyle={styles.xxsBottomSpacing}
                            label="Lainnya"
                            value={customProblem}
                            onChangeText={(value: string) =>
                              onChangeUnverifiedCategories(
                                value,
                                'customProblem',
                                idx,
                              )
                            }
                            placeholder="Keterangan"
                            disabled={viewMode}
                            required
                          />
                          <TextInput
                            containerStyle={styles.mBottomSpacing}
                            label="Next Action"
                            value={action}
                            onChangeText={(value: string) =>
                              onChangeUnverifiedCategories(value, 'action', idx)
                            }
                            placeholder="Masukan Aksi Berikutnya"
                            disabled={viewMode}
                            required
                          />
                        </>
                      ) : (
                        problem.length > 0 && (
                          <Text style={styles.mBottomSpacing}>
                            {`Next Action: ${action}`}
                          </Text>
                        )
                      )}
                      <View style={[styles.border, styles.mBottomSpacing]} />
                    </>
                  ),
                )}
                <Text
                  color={
                    unverifiedCategories.length < 3 && !viewMode
                      ? 'blue'
                      : 'grey'
                  }
                  onPress={() => {
                    if (unverifiedCategories.length < 3 && !viewMode) {
                      setUnverifiedCategories([
                        ...unverifiedCategories,
                        emptyUnverifiedCategories,
                      ]);
                    }
                  }}
                >
                  + Tambah Kategori
                </Text>
              </SectionContainer>

              <SectionContainer>
                <TextInput
                  data-cy="store-verification-note"
                  containerStyle={!viewMode && styles.lBottomSpacing}
                  value={data.note}
                  onChangeText={() => {}}
                  leftNode={
                    <SubjectIcon
                      htmlColor={colors.border.primary}
                      style={{
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    />
                  }
                  placeholder="Data Valid"
                  disabled
                  multiline
                  rows={3}
                />
              </SectionContainer>
              {submitError && (
                <Text style={{ color: colors.text.red }}>
                  {t([
                    'Gagal mengubah data, lengkapi data" dan coba lagi',
                    'Failed to change the phone number. Please try again later.',
                  ])}
                </Text>
              )}

              <RowContainer style={{ justifyContent: 'flex-end' }}>
                <Button
                  style={styles.buttonWidth}
                  preset="primary"
                  title={t(['Simpan', 'Save'])}
                  onPress={openModal}
                  disabled={isDisabled()}
                />
              </RowContainer>
            </View>
          ) : (
            <View>
              <SectionContainer>
                {historyData?.getUpdatedStoreHistory.map(
                  ({ currentData, updatedAttributes, createdAt }, idx) => {
                    const changes: StoreLogChangesRespond = JSON.parse(
                      updatedAttributes || '',
                    );

                    let count = 0;
                    for (let key in changes) {
                      if (key === 'employees') {
                        for (let employee of changes[key]) {
                          for (let e in employee) {
                            if (e === 'userId' || e === 'email') {
                              continue;
                            }

                            employee[e as keyof EmployeeStoreLogChanges] &&
                              count++;
                          }
                        }
                      } else {
                        changes[key as keyof StoreLogChangesRespond] && count++;
                      }
                    }

                    return (
                      <View key={idx} style={styles.lBottomSpacing}>
                        <Collapsible
                          onPressClosing={() => {}}
                          onPressOpening={() => {}}
                          title={
                            <HeaderCollapsible
                              collapsed={false}
                              title={getFormattedSentenceDate(
                                new Date(createdAt),
                              )}
                              changes={count}
                            />
                          }
                          titleOpened={
                            <HeaderCollapsible
                              collapsed={true}
                              title={getFormattedSentenceDate(
                                new Date(createdAt),
                              )}
                              changes={count}
                            />
                          }
                        >
                          <TokoCollapsedItem
                            currentData={currentData || ''}
                            updatedData={updatedAttributes || ''}
                          />
                        </Collapsible>
                      </View>
                    );
                  },
                )}
              </SectionContainer>
            </View>
          )}
        </View>
        <View>
          <Modal
            open={modalOpen}
            onClose={closeModal}
            title={t(['Simpan Perubahan', 'Save Changes'])}
            titleSize="xl"
            TextComponent={Text}
          >
            <Text style={styles.bottomContainer}>
              {t([
                'Apakah Anda sudah yakin dengan perubahan ini?',
                'Are you sure with this change?',
              ])}
            </Text>
            <View style={styles.confirmationModalButton}>
              <Button
                title={t(['Tidak', 'No'])}
                preset="secondary"
                onPress={closeModal}
              />
              <Button
                title={t(['Ya', 'Yes'])}
                onPress={updateData}
                isLoading={loading}
              />
            </View>
          </Modal>
        </View>
      </>
    );
  },
);

type EmployeeInfoCardProps = {
  name: string;
  role?: Role | null;
  email?: string | null;
  privileges: Array<AccessUser>;
  status?: StatusActive | null;
  onChangeProperty: (property: keyof FormEmployee, value: unknown) => void;
};

const EmployeeInfoCard = ({
  name,
  role,
  email = '',
  privileges,
  status,
  onChangeProperty,
}: EmployeeInfoCardProps) => {
  const [isNameError, setIsNameError] = useState(false);

  let onPressCheckbox = (permission: AccessUser) => {
    if (privileges.includes(permission)) {
      let newAccess = privileges.filter((a) => a !== permission);
      onChangeProperty('privileges', newAccess);
    } else {
      let newAccess = [...privileges, permission];
      onChangeProperty('privileges', newAccess);
    }
  };

  return (
    <View style={styles.employeeInfo}>
      <View style={[styles.mPaddingHorizontal, styles.mPaddingTop]}>
        <RowContainer noBottomSpace={isNameError}>
          <TextInput
            containerStyle={styles.w50}
            value={name}
            onChangeText={(value) => {
              if (!nameRegex.test(value)) {
                setIsNameError(true);
              } else {
                setIsNameError(false);
              }
              onChangeProperty('name', value);
            }}
            label="Name"
          />
          <RoleDropdown
            containerStyle={styles.w50}
            value={role || ''}
            onChange={(value) => onChangeProperty('role', value)}
            label="Role"
            disabled
          />
        </RowContainer>
        {isNameError && (
          <Text size="xs" color="red" style={styles.lBottomSpacing}>
            Nama tidak boleh berisi nomor atau simbol khusus
          </Text>
        )}

        <TextInput
          containerStyle={styles.lBottomSpacing}
          value={email}
          onChangeText={(value) => onChangeProperty('email', value)}
          label="Email (opsional)"
          disabled
        />

        <Text size="xs" style={styles.formLabel}>
          Permission
        </Text>
        <RowContainer style={{ justifyContent: 'flex-start' }}>
          <View style={styles.flex}>
            <Checkbox
              checked={privileges.includes(AccessUser.CAN_ORDER)}
              onPress={() => onPressCheckbox(AccessUser.CAN_ORDER)}
              labelNode={<Text>Dapat Memesan</Text>}
            />
          </View>

          <View style={styles.flex}>
            <Checkbox
              checked={privileges.includes(AccessUser.ADD_STAFF)}
              onPress={() => onPressCheckbox(AccessUser.ADD_STAFF)}
              labelNode={<Text>Menambah Staff</Text>}
            />
          </View>
        </RowContainer>
      </View>

      <RowContainer style={styles.employeeStatus}>
        <Text>Status Karyawan</Text>
        <View style={styles.switchContainer}>
          <Text bold style={styles.switchText}>
            {status === StatusActive.ACTIVE ? 'Aktif' : 'Tidak Aktif'}
          </Text>
          <Switch
            checked={status === StatusActive.ACTIVE}
            onPress={(check) =>
              onChangeProperty(
                'status',
                check ? StatusActive.ACTIVE : StatusActive.INACTIVE,
              )
            }
          />
        </View>
      </RowContainer>
    </View>
  );
};

type RowContainerProps = {
  style?: ViewStyle;
  noBottomSpace?: boolean;
};

const RowContainer: React.FC<RowContainerProps> = ({
  children,
  style,
  noBottomSpace = false,
}) => {
  return (
    <View
      style={[
        styles.row,
        !noBottomSpace ? styles.lBottomSpacing : undefined,
        { justifyContent: 'space-between' },
        style,
      ]}
    >
      {children}
    </View>
  );
};

const SectionContainer: React.FC = ({ children }) => {
  return <View style={styles.sectionContainer}>{children}</View>;
};

const HeaderCollapsible = ({
  title,
  changes,
  collapsed,
}: {
  title: string;
  changes: number;
  collapsed: boolean;
}) => {
  return (
    <View style={styles.headerContainer}>
      <Text bold>{title}</Text>
      <View style={styles.headerText}>
        <Text>{changes.toString()} perubahan</Text>
        {collapsed ? (
          <KeyboardArrowDownOutlinedIcon />
        ) : (
          <KeyboardArrowUpOutlined />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { width: 529, height: 1000, backgroundColor: colors.background.white },
  row: { flexDirection: 'row' },
  flex: { flex: 1 },
  w100: { width: '100%' },
  w50: { width: '48%' },
  w40: { width: '40%' },
  w30: { width: '30%' },
  w20: { width: '20%' },
  w15: { width: '15%' },
  w10: { width: '10%' },
  border: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  ///////////////////////////////////////////////
  header: {
    backgroundColor: colors.background.white,
    flexDirection: 'row',
    height: 40,
    shadowColor: colors.background.shadow,
  },
  tab: {
    justifyContent: 'flex-start',
    height: '100%',
    marginHorizontal: spacing.l,
  },
  selectedTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.button.background.primary,
  },
  ///////////////////////////////////////////////
  mPaddingHorizontal: {
    paddingHorizontal: spacing.m,
  },
  mPaddingTop: { paddingTop: spacing.m },
  mRightSpacing: { marginRight: spacing.m },
  mBottomSpacing: { marginBottom: spacing.m },
  lBottomSpacing: {
    marginBottom: spacing.l,
  },
  xxsBottomSpacing: {
    marginBottom: spacing.xxs,
  },
  textAlignRight: { textAlign: 'right' },
  ///////////////////////////////////////////////
  sectionContainer: {
    paddingTop: spacing.l,
    marginBottom: spacing.m,
    marginHorizontal: spacing.s,
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
  },
  employeeInfo: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    marginBottom: spacing.m,
  },
  employeeStatus: {
    marginBottom: 0,
    padding: spacing.m,
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
  },
  formLabel: {
    opacity: 0.8,
  },
  mapsButton: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '65%',
    width: '30%',
  },
  labelMapsButton: {
    color: colors.text.blue,
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  switchText: {
    marginRight: spacing.s,
  },
  buttonWidth: {
    width: 30,
  },
  headerContainer: {
    backgroundColor: colors.background.white,
    borderColor: colors.border.primary,
    padding: 16,
    borderWidth: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerText: { flexDirection: 'row', alignItems: 'center' },
  bottomContainer: {
    marginTop: 24,
    padding: 24,
    width: 529,
    backgroundColor: colors.background.white,
  },
  confirmationModalButton: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingVertical: spacing.m,
    paddingRight: spacing.l,
  },
  unverifCategoryContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
});

export default TokoSuperEditForm;
