import gql from 'graphql-tag';

export const GET_RANGES = gql`
  query Ranges($brandId: String!) {
    ranges(brandId: $brandId) {
      id
      name
    }
  }
`;

export const GET_BRAND_RANGES = gql`
  query BrandRangeParentAndChild($brandId: ID!) {
    brandRangeParentAndChild(brandId: $brandId) {
      id
      brandId
      name
    }
  }
`;

export const GET_PRODUCTS = gql`
  query Products($brandID: String!, $rangeID: String!) {
    productList(brandId: $brandID, rangeId: $rangeID) {
      dataProducts {
        id
        productName
        unit
      }
    }
  }
`;
