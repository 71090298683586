import React, { useRef, useMemo, ComponentType } from 'react';
import { ActivityIndicator, View } from 'react-native';
import clsx from 'clsx';
import {
  TableBody as MTableBody,
  TableRow as MTableRow,
  TableCellProps,
  TableCell,
  TableRowProps,
  makeStyles,
} from '@material-ui/core';

import Text from '../Text';
import { flattenObject, getComparator, Order } from '../../helpers';
import { theme } from '../../constants/theme';

import TableRow from './TableRow';
import { Props as TableProps } from './Table';

type Props<T, U> = Pick<
  TableProps<T, U>,
  'data' | 'emptyPlaceholder' | 'structure'
> & {
  'data-cy'?: string;
  error?: boolean;
  loading?: boolean;
  order?: Order;
  orderBy?: string | null;
  TextComponent?: ComponentType;
  cellClasses?: TableCellProps['classes'];
  rowClasses?: TableRowProps['classes'];
  override?: boolean;
};

const useStyles = makeStyles({
  root: { width: '100%', overflowX: 'scroll' },
  removeBorder: { borderWidth: 0 },
  separatorTypeTwo: {
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 4,
    borderColor: theme.colors.border.white,
  },
  normalCell: { backgroundColor: theme.colors.table.backgroundWhite },
  removeHorizontalPadding: { padding: '6px 12px 6px 6px' },
  removeBottomBorder: {
    borderBottomWidth: 0,
  },
});

const TableBody = <
  T extends ObjectOf<unknown>, // NOTE: the shape of the data
  U extends { [key in keyof T | string]: unknown } // NOTE: the shape of the flattened data
>(
  props: Props<T, U>,
) => {
  let {
    data,
    error,
    emptyPlaceholder,
    loading,
    structure,
    order,
    orderBy,
    TextComponent = Text,
    cellClasses,
    rowClasses,
    override,
  } = props;
  const {
    separatorTypeTwo,
    normalCell,
    removeBorder,
    removeHorizontalPadding,
    removeBottomBorder,
  } = useStyles();

  const normalCellClasses = useMemo(
    () => ({
      root: clsx(
        normalCell,
        separatorTypeTwo,
        removeBorder,
        removeHorizontalPadding,
        removeBottomBorder,
      ),
    }),
    [
      normalCell,
      separatorTypeTwo,
      removeHorizontalPadding,
      removeBorder,
      removeBottomBorder,
    ],
  );
  // NOTE: we need both original and flattened data.
  //       we send the original data as parameter in callbacks.
  //       we use flattened data to resolve dataPath and for ordering.
  const withFlattenedData = useMemo(
    () =>
      data.map((original) => {
        let flattened = flattenObject(original) as U;
        return [original, flattened] as [T, U];
      }),
    [data],
  );

  const orderedData = useMemo(() => {
    if (order && orderBy) {
      //NOTE: use custom compare function if provided
      if (structure[orderBy]?.compareFn) {
        return [...withFlattenedData].sort((a, b) => {
          if (order && orderBy) {
            let comparator = structure[orderBy]?.compareFn;
            return comparator
              ? order === 'asc'
                ? comparator(a[0], b[0])
                : -comparator(a[0], b[0])
              : 0;
          } else {
            return 0;
          }
        });
      }
      //NOTE: if compare function is not provided, use default comparator
      return [...withFlattenedData].sort((a, b) => {
        if (order && orderBy) {
          let orderByData = orderBy;
          const orderByStructure = structure[orderBy];
          if (
            orderByStructure &&
            typeof orderByStructure.dataPath === 'string'
          ) {
            orderByData = orderByStructure.dataPath;
          }
          // NOTE: get the correct compare logic based on order property or its dataPath if available
          return getComparator<U>(order, orderByData)(a[1], b[1]);
        } else {
          return 0;
        }
      });
    } else {
      // NOTE: if there's no need to order the data, we return the [original, flattened] tupple array
      return withFlattenedData;
    }
  }, [withFlattenedData, order, orderBy, structure]);

  const colSpan = useMemo(() => Object.keys(structure).length, [structure]);

  const tbodyRef = useRef<HTMLTableElement>();
  const cellStyle =
    loading && tbodyRef.current && tbodyRef.current.clientHeight !== 0
      ? { height: tbodyRef.current.clientHeight - 2, padding: 0 }
      : undefined;

  // TODO: if necessary, research Virtualized Tables
  return (
    <MTableBody data-cy={props['data-cy']} innerRef={tbodyRef}>
      {loading ? (
        <MTableRow>
          <TableCell
            colSpan={colSpan}
            classes={override ? normalCellClasses : cellClasses}
            style={cellStyle}
            align="center"
          >
            <View
              testID="loadMoreIndicator"
              data-cy={`${props['data-cy']}-loading`}
            >
              <ActivityIndicator />
            </View>
          </TableCell>
        </MTableRow>
      ) : !error && orderedData.length === 0 ? (
        <MTableRow>
          <TableCell colSpan={colSpan} classes={cellClasses} align="center">
            <TextComponent>{emptyPlaceholder}</TextComponent>
          </TableCell>
        </MTableRow>
      ) : (
        orderedData.map((rowData, index) => (
          <TableRow
            data-cy={`${props['data-cy']}-row`}
            rowData={rowData[0]}
            flattenedRowData={rowData[1]}
            structure={structure}
            key={index}
            cellClasses={override ? normalCellClasses : cellClasses}
            rowClasses={rowClasses}
            rowNumber={index}
            override={override}
            TextComponent={TextComponent}
          />
        ))
      )}
    </MTableBody>
  );
};

export default TableBody;
