import React, { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  useFonts,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
} from '@expo-google-fonts/roboto';
import { Inter_400Regular, Inter_600SemiBold } from '@expo-google-fonts/inter';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { ActivityIndicator } from 'react-native';
//NOTE: We need this to specify worker for react-pdf so it can render the page
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import packageJson from '../package.json';

import { AuthProvider } from './context';
import { MainScene } from './scenes';
import { client } from './graphql/client';
import { ENVIRONMENT, SENTRY_DSN } from './constants/api';
import { SnackbarProvider } from './features/verification/context';
import ErrorLog from './services/ErrorLog';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT,
  beforeSend: (event) => {
    let { apiInfo } = ErrorLog.getLogInfo();
    let exceptionMessage = event.exception?.values?.[0].value;

    let tmpAnchor = document.createElement('a');
    tmpAnchor.href = window.location.href;
    let currentScreen = tmpAnchor.pathname;

    let customEvent: Sentry.Event = {
      ...event,
      exception: {
        values: [
          {
            ...event.exception?.values?.[0],
            type: `Path: ${currentScreen} - ${exceptionMessage}`, // If the currentScreen
          },
        ],
      },
      contexts: {
        ...event.contexts,
        'API Info': {
          ...apiInfo,
          exceptionResponse: {
            operationName: 'Exception Response',
            response: exceptionMessage,
          },
        },
        'Event Exception': event.exception ?? {},
      },
    };
    return customEvent;
  },
});

export default function App() {
  const [fontsLoaded] = useFonts({
    Roboto: Roboto_400Regular,
    RobotoItalic: Roboto_400Regular_Italic,
    RobotoBold: Roboto_700Bold,
    RobotoBoldItalic: Roboto_700Bold_Italic,
    Inter: Inter_400Regular,
    InterBold: Inter_600SemiBold,
  });

  useEffect(() => {
    //This is a function to purge the local data from client
    //its using package.json version to determine if there is an update or not from cms
    let currentVersion = localStorage.getItem('version');
    let deployedVersion = packageJson.version;
    if (currentVersion !== deployedVersion) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => caches.delete(name));
        });
      }
      window.location.reload();
      localStorage.clear();
    }
    localStorage.setItem('version', packageJson.version);
  }, []);

  if (!fontsLoaded) {
    return <ActivityIndicator />;
  }

  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AuthProvider>
          <SnackbarProvider>
            <MainScene />
          </SnackbarProvider>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
