import { MAPS_API_KEY } from 'react-native-dotenv';

export default async function getAddress(lat: number, lng: number) {
  let result = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAPS_API_KEY}`,
  );
  let json = await result.json();

  if (json.status === 'OK') {
    return {
      success: true,
      address: json.results[0].formatted_address,
    };
  } else {
    return { success: false, errorMessage: json.error_message };
  }
}
