export const emptyUnverifiedCategories = {
  type: '',
  detail: '',
  problem: '',
  customProblem: '',
  action: '',
};

export const typeCategories = [
  { label: 'Telepon', value: 'Telepon' },
  { label: 'Foto', value: 'Foto' },
];

export const photoCategories = [
  { label: 'Foto Tampak Depan', value: 'Foto Tampak Depan' },
  { label: 'Foto Bagian Dalam', value: 'Foto Bagian Dalam' },
];

export const phoneCategories = [
  { label: 'Telepon diangkat', value: 'Telepon diangkat' },
  { label: 'Telepon tidak diangkat', value: 'Telepon tidak diangkat' },
];

export const photoFrontProblems = [
  {
    label: 'Foto tidak jelas (buram/kurang cahaya/terpotong)',
    value: 'Foto tidak jelas (buram/kurang cahaya/terpotong)',
  },
  {
    label: 'Foto bangunan sekitar toko tidak terlihat',
    value: 'Foto bangunan sekitar toko tidak terlihat',
  },
  {
    label: 'Bukan foto asli',
    value: 'Bukan foto asli',
  },
];

export const photoInsideProblems = [
  {
    label: 'Foto tidak jelas (buram/kurang cahaya/terpotong)',
    value: 'Foto tidak jelas (buram/kurang cahaya/terpotong)',
  },
  {
    label: 'Foto produk tidak terlihat',
    value: 'Foto produk tidak terlihat',
  },
  {
    label: 'Bukan foto asli',
    value: 'Bukan foto asli',
  },
];

export const phonePickedUpProblems = [
  {
    label: 'Bukan Toko',
    value: 'Bukan Toko',
  },
  {
    label: 'Double Registrasi',
    value: 'Double Registrasi',
  },
  {
    label: 'Menolak Karena Toko sedang sibuk',
    value: 'Menolak Karena Toko sedang sibuk',
  },
  {
    label: 'Nomor Sales yang digunakan',
    value: 'Nomor Sales yang digunakan',
  },
  {
    label: 'Ragu-ragu',
    value: 'Ragu-ragu',
  },
  {
    label: 'Telepon salah sambung',
    value: 'Telepon salah sambung',
  },
  {
    label: 'Tidak Ingin Menggunakan Toko App',
    value: 'Tidak Ingin Menggunakan Toko App',
  },
  {
    label: 'Tidak tahu cara pakai toko app',
    value: 'Tidak tahu cara pakai toko app',
  },
  {
    label: 'Tidak tahu toko app',
    value: 'Tidak tahu toko app',
  },
  {
    label: 'Toko demo',
    value: 'Toko demo',
  },
  {
    label: 'Toko tidak merasa daftar',
    value: 'Toko tidak merasa daftar',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];

export const phoneNotPickedUpProblems = [
  {
    label: 'Telepon di luar jangkauan/Tidak aktif',
    value: 'Telepon di luar jangkauan/Tidak aktif',
  },
  {
    label: 'Telepon dialihkan',
    value: 'Telepon dialihkan',
  },
  {
    label: 'Telepon direject',
    value: 'Telepon direject',
  },
  {
    label: 'Telepon tidak bisa dihubungi',
    value: 'Telepon tidak bisa dihubungi',
  },
  {
    label: 'Telepon tidak diangkat',
    value: 'Telepon tidak diangkat',
  },
  {
    label: 'Nomor Telepon Sibuk',
    value: 'Nomor Telepon Sibuk',
  },
  {
    label: 'Toko demo',
    value: 'Toko demo',
  },
  {
    label: 'Lainnya',
    value: 'Lainnya',
  },
];

export const nextActions = [
  {
    problem: 'Bukan Toko',
    action: 'Tidak di Follow Up',
  },
  {
    problem: 'Double Registrasi',
    action: 'Diinformasikan  bahwa toko sudah pernah melakukan Registrasi',
  },
  {
    problem: 'Menolak Karena Toko sedang sibuk',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Nomor Sales yang digunakan',
    action: 'Tidak di Follow Up',
  },
  {
    problem: 'Ragu-ragu',
    action: 'Diarahkannya ke product tour Home page',
  },
  {
    problem: 'Telepon salah sambung',
    action: 'Tidak di Follow Up',
  },
  {
    problem: 'Tidak Ingin Menggunakan Toko App',
    action: 'Tidak di Follow Up',
  },
  {
    problem: 'Tidak tahu cara pakai toko app',
    action: 'Diarahkannya ke product tour Home page ',
  },
  {
    problem: 'Tidak tahu toko app',
    action: 'Diarahkannya ke product tour Home page ',
  },
  {
    problem: 'Toko demo',
    action: 'Tidak di Follow Up',
  },
  {
    problem: 'Toko tidak merasa daftar',
    action: 'Diarahkannya ke product tour Home page ',
  },
  {
    problem: 'Telepon di luar jangkauan/Tidak aktif',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Telepon dialihkan',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Telepon direject',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Telepon tidak bisa dihubungi',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Telepon tidak diangkat',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Nomor Telepon Sibuk',
    action: 'Akan dihubungi oleh AV',
  },
  {
    problem: 'Toko demo',
    action: 'Tidak di Follow Up',
  },
  {
    problem: 'Foto tidak jelas (buram/kurang cahaya/terpotong)',
    action:
      'Mengambil kembali foto dan pastikan tidak buram/kurang cahaya/terpotong',
  },
  {
    problem: 'Foto bangunan sekitar toko tidak terlihat',
    action:
      'Mengambil kembali foto, beri jarak dari bangunan utama dan pastikan bangunan disisi kiri-kanan terlihat',
  },
  {
    problem: 'Bukan foto asli',
    action:
      'Mengambil kembali foto dan pastikan foto asli diambil saat itu juga',
  },
  {
    problem: 'Foto produk tidak terlihat',
    action:
      'Mengambil kembali foto dan pastikan ada produk dari DBC group terlihat jelas',
  },
];
