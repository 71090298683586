import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { CategoryProductTADA } from '../../generated/CategoryProductTADA';
import { CATEGORY_PRODUCT_TADA } from '../../graphql/queries';
import { ID_CATEGORY_EWALLET } from '../../constants/deepLinkList';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Cat', 'Select Brand']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
};

const LoyaltyCategoryDropdown = memo((props: Props) => {
  let { title = defaultTitle, selectedOption, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<CategoryProductTADA>(
    CATEGORY_PRODUCT_TADA,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      /* For now only show E-Wallet */
      data?.categoryProductTada
        .filter(({ id }) => id.toString() === ID_CATEGORY_EWALLET)
        .map(({ id, name }) => ({
          label: name,
          value: id.toString(),
        })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t([
        'mengambil data kategori loyalty',
        'retrieve the loyalty category data',
      ])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      selectedOption={selectedOption}
      {...otherProps}
    />
  );
});

export default LoyaltyCategoryDropdown;
