import gql from 'graphql-tag';

export const SOCIAL_MEDIA_FRAGMENT = gql`
  fragment SocialMediaFragment on SocialMedia {
    id
    icon
    link
    order
  }
`;
