import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_PRODUCTS } from '../../graphql/queries/product';
import { Products, ProductsVariables } from '../../generated/Products';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Produk', 'Select Product']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  brandID?: string;
  rangeID?: string;
  title?: string;
};

const ProductDropdown = memo((props: Props) => {
  let { title = defaultTitle, brandID, rangeID, ...otherProps } = props;

  const [getProducts, { data, loading, error }] = useLazyQuery<
    Products,
    ProductsVariables
  >(GET_PRODUCTS);

  const refetchData = useCallback(() => {
    if (brandID && rangeID) {
      getProducts({ variables: { brandID, rangeID } });
    }
  }, [brandID, rangeID, getProducts]);

  useEffect(refetchData, [brandID, rangeID, refetchData]);

  const options = useMemo(
    () =>
      data?.productList.dataProducts.map(({ id, productName }) => ({
        label: productName,
        value: id.toString(),
      })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data produk', 'retrieve the product data'])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default ProductDropdown;
