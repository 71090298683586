import gql from 'graphql-tag';

import { SCREEN_FRAGMENT } from './screen';
import { BRAND_FRAGMENT, RANGE_FRAGMENT } from './brand';

export const POP_UP_FRAGMENT = gql`
  fragment PopUpFragment on PopUp {
    id
    name
    image
    title
    textLine1
    textLine2
    textLine3
    linkType
    color
    externalLink
    whatsAppLink
    internalLink {
      ...ScreenFragment
    }
    who
    region
    city
    page {
      ...ScreenFragment
    }
    startDate
    endDate
    status
    brandId
    rangeId
    conditionBrandId
    conditionRangeId
    brand {
      ...BrandFragment
    }
    range {
      ...RangeFragment
    }
    conditionBrand {
      ...BrandFragment
    }
    conditionRange {
      ...RangeFragment
    }
  }
  ${SCREEN_FRAGMENT}
  ${BRAND_FRAGMENT}
  ${RANGE_FRAGMENT}
`;
