import React, { useCallback, useEffect } from 'react';
import { View, StyleSheet, Image, Linking, Platform } from 'react-native';
import { isBrowser, isMobile } from 'react-device-detect';
import { logEvent } from 'expo-firebase-analytics';

import { correctIcon, appstore, playstore } from '../../../assets';
import { Text, Button } from '../../core-ui';
import { spacing } from '../../constants/theme';
import {
  appStoreLocale,
  appName,
  appStoreId,
  playStoreId,
} from '../../constants/app';
import { pageTitle } from '../../constants/pageTitle';

export default function SuccessVerifyEmailScene() {
  const onOpenAppPress = useCallback(() => {
    const url = 'dbo://redirect?path=home'; //TODO: check if link is working
    Linking.openURL(url).catch((_e) => {
      if (Platform.OS === 'ios') {
        Linking.openURL(
          `https://itunes.apple.com/${appStoreLocale}/app/${appName}/id${appStoreId}`,
        );
      } else {
        Linking.openURL(
          `https://play.google.com/store/apps/details?id=${playStoreId}`,
        );
      }
    });
  }, []);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.SuccessVerifyEmailScene,
      });
    };
    eventLog();
  }, []);

  return (
    <View style={styles.container}>
      <Image source={correctIcon} style={styles.image} resizeMethod={'auto'} />
      <Text size="medium" style={styles.title}>
        {t(['Selamat', 'Congratulation'])}
      </Text>
      <Text>
        {t(['Email anda sudah terverifikasi', 'Your email has been verified'])}
      </Text>
      {isBrowser && (
        <View style={styles.row}>
          <Button
            preset="transparent"
            title=""
            icon={
              <Image
                source={{ uri: playstore }}
                resizeMode="contain"
                style={styles.imageButton}
              />
            }
            onPress={() => {
              Linking.openURL(
                `https://play.google.com/store/apps/details?id=${playStoreId}`,
              );
            }}
            style={styles.button}
          />
          <Button
            preset="transparent"
            title=""
            icon={
              <Image
                source={{ uri: appstore }}
                resizeMode="contain"
                style={styles.imageButton}
              />
            }
            onPress={() => {
              Linking.openURL(
                `https://itunes.apple.com/${appStoreLocale}/app/${appName}/id${appStoreId}`,
              );
            }}
            style={styles.button}
          />
        </View>
      )}
      {isMobile && (
        <Button
          title={t(['Buka Aplikasi', 'Open Application'])}
          onPress={onOpenAppPress}
          style={styles.button}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 8,
    fontWeight: 'bold',
  },
  image: {
    width: 48,
    height: 36,
    marginBottom: 16,
  },
  row: {
    flexDirection: 'row',
  },
  button: {
    marginTop: spacing.medium,
    marginLeft: spacing.xsmall,
    marginRight: spacing.xsmall,
  },
  imageButton: {
    width: 164,
    height: 48,
  },
});
