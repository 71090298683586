import React, { useCallback } from 'react';
import { View, StyleSheet, Image, Clipboard } from 'react-native';

import {
  StepHeader,
  Option,
  LiveDateRadioGroup,
  BrandContactDropdown,
  BrandDropdown,
  RangeDropdown,
  RadioGroup,
  ScreenDropdown,
} from '../../components';
import { colors, fonts, spacing, theme } from '../../constants/theme';
import { TextInput, Text, Button } from '../../core-ui';
import Dropzone, { FileWithPreview } from '../../core-ui/Dropzone';
import { emailIcon } from '../../../assets';
import { NewsTypeLink } from '../../generated/globalTypes';

type Props = {
  name: string;
  setName: (value: string) => void;
  brand: string | null;
  setBrand: (value: Option) => void;
  managerBrandId: string | null;
  setManagerBrandId: (value: string) => void;
  range: string | null;
  setRange: (value: Option) => void;
  about: string;
  setAbout: (value: string) => void;
  brandContact: string | null;
  setBrandContact: (value: Option) => void;
  image: FileWithPreview | string | null;
  setImage: (file: FileWithPreview) => void;
  dedicatedPageImage: FileWithPreview | string | null;
  setDedicatedPageImage: (file: FileWithPreview) => void;
  liveDate?: Date;
  setLiveDate: (date: Date) => void;
  endDate: Date;
  setEndDate: (date: Date) => void;
  onSubmit: () => void;
  onEdit: () => void;
  onSendEmail: () => void;
  submitDisabled: boolean;
  editable: boolean;
  submitLoading: boolean;
  emailDisabled: boolean;
  setExternalLink: (text: string) => void;
  externalLink: string;
  screen: string | null;
  setScreen: (text: string | null) => void;
  subScreenOne: string | null;
  setSubScreenOne: (text: string | null) => void;
  subScreenTwo: string | null;
  setSubScreenTwo: (text: string | null) => void;
  linkType: NewsTypeLink;
  setLinkType: (text: NewsTypeLink) => void;
  externalButton: string;
  setExternalButton: (value: string) => void;
  salesmanAppDeepLink: string;
  setSalesmanAppDeepLink: (value: string) => void;
  aboutSalesmanApp: string;
  setAboutSalesmanApp: (value: string) => void;
};

export default function StepSixNews(props: Props) {
  let {
    name,
    brand,
    managerBrandId,
    range,
    about,
    brandContact,
    image,
    liveDate,
    endDate,
    submitDisabled,
    editable,
    submitLoading,
    emailDisabled,
    externalLink,
    screen,
    externalButton,
    dedicatedPageImage,
    salesmanAppDeepLink,
    aboutSalesmanApp,
    setName,
    setBrand,
    setManagerBrandId,
    setRange,
    setAbout,
    setBrandContact,
    setImage,
    setLiveDate,
    setEndDate,
    onSubmit,
    onEdit,
    onSendEmail,
    setExternalLink,
    setScreen,
    setExternalButton,
    setDedicatedPageImage,
    setSalesmanAppDeepLink,
    setAboutSalesmanApp,
    subScreenOne,
    setSubScreenOne,
    subScreenTwo,
    setSubScreenTwo,
    linkType,
    setLinkType,
  } = props;

  const onSelectBrand = useCallback(
    (option: Option) => {
      setBrand(option);
      setManagerBrandId(option.subValue || '');
    },
    [setBrand, setManagerBrandId],
  );

  const createSetSelected = useCallback(
    <T extends unknown>(
      setter: (value: T) => void,
      ...nullSetters: Array<(value: null) => void>
    ) => (option: Option) => {
      option && setter(option.value as T);
      nullSetters.forEach((cb) => cb(null));
    },
    [],
  );

  const onSelectLinkType = useCallback(
    (value: NewsTypeLink) => {
      if (value === NewsTypeLink.RANGE) {
        setExternalLink('');
        setScreen(null);
        setSubScreenOne(null);
        setSubScreenTwo(null);
      } else if (value === NewsTypeLink.EXTERNAL_LINK) {
        setScreen(null);
        setSubScreenOne(null);
        setSubScreenTwo(null);
      } else {
        setExternalLink('');
      }
      setLinkType(value);
    },
    [setLinkType, setExternalLink, setScreen, setSubScreenOne, setSubScreenTwo],
  );
  const onCopyText = useCallback(() => {
    Clipboard.setString(salesmanAppDeepLink);
  }, [salesmanAppDeepLink]);

  return (
    <View>
      <StepHeader
        step={6}
        title={t(['Lengkapi Data', 'Complete Details'])}
        style={styles.stepHeader}
      />
      <TextInput
        data-cy="news-title"
        value={name}
        onChangeText={setName}
        label={t(['Judul', 'Title'])}
        containerStyle={styles.field}
        disabled={!editable}
      />
      <Text bold style={styles.label}>
        {t(['Lihat Berita', 'View Page'])}
      </Text>
      <>
        <RadioGroup
          disabled={!editable}
          selectedOption={linkType}
          setSelectedOption={onSelectLinkType}
          options={[
            {
              'data-cy': 'form-event-radio-range',
              value: NewsTypeLink.RANGE,
              label: (
                <>
                  <Text bold style={styles.fieldLabel}>
                    {'Range'}
                  </Text>
                  <View style={styles.label}>
                    <BrandDropdown
                      data-cy="brand-dropdown"
                      selectedOption={linkType === 'RANGE' ? brand : undefined}
                      onSelect={onSelectBrand}
                      disabled={
                        !editable ||
                        linkType === 'EXTERNAL_LINK' ||
                        linkType === 'INTERNAL_LINK'
                      }
                    />
                  </View>
                  <View style={styles.field}>
                    <RangeDropdown
                      data-cy="range-dropdown"
                      brandID={managerBrandId ?? undefined}
                      selectedOption={linkType === 'RANGE' ? range : undefined}
                      onSelect={setRange}
                      disabled={
                        !editable ||
                        linkType === 'EXTERNAL_LINK' ||
                        linkType === 'INTERNAL_LINK'
                      }
                    />
                  </View>
                </>
              ),
            },
            {
              'data-cy': 'form-event-radio-external-link',
              value: NewsTypeLink.EXTERNAL_LINK,
              label: (
                <View>
                  <TextInput
                    data-cy="form-event-external-link"
                    label={t(['Link Eksternal', 'External Link'])}
                    value={externalLink}
                    onChangeText={setExternalLink}
                    containerStyle={[styles.field, { marginBottom: 0 }]}
                    disabled={
                      !editable ||
                      linkType === 'RANGE' ||
                      linkType === 'INTERNAL_LINK'
                    }
                  />
                  <Text style={styles.assistiveText}>
                    Pastikan menggunakan Long URL
                  </Text>
                </View>
              ),
            },
            {
              'data-cy': 'form-event-radio-internal-link',
              value: NewsTypeLink.INTERNAL_LINK,
              label: (
                <>
                  <Text bold style={styles.fieldLabel}>
                    {'Link Internal'}
                  </Text>
                  <ScreenDropdown
                    data-cy="form-event-internal-link"
                    selectedScreen={screen}
                    selectedSubScreenOne={subScreenOne}
                    selectedSubScreenTwo={subScreenTwo}
                    onScreenSelect={createSetSelected(
                      setScreen,
                      setSubScreenOne,
                      setSubScreenTwo,
                    )}
                    onSubScreenOneSelect={createSetSelected(
                      setSubScreenOne,
                      setSubScreenTwo,
                    )}
                    onSubScreenTwoSelect={createSetSelected(setSubScreenTwo)}
                    disabled={
                      !editable ||
                      linkType === 'EXTERNAL_LINK' ||
                      linkType === 'RANGE'
                    }
                  />
                </>
              ),
            },
          ]}
        />
      </>
      <TextInput
        multiline
        rows={4}
        maxLength={2500}
        value={about}
        onChangeText={setAbout}
        label={t([
          'Deskripsi - Kontent Toko App',
          'Description - Toko App Content',
        ])}
        containerStyle={styles.field}
        disabled={!editable}
      />
      <TextInput
        multiline
        rows={4}
        maxLength={2500}
        value={aboutSalesmanApp}
        onChangeText={setAboutSalesmanApp}
        label={t([
          'Deskripsi - Konten Salesman App',
          'Description - Salesman App Content',
        ])}
        containerStyle={styles.field}
        disabled={!editable}
      />
      <View style={[styles.row, styles.field]}>
        <View style={styles.flex}>
          <View style={[styles.row, styles.label]}>
            <Text bold style={styles.flex}>
              {t(['Brand', 'Brand'])}
            </Text>
            <Text bold style={[styles.flex, styles.brandSubLabel]}>
              {t(['Principal', 'Principal'])}
            </Text>
          </View>
          <View>
            <View style={styles.flex}>
              <BrandContactDropdown
                isOptional={true}
                data-cy="principal-dropdown"
                selectedOption={brandContact ?? undefined}
                onSelect={setBrandContact}
                disabled={!editable}
              />
            </View>
          </View>
        </View>
        <Button
          preset="transparent"
          title="Send"
          onPress={onSendEmail}
          icon={
            <Image
              source={{ uri: emailIcon }}
              style={[styles.emailIcon, { opacity: emailDisabled ? 0.3 : 1 }]}
              resizeMode="contain"
            />
          }
          style={styles.emailButton}
          disabled={emailDisabled}
        />
      </View>
      <Text style={styles.label}>
        <Text bold>{t(['Gambar ', 'Image '])}</Text>
      </Text>
      <View style={styles.imageContainer}>
        <View style={styles.paddingRight}>
          <Text style={styles.label}>{t(['Homepage ', 'Homepage '])}</Text>
          <Text style={styles.sizeHelper}>
            {t(['Size: 136 x 84px (opsional)', 'Size: 136 x 84px (optional)'])}
          </Text>
          <Dropzone
            data-cy="news-image"
            source={image}
            getPreview={setImage}
            imageSize={styles.imageRes}
            containerStyle={styles.dropzone}
            disabled={!editable}
          />
        </View>
        <View style={styles.paddingLeft}>
          <Text style={styles.label}>
            {t(['Pop Up Image ', 'Pop Up Image '])}
          </Text>
          <Text style={styles.sizeHelper}>
            {t([
              'Size: 375 x 180px (opsional)',
              'Size: 375 x 180px  (optional)',
            ])}
          </Text>
          <Dropzone
            data-cy="news-image"
            source={dedicatedPageImage}
            getPreview={setDedicatedPageImage}
            imageSize={styles.dedicatedImageRes}
            containerStyle={styles.dropzone}
            disabled={!editable}
          />
        </View>
      </View>
      <TextInput
        data-cy="news-external-button"
        value={externalButton}
        onChangeText={setExternalButton}
        label={t(['Tombol Eksternal', 'External Button'])}
        containerStyle={styles.externalButton}
        disabled={!editable}
        maxLength={35}
      />

      <TextInput
        label={t([
          'URL Deeplink - Konten Salesman App',
          'URL Deeplink - Salesman App Content',
        ])}
        value={salesmanAppDeepLink}
        onChangeText={setSalesmanAppDeepLink}
        containerStyle={styles.field}
        disabled={true}
      />
      <Button
        onPress={onCopyText}
        style={styles.copyLink}
        title={t(['Salin', 'Copy'])}
      />

      <View style={styles.liveContainer}>
        <Text bold color="link">
          {t(['Tayang di Aplikasi', 'Go Live on App'])}:
        </Text>
        <View style={styles.field}>
          <LiveDateRadioGroup
            withEndDate
            liveDate={liveDate}
            setLiveDate={setLiveDate}
            endDate={endDate}
            setEndDate={setEndDate}
            disabled={!editable}
          />
        </View>
        <View style={styles.row}>
          <View style={[styles.flex, styles.buttonSpacing]}>
            <Button
              data-cy="news-submit"
              isLoading={submitLoading}
              title={t(['Simpan', 'Submit'])}
              onPress={onSubmit}
              disabled={submitDisabled || !editable}
            />
          </View>
          <View style={styles.flex}>
            <Button
              data-cy="news-edit-cancel"
              preset="secondary"
              title={editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])}
              onPress={onEdit}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  stepHeader: { marginBottom: spacing.small },
  field: {
    marginBottom: spacing.xsmall,
  },
  fieldLabel: { paddingBottom: spacing.xxsmall },
  label: {
    marginBottom: spacing.xxsmall,
  },
  liveContainer: {
    marginTop: spacing.large,
    borderColor: theme.colors.border.primary,
    borderWidth: 1,
    padding: spacing.medium,
  },
  row: {
    flexDirection: 'row',
  },
  flex: {
    flex: 1,
  },
  buttonSpacing: {
    marginLeft: 46,
    marginRight: spacing.small,
  },
  assistiveText: {
    fontSize: fonts.sizes.xsmall,
    marginBottom: spacing.xsmall,
    marginLeft: 6,
    fontStyle: 'italic',
  },
  emailIcon: { width: 38, height: 38 },
  emailButton: { marginTop: 23 },
  brandSubLabel: { paddingRight: spacing.medium }, // NOTE: this is === arrow down icon size
  externalButton: { marginTop: spacing.small },
  sizeHelper: { color: colors.text.disabled },
  paddingLeft: { paddingLeft: 10 },
  paddingRight: { paddingRight: 10 },
  imageContainer: { flexDirection: 'row', alignContent: 'stretch' },
  imageRes: { width: 136, height: 84 },
  dedicatedImageRes: { width: 375, height: 180 },
  dropzone: {
    width: 175,
    height: 120,
  },
  copyLink: {
    color: colors.text.link,
    marginTop: 4,
  },
});
