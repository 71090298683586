import React, {
  CSSProperties,
  useState,
  useMemo,
  memo,
  useCallback,
} from 'react';
import ReactDropzone, { DropzoneProps } from 'react-dropzone';
import { Document, Page } from 'react-pdf';
import {
  StyleSheet,
  Image,
  View,
  StyleProp,
  ImageStyle,
  ActivityIndicator,
} from 'react-native';
import { CircularProgress, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import { colors, spacing } from '../constants/theme';
import { uploadPlaceholder } from '../../assets';
import { Orientation } from '../generated/globalTypes';

import Text from './Text';

export type FileWithPreview = { file: File; preview: string };
type ThumbnailOrientation = Orientation.VERTICAL | Orientation.HORIZONTAL;

type Props = DropzoneProps & {
  'data-cy'?: string;
  note?: string;
  type?: 'image' | 'pdf' | 'csv';
  source?: FileWithPreview | string | null;
  getPreview?: (withPreview: FileWithPreview) => void;
  onDropSuccess?: (file: File) => void;
  loading?: boolean;
  uploadLoading?: boolean;
  progress?: number;
  done?: boolean;
  containerStyle?: StyleProp<ImageStyle>;
  placeholderStyle?: StyleProp<ImageStyle>;
  imageSize?: {
    height: number;
    width: number;
  };
  maxFileSize?: number;
  bottomNote?: string;
  topNote?: string;
  withBorder?: boolean;
  getError?: (error: boolean) => void;
  withUploadText?: boolean;
  getOrientation?: (orientation: ThumbnailOrientation) => void;
  getPreviewRatio?: (ratio: number) => void;
};

const defaultImageSize = {
  height: 300,
  width: 300,
};

const useCircularProgressStyles = makeStyles({
  bottom: { color: colors.progress.background },
  top: { position: 'absolute', left: 0, top: 0, color: colors.progress.value },
});

const Dropzone = memo((props: Props) => {
  const circularProgressClasses = useCircularProgressStyles();
  let {
    note,
    source,
    bottomNote,
    getPreview,
    loading,
    uploadLoading,
    progress,
    done,
    onDropSuccess,
    containerStyle,
    placeholderStyle,
    getError,
    getOrientation,
    getPreviewRatio,
    withBorder = true,
    maxFileSize = 10000000,
    type = 'image',
    imageSize,
    withUploadText,
    disabled,
    ...dropzoneProps
  } = props;
  let [isSizeBigger, setIsSizeBigger] = useState<boolean>(false);
  //TODO: errorMessage to Show
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [errorMessage, setErrorMessage] = useState<string>('');
  const [pdfOrientation, setPdfOrientation] = useState<ThumbnailOrientation>(
    Orientation.VERTICAL,
  );
  const flatContainerStyle = StyleSheet.flatten(containerStyle);
  const containerStyleSize = useMemo(
    () =>
      flatContainerStyle
        ? {
            borderRadius: flatContainerStyle.borderRadius, // NOTE: to override styles.preview
            width: flatContainerStyle.width,
            height: flatContainerStyle.height,
          }
        : {},
    [flatContainerStyle],
  );

  let getImageSize = (uri: string) => {
    Image.getSize(
      uri,
      (width, height) => {
        if (uri === '') {
          return;
        }
        if (
          !!imageSize &&
          (width > imageSize.width || height > imageSize.height)
        ) {
          setIsSizeBigger(true);
          getError?.(true);
        } else {
          setIsSizeBigger(false);
          getError?.(false);
        }
      },
      (error) => {
        setErrorMessage(error);
      },
    );
  };

  const accept = useMemo(() => {
    switch (type) {
      case 'pdf':
        return 'application/pdf';
      case 'image':
        return 'image/*';
      case 'csv':
        return ['text/csv', '.csv'];
    }
  }, [type]);

  const onDrop = useCallback(
    (acceptedFiles: Array<File>) => {
      onDropSuccess?.(acceptedFiles[0]);
      getPreview?.(
        // NOTE: only do the mapping process if getPreview exists
        acceptedFiles.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }))[0],
      );
    },
    [onDropSuccess, getPreview],
  );

  const previewSource = useMemo(() => {
    if (source) {
      if (typeof source === 'string') {
        return { uri: source };
      } else {
        return { uri: source.preview };
      }
    }
  }, [source]);

  const onLoadDocumentSuccess = useCallback(
    async (pdf) => {
      const firstPage = await pdf.getPage(1);
      const { width, height } = firstPage.getViewport({ scale: 1 });
      getPreviewRatio?.(width / height);
      if (width > height) {
        setPdfOrientation(Orientation.HORIZONTAL);
        getOrientation?.(Orientation.HORIZONTAL);
      } else {
        getOrientation?.(Orientation.VERTICAL);
      }
    },
    [getPreviewRatio, getOrientation],
  );

  const pdfSize = useMemo(() => {
    return {
      height:
        pdfOrientation === 'VERTICAL'
          ? imageSize?.height ?? defaultImageSize.height
          : 0,
      width:
        pdfOrientation === 'HORIZONTAL'
          ? imageSize?.width ?? defaultImageSize.width
          : 0,
    };
  }, [imageSize, pdfOrientation]);

  const rootDivStyle = useMemo(() => {
    return StyleSheet.flatten([
      styles.root,
      styles.border,
      { cursor: disabled ? 'default' : 'pointer' },
      isSizeBigger
        ? { borderColor: colors.border.error }
        : { borderColor: colors.border.primary },
      containerStyle,
    ]) as CSSProperties;
  }, [disabled, isSizeBigger, containerStyle]);

  const rootDivNoBorderStyle = useMemo(() => {
    return StyleSheet.flatten([
      styles.root,
      { cursor: disabled ? 'default' : 'pointer' },
      containerStyle,
    ]) as CSSProperties;
  }, [disabled, containerStyle]);

  return (
    <View>
      <ReactDropzone
        accept={accept}
        multiple={false}
        preventDropOnDocument
        onDrop={onDrop}
        maxSize={maxFileSize}
        disabled={disabled || uploadLoading}
        {...dropzoneProps}
      >
        {({ getRootProps, getInputProps }) => {
          let content;
          if (loading) {
            content = <ActivityIndicator />;
          } else if (type === 'image' && previewSource) {
            getImageSize(previewSource.uri);
            content = (
              <Image
                source={previewSource}
                style={[styles.preview, containerStyleSize]}
                resizeMode="contain"
              />
            );
          } else if (type === 'pdf' && previewSource) {
            content = (
              <Document
                file={previewSource.uri}
                loading={<Text>{t(['Memuat...', 'Loading...'])}</Text>}
                error={
                  <Text>
                    {t([
                      'Oops, gagal memuat PDF.',
                      'Oops, failed to load PDF.',
                    ])}
                  </Text>
                }
                onLoadSuccess={onLoadDocumentSuccess}
              >
                <Page
                  pageNumber={1}
                  renderAnnotationLayer={false}
                  {...pdfSize}
                />
              </Document>
            );
          } else if (type === 'csv' && typeof source === 'object' && source) {
            if (uploadLoading) {
              content = (
                <View>
                  <CircularProgress
                    variant="static"
                    value={100}
                    size={60}
                    thickness={5}
                    className={circularProgressClasses.bottom}
                  />
                  <CircularProgress
                    variant="static"
                    value={progress}
                    size={60}
                    thickness={5}
                    className={circularProgressClasses.top}
                  />
                  <View style={styles.progressTextWrapper}>
                    <Text size="small">{progress}%</Text>
                  </View>
                </View>
              );
            } else if (done) {
              content = (
                <View>
                  <CheckIcon
                    style={StyleSheet.flatten(styles.progressDoneIcon)}
                    htmlColor={colors.icon.primary}
                  />
                </View>
              );
            } else if (progress === 0) {
              content = <Text>{source.file.name}</Text>;
            }
          } else {
            content = (
              <>
                <Image
                  source={uploadPlaceholder}
                  style={[styles.preview, styles.placeholder, placeholderStyle]}
                  resizeMode="contain"
                />
                {withUploadText && (
                  <Text bold color="link" size="small" style={styles.preview}>
                    {t(['Unggah Berkas', 'Upload File'])}
                  </Text>
                )}
              </>
            );
          }
          return withBorder ? (
            <>
              {note && (
                <Text
                  bold
                  color={isSizeBigger ? 'error' : 'link'}
                  size="small"
                  style={styles.topNote}
                >
                  {note}
                </Text>
              )}
              <View>
                <div {...getRootProps({ style: rootDivStyle })}>
                  <input
                    data-cy={props['data-cy']}
                    disabled={disabled}
                    {...getInputProps()}
                  />
                  {content}
                  {disabled && (
                    <View
                      style={[styles.root, styles.disabled, containerStyleSize]}
                    />
                  )}
                </div>
              </View>
              {bottomNote && (
                <Text
                  bold
                  color={isSizeBigger ? 'error' : 'link'}
                  size="small"
                  style={styles.bottomNote}
                >
                  {bottomNote}
                </Text>
              )}
            </>
          ) : (
            <>
              <div {...getRootProps({ style: rootDivNoBorderStyle })}>
                <input
                  data-cy={props['data-cy']}
                  disabled={disabled}
                  {...getInputProps()}
                />
                <Image
                  source={uploadPlaceholder}
                  style={[styles.preview, styles.placeholder, placeholderStyle]}
                  resizeMode="contain"
                />
                <Text bold color="link" size="small" style={styles.preview}>
                  {t(['Unggah Berkas', 'Upload File'])}
                </Text>
                {disabled && (
                  <View
                    style={[styles.root, styles.disabled, containerStyleSize]}
                  />
                )}
              </div>
            </>
          );
        }}
      </ReactDropzone>
    </View>
  );
});

const styles = StyleSheet.create({
  border: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  disabled: {
    ...StyleSheet.absoluteFillObject,
    top: 1,
    left: 1,
    backgroundColor: colors.dropzone.disabled,
    borderRadius: 4,
  },
  preview: {
    borderRadius: 4,
  },
  placeholder: { width: 100, height: 100 },
  topNote: { paddingBottom: spacing.small },
  bottomNote: { paddingTop: spacing.small },
  progressRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressText: { minWidth: 32, textAlign: 'right' },
  progressTextWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressDoneIcon: { fontSize: 40 },
});

export default Dropzone;
