import gql from 'graphql-tag';

export const REQUEST_MATCHMAKING = gql`
  query RequestMatchMaking($storeId: String!, $isVerified: Boolean) {
    requestMatchMaking(storeId: $storeId, isVerified: $isVerified) {
      id
      agentName
      brandId
      brand {
        name
      }
    }
  }
`;
