import React, { useState, useCallback } from 'react';
import { StyleSheet } from 'react-native';

import { SearchHeader, Header, Option, BrandDropdown } from '../../components';
import { theme } from '../../constants/theme';
import { LibraryDocument } from '../../generated/LibraryDocument';
import { LibraryDocumentsVariables } from '../../generated/LibraryDocuments';

import LibraryTable from './LibraryTable';

type Props = {
  refetchToggle?: boolean;
  hideEdit?: boolean;
  hideArchiveCheck?: boolean;
  queryVariables?: LibraryDocumentsVariables;
  onSubmit?: () => void;
  setEditDocument?: (value: LibraryDocument) => void;
};

const LibrarySearch = (props: Props) => {
  let {
    refetchToggle,
    hideEdit,
    hideArchiveCheck,
    queryVariables,
    onSubmit,
    setEditDocument,
  } = props;

  // NOTE: these are used for filter state
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState<Option | null>(null);

  // NOTE: these are used to filter the queries
  const [searchArchived, setSearchArchived] = useState(false);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);

  const clearSearch = useCallback(() => {
    setIncludeArchived(false);
    setSearchArchived(false);
    setSelectedBrand(null);
    setSearchByBrand(null);
  }, [
    setIncludeArchived,
    setSearchArchived,
    setSelectedBrand,
    setSearchByBrand,
  ]);

  const submitSearch = useCallback(() => {
    setSearchArchived(includeArchived);
    setSearchByBrand(selectedBrand?.value.toString() ?? null);
  }, [setSearchArchived, includeArchived, setSearchByBrand, selectedBrand]);

  return (
    <>
      <SearchHeader
        data-cy="search-library"
        archivable={!hideArchiveCheck}
        includeArchived={includeArchived}
        title={t(['Perpustakaan Dokumen', 'Document Library'])}
        onArchivePress={setIncludeArchived}
        onClearPress={clearSearch}
        onSearchPress={submitSearch}
      >
        <BrandDropdown
          data-cy="search-library-dropdown"
          title={t(['Cari Brand', 'Search by Brand'])}
          type="side"
          selectedOption={selectedBrand?.value}
          onSelect={setSelectedBrand}
        />
      </SearchHeader>
      <Header
        withSeparator
        title={t(['Hasil', 'Results'])}
        titleColor="link"
        infotipContent={t([
          'Seluruh data Library akan ditampilkan pada daftar berikut. Klik Judul Kolom untuk penyortiran berdasarkan judul kolom yang di kilik. Klik tulisan “Lihat” untuk melihat detail konten judul tersebut. Klik “Icon” Arsip untuk mengirimkan data ke halaman folder arsip. Data yang sudah berada di Folder Arsip tidak akan nampak pada daftar ini, Anda dapat mengembalikan ke daftar ini dengan cara masuk pada menu “Halaman Folder Arsip”.',
          'All Library data will be displayed in the following list. Click Column Headers for sorting based on the column headings that are clicked. Click "View" to see the detailed content of the title. Click the Archive “Icon” to send the data to the archive folder page. The data that is already in the Archive Folder will not appear in this list, you can return the data to this list by going to the "Archive Folder Page" menu.',
        ])}
        style={styles.topPadding}
      />
      <LibraryTable
        data-cy="library-table"
        hideEdit={hideEdit}
        queryVariables={queryVariables}
        searchArchived={searchArchived}
        searchByBrand={searchByBrand ?? undefined}
        refetchToggle={refetchToggle}
        onSubmit={onSubmit}
        setEditDocument={setEditDocument}
      />
    </>
  );
};

const styles = StyleSheet.create({
  topPadding: { paddingTop: theme.spacing.xlarge },
});

export default LibrarySearch;
