import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { Text } from '../../core-ui';
import { HeaderNavigationBar, Header } from '../../components';
import { spacing, colors } from '../../constants/theme';
import { PopUpFragment } from '../../generated/PopUpFragment';
import { pageTitle } from '../../constants/pageTitle';

import CustomPopUpForm from './CustomPopUpForm';
import CustomPopUpList from './CustomPopUpList';
import CustomPopUpTable from './CustomPopUpTable';
import AddNewPopUpMessages from './AddNewPopUpMessages';

const PopUpScene = () => {
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [editPopUp, setEditPopUp] = useState<PopUpFragment | null>(null);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.PopUpScene });
    };
    eventLog();
  }, []);

  const onSubmit = useCallback(() => {
    setRefetchToggle(!refetchToggle);
    setEditPopUp(null);
  }, [refetchToggle, setRefetchToggle, setEditPopUp]);

  const onArchive = useCallback(() => setRefetchToggle(!refetchToggle), [
    refetchToggle,
    setRefetchToggle,
  ]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <View style={styles.section}>
        <Header
          title={t(['Admin Hub', 'Admin Hub'])}
          subtitle={t(['Pesan Pop Up', 'Pop Up Messages'])}
          subtitleColor="default"
        />
        <Text style={styles.note}>
          {t([
            'Tambah & ubah pesan & peringatan',
            'Add & edit messages & alerts',
          ])}
        </Text>
      </View>
      <View style={styles.section}>
        <CustomPopUpForm editPopUp={editPopUp} onSubmit={onSubmit} />
      </View>
      <View style={styles.separator} />
      <View style={styles.section}>
        <CustomPopUpList
          refetchToggle={refetchToggle}
          editPopUp={editPopUp}
          setEditPopUp={setEditPopUp}
          onArchive={onArchive}
        />
      </View>
      <View style={styles.section}>
        <AddNewPopUpMessages
          refetchToggle={refetchToggle}
          onSubmitComplete={onSubmit}
        />
      </View>
      <CustomPopUpTable refetchToggle={refetchToggle} onArchive={onArchive} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  section: { paddingBottom: spacing.xlarge },
  note: { paddingTop: spacing.xsmall },
  separator: {
    marginBottom: spacing.medium,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
});

export default PopUpScene;
