import gql from 'graphql-tag';

export const UPDATE_STORE_POINT = gql`
  mutation UpdateStorePoint(
    $storeId: ID!
    $action: LumpSumAction!
    $lumpSumType: LumpSumType
    $promotionId: ID
    $eventId: ID
    $brandId: ID
    $competitionId: ID
    $point: Float!
    $note: String!
    $nameActivity: String
  ) {
    updateStorePoint(
      storeId: $storeId
      action: $action
      lumpSumType: $lumpSumType
      promotionId: $promotionId
      eventId: $eventId
      brandId: $brandId
      competitionId: $competitionId
      point: $point
      note: $note
      nameActivity: $nameActivity
    ) {
      success
    }
  }
`;
