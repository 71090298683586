import React, { useCallback } from 'react';
import { View, StyleSheet } from 'react-native';

import { Button } from '../core-ui';
import { spacing } from '../constants/theme';

type Props<T> = {
  'data-cy'?: string;
  tags: Array<T>;
  selectedTags: Array<T>;
  setSelectedTags: (tags: Array<T>) => void;
  customTitles?: Array<string>;
};

const Tags = <T extends string>(props: Props<T>) => {
  let { tags, selectedTags, setSelectedTags, customTitles } = props;

  const toggleTags = useCallback(
    (tag: T) => {
      let filteredTags = [];
      let newSelectedTags = selectedTags;
      if (newSelectedTags.includes(tag)) {
        filteredTags = newSelectedTags.filter((item) => item !== tag);
      } else {
        newSelectedTags.push(tag);
        filteredTags = [...newSelectedTags];
      }
      setSelectedTags(filteredTags);
    },
    [selectedTags, setSelectedTags],
  );
  return (
    <View style={styles.root}>
      {tags.map((tag, index) => {
        let isSelected = selectedTags.includes(tag);
        return (
          <Button
            large
            key={index}
            data-cy={`${props['data-cy']}-${index}`}
            style={index !== tags.length - 1 ? styles.button : undefined}
            onPress={() => toggleTags(tag)}
            title={
              customTitles && customTitles?.length > 0
                ? customTitles[index]
                : tag.split('_').join(' ') ?? '-'
            }
            preset={isSelected ? 'primary' : 'secondary'}
            titleTextProps={isSelected ? {} : { color: 'black' }}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  root: { flexDirection: 'row', flex: 1, flexWrap: 'wrap' },
  button: { marginRight: spacing.s, marginBottom: spacing.s },
});

export default Tags;
