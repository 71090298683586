import React from 'react';
import { View, StyleSheet, Image, Dimensions } from 'react-native';

import { Modal } from '../../components';
import { Button, Text } from '../../core-ui';
import { spacing } from '../../constants/theme';
import { correctIcon } from '../../../assets';

type Props = {
  open: boolean;
  onClose: () => void;
};
export default function ModalSameAgent({ open, onClose }: Props) {
  const windowWidth = Dimensions.get('window').width;
  const paddingHorizontal = windowWidth * 0.1;
  const padding = `${spacing.large}px ${paddingHorizontal}px`;
  return (
    <Modal open={open} onClose={onClose} contentStyle={{ padding }}>
      <View style={styles.center}>
        <Image source={correctIcon} style={styles.icon} />
        <Text style={styles.message}>
          {t([
            'Sudah terhubung pada distributor',
            'Sudah terhubung pada distributor',
          ])}
        </Text>
        <Button preset="primary" title={'Ok'} onPress={onClose} />
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  message: {
    textAlign: 'center',
    marginVertical: spacing.small,
  },
  icon: { width: 56, height: 50 },
});
