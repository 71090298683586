import React, { useState, useCallback, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { View, StyleSheet, Image, ActivityIndicator } from 'react-native';
import { useParams, useHistory } from 'react-router-dom';
import { logEvent } from 'expo-firebase-analytics';

import { Option, ErrorModal, ErrorMessage } from '../../components';
import { SetAgent, SetAgentVariables } from '../../generated/SetAgent';
import { SET_AGENT } from '../../graphql/mutations';
import { spacing } from '../../constants/theme';
import { correctIcon } from '../../../assets';
import { Text, Button } from '../../core-ui';
import { GET_AGENT_REQUEST_BY_TOKEN } from '../../graphql/queries';
import {
  AgentRequestByToken,
  AgentRequestByTokenVariables,
} from '../../generated/AgentRequestByToken';
import {
  CmsRequestStatus,
  RequestStatus,
  RoleAdmin,
} from '../../generated/globalTypes';
import { pageTitle } from '../../constants/pageTitle';
import { useAuth } from '../../context';
import { routePaths } from '../../constants/routes';

import SceneSelector from './SceneSelector';
import ModalSameAgent from './ModalSameAgent';

const BusinessUnitFacing = () => {
  const { token } = useParams();
  const { role } = useAuth();
  const history = useHistory();
  const [selectedDistributor, setSelectedDistributor] = useState<Option | null>(
    null,
  );
  const [rejectReason, setRejectReason] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [isSuccess, setSuccess] = useState(false);
  const [isSameAgentSuccess, setSameAgentSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengirim data distributor', 'submit distributor data']));
    setErrorInstance(error);
  }, []);

  const { data, loading, error, refetch } = useQuery<
    AgentRequestByToken,
    AgentRequestByTokenVariables
  >(GET_AGENT_REQUEST_BY_TOKEN, {
    variables: { token },
  });

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.BusinessUnitFacing,
      });
    };
    eventLog();
  }, []);

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const [setAgent, { loading: submitLoading }] = useMutation<
    SetAgent,
    SetAgentVariables
  >(SET_AGENT, {
    onCompleted: (data) => {
      if (data.setAgent.success && !data.setAgent.isSameAgent) {
        setSuccess(true);
      } else {
        setSameAgentSuccess(true);
      }
    },
    onError: openErrorModal,
  });

  const onSubmit = useCallback(() => {
    setAgent({
      variables: {
        requestToken: token,
        agentId: selectedDistributor ? selectedDistributor.value : '',
        rejectReason: '',
      },
    });
  }, [selectedDistributor, token, setAgent]);

  const onReject = useCallback(() => {
    setAgent({
      variables: {
        requestToken: token,
        agentId: selectedDistributor ? selectedDistributor.value : '',
        rejectReason: rejectReason ? rejectReason : '',
      },
    });
  }, [token, rejectReason, setAgent, selectedDistributor]);

  //NOTE: to prevent user from setting agent multiple times
  useEffect(() => {
    if (
      data &&
      ((data.requestAgent.status !== RequestStatus.REQUEST &&
        data.requestAgent.status !== RequestStatus.CANCELED) ||
        (data.requestAgent.cmsRequestStatus !== CmsRequestStatus.WAITING &&
          data.requestAgent.cmsRequestStatus !== CmsRequestStatus.CANCEL) ||
        !data.requestAgent.store.storeVerified ||
        !data.requestAgent.store.storeManager?.statusActive ||
        (data.requestAgent.status === RequestStatus.CANCELED &&
          data.requestAgent.isCancelledInAction === true))
    ) {
      setDisabled(true);
    }
  }, [data]);

  const onGoBackButtonPress = useCallback(() => {
    history.push(
      role === RoleAdmin.PRINCIPAL ? '/' : routePaths.agentRequestStatus,
    );
  }, [history, role]);

  return (
    <>
      {loading && <ActivityIndicator style={styles.root} />}
      {!!error && (
        <View style={styles.root}>
          <ErrorMessage
            action={t([
              'mengambil data pengajuan distributor',
              'retrieve the distributor application data',
            ])}
            error={error}
            onPress={refetchData}
          />
        </View>
      )}
      {isSuccess ? (
        <View style={styles.root}>
          <Image
            source={correctIcon}
            resizeMode="contain"
            style={styles.icon}
          />
          <Text bold size="large" style={styles.spacing}>
            {t(['Selamat', 'Congratulations'])}
          </Text>
          <Text>
            {t([
              'Pengajuan distributor untuk toko sudah sukses',
              'Distributor application for toko is successful',
            ])}
          </Text>
          {role !== RoleAdmin.PRINCIPAL ? null : (
            <Button
              style={styles.goBackButton}
              title={t([
                'Kembali ke Daftar Permintaan Distributor',
                'Go to Distributor Request List',
              ])}
              onPress={onGoBackButtonPress}
            />
          )}
        </View>
      ) : (
        data && (
          <SceneSelector
            review={{
              data: data,
              inputPlaceholder: t(['Cari Distributor', 'Look up Distributor']),
              selectedDistributor: selectedDistributor ?? undefined,
              rejectReason: rejectReason,
              submitLoading: submitLoading,
              submitDisabled: !selectedDistributor,
              onSelectDistributor: setSelectedDistributor,
              onSetRejectReason: setRejectReason,
              onSubmit: onSubmit,
              onReject: onReject,
            }}
            disabled={disabled}
          />
          // <ReviewAgentApplication
          //   data={data}
          //   title={t([
          //     'Pengajuan Distributor untuk Toko',
          //     'Distributor Application for Toko',
          //   ])}
          //   inputPlaceholder={t(['Cari Distributor', 'Look up Distributor'])}
          //   selectedDistributor={selectedDistributor ?? undefined}
          //   rejectReason={rejectReason}
          //   submitLoading={submitLoading}
          //   submitDisabled={!selectedDistributor}
          //   onSelectDistributor={setSelectedDistributor}
          //   onSetRejectReason={setRejectReason}
          //   onSubmit={onSubmit}
          // />
        )
      )}
      <ModalSameAgent open={isSameAgentSuccess} onClose={onGoBackButtonPress} />
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 48,
    height: 36,
  },
  spacing: {
    marginVertical: spacing.small,
  },
  goBackButton: { marginTop: 24 },
});

export default BusinessUnitFacing;
