import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { FileWithPreview } from '../../core-ui/Dropzone';
import { Option, Header, ErrorModal } from '../../components';
import { spacing } from '../../constants/theme';
import {
  CREATE_COMPETITION,
  UPDATE_COMPETITION,
  CREATE_EVENT,
  UPDATE_EVENT,
  CREATE_NEWS,
  UPDATE_NEWS,
  UPLOAD,
  EMAIL_COMPETITION_WINNER,
} from '../../graphql/mutations';
import {
  EMAIL_COMPETITION_TO_BU,
  EMAIL_EVENT_TO_BU,
  EMAIL_NEWS_TO_BU,
} from '../../graphql/queries';
import {
  CreateCompetition,
  CreateCompetitionVariables,
} from '../../generated/CreateCompetition';
import {
  UpdateCompetition,
  UpdateCompetitionVariables,
} from '../../generated/UpdateCompetition';
import { CreateEvent, CreateEventVariables } from '../../generated/CreateEvent';
import { UpdateEvent, UpdateEventVariables } from '../../generated/UpdateEvent';
import { CreateNews, CreateNewsVariables } from '../../generated/CreateNews';
import { UpdateNews, UpdateNewsVariables } from '../../generated/UpdateNews';
import { Upload, UploadVariables } from '../../generated/Upload';
import { CompetitionFragment } from '../../generated/CompetitionFragment';
import { EventFragment } from '../../generated/EventFragment';
import { NewsFragment } from '../../generated/NewsFragment';
import {
  EmailCompetitionToBU,
  EmailCompetitionToBUVariables,
} from '../../generated/EmailCompetitionToBU';
import {
  EmailEventToBU,
  EmailEventToBUVariables,
} from '../../generated/EmailEventToBU';
import {
  CompetitionSocialMediaInput,
  MediaSocial,
  FolderType,
  NewsTypeLink,
} from '../../generated/globalTypes';
import {
  EmailCompetitionWinner,
  EmailCompetitionWinnerVariables,
} from '../../generated/EmailCompetitionWinner';
import {
  EmailNewsToBU,
  EmailNewsToBUVariables,
} from '../../generated/EmailNewsToBU';
import { Button } from '../../core-ui';

import StepOneToFive, { NewsCategory } from './StepOneToFive';
import StepSixCompetition, { EnterOptions } from './StepSixCompetition';
import StepSevenCompetition, {
  CompetitionWinner,
} from './StepSevenCompetition';
import StepEightCompetition from './StepEightCompetition';
import StepSixEvent from './StepSixEvent';
import StepSixNews from './StepSixNews';
import StepOneToFiveNews from './StepOneToFiveNews';

type Props = {
  editCompetition: CompetitionFragment | null;
  editEvent: EventFragment | null;
  editNews: NewsFragment | null;
  setCategory: (value: NewsCategory) => void;
  onSubmit: () => void;
};

const blankData = {
  id: null as string | null,
  name: '',
  notified: false,
  email: '',
  storeName: '',
};

const CreateDboNews = (props: Props) => {
  let { editCompetition, editEvent, editNews, setCategory, onSubmit } = props;
  const [about, setAbout] = useState('');
  const [aboutSalesman, setAboutSalesman] = useState('');
  const [selectedEvent, setSelectedEvent] = useState<NewsCategory | null>(null);
  const [selectedAppUser, setSelectedAppUser] = useState<string | null>(null);
  // NOTE: selectedRegion = region ID for city dropdown
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  // NOTE: regionName = region name for BE data
  const [regionName, setRegionName] = useState<string | null>(null);
  const [, setSelectedCity] = useState<string | null>(null);
  const [cityName, setCityName] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editable, setEditable] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [name, setName] = useState('');
  const [externalButton, setExternalButton] = useState('');
  const [venue, setVenue] = useState('');
  const [dateTime, setDateTime] = useState(new Date());
  const [closeDate, setCloseDate] = useState(new Date());
  const [registrationRequired, setRegistrationRequired] = useState(true);
  const [registrationDeadline, setRegistrationDeadline] = useState(new Date());
  const [externalLink, setExternalLink] = useState('');
  const [salesmanAppDeepLink, setSalesmanAppDeepLink] = useState('');
  //NOTE: brandId = brand id of selected bu
  //      brandBU = id of selected bu
  //      we need both id because competition & event uses brand ID for mutations,
  //      meanwhile news uses business contact ID for mutations
  const [brandId, setBrandId] = useState<string | null>(null);
  const [brandBU, setBrandBU] = useState<string | null>(null);
  const [image, setImage] = useState<FileWithPreview | string | null>(null);
  const [dedicatedPageImage, setDedicatedPageImage] = useState<
    FileWithPreview | string | null
  >(null);
  const [liveStartDate, setLiveStartDate] = useState(new Date());
  const [liveEndDate, setLiveEndDate] = useState(new Date());
  const [enterChannels, setEnterChannels] = useState<EnterOptions>([
    false,
    false,
    false,
  ]);
  const [facebookLink, setFacebookLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');
  const [winnersList, setWinners] = useState<Array<CompetitionWinner>>([]);
  const [brand, setBrand] = useState<string | null>(null);
  const [range, setRange] = useState<string | null>(null);
  const [managerBrandId, setManagerBrandId] = useState<string | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const [screen, setScreen] = useState<string | null>(null);
  const [subScreenOne, setSubScreenOne] = useState<string | null>(null);
  const [subScreenTwo, setSubScreenTwo] = useState<string | null>(null);

  const [linkType, setLinkType] = useState<NewsTypeLink>(NewsTypeLink.RANGE);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty(),
  );
  const [salesmanEditorState, setSalesmanEditorState] = useState<EditorState>(
    () => EditorState.createEmpty(),
  );

  const createSetSelected = useCallback(
    <T extends unknown>(
      setter: (value: T) => void,
      labelSetter?: (value: T) => void,
      ...nullSetter: Array<(value: null) => void>
    ) => (selected: Option | null) => {
      setter((selected?.value ?? null) as T);
      labelSetter?.((selected?.label ?? null) as T);
      for (let setToNull of nullSetter) {
        setToNull(null);
      }
    },
    [],
  );

  const wrappedSetBrandBU = useCallback((selected: Option) => {
    if (selected.label === '-') {
      setBrandBU(selected.value);
      setBrandId(null);
    } else {
      setBrandBU(selected.value);
      setBrandId(selected.subValue ?? null);
    }
  }, []);

  const setEditorContent = useCallback((content: string) => {
    let { contentBlocks, entityMap } = htmlToDraft(content);
    let contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  const setEditorSalesmanContent = useCallback((content: string) => {
    let { contentBlocks, entityMap } = htmlToDraft(content);
    let contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap,
    );
    setSalesmanEditorState(EditorState.createWithContent(contentState));
  }, []);

  const resetStates = useCallback(() => {
    setAbout('');
    setAboutSalesman('');
    setSalesmanAppDeepLink('');
    setEditorContent('');
    setEditorSalesmanContent('');
    setSelectedAppUser(null);
    setSelectedRegion(null);
    setRegionName(null);
    setSelectedCity(null);
    setCityName(null);
    setSelectedDate(new Date());
    setEditable(true);
    setName('');
    setExternalButton('');
    setVenue('');
    setDateTime(new Date());
    setCloseDate(new Date());
    setRegistrationRequired(true);
    setRegistrationDeadline(new Date());
    setExternalLink('');
    setBrandBU(null);
    setImage(null);
    setDedicatedPageImage(null);
    setLiveStartDate(new Date());
    setLiveEndDate(new Date());
    setEnterChannels([false, false, false]);
    setFacebookLink('');
    setInstagramLink('');
    setWinners([]);
  }, [
    setAbout,
    setAboutSalesman,
    setSalesmanAppDeepLink,
    setEditorContent,
    setEditorSalesmanContent,
    setSelectedAppUser,
    setSelectedRegion,
    setRegionName,
    setSelectedCity,
    setCityName,
    setSelectedDate,
    setEditable,
    setName,
    setExternalButton,
    setVenue,
    setDateTime,
    setCloseDate,
    setRegistrationRequired,
    setRegistrationDeadline,
    setExternalLink,
    setBrandBU,
    setImage,
    setDedicatedPageImage,
    setLiveStartDate,
    setLiveEndDate,
    setEnterChannels,
    setFacebookLink,
    setInstagramLink,
    setWinners,
  ]);

  useEffect(() => {
    if (editCompetition) {
      let newEnterChannels: EnterOptions = [false, false, false];
      let newFbLink = '';
      let newIgLink = '';

      for (let { socialMediaType, link } of editCompetition.medsos) {
        if (socialMediaType === MediaSocial.FACEBOOK) {
          newEnterChannels[0] = true;
          newFbLink = link;
        }
        if (socialMediaType === MediaSocial.INSTAGRAM) {
          newEnterChannels[1] = true;
          newIgLink = link;
        }
      }
      if (editCompetition.externalLink) {
        newEnterChannels[2] = true;
      }

      setEditable(false);

      setSelectedAppUser(editCompetition.appUser);
      setRegionName(editCompetition.region);
      setCityName(editCompetition.city);
      setSelectedDate(new Date(editCompetition.eventDate));
      setCloseDate(new Date(editCompetition.closeDate));
      setName(editCompetition.name);
      setExternalButton(editCompetition.externalButton ?? '');
      setAbout(editCompetition.desc ?? '');
      setAboutSalesman(editCompetition.descSalesman ?? '');
      setSalesmanAppDeepLink(editCompetition.urlDeeplinkSalesman ?? '');
      setEditorContent(editCompetition.desc ?? '');
      setEditorSalesmanContent(editCompetition.descSalesman ?? '');
      setEnterChannels(newEnterChannels);
      setFacebookLink(newFbLink);
      setInstagramLink(newIgLink);
      setExternalLink(editCompetition.externalLink ?? '');
      setBrandId(editCompetition.brand && editCompetition.brand.id);
      setBrandBU(
        (editCompetition.brand &&
          editCompetition.brand.businessUnitContact?.id) ??
          null,
      );
      setImage(editCompetition.image);
      setDedicatedPageImage(editCompetition.dedicatedPageImage);
      setLiveStartDate(new Date(editCompetition.liveDate));
      setLiveEndDate(new Date(editCompetition.endLiveDate));
      setWinners(
        editCompetition.winners.map(({ __typename, ...rest }) => ({
          ...rest,
        })),
      );
    }
  }, [editCompetition, setEditorContent, setEditorSalesmanContent]);

  useEffect(() => {
    if (editEvent) {
      setEditable(false);

      setSelectedAppUser(editEvent.appUser);
      setRegionName(editEvent.region);
      setCityName(editEvent.city);
      setSelectedDate(new Date(editEvent.eventDate));
      setName(editEvent.name);
      setExternalButton(editEvent.externalButton ?? '');
      setAbout(editEvent.desc ?? '');
      setAboutSalesman(editEvent.descSalesman ?? '');
      setSalesmanAppDeepLink(editEvent.urlDeeplinkSalesman ?? '');
      setEditorSalesmanContent(editEvent.descSalesman ?? '');
      setEditorContent(editEvent.desc ?? '');
      setVenue(editEvent.venue);
      setDateTime(new Date(editEvent.eventDate));
      setRegistrationRequired(!!editEvent.formLink || !!editEvent.externalLink);
      setRegistrationDeadline(
        editEvent.registrationDeadline
          ? new Date(editEvent.registrationDeadline)
          : new Date(),
      );
      setExternalLink(editEvent.externalLink ?? '');
      setBrandId(editEvent.brand && editEvent.brand.id);
      setBrandBU(
        (editEvent.brand && editEvent.brand.businessUnitContact?.id) ?? null,
      );
      setImage(editEvent.image || null);
      setDedicatedPageImage(editEvent.dedicatedPageImage || null);
      setLiveStartDate(new Date(editEvent.liveDate));
      setLiveEndDate(new Date(editEvent.endLiveDate));
    }
  }, [editEvent, setEditorContent, setEditorSalesmanContent]);

  useEffect(() => {
    if (editNews) {
      setEditable(false);

      setSelectedAppUser(editNews.appUser);
      setRegionName(editNews.region);
      setCityName(editNews.city);
      setSelectedDate(new Date(editNews.startDate));
      setName(editNews.title);
      setExternalButton(editNews.externalButton ?? '');
      setAbout(editNews.desc);
      setAboutSalesman(editNews.descSalesman ?? '');
      setSalesmanAppDeepLink(editNews.urlDeeplinkSalesman ?? '');
      setEditorSalesmanContent(editNews.descSalesman ?? '');
      setEditorContent(editNews.desc);
      setBrand(editNews.brandId);
      setManagerBrandId(editNews.brand?.brandId ?? '');
      setRange(editNews.rangeId);
      setBrandBU(editNews.businessUnitContact?.id ?? null);
      setImage(editNews.image);
      setDedicatedPageImage(editNews.dedicatedPageImage);
      setLiveStartDate(new Date(editNews.liveDate));
      setLiveEndDate(new Date(editNews.endLiveDate));
      setExternalLink(editNews.externalLink ?? '');
      setLinkType(editNews.typeLink ?? NewsTypeLink.RANGE);
      if (editNews.internalLink) {
        setScreen(editNews.internalLink.id ?? null);
        setSubScreenOne(editNews.brandId ?? editNews.internalLink.id ?? null);
        setSubScreenTwo(editNews.rangeId ?? null);
      }
    }
  }, [editNews, setEditorContent, setEditorSalesmanContent]);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorInstance(error);
      setErrorAction(action);
    },
    [],
  );
  const onUploadError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);
  const onCreateCompetitionError = useMemo(() => {
    return openErrorModal(
      t(['membuat data kompetisi', 'create competition data']),
    );
  }, [openErrorModal]);
  const onUpdateCompetitionError = useMemo(() => {
    return openErrorModal(
      t(['mengubah data kompetisi', 'update competition data']),
    );
  }, [openErrorModal]);
  const onCreateEventError = useMemo(() => {
    return openErrorModal(t(['membuat data event', 'create event data']));
  }, [openErrorModal]);
  const onUpdateEventError = useMemo(() => {
    return openErrorModal(t(['mengubah data event', 'update event data']));
  }, [openErrorModal]);
  const onCreateNewsError = useMemo(() => {
    return openErrorModal(t(['membuat data berita', 'create news data']));
  }, [openErrorModal]);
  const onUpdateNewsError = useMemo(() => {
    return openErrorModal(t(['mengubah data berita', 'update news data']));
  }, [openErrorModal]);
  const onSendEmailBuError = useMemo(() => {
    return openErrorModal(
      t(['mengirim email ke principal', 'send email to principal']),
    );
  }, [openErrorModal]);
  const onSendEmailWinnerError = useMemo(() => {
    return openErrorModal(
      t(['mengirim email ke pemenang', 'send email to winner']),
    );
  }, [openErrorModal]);
  const onMutationCompleted = useCallback(() => {
    onSubmit();
    resetStates();
  }, [onSubmit, resetStates]);

  const [uploadFile, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, {
    onError: onUploadError,
  });
  const [createCompetition, { loading: createCompLoading }] = useMutation<
    CreateCompetition,
    CreateCompetitionVariables
  >(CREATE_COMPETITION, {
    onCompleted: onMutationCompleted,
    onError: onCreateCompetitionError,
  });
  const [updateCompetition, { loading: updateCompLoading }] = useMutation<
    UpdateCompetition,
    UpdateCompetitionVariables
  >(UPDATE_COMPETITION, {
    onCompleted: onMutationCompleted,
    onError: onUpdateCompetitionError,
  });
  const [
    updateWinners,
    { loading: updateWinnersLoading, error: updateWinnerError },
  ] = useMutation<UpdateCompetition, UpdateCompetitionVariables>(
    UPDATE_COMPETITION,
  );
  const [createEvent, { loading: createEventLoading }] = useMutation<
    CreateEvent,
    CreateEventVariables
  >(CREATE_EVENT, {
    onCompleted: onMutationCompleted,
    onError: onCreateEventError,
  });
  const [updateEvent, { loading: updateEventLoading }] = useMutation<
    UpdateEvent,
    UpdateEventVariables
  >(UPDATE_EVENT, {
    onCompleted: onMutationCompleted,
    onError: onUpdateEventError,
  });
  const [createNews, { loading: createNewsLoading }] = useMutation<
    CreateNews,
    CreateNewsVariables
  >(CREATE_NEWS, {
    onCompleted: onMutationCompleted,
    onError: onCreateNewsError,
  });
  const [updateNews, { loading: updateNewsLoading }] = useMutation<
    UpdateNews,
    UpdateNewsVariables
  >(UPDATE_NEWS, {
    onCompleted: onMutationCompleted,
    onError: onUpdateNewsError,
  });
  const [emailCompetition] = useLazyQuery<
    EmailCompetitionToBU,
    EmailCompetitionToBUVariables
  >(EMAIL_COMPETITION_TO_BU, { onError: onSendEmailBuError });
  const [emailEvent] = useLazyQuery<EmailEventToBU, EmailEventToBUVariables>(
    EMAIL_EVENT_TO_BU,
    { onError: onSendEmailBuError },
  );
  const [emailNews] = useLazyQuery<EmailNewsToBU, EmailNewsToBUVariables>(
    EMAIL_NEWS_TO_BU,
    { onError: onSendEmailBuError },
  );
  const [emailWinner] = useMutation<
    EmailCompetitionWinner,
    EmailCompetitionWinnerVariables
  >(EMAIL_COMPETITION_WINNER, { onError: onSendEmailWinnerError });

  const onSendEmailEvent = useCallback(() => {
    if (editEvent) {
      emailEvent({ variables: { eventId: editEvent.id } });
    }
  }, [editEvent, emailEvent]);
  const onSendEmailCompetition = useCallback(() => {
    if (editCompetition) {
      emailCompetition({ variables: { competitionId: editCompetition.id } });
    }
  }, [editCompetition, emailCompetition]);
  const onSendEmailNews = useCallback(() => {
    if (editNews) {
      emailNews({ variables: { newsId: editNews.id } });
    }
  }, [editNews, emailNews]);
  const onSendEmailWinner = useCallback(
    (winnerId: string) => {
      emailWinner({ variables: { winnerId } });
    },
    [emailWinner],
  );

  const onNameChange = useCallback(
    (name, index) => {
      setWinners(
        winnersList.map((winner, i) =>
          i === index
            ? {
                ...winner,
                name,
              }
            : winner,
        ),
      );
    },
    [winnersList],
  );
  const onStoreNameChange = useCallback(
    (storeName, index) => {
      setWinners(
        winnersList.map((winner, i) =>
          i === index
            ? {
                ...winner,
                storeName,
              }
            : winner,
        ),
      );
    },
    [winnersList],
  );
  const onEmailChange = useCallback(
    (email, index) => {
      setWinners(
        winnersList.map((winner, i) =>
          i === index
            ? {
                ...winner,
                email,
              }
            : winner,
        ),
      );
    },
    [winnersList],
  );

  const onAddWinner = useCallback(() => {
    setSaveDisabled(true);
    setWinners([...winnersList, blankData as CompetitionWinner]);
  }, [winnersList, setWinners]);

  const onDeleteWinner = useCallback(
    (index) => {
      if (editCompetition) {
        let newWinners = winnersList;
        if (newWinners[index].id == null) {
          let deletedWinners = newWinners.filter((item, i) => {
            return i !== index;
          });
          setWinners(deletedWinners);
        } else if (index <= newWinners.length - 1) {
          let {
            __typename,
            id,
            brand,
            image,
            dedicatedPageImage,
            name,
            desc,
            closeDate,
            winners,
            ...others
          } = editCompetition;
          updateWinners({
            variables: {
              image: image || '',
              dedicatedPageImage: dedicatedPageImage || '',
              competitionId: id,
              brandId: brand ? brand.id : '',
              competitionName: name,
              competitionDesc: desc || '',
              competitionCloseDate: closeDate,
              winners: {
                createEdit: [],
                delete: [{ id: newWinners[index].id }],
              },
              ...others,
            },
          });
          newWinners.splice(index, 1);
          if (updateWinnersLoading && !updateWinnerError) {
            setWinners(newWinners);
          }
        }
      }
    },
    [
      winnersList,
      setWinners,
      updateWinners,
      editCompetition,
      updateWinnersLoading,
      updateWinnerError,
    ],
  );
  const processFileUpload = useCallback(
    async (image: File, folder: FolderType) => {
      let { data } = await uploadFile({
        variables: { file: image, folder },
      });
      return data?.upload?.link ?? '';
    },
    [uploadFile],
  );
  const onSaveWinner = useCallback(() => {
    if (editCompetition) {
      let {
        __typename,
        id,
        brand,
        image,
        dedicatedPageImage,
        name,
        desc,
        closeDate,
        winners,
        ...others
      } = editCompetition;
      updateWinners({
        variables: {
          image: image || '',
          dedicatedPageImage: dedicatedPageImage || '',
          competitionId: id,
          brandId: brand ? brand.id : '',
          competitionName: name,
          competitionDesc: desc || '',
          competitionCloseDate: closeDate,
          winners: {
            createEdit: winnersList,
            delete: [],
          },
          ...others,
        },
      });
      onSubmit();
      resetStates();
    }
  }, [updateWinners, onSubmit, resetStates, winnersList, editCompetition]);

  useEffect(() => {
    let shouldDisableSave = false;
    for (let item of winnersList) {
      if (!item.name || !item.email) {
        shouldDisableSave = true;
        break;
      }
    }
    setSaveDisabled(shouldDisableSave);
  }, [winnersList]);

  const onSubmitCompetition = useCallback(async () => {
    let imageLink =
      typeof image === 'object' && image
        ? await processFileUpload(image.file, FolderType.COMPETITION)
        : '';

    let dedicatedPageImageLink =
      typeof dedicatedPageImage === 'object' && dedicatedPageImage
        ? await processFileUpload(
            dedicatedPageImage.file,
            FolderType.COMPETITION,
          )
        : '';

    if (
      name &&
      selectedAppUser &&
      regionName &&
      cityName &&
      dateTime &&
      (!enterChannels[0] || (enterChannels[0] && facebookLink)) &&
      (!enterChannels[1] || (enterChannels[1] && instagramLink)) &&
      (!enterChannels[2] || (enterChannels[2] && externalLink)) &&
      liveStartDate &&
      liveEndDate
    ) {
      let commonVariables = {
        brandId: brandId ?? '',
        competitionName: name,
        competitionDesc: about,
        competitionDescSalesman: aboutSalesman,
        urlDeeplinkSalesman: salesmanAppDeepLink,
        appUser: selectedAppUser,
        region: regionName,
        city: cityName,
        competitionCloseDate: closeDate,
        image: imageLink,
        dedicatedPageImage: dedicatedPageImageLink,
        externalLink: enterChannels[2] ? externalLink : null,
        eventDate: selectedEvent === 'event' ? dateTime : selectedDate,
        liveDate: liveStartDate,
        endLiveDate: liveEndDate,
        externalButton,
      };

      if (editCompetition) {
        let createLinks: CompetitionSocialMediaInput['create'] = [];
        let updateLinks: CompetitionSocialMediaInput['update'] = [];
        let deleteLinks: CompetitionSocialMediaInput['delete'] = [];
        // NOTE: if imageLink is an empty string, it would be replaced with existing image if any
        imageLink = imageLink || (editCompetition.image ?? '');
        dedicatedPageImageLink =
          dedicatedPageImageLink || (editCompetition.dedicatedPageImage ?? '');
        let existingFb = editCompetition.medsos.find(
          ({ socialMediaType }) => socialMediaType === MediaSocial.FACEBOOK,
        );
        if (enterChannels[0]) {
          // NOTE: Facebook is checked
          let base = {
            socialMediaType: MediaSocial.FACEBOOK,
            link: facebookLink,
          };
          if (existingFb) {
            // NOTE: has an existing value in BE (update)
            updateLinks.push({ ...base, id: existingFb.id });
          } else {
            // NOTE: new value (create)
            createLinks.push(base);
          }
        } else if (existingFb) {
          // NOTE: Facebook is not checked but has an existing value in BE (delete)
          deleteLinks.push({ id: existingFb.id });
        }

        let existingIg = editCompetition.medsos.find(
          ({ socialMediaType }) => socialMediaType === MediaSocial.INSTAGRAM,
        );
        if (enterChannels[1]) {
          // NOTE: Instagram is checked
          let base = {
            socialMediaType: MediaSocial.INSTAGRAM,
            link: instagramLink,
          };
          if (existingIg) {
            // NOTE: has an existing value in BE (update)
            updateLinks.push({ ...base, id: existingIg.id });
          } else {
            // NOTE: new value (create)
            createLinks.push(base);
          }
        } else if (existingIg) {
          // NOTE: Instagram is not checked but has an existing value in BE (delete)
          deleteLinks.push({ id: existingIg.id });
        }

        updateCompetition({
          variables: {
            ...commonVariables,
            competitionId: editCompetition.id,
            competitionDesc: about,
            competitionDescSalesman: aboutSalesman,
            urlDeeplinkSalesman: salesmanAppDeepLink,
            image: imageLink,
            dedicatedPageImage: dedicatedPageImageLink,
            mediaSocial: {
              create: createLinks,
              update: updateLinks,
              delete: deleteLinks, // NOTE: this is unused, yet necessary as BE requires
            },
          },
        });
      } else {
        let socialLinks: CreateCompetitionVariables['mediaSocial'] = [];
        if (facebookLink) {
          socialLinks.push({
            socialMediaType: MediaSocial.FACEBOOK,
            link: facebookLink,
          });
        }
        if (instagramLink) {
          socialLinks.push({
            socialMediaType: MediaSocial.INSTAGRAM,
            link: instagramLink,
          });
        }
        createCompetition({
          variables: {
            ...commonVariables,
            competitionDesc: about,
            competitionDescSalesman: aboutSalesman,
            urlDeeplinkSalesman: salesmanAppDeepLink,
            mediaSocial: socialLinks,
          },
        });
      }
    }
  }, [
    editCompetition,
    brandId,
    about,
    aboutSalesman,
    salesmanAppDeepLink,
    name,
    selectedAppUser,
    regionName,
    cityName,
    dateTime,
    selectedDate,
    selectedEvent,
    closeDate,
    enterChannels,
    facebookLink,
    instagramLink,
    externalLink,
    image,
    dedicatedPageImage,
    liveStartDate,
    liveEndDate,
    externalButton,
    processFileUpload,
    createCompetition,
    updateCompetition,
  ]);

  const onSubmitEvent = useCallback(async () => {
    let imageLink =
      typeof image === 'object' && image
        ? await processFileUpload(image.file, FolderType.EVENT)
        : '';
    let dedicatedPageImageLink =
      typeof dedicatedPageImage === 'object' && dedicatedPageImage
        ? await processFileUpload(dedicatedPageImage.file, FolderType.EVENT)
        : '';

    if (
      name &&
      selectedAppUser &&
      regionName &&
      cityName &&
      venue &&
      (registrationRequired ? externalLink : true)
    ) {
      let commonVariables = {
        brandId: brandId ?? '',
        eventName: name,
        eventDesc: about,
        eventDescSalesman: aboutSalesman,
        urlDeeplinkSalesman: salesmanAppDeepLink,
        appUser: selectedAppUser,
        region: regionName,
        city: cityName,
        venue,
        registrationDeadline,
        externalLink: registrationRequired ? externalLink : null,
        image: imageLink,
        dedicatedPageImage: dedicatedPageImageLink,
        eventDate: dateTime,
        liveDate: liveStartDate,
        endLiveDate: liveEndDate,
        externalButton,
      };
      if (editEvent) {
        // NOTE: if imageLink is an empty string, it would be replaced with existing image if any
        imageLink = imageLink || (editEvent.image ?? '');
        dedicatedPageImageLink =
          dedicatedPageImageLink || (editEvent.dedicatedPageImage ?? '');
        updateEvent({
          variables: {
            ...commonVariables,
            eventId: editEvent.id,
            image: imageLink,
            dedicatedPageImage: dedicatedPageImageLink,
          },
        });
      } else {
        createEvent({ variables: { ...commonVariables } });
      }
    }
  }, [
    editEvent,
    about,
    aboutSalesman,
    salesmanAppDeepLink,
    image,
    dedicatedPageImage,
    brandId,
    name,
    selectedAppUser,
    regionName,
    cityName,
    venue,
    registrationRequired,
    externalLink,
    dateTime,
    registrationDeadline,
    liveStartDate,
    liveEndDate,
    externalButton,
    processFileUpload,
    createEvent,
    updateEvent,
  ]);

  const onSubmitNews = useCallback(async () => {
    let imageLink =
      typeof image === 'object' && image
        ? await processFileUpload(image.file, FolderType.NEWS)
        : '';
    let dedicatedPageImageLink =
      typeof dedicatedPageImage === 'object' && dedicatedPageImage
        ? await processFileUpload(dedicatedPageImage.file, FolderType.NEWS)
        : '';

    if (
      selectedAppUser &&
      regionName &&
      cityName &&
      name &&
      about &&
      liveStartDate &&
      liveEndDate &&
      linkType
    ) {
      let commonVariables: CreateNewsVariables = {
        appUser: selectedAppUser,
        region: regionName,
        city: cityName,
        startDate: liveStartDate,
        title: name,
        desc: about,
        descSalesman: aboutSalesman,
        urlDeeplinkSalesman: salesmanAppDeepLink,
        businessUnitContactId: brandBU === '-' ? null : brandBU || null,
        image: imageLink,
        dedicatedPageImage: dedicatedPageImageLink,
        liveDate: liveStartDate,
        endLiveDate: liveEndDate,
        typeLink: linkType,
        externalButton,
      };
      switch (linkType) {
        case 'RANGE': {
          commonVariables = {
            ...commonVariables,
            rangeId: range,
            brandId: brand,
          };
          break;
        }
        case 'INTERNAL_LINK': {
          if (subScreenOne && subScreenTwo) {
            commonVariables = {
              ...commonVariables,
              internalLinkId: screen,
              brandId: subScreenOne,
              rangeId: subScreenTwo,
            };
          } else if (subScreenOne) {
            commonVariables = {
              ...commonVariables,
              internalLinkId: subScreenOne,
            };
          } else {
            commonVariables = { ...commonVariables, internalLinkId: screen };
          }
          break;
        }
        case 'EXTERNAL_LINK': {
          commonVariables = { ...commonVariables, externalLink: externalLink };
          break;
        }
      }
      if (editNews) {
        // NOTE: if imageLink is an empty string, it would be replaced with existing image if any
        imageLink = imageLink || (editNews.image ?? '');
        dedicatedPageImageLink =
          dedicatedPageImageLink || (editNews.dedicatedPageImage ?? '');
        updateNews({
          variables: {
            ...commonVariables,
            newsId: editNews.id,
            image: imageLink,
            dedicatedPageImage: dedicatedPageImageLink,
          },
        });
      } else {
        createNews({ variables: { ...commonVariables } });
      }
    }
  }, [
    editNews,
    image,
    dedicatedPageImage,
    selectedAppUser,
    name,
    brand,
    range,
    brandBU,
    regionName,
    cityName,
    about,
    aboutSalesman,
    salesmanAppDeepLink,
    liveStartDate,
    liveEndDate,
    externalLink,
    processFileUpload,
    createNews,
    updateNews,
    screen,
    subScreenOne,
    subScreenTwo,
    linkType,
    externalButton,
  ]);
  const onEdit = useCallback(() => {
    setEditable(!editable);
    window.scrollTo({ top: 170 });
  }, [editable, setEditable]);

  const onCalendarSelect = useCallback(
    (date: Date) => {
      setSelectedDate(date);
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      if (date.valueOf() >= today.valueOf()) {
        setDateTime(date);
      }
    },
    [setSelectedDate, setDateTime],
  );

  useEffect(() => {
    if (selectedEvent) {
      setCategory(selectedEvent);
      resetStates();
    }
  }, [selectedEvent, setCategory, resetStates]);

  const competitionSubmitDisabled = useMemo(
    () =>
      selectedEvent === 'competition' &&
      (!selectedAppUser ||
        !regionName ||
        !cityName ||
        !name ||
        !about ||
        about.trim() === '<p></p>' ||
        aboutSalesman.trim() === '<p></p>' ||
        !closeDate ||
        (image == null && dedicatedPageImage != null) ||
        (dedicatedPageImage == null && image != null) ||
        !aboutSalesman ||
        (enterChannels[0] ? !facebookLink : false) ||
        (enterChannels[1] ? !instagramLink : false) ||
        (enterChannels[2] ? !externalLink : false) ||
        !liveStartDate ||
        !liveEndDate),
    [
      selectedEvent,
      selectedAppUser,
      regionName,
      cityName,
      name,
      about,
      closeDate,
      image,
      dedicatedPageImage,
      aboutSalesman,
      enterChannels,
      facebookLink,
      instagramLink,
      externalLink,
      liveStartDate,
      liveEndDate,
    ],
  );

  const eventSubmitDisabled = useMemo(
    () =>
      selectedEvent === 'event' &&
      (!selectedAppUser ||
        !regionName ||
        !cityName ||
        !name ||
        !venue ||
        !about ||
        about.trim() === '<p></p>' ||
        aboutSalesman.trim() === '<p></p>' ||
        !dateTime ||
        (image == null && dedicatedPageImage != null) ||
        (dedicatedPageImage == null && image != null) ||
        !aboutSalesman ||
        (registrationRequired && !externalLink) ||
        !liveStartDate ||
        !liveEndDate),
    [
      selectedEvent,
      selectedAppUser,
      image,
      dedicatedPageImage,
      aboutSalesman,
      regionName,
      cityName,
      name,
      venue,
      about,
      dateTime,
      registrationRequired,
      externalLink,
      liveStartDate,
      liveEndDate,
    ],
  );

  const newsSubmitDisabled = useMemo(
    () =>
      selectedEvent === 'news' &&
      (!selectedAppUser ||
        !regionName ||
        !cityName ||
        !name ||
        (!range &&
          !externalLink &&
          !screen &&
          !brand &&
          !subScreenOne &&
          !subScreenTwo) ||
        !about ||
        (image == null && dedicatedPageImage != null) ||
        (dedicatedPageImage == null && image != null) ||
        !aboutSalesman ||
        !liveStartDate ||
        !liveEndDate),
    [
      selectedEvent,
      selectedAppUser,
      regionName,
      cityName,
      name,
      aboutSalesman,
      brand,
      range,
      dedicatedPageImage,
      image,
      externalLink,
      screen,
      about,
      liveStartDate,
      liveEndDate,
      subScreenOne,
      subScreenTwo,
    ],
  );

  const getRegionID = useCallback(
    (values: Array<string>) => {
      // NOTE: we're using `getSelectedValues` prop of Dropdown which returns an array of the selected values
      //       but because this is a single selection dropdown, we can get just the first entry
      setSelectedRegion(values[0]);
    },
    [setSelectedRegion],
  );

  return (
    <>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <Header
        title={t(['Beranda', 'Homepage'])}
        subtitle={t(['Berita DBO', 'DBO News'])}
        subtitleColor="default"
        style={styles.header}
      />
      {selectedEvent === 'news' ? (
        <StepOneToFiveNews
          selectedEvent={selectedEvent}
          selectedAppUser={selectedAppUser}
          selectedRegion={selectedRegion}
          regionName={regionName}
          cityName={cityName}
          selectedDate={selectedDate}
          stepSix={
            <StepSixNews
              editable={editable}
              name={name}
              setName={setName}
              brand={brand}
              dedicatedPageImage={dedicatedPageImage}
              setDedicatedPageImage={setDedicatedPageImage}
              setBrand={createSetSelected(setBrand, undefined, setRange)}
              managerBrandId={managerBrandId}
              setManagerBrandId={setManagerBrandId}
              range={range}
              setRange={createSetSelected(setRange)}
              about={about}
              setAbout={setAbout}
              aboutSalesmanApp={aboutSalesman}
              setAboutSalesmanApp={setAboutSalesman}
              salesmanAppDeepLink={salesmanAppDeepLink}
              setSalesmanAppDeepLink={setSalesmanAppDeepLink}
              brandContact={brandBU}
              setBrandContact={wrappedSetBrandBU}
              image={image}
              setImage={setImage}
              liveDate={editNews ? liveStartDate : undefined}
              setLiveDate={setLiveStartDate}
              endDate={liveEndDate}
              setEndDate={setLiveEndDate}
              onSubmit={onSubmitNews}
              onEdit={onEdit}
              submitDisabled={newsSubmitDisabled}
              submitLoading={
                uploadLoading || createNewsLoading || updateNewsLoading
              }
              onSendEmail={onSendEmailNews}
              emailDisabled={!editNews}
              setExternalLink={setExternalLink}
              externalLink={externalLink}
              screen={screen}
              setScreen={setScreen}
              subScreenOne={subScreenOne}
              subScreenTwo={subScreenTwo}
              setSubScreenOne={setSubScreenOne}
              setSubScreenTwo={setSubScreenTwo}
              linkType={linkType}
              setLinkType={setLinkType}
              externalButton={externalButton}
              setExternalButton={setExternalButton}
            />
          }
          editable={editable}
          getRegionID={getRegionID}
          onEventSelected={createSetSelected(setSelectedEvent)}
          onAppUserSelected={createSetSelected(setSelectedAppUser)}
          onRegionSelected={createSetSelected(setSelectedRegion, setRegionName)}
          onCitySelected={createSetSelected(setSelectedCity, setCityName)}
          onDateSelected={onCalendarSelect}
        />
      ) : (
        <StepOneToFive
          selectedEvent={selectedEvent}
          selectedAppUser={selectedAppUser}
          selectedRegion={selectedRegion}
          regionName={regionName}
          cityName={cityName}
          selectedDate={selectedDate}
          setDescription={setAbout}
          setSalesmanDescription={setAboutSalesman}
          editorState={editorState}
          salesmanEditorState={salesmanEditorState}
          setEditorState={setEditorState}
          setSalesmanEditorState={setSalesmanEditorState}
          liveDate={editEvent || editCompetition ? liveStartDate : undefined}
          setLiveDate={setLiveStartDate}
          endDate={liveEndDate}
          setEndDate={setLiveEndDate}
          onSubmit={
            selectedEvent === 'event' ? onSubmitEvent : onSubmitCompetition
          }
          onEdit={onEdit}
          submitDisabled={
            selectedEvent === 'event'
              ? eventSubmitDisabled
              : competitionSubmitDisabled
          }
          submitLoading={
            selectedEvent === 'event'
              ? uploadLoading || createEventLoading || updateEventLoading
              : uploadLoading || createCompLoading || updateCompLoading
          }
          stepSix={
            selectedEvent === 'competition' ? (
              <StepSixCompetition
                name={name}
                dateTime={closeDate}
                facebookLink={facebookLink}
                instagramLink={instagramLink}
                externalLink={externalLink}
                brandBU={brandBU}
                editable={editable}
                image={image}
                dedicatedPageImage={dedicatedPageImage}
                setDedicatedPageImage={setDedicatedPageImage}
                enterOptions={enterChannels}
                setImage={setImage}
                setEnterOptions={setEnterChannels}
                setName={setName}
                setFacebookLink={setFacebookLink}
                setInstagramLink={setInstagramLink}
                setExternalLink={setExternalLink}
                setBrandBU={wrappedSetBrandBU}
                setDateTime={setCloseDate}
                onSendEmail={onSendEmailCompetition}
                emailDisabled={!editCompetition}
                externalButton={externalButton}
                setExternalButton={setExternalButton}
                salesmanAppDeepLink={salesmanAppDeepLink}
                setSalesmanAppDeepLink={setSalesmanAppDeepLink}
              />
            ) : (
              <StepSixEvent
                editable={editable}
                name={name}
                venue={venue}
                dateTime={dateTime}
                registrationRequired={registrationRequired}
                registrationDeadline={registrationDeadline}
                externalLink={externalLink}
                brandBU={brandBU}
                image={image}
                setName={setName}
                setVenue={setVenue}
                setDateTime={setDateTime}
                setRegistrationRequired={setRegistrationRequired}
                setRegistrationDeadline={setRegistrationDeadline}
                setExternalLink={setExternalLink}
                setBrandBU={wrappedSetBrandBU}
                setImage={setImage}
                onSendEmail={onSendEmailEvent}
                emailDisabled={!editEvent}
                externalButton={externalButton}
                setExternalButton={setExternalButton}
                dedicatedPageImage={dedicatedPageImage}
                setDedicatedPageImage={setDedicatedPageImage}
                salesmanAppDeepLink={salesmanAppDeepLink}
                setSalesmanAppDeepLink={setSalesmanAppDeepLink}
              />
            )
          }
          editable={editable}
          getRegionID={getRegionID}
          onEventSelected={createSetSelected(setSelectedEvent)}
          onAppUserSelected={createSetSelected(setSelectedAppUser)}
          onRegionSelected={createSetSelected(setSelectedRegion, setRegionName)}
          onCitySelected={createSetSelected(setSelectedCity, setCityName)}
          onDateSelected={onCalendarSelect}
        />
      )}
      {selectedEvent === 'competition' &&
        (editCompetition ? (
          <View>
            <View style={styles.row}>
              <View style={[styles.flex, styles.column]} />
              <View style={[styles.flex, styles.column]} />
              <View style={[styles.flexTwo, styles.column]}>
                <StepSevenCompetition
                  onStoreNameChange={onStoreNameChange}
                  editable={editable}
                  winners={winnersList}
                  onNameChange={onNameChange}
                  onAddNewPress={onAddWinner}
                  onDeletePress={onDeleteWinner}
                />
              </View>
              <View style={styles.flex}>
                <StepEightCompetition
                  editable={editable}
                  winners={winnersList}
                  onEmailChange={onEmailChange}
                  onNotify={onSendEmailWinner}
                />
              </View>
            </View>
            <Button
              disabled={!editable || saveDisabled}
              title={t(['Save', 'Simpan'])}
              isLoading={updateWinnersLoading}
              onPress={onSaveWinner}
              style={styles.saveButton}
            />
          </View>
        ) : null)}
    </>
  );
};

const styles = StyleSheet.create({
  header: { paddingBottom: spacing.small },
  row: { flexDirection: 'row', paddingTop: spacing.large },
  flex: { flex: 1 },
  flexTwo: { flex: 2 },
  column: { paddingRight: spacing.xlarge },
  saveButton: { width: 100, alignSelf: 'flex-end' },
});

export default CreateDboNews;
