import gql from 'graphql-tag';

export const GET_DISTRICTS = gql`
  query Districts($cityID: ID!) {
    districtByCityId(id: $cityID) {
      id
      name
      cityId
    }
  }
`;
