import gql from 'graphql-tag';

export const BRAND_FRAGMENT = gql`
  fragment BrandFragment on Brand {
    id
    brandId
    name
  }
`;

export const RANGE_FRAGMENT = gql`
  fragment RangeFragment on ManagerRange {
    id
    name
  }
`;
