import { useCallback, useMemo, useState } from 'react';

import { OrderByArg } from '../generated/globalTypes';

export const useTableSort = <TOrderBy extends string>() => {
  const [order, setOrder] = useState<OrderByArg | undefined>();
  const [orderBy, setOrderBy] = useState<TOrderBy | undefined>();
  const onRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: string) => {
      // NOTE: this follows the same logic as the Table's onRequest sort to keep the same UX
      if (orderBy === property) {
        if (order === OrderByArg.asc) {
          setOrder(OrderByArg.desc);
        } else {
          setOrderBy(undefined);
          setOrder(undefined);
        }
      } else {
        setOrder(OrderByArg.asc);
        setOrderBy(property as TOrderBy);
      }
    },
    [orderBy, order],
  );
  const orderByVariable = useMemo(() => {
    if (order && orderBy) {
      return { order, type: orderBy };
    }
  }, [order, orderBy]);

  return { order, orderBy, orderByVariable, onRequestSort };
};
