import gql from 'graphql-tag';

export const GET_STORE_DETAILS = gql`
  query StoreDetails($storeId: String!) {
    store(where: { id: $storeId }) {
      id
      totalPurchase
      point
      storeManager {
        storeName
      }
    }
  }
`;

export const GET_LOYALTY_POINT_HISTORIES = gql`
  query LoyaltyPointHistories(
    $storeId: ID!
    $date: DateFilter
    $skip: Int
    $first: Int
  ) {
    loyaltyPointHistoryAdvance(
      storeId: $storeId
      date: $date
      orderBy: { createdAt: desc }
      pagination: { skip: $skip, first: $first }
    ) {
      row {
        id
        loyaltyActivity {
          id
          name
        }
        brand {
          id
          name
        }
        balancePoint
        point
        type
        date
        note
        activityName
        lumpSumType
        competition {
          id
          name
        }
        event {
          id
          name
        }
        promotion {
          id
          name
        }
      }
      total
    }
  }
`;

export const GET_TRANSACTION_HISTORIES = gql`
  query TransactionHistories(
    $storeId: String!
    $date: DateFilter
    $brandName: String
    $skip: Int
    $first: Int
  ) {
    HistoryTransactionCMS(
      storeId: $storeId
      orderDate: $date
      brandName: $brandName
      cursor: $skip
      limit: $first
    ) {
      row {
        orderId
        orderDate
        brandName
        totalPrice
        orderType
        orderNumber
        agentName
      }
      total
    }
  }
`;

//TODO: Brand ID should be required, but since this endpoint is old, we can't just change it. further consideration is required.
// Brand ID is required because the endpoint will do a unique search to database that will at least required one argument.
// Passing null BrandId should never be an option.
export const GET_POINT_RATE = gql`
  query PointRate($brandId: ID) {
    brand(id: $brandId) {
      id
      pointRate
    }
  }
`;

export const DOWNLOAD_POINT_HISTORY = gql`
  query DownloadPointHistory(
    $startDate: DateTime
    $endDate: DateTime
    $storeId: ID!
  ) {
    downloadLoyaltyPoint(
      startDate: $startDate
      endDate: $endDate
      storeId: $storeId
    ) {
      link
    }
  }
`;

export const CATEGORY_PRODUCT_TADA = gql`
  query CategoryProductTADA {
    categoryProductTada {
      id
      name
    }
  }
`;

export const GET_LIST_GIFT = gql`
  query GetListGift($categoryId: String) {
    listGift(categoryId: $categoryId) {
      itemId
      variantId
      categoryId
      name
    }
  }
`;
