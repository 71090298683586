import React, { useState, useCallback, useMemo, useEffect } from 'react';
import DragIndicator from '@material-ui/icons/DragIndicator';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { FileWithPreview } from '../../core-ui/Dropzone';
import { Dropzone, Text, Button, TextInput } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { SocialMedias } from '../../generated/SocialMedias';
import { GET_SOCIAL_MEDIAS } from '../../graphql/queries';
import { UPLOAD, UPDATE_SOCIAL_MEDIA } from '../../graphql/mutations';
import { Upload, UploadVariables } from '../../generated/Upload';
import {
  UpdateSocialMedia,
  UpdateSocialMediaVariables,
} from '../../generated/UpdateSocialMedia';
import { SocialMediaFragment } from '../../generated/SocialMediaFragment';
import {
  SocialMediaCreateInput,
  SocialMediaUpdateInput,
} from '../../generated/globalTypes';
import { ErrorMessage, ErrorModal } from '../../components';

type SocialMediaLink = Omit<SocialMediaFragment, '__typename' | 'icon'> & {
  icon: string | FileWithPreview;
};

const SocialMediaLinks = () => {
  const [editable, setEditable] = useState(false);
  const [socialLinks, setSocialLinks] = useState<Array<SocialMediaLink>>([]);
  // NOTE: tempSocialLinks is used to "remember" the old social links before going into edit mode
  //       so when we cancel we can revert the changes and display the last BE data
  const [tempSocialLinks, setTempSocialLinks] = useState<
    Array<SocialMediaLink>
  >(socialLinks);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  // NOTE: states for ErrorModal, errorOpen is necessary so that the error message doesn't change before the modal closes
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>(); // NOTE: only initial state is undefined

  const queryErrorAction = useMemo(
    () =>
      t([
        'mengambil data tautan sosial media',
        'retrieve the social media link data',
      ]),
    [],
  );
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorInstance(error);
      setErrorAction(action);
    },
    [],
  );
  const onUploadError = useMemo(() => {
    return openErrorModal(
      t(['mengunggah logo sosial media', 'upload social media logo']),
    );
  }, [openErrorModal]);
  const onUpdateError = useMemo(() => {
    return openErrorModal(
      t(['mengubah data tautan sosial media', 'edit social media link data']),
    );
  }, [openErrorModal]);
  const onDeleteError = useMemo(() => {
    return openErrorModal(
      t([
        'menghapus data tautan sosial media',
        'delete social media link data',
      ]),
    );
  }, [openErrorModal]);

  const { data, loading, error, refetch } = useQuery<SocialMedias>(
    GET_SOCIAL_MEDIAS,
    { notifyOnNetworkStatusChange: true },
  );

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);
  const onUpdateCompleted = useCallback(() => {
    refetchData();
    setSelectedIndex(null);
    setEditable(false);
  }, [refetchData]);
  const onDeleteCompleted = useCallback(() => {
    refetchData();
    setSelectedIndex(null);
  }, [refetchData]);

  const [upload, { loading: uploadLoading }] = useMutation<
    Upload,
    UploadVariables
  >(UPLOAD, { onError: onUploadError });
  const [updateSocialMedia, { loading: updateLoading }] = useMutation<
    UpdateSocialMedia,
    UpdateSocialMediaVariables
  >(UPDATE_SOCIAL_MEDIA, {
    onCompleted: onUpdateCompleted,
    onError: onUpdateError,
  });
  const [deleteSocialMedia, { loading: deleteLoading }] = useMutation<
    UpdateSocialMedia,
    UpdateSocialMediaVariables
  >(UPDATE_SOCIAL_MEDIA, {
    onCompleted: onDeleteCompleted,
    onError: onDeleteError,
  });

  useEffect(() => {
    if (data) {
      //NOTE: remove __typename from socialMedias object as it will cause error in update mutation
      let socialMedias = data.socialMedias.map((item) => {
        let { __typename, ...otherProps } = item;
        return { ...otherProps };
      });
      setTempSocialLinks(socialMedias);
      setSocialLinks(socialMedias);
    }
  }, [data]);

  const headerButtonText = useMemo(
    // NOTE: in edit mode this says "add new", otherwise this says "edit"
    () => (editable ? t(['+ Buat baru', '+ Add new']) : t(['Ubah', 'Edit'])),
    [editable],
  );

  const onDrop = useCallback(
    (index: number) => ({
      // NOTE: this returns an object with 2 functions, for Dropzone and TextInput
      image: (icon: FileWithPreview) => {
        setSocialLinks(
          socialLinks.map((socialLink, i) =>
            i === index ? { ...socialLink, icon } : socialLink,
          ),
        );
      },
      link: (link: string) => {
        setSocialLinks(
          socialLinks.map((socialLink, i) =>
            i === index ? { ...socialLink, link } : socialLink,
          ),
        );
      },
    }),
    [socialLinks],
  );

  const headerButtonPress = useMemo(
    () =>
      editable // NOTE: in edit mode this adds an empty row, otherwise turn on edit mode
        ? () =>
            setSocialLinks([
              ...socialLinks,
              {
                id: '',
                icon: '',
                link: '',
                order: socialLinks.length + 1,
              },
            ])
        : () => {
            setEditable(true);
          },
    [editable, socialLinks],
  );

  const onDelete = useCallback(
    (index: number) => () => {
      setSelectedIndex(index);
      if (socialLinks[index].id) {
        // NOTE: if the item has an ID, this needs to be deleted from the server
        deleteSocialMedia({
          variables: {
            socialMediaInput: {
              delete: [{ id: socialLinks[index].id }],
            },
          },
        });
      } else {
        // NOTE: doesn't have any ID so remove it from state
        setSocialLinks(socialLinks.filter((_, i) => i !== index));
      }
    },
    [socialLinks, deleteSocialMedia],
  );

  const onSubmit = useCallback(async () => {
    let createVariables: Array<SocialMediaCreateInput> = [];
    let updateVariables: Array<SocialMediaUpdateInput> = [];

    let socialLinksToUpload: Array<ReturnType<typeof upload>> = [];
    for (let item of socialLinks) {
      if (typeof item.icon === 'object' && item.icon) {
        socialLinksToUpload.push(
          upload({ variables: { file: item.icon.file } }),
        );
      }
    }
    let uploadResults = await Promise.all(socialLinksToUpload);

    let index = 0;
    let uploadResultIndex = 0;
    for (let item of socialLinks) {
      let icon = '';
      if (typeof item.icon === 'object' && item.icon) {
        let { data } = uploadResults[uploadResultIndex++];
        if (data) {
          icon = data.upload.link;
        }
      } else {
        icon = item.icon;
      }
      if (item.id === '') {
        let { id, ...otherProps } = item;
        createVariables.push({ ...otherProps, icon, order: ++index });
      } else if (item.id) {
        updateVariables.push({ ...item, icon, order: ++index });
      }
    }

    updateSocialMedia({
      variables: {
        socialMediaInput: {
          create: createVariables,
          update: updateVariables,
        },
      },
    });
  }, [socialLinks, updateSocialMedia, upload]);

  const onCancel = useCallback(() => {
    setEditable(false);
    setSocialLinks(tempSocialLinks);
    refetchData();
  }, [tempSocialLinks, setEditable, setSocialLinks, refetchData]);

  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result;
      if (editable && destination && destination.index !== source.index) {
        let newSocialLinks = [...socialLinks];
        newSocialLinks.splice(source.index, 1);
        newSocialLinks.splice(destination.index, 0, socialLinks[draggableId]);
        //NOTE: set order number according to index
        let sortedSocialLinks = newSocialLinks.map((item, index) => ({
          ...item,
          order: index + 1,
        }));
        setSocialLinks(sortedSocialLinks);
      }
    },
    [editable, socialLinks],
  );

  return (
    <View data-cy="social">
      <View style={[styles.row, styles.header]}>
        <Text bold style={styles.flex}>
          {t(['Tautan Sosial Media', 'Social Media Links'])}
        </Text>
        <Button
          data-cy="social-edit-add"
          preset="transparent"
          title={headerButtonText}
          onPress={headerButtonPress}
        />
      </View>
      <View>
        {loading && <ActivityIndicator />}
        {!!error && (
          <ErrorMessage
            error={error}
            action={queryErrorAction}
            onPress={refetchData}
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="socialLinks">
            {(provided) => (
              // NOTE: we need to use <div /> because the package needs access to the DOM node,
              //       not the react Component instance
              <div
                data-cy="social-dropable"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {socialLinks.map(({ icon, link }, index) => (
                  <Draggable
                    key={index}
                    index={index}
                    draggableId={index.toString()}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <View style={[styles.row, styles.socialLink]}>
                          <View style={styles.column}>
                            <DragIndicator
                              htmlColor={colors.icon.dragIndicator}
                            />
                          </View>
                          <View style={styles.column}>
                            <Dropzone
                              data-cy={`social-icon-${index}`}
                              imageSize={{ height: 50, width: 50 }}
                              containerStyle={styles.dropzoneContainer}
                              placeholderStyle={styles.dropzonePlaceholder}
                              getPreview={onDrop(index).image}
                              source={icon}
                              disabled={!editable}
                            />
                          </View>
                          <Text bold style={styles.numbering}>
                            {index + 1}
                          </Text>
                          <TextInput
                            data-cy={`social-link-${index}`}
                            value={link}
                            disabled={!editable}
                            onChangeText={onDrop(index).link}
                            containerStyle={[styles.flex, styles.column]}
                          />
                          <Button
                            data-cy="social-delete"
                            preset="transparent"
                            title="Delete"
                            disabled={uploadLoading || updateLoading}
                            isLoading={selectedIndex === index && deleteLoading}
                            onPress={onDelete(index)}
                          />
                        </View>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {editable && (
          <View style={[styles.row, styles.actions]}>
            <Button
              data-cy="social-submit"
              style={styles.submit}
              title={t(['Kirim', 'Submit'])}
              onPress={onSubmit}
              disabled={deleteLoading}
              isLoading={uploadLoading || updateLoading}
            />
            <Button
              data-cy="social-cancel"
              preset="secondary"
              title={t(['Batal', 'Cancel'])}
              onPress={onCancel}
            />
          </View>
        )}
      </View>
      <Text size="small" style={styles.footnote}>
        {t([
          'CATATAN: Urutan ikon di atas adalah urutan ikon yang akan ditampilkan di aplikasi, dan ukuran maksimal untuk icon social media adalah 50x50',
          'NOTE:  The order of icons above will be the order of icons throughout the app, and maximum size for social media icon is 50x5',
        ])}
      </Text>
      <ErrorModal
        action={errorAction}
        open={errorOpen}
        error={errorInstance}
        onClose={closeErrorModal}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: 'row', alignItems: 'center' },
  header: { paddingBottom: spacing.medium },
  socialLink: { paddingBottom: spacing.small },
  dropzoneContainer: { width: 30, height: 30, borderRadius: 30 },
  dropzonePlaceholder: { width: 25, height: 25 },
  column: { paddingRight: spacing.xsmall },
  numbering: { width: 18, paddingRight: spacing.xxsmall },
  actions: { justifyContent: 'flex-end' },
  submit: { marginRight: spacing.small },
  footnote: { paddingTop: spacing.xsmall },
});

export default SocialMediaLinks;
