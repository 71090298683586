import gql from 'graphql-tag';

export const GET_VILLAGES = gql`
  query Villages($districtID: ID!) {
    villageByDistrictId(id: $districtID) {
      id
      name
      districtId
    }
  }
`;
