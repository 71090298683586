import React from 'react';
import { View, TouchableOpacity, Image, StyleSheet } from 'react-native';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useHistory } from 'react-router-dom';

import { Text, Dropzone, TextInput, Button } from '../../../core-ui';
import {
  LiveDateRadioGroup,
  Modal,
  Option,
  BrandDropdown,
  RangeDropdown,
  StepHeader,
  RadioGroup,
  SelectLogoItem,
} from '../../../components';
import { colors, theme, spacing } from '../../../constants/theme';
import { uploadPlaceholder } from '../../../../assets';
import { FileWithPreview } from '../../../core-ui/Dropzone';
import { BrandPage_brand_logos } from '../../../generated/BrandPage';
import { StatusActive } from '../../../generated/globalTypes';
import { BrandsVariables } from '../../../generated/Brands';
import { routePaths } from '../../../constants/routes';

type Logo = Omit<BrandPage_brand_logos, '__typename'>;
type StepOneProps = {
  brandsQueryVariables?: BrandsVariables;
  selectedNamePage: Option | null;
  onSelectNamePage: (option: Option) => void;
  title?: string;
  dropdownTitle?: string;
  renderAddButton?: () => void;
};
type StepFourProps = {
  index?: number;
  about: string;
  setAbout: (value: string) => void;
  editable?: boolean;
  create?: boolean;
};
type StepFiveProps = {
  index?: number;
  brandID?: string;
  showTitle?: boolean;
  selectedRangeName: string | null;
  onSelectRangeName: (option: Option) => void;
  description: string;
  setDescription: (value: string) => void;
  availableIn: string;
  setAvailableIn: (value: string) => void;
  editable?: boolean;
  create?: boolean;
};
type StepSixProps = {
  index?: number;
  showTitle?: boolean;
  rangeImage: FileWithPreview | null | string;
  setRangeImage: (image: FileWithPreview) => void;
  editable?: boolean;
  create?: boolean;
};
type StepSevenProps = {
  index?: number;
  brandID?: string;
  showTitle?: boolean;
  liveDate?: Date;
  setLiveDate: (date: Date) => void;
  selectedRange: Option | null;
  onSelectRange: (option: Option) => void;
  editable?: boolean;
  create?: boolean;
};
type StatusStepProps = {
  status: StatusActive;
  setStatus: (value: StatusActive) => void;
};
type UploadStepProps = {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  headerImage: FileWithPreview | null | string;
  setHeaderImage: (image: FileWithPreview) => void;
  logoLibrary: Array<Logo>;
  logoImage: null | Logo;
  setLogoImage: (image: Logo) => void;
  create?: boolean;
  editable?: boolean;
};

const StepOne = (props: StepOneProps) => {
  let {
    brandsQueryVariables,
    selectedNamePage,
    onSelectNamePage,
    title = t(['Nama Halaman', 'Name Page']),
    dropdownTitle = t(['Pilih Nama Halaman', 'Select Name Page']),
    renderAddButton,
  } = props;
  return (
    <View style={styles.row}>
      <View style={styles.stepContainer}>
        <StepHeader step={1} style={styles.stepHeader} title={title} />
        <View style={styles.smallBottomSpacing}>
          <BrandDropdown
            data-cy="brand-dropdown"
            queryVariables={brandsQueryVariables}
            title={dropdownTitle}
            selectedOption={selectedNamePage?.value}
            onSelect={onSelectNamePage}
            type="basic"
          />
        </View>
      </View>
      {!!renderAddButton ? (
        renderAddButton()
      ) : (
        <View style={styles.stepContainer} />
      )}
      <View style={styles.stepContainer} />
      <View style={styles.flex} />
    </View>
  );
};

const UploadStep = (props: UploadStepProps) => {
  const history = useHistory();
  let {
    isModalOpen,
    setModalOpen,
    headerImage,
    setHeaderImage,
    logoImage,
    setLogoImage,
    logoLibrary,
    create,
    editable,
  } = props;

  return (
    <>
      <View style={styles.row}>
        <View style={styles.stepContainer}>
          <StepHeader
            step={2}
            style={styles.stepHeader}
            infotipContent=" "
            title={t(['Pilih Logo dari Library', 'Get Logo from Library'])}
          />
          <TouchableOpacity
            onPress={() => setModalOpen(true)}
            style={styles.logoPlaceholder}
            activeOpacity={1}
            disabled={!editable}
          >
            <Image
              source={!!logoImage ? logoImage.imageUrl : uploadPlaceholder}
              resizeMode="contain"
              style={
                !!logoImage ? styles.selectedLogo : styles.uploadPlaceholder
              }
            />
            {!editable && <View style={styles.disabledImage} />}
          </TouchableOpacity>
          <Text bold color="link" style={{ fontSize: 12 }}>
            {t([
              'Ukuran: 500 x 250px format .jpg',
              'Size: 500 x 250px .jpg format',
            ])}
          </Text>
        </View>
        <View style={[styles.stepContainer, { flex: 2 }]}>
          <StepHeader
            step={3}
            style={styles.stepHeader}
            infotipContent=" "
            title={t(['Unggah Gambar Header', 'Upload Header Image'])}
          />
          <Dropzone
            data-cy="header-image"
            disabled={!editable}
            imageSize={{ width: 1440, height: 415 }}
            type="image"
            containerStyle={{ width: 480, height: 136 }}
            source={headerImage}
            getPreview={setHeaderImage}
            bottomNote={t([
              'Ukuran: 1440 x 415px format .png',
              'Size: 1440 x 415px .png format',
            ])}
          />
        </View>
        {create && <View style={styles.stepContainer} />}
      </View>
      <Modal
        fullWidth={logoLibrary.length > 0}
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Button
          preset="transparent"
          title="close"
          onPress={() => setModalOpen(false)}
          icon={<CloseRoundedIcon />}
          style={styles.closeButton}
        />
        <View style={styles.dialogContent}>
          {logoLibrary.length === 0 ? (
            <View style={{ alignItems: 'center' }}>
              <WarningRoundedIcon fontSize="large" />
              <Text bold size="medium" style={styles.verticalSpacing}>
                {t(['Belum ada logo', 'There is no logo yet'])}
              </Text>
              <Text style={styles.smallBottomSpacing}>
                {t([
                  'Silahkan unggah gambar logo di halaman Logo',
                  'Please upload logo picture on Logo Page',
                ])}
              </Text>
              <Button
                preset="transparent"
                title={t(['Buka Halaman Logo', 'Go to Logo Page'])}
                onPress={() => history.push(routePaths.logoLibrary)}
              />
            </View>
          ) : (
            logoLibrary.map((logo, index) => {
              return (
                <SelectLogoItem
                  key={index}
                  logo={logo}
                  setLogoImage={setLogoImage}
                  style={styles.logoImage}
                />
              );
            })
          )}
        </View>
      </Modal>
    </>
  );
};

const StepFour = (props: StepFourProps) => {
  let { about, setAbout, editable, create, index } = props;
  return (
    <View style={styles.stepContainer}>
      <StepHeader
        step={create ? 4 : 5}
        style={styles.stepHeader}
        title={t(['Tentang', 'About'])}
      />
      <TextInput
        data-cy={index !== undefined ? `about-${index}` : 'about'}
        value={about}
        onChangeText={setAbout}
        maxLength={500}
        multiline
        rows={16}
        disabled={!editable}
      />
    </View>
  );
};

const StepFive = (props: StepFiveProps) => {
  let {
    brandID,
    showTitle = false,
    selectedRangeName,
    onSelectRangeName,
    description,
    setDescription,
    availableIn,
    setAvailableIn,
    editable,
    create,
    index,
  } = props;
  return (
    <View style={styles.stepContainer}>
      {showTitle && (
        <StepHeader step={create ? 5 : 6} style={styles.stepHeader} />
      )}
      <Text bold style={{ marginBottom: 6 }}>
        {t(['Nama Range:', 'Range Name:'])}
      </Text>
      <View style={styles.smallBottomSpacing}>
        <RangeDropdown
          data-cy={
            index !== undefined ? `range-dropdown-${index}` : 'range-dropdown'
          }
          type="basic"
          dataKey="label"
          brandID={brandID}
          title={t(['Pilih Nama Range', 'Select Range Name'])}
          selectedOption={selectedRangeName ?? undefined}
          onSelect={onSelectRangeName}
          disabled={!editable}
        />
      </View>
      <TextInput
        data-cy={index !== undefined ? `description-${index}` : 'description'}
        label={t(['Deskripsi:', 'Description:'])}
        value={description}
        onChangeText={setDescription}
        maxLength={120}
        multiline
        rows={3}
        disabled={!editable}
      />
      <TextInput
        data-cy={index !== undefined ? `available-in-${index}` : 'available-in'}
        label={t(['Tersedia dalam:', 'Available in:'])}
        value={availableIn}
        onChangeText={setAvailableIn}
        multiline
        maxRows={6}
        disabled={!editable}
      />
    </View>
  );
};

const StepSix = (props: StepSixProps) => {
  let {
    showTitle = false,
    rangeImage,
    setRangeImage,
    editable,
    create,
    index,
  } = props;
  return (
    <View style={styles.stepContainer}>
      {showTitle && (
        <StepHeader step={create ? 6 : 7} style={styles.stepHeader} />
      )}
      <Dropzone
        data-cy={index !== undefined ? `range-image-${index}` : 'range-image'}
        bottomNote={t([
          'Ukuran: 500 x 500px format .png',
          'Size: 500 x 500px .png format',
        ])}
        imageSize={{ height: 500, width: 500 }}
        type="image"
        containerStyle={{ height: 270, width: 270 }}
        source={rangeImage}
        getPreview={setRangeImage}
        disabled={!editable}
      />
    </View>
  );
};

const StepSeven = (props: StepSevenProps) => {
  let {
    showTitle = false,
    brandID,
    setLiveDate,
    selectedRange,
    onSelectRange,
    liveDate,
    editable,
    create,
    index,
  } = props;
  return (
    <View style={styles.mediumBottomSpacing}>
      {showTitle && (
        <StepHeader step={create ? 7 : 8} style={styles.stepHeader} />
      )}
      <LiveDateRadioGroup
        liveDate={liveDate}
        setLiveDate={setLiveDate}
        disabled={!editable}
      />
      <Text bold style={styles.orderText}>
        {t(['Pesan Sekarang', 'Order Now'])}
      </Text>
      <RangeDropdown
        data-cy={
          index !== undefined
            ? `order-now-dropdown-${index}`
            : 'order-now-dropdown'
        }
        type="basic"
        brandID={brandID}
        selectedOption={selectedRange?.value}
        onSelect={onSelectRange}
        disabled={!editable}
      />
    </View>
  );
};

const statusOptions = [
  { value: StatusActive.ACTIVE, label: <Text>{t(['Aktif', 'Active'])}</Text> },
  {
    value: StatusActive.INACTIVE,
    label: <Text>{t(['Non-aktif', 'Inactive'])}</Text>,
  },
];

const StatusStep = (props: StatusStepProps) => {
  let { status, setStatus } = props;
  return (
    <View style={styles.flex}>
      <StepHeader step={4} style={styles.stepHeader} />
      <RadioGroup
        options={statusOptions}
        selectedOption={status}
        setSelectedOption={setStatus}
      />
    </View>
  );
};

export {
  StepOne,
  UploadStep,
  StepFour,
  StepFive,
  StepSix,
  StepSeven,
  StatusStep,
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  stepHeader: { marginBottom: theme.spacing.small },
  row: {
    flexDirection: 'row',
  },
  stepContainer: {
    flex: 1,
    marginRight: spacing.xlarge,
    marginBottom: spacing.large,
  },
  smallBottomSpacing: {
    marginBottom: spacing.small,
  },
  orderText: {
    marginTop: spacing.large,
    marginBottom: spacing.xsmall,
  },
  logoPlaceholder: {
    borderColor: colors.border.primary,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: spacing.xsmall,
    width: 270,
    height: 138,
  },
  uploadPlaceholder: {
    width: 84,
    height: 74,
  },
  selectedLogo: {
    width: 270,
    height: 138,
  },
  dialogContent: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  logoImage: {
    margin: theme.spacing.small,
  },
  mediumBottomSpacing: {
    marginBottom: theme.spacing.medium,
  },
  verticalSpacing: {
    marginVertical: theme.spacing.small,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 8,
  },
  disabledImage: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: colors.dropzone.disabled,
    borderRadius: 4,
  },
});
