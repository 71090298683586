import React, {
  createContext,
  useState,
  useCallback,
  useContext,
  ReactNode,
} from 'react';

import { Snackbar, SnackbarProps } from '../core-ui';

const SnackbarContext = createContext<{
  openSnackbar: (props: Partial<SnackbarProps>) => void;
  closeSnackbar: () => void;
}>({ openSnackbar: () => {}, closeSnackbar: () => {} });

type Props = { children?: ReactNode };
export const SnackbarProvider = (props: Props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<Partial<SnackbarProps>>(
    {},
  );

  const openSnackbar = useCallback((snackbarProps: Partial<SnackbarProps>) => {
    setSnackbarOpen(true);
    setSnackbarProps(snackbarProps);
  }, []);
  const closeSnackbar = useCallback(() => setSnackbarOpen(false), []);

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {props.children}
      <Snackbar
        open={snackbarOpen}
        onClose={closeSnackbar}
        message=""
        variant="success"
        {...snackbarProps}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
