import gql from 'graphql-tag';

export const ADMIN_FRAGMENT = gql`
  fragment AdminFragment on Admin {
    id
    name
    email
    role
    phoneNumber
    status
  }
`;

export const ADMIN_REQUEST_VERIFICATION_FRAGMENT = gql`
  fragment AdminRequestVerificationFragment on AdminRequestVerificationStoreBulk {
    id
    name
    email
    role
    phoneNumber
    status
  }
`;
