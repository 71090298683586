import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { spacing } from '../../constants/theme';
import { HeaderNavigationBar } from '../../components';
import { CompetitionFragment } from '../../generated/CompetitionFragment';
import { EventFragment } from '../../generated/EventFragment';
import { NewsFragment } from '../../generated/NewsFragment';
import { pageTitle } from '../../constants/pageTitle';

import DboNewsForm from './DboNewsForm';
import DboNewsSearchEvent from './DboNewsSearchEvent';
import DboNewsSearchCompetition from './DboNewsSearchCompetition';
import SearchNews from './SearchNews';
import { NewsCategory } from './StepOneToFive';

const DboNewsScene = () => {
  const [category, setCategory] = useState<NewsCategory | null>(null);
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [
    editCompetition,
    setEditCompetition,
  ] = useState<CompetitionFragment | null>(null);
  const [editEvent, setEditEvent] = useState<EventFragment | null>(null);
  const [editNews, setEditNews] = useState<NewsFragment | null>(null);

  // NOTE: refetchToggle is necessary to trigger the refetch in Tables after form submission.
  //       using refetchQueries param in useMutation doesn't seem to do the trick.
  //       this will trigger a render (and useEffect) in component which will do the actual refetch
  const onSubmit = useCallback(() => {
    setRefetchToggle(!refetchToggle);
    setEditCompetition(null);
    setEditEvent(null);
    setEditNews(null);
  }, [refetchToggle, setRefetchToggle]);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.DboNewsScene });
    };
    eventLog();
  }, []);

  useEffect(() => {
    setEditCompetition(null);
    setEditEvent(null);
    setEditNews(null);
  }, [category, setEditCompetition, setEditEvent, setEditNews]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <View style={styles.section}>
        <DboNewsForm
          editCompetition={editCompetition}
          editEvent={editEvent}
          editNews={editNews}
          setCategory={setCategory}
          onSubmit={onSubmit}
        />
      </View>
      <View>
        {category === 'competition' && (
          <DboNewsSearchCompetition
            refetchToggle={refetchToggle}
            setEditCompetition={setEditCompetition}
          />
        )}
        {category === 'event' && (
          <DboNewsSearchEvent
            refetchToggle={refetchToggle}
            setEditEvent={setEditEvent}
          />
        )}
        {category === 'news' && (
          <SearchNews refetchToggle={refetchToggle} setEditNews={setEditNews} />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  section: { paddingBottom: spacing.xlarge },
});

export default DboNewsScene;
