import gql from 'graphql-tag';

export const PHOTO_EXIF = gql`
  fragment PhotoExifFragment on PhotoExif {
    latitude
    longitude
    date
  }
`;

export const STORE_FRAGMENT = gql`
  fragment StoreFragment on StoreBulk {
    id
    proofOfBusiness
    storeEmailVerified
    storeVerified
    createdAt
    storeManagerBulk {
      id
      storeName
      storeCode
      storeCategoryId
      storeCategoryName
      storeEmail
      address
      storePhoneNumber
      provinceId
      province
      cityId
      city
      districtId
      district
      villageId
      village
      postalCode
      latitude
      longitude
      yearEstablished
      salesmanReferralCode
    }
    rt
    rw
    reUpload
    storePhotoFront
    storePhotoInside
    frontExif {
      ...PhotoExifFragment
    }
    insideExif {
      ...PhotoExifFragment
    }
    website
    User {
      id
      profile {
        id
        name
        email
      }
      status
      role
      accessUser
      phoneNumber
    }
  }
  ${PHOTO_EXIF}
`;
