import { useEffect, useMemo, useState } from 'react';
import { ApolloError } from 'apollo-client';

import { CustomNetworkError } from '../types/errorTypes';

type Props = {
  callback?: () => void;
  error?: ApolloError;
};

export const useGetNetworkerror = ({ callback, error }: Props) => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (error) {
      if (
        (error.networkError as CustomNetworkError)?.result?.errors.find(
          (item: { message: string }) =>
            item.message.includes('Phone Number must start with 08'),
        )
      ) {
        callback && callback();
        setIsError(false);
      }
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [error, callback]);

  const returned = useMemo(() => isError, [isError]);

  return returned;
};
