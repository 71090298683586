import gql from 'graphql-tag';

import { BRAND_IMAGE_FRAGMENT } from '../fragments';

export const CREATE_BRAND_LOGO = gql`
  mutation CreateBrandLogo($brandId: ID!, $logo: String!, $name: String!) {
    createBrandLogo(brandId: $brandId, logo: $logo, name: $name) {
      ...BrandImageFragment
    }
  }
  ${BRAND_IMAGE_FRAGMENT}
`;

export const EDIT_BRAND_LOGO = gql`
  mutation EditBrandLogo($brandImageId: ID!, $logo: String!, $name: String!) {
    editBrandLogo(brandImageId: $brandImageId, logo: $logo, name: $name) {
      ...BrandImageFragment
    }
  }
  ${BRAND_IMAGE_FRAGMENT}
`;

export const DELETE_BRAND_LOGO = gql`
  mutation DeleteBrandLogo($brandImageId: ID!) {
    deleteBrandLogo(brandImageId: $brandImageId) {
      success
    }
  }
`;
