export const routePaths = {
  verificationSuccessful: '/verification-successful',
  pageNotFound: '/page-not-found',
  invalidLink: '/invalid-link',
  assignDistributor: '/assign-distributor/:token',
  competitionForm: '/form-competition/:token',
  eventForm: '/form-event/:token',
  brandPages: '/brand-pages',
  newBrandPage: '/brand-pages/new',
  library: '/library',
  dboNews: '/dbo-news',
  homepageSlider: '/homepage-slider',
  promotions: '/promotions',
  onlineForms: '/online-forms',
  logoLibrary: '/logo-library',
  popUp: '/pop-up',
  tokoUser: '/toko-user',
  archive: '/archive',
  agentRequestStatus: '/distributor-request-status',
  eventFormSubmissions: '/event-form-submissions',
  competitionFormSubmissions: '/competition-form-submissions',
  loyaltyStoreList: '/loyalty-point',
  pointHistory: '/loyalty-point/:id',
  redirect: '/redirect',
  orderDetail: '/loyalty-point/:id/order-detail',
  bulkLoyalty: '/bulk-loyalty-point',
  bulkPointReview: '/bulk-loyalty-point/:id',
  deepLinkList: '/deep-link-list',
  automaticNotificationList: '/automatic-notification',
  automaticNotificationEdit: '/automatic-notification/:id',
  salesmanReferralCode: '/salesman-referral-code',
  gamification: '/gamification',
  invalidDataHistory: '/invalid-data-history',
  // userTierList: '/user-tier-list',
};
