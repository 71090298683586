import gql from 'graphql-tag';

import { POP_UP_FRAGMENT } from '../fragments';

export const CREATE_POP_UP = gql`
  mutation CreatePopUp(
    $popUpName: String!
    $title: String
    $textLine1: String
    $textLine2: String
    $textLine3: String
    $image: String
    $linkTypePopUp: LinkTypePopUp
    $color: PopUpColor
    $externalLink: String
    $whatsAppLink: String
    $internalLinkId: ID
    $brandId: String
    $rangeId: String
  ) {
    createPopUp(
      popUpName: $popUpName
      title: $title
      textLine1: $textLine1
      textLine2: $textLine2
      textLine3: $textLine3
      image: $image
      linkTypePopUp: $linkTypePopUp
      color: $color
      externalLink: $externalLink
      whatsAppLink: $whatsAppLink
      internalLinkId: $internalLinkId
      brandId: $brandId
      rangeId: $rangeId
    ) {
      ...PopUpFragment
    }
  }
  ${POP_UP_FRAGMENT}
`;

export const UPDATE_POP_UP = gql`
  mutation UpdatePopUp(
    $popUpId: ID!
    $popUpName: String!
    $title: String
    $textLine1: String
    $textLine2: String
    $textLine3: String
    $image: String
    $linkTypePopUp: LinkTypePopUp
    $color: PopUpColor
    $externalLink: String
    $whatsAppLink: String
    $internalLinkId: ID
    $who: String
    $region: String
    $city: String
    $pageId: ID
    $startDate: DateTime
    $endDate: DateTime
    $brandId: String
    $rangeId: String
    $conditionBrandId: String
    $conditionRangeId: String
  ) {
    updatePopUp(
      popUpId: $popUpId
      popUpName: $popUpName
      title: $title
      textLine1: $textLine1
      textLine2: $textLine2
      textLine3: $textLine3
      image: $image
      linkTypePopUp: $linkTypePopUp
      color: $color
      externalLink: $externalLink
      whatsAppLink: $whatsAppLink
      internalLinkId: $internalLinkId
      who: $who
      region: $region
      city: $city
      pageId: $pageId
      startDate: $startDate
      endDate: $endDate
      brandId: $brandId
      rangeId: $rangeId
      conditionBrandId: $conditionBrandId
      conditionRangeId: $conditionRangeId
    ) {
      ...PopUpFragment
    }
  }
  ${POP_UP_FRAGMENT}
`;

export const ARCHIVE_POP_UP = gql`
  mutation ArchivePopUp($popUpId: ID!) {
    updatePopUpArchiveStatus(popUpId: $popUpId) {
      success
    }
  }
`;
