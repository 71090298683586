import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useQuery } from '@apollo/react-hooks';

import { Text } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import {
  AccessUser,
  Role,
  StatusActive,
} from '../../../../generated/globalTypes';
import { StoreCategories } from '../../../../generated/StoreCategories';
import { GET_STORE_CATEGORIES } from '../../graphql/queries';

import {
  EmployeeStoreLog,
  StoreLog,
  StoreLogChanges,
  StoreLogChangesRespond,
  StoreLogRespond,
} from './types';

type Props = {
  currentData: string;
  updatedData: string;
};

const getStringEmployeeInfo = (employee: EmployeeStoreLog) => {
  let role = '';
  let privileges = '';
  let status = '';

  if (employee.role === Role.TOKO_OWNER) {
    role = 'Owner';
  } else if (employee.role === Role.SUPERVISOR) {
    role = 'Supervisor';
  } else if (employee.role === Role.STAFF) {
    role = 'Staff';
  }

  if (employee.privileges.length === 1) {
    if (employee.privileges.includes(AccessUser.ADD_STAFF)) {
      privileges = 'Dapat Menambah Staff';
    } else if (employee.privileges.includes(AccessUser.CAN_ORDER)) {
      privileges = 'Dapat Memesan';
    }
  } else if (
    employee.privileges.length === 2 &&
    employee.privileges.includes(AccessUser.ADD_STAFF) &&
    employee.privileges.includes(AccessUser.CAN_ORDER)
  ) {
    privileges = 'Dapat Memesan & Dapat Menambah Staff';
  }

  if (employee.status === StatusActive.ACTIVE) {
    status = 'Aktif';
  } else if (employee.status === StatusActive.INACTIVE) {
    status = 'Tidak Aktif';
  }

  return { role, privileges, status };
};

export default function TokoCollapsedItem({ currentData, updatedData }: Props) {
  const { data: storeCategories } = useQuery<StoreCategories>(
    GET_STORE_CATEGORIES,
  );

  useEffect(() => {
    processData(currentData, updatedData);
  }, [currentData, updatedData]);

  let [changes, setChanges] = useState<StoreLogChanges>({
    storeName: false,
    phoneNumber: false,
    storeCategoryId: false,
    storeEmail: false,
    address: false,
    urbanVillageName: false,
    districtName: false,
    cityName: false,
    provinceName: false,
    rt: false,
    rw: false,
    postalCode: false,
    employees: [],
  });

  let [data, setData] = useState<StoreLog>({
    storeName: '',
    storePhoneNumber: '',
    storeCategoryId: '',
    storeEmail: '',
    address: '',
    urbanVillageName: '',
    districtName: '',
    cityName: '',
    provinceName: '',
    rt: '00',
    rw: '00',
    postalCode: '',
    employees: [],
  });

  let processData = (currentData: string, updatedData: string) => {
    let { updateDataStore, employees }: StoreLogRespond =
      currentData && JSON.parse(currentData);
    let updatedChanges: StoreLogChangesRespond =
      currentData && JSON.parse(updatedData);

    setChanges({ ...updatedChanges });
    setData({ ...updateDataStore, employees });
  };

  const getEmployee = useCallback(() => {
    if (!data || !data.employees) {
      return [];
    }

    let employees: Array<RowDetailProps> = [];
    let i = 1;

    for (let employee of data.employees) {
      const changesData = changes.employees[i - 1];

      const { role, privileges, status } = getStringEmployeeInfo(employee);

      [
        {
          isChanged: changesData.name,
          label: `Nama Pengguna ${i}`,
          value: employee.name,
        },
        {
          isIndent: true,
          isChanged: changesData.role,
          label: 'Posisi',
          value: role,
        },
        {
          isIndent: true,
          isChanged: changesData.privileges,
          label: 'Permission',
          value: privileges,
        },
        {
          isIndent: true,
          isChanged: changesData.status,
          label: 'Status',
          value: status,
          separator: true,
        },
      ].forEach((employeeData) => employees.push(employeeData));
      i++;
    }
    return employees;
  }, [data, changes]);

  const rowProps: Array<RowDetailProps> = useMemo(
    () => [
      {
        isChanged: changes.storeName,
        label: 'Nama Toko',
        value: data.storeName,
      },
      {
        isChanged: changes.phoneNumber,
        label: 'Telepon Toko',
        value: data.storePhoneNumber,
      },
      {
        isChanged: changes.storeCategoryId,
        label: 'Kategori Toko',
        value:
          storeCategories?.storeCategory.find(
            (category) => category.id === data.storeCategoryId,
          )?.name ?? '',
      },
      {
        isChanged: changes.storeEmail,
        label: 'Email Toko',
        value: data.storeEmail,
      },
      {
        isChanged: changes.address,
        label: 'Alamat Toko',
        value: data.address,
      },
      {
        isIndent: true,
        isChanged: changes.urbanVillageName,
        label: 'Kelurahan',
        value: data.urbanVillageName,
      },
      {
        isIndent: true,
        isChanged: changes.districtName,
        label: 'Kecamatan',
        value: data.districtName,
      },
      {
        isIndent: true,
        isChanged: changes.cityName,
        label: 'Kabupaten/Kota',
        value: data.cityName,
      },
      {
        isIndent: true,
        isChanged: changes.provinceName,
        label: 'Provinsi',
        value: data.provinceName,
      },
      {
        isIndent: true,
        isChanged: changes.postalCode,
        label: 'Kode Pos',
        value: data.postalCode,
      },
      {
        isIndent: true,
        isChanged: changes.rt,
        label: 'RT',
        value: data.rt ? ('0' + data.rt).slice(-2) : '00',
      },
      {
        isIndent: true,
        isChanged: changes.rw,
        label: 'RW',
        value: data.rw ? ('0' + data.rw).slice(-2) : '00',
        separator: true,
      },
      ...getEmployee(),
    ],
    [data, changes, storeCategories, getEmployee],
  );

  return (
    <View>
      {/* rows */}
      {rowProps.map((props, idx) => (
        <RowDetail key={idx} {...props} />
      ))}
      {/* seperator */}
      <View style={styles.footNote}>
        <Text style={styles.normalText}>
          *Indikator berwarna biru menandakan adanya perubahan data
        </Text>
      </View>
    </View>
  );
}

type RowDetailProps = {
  isChanged?: boolean;
  isIndent?: boolean;
  label: string;
  value: string;
  separator?: boolean;
};

const RowDetail = ({
  isChanged = false,
  isIndent = false,
  label,
  value,
  separator = false,
}: RowDetailProps) => {
  return (
    <>
      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          {isIndent && <View style={styles.sRightSpacing} />}
          <View style={isChanged ? styles.bluePoint : styles.blank} />
          <Text style={isChanged ? styles.highlightedText : styles.normalText}>
            {label}
          </Text>
        </View>
        <Text style={isChanged ? styles.highlightedText : styles.normalText}>
          {value}
        </Text>
      </View>
      {separator && <View style={styles.separator} />}
    </>
  );
};

const styles = StyleSheet.create({
  containers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginTop: 12,
  },
  bluePoint: {
    width: 6,
    marginRight: 8,
    height: 6,
    backgroundColor: colors.background.blue,
    borderRadius: 3,
  },
  blank: {
    width: 6,
    marginRight: 8,
    height: 6,
    backgroundColor: 'transparent',
    borderRadius: 3,
  },
  normalText: { color: colors.text.black, opacity: 0.6 },
  highlightedText: { color: colors.text.black, fontWeight: 'bold' },
  containerPoint: { alignItems: 'center', flexDirection: 'row' },
  footNote: {
    flexDirection: 'row',
    marginTop: 12,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderTopWidth: 1,
    borderColor: colors.border.primary,
  },
  separator: {
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
    marginHorizontal: spacing.s,
    marginTop: spacing.s,
  },
  sRightSpacing: { marginRight: spacing.s },
});
