import React, { ReactNode, CSSProperties, memo, ComponentType } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
  DialogActions,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { colors, spacing, theme } from '../constants/theme';
import { Text } from '../core-ui';

type Props = {
  title?: string;
  children: ReactNode;
  open: boolean;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  onClose: () => void;
  label?: 'owner' | 'toko';
  fullWidth?: boolean;
  actions?: ReactNode;
  titleSize?: string;
  dismissable?: boolean;
  TextComponent?: ComponentType;
};

type DialogTitleProps = {
  children: string;
  onClose: () => void;
  TextComponent: ComponentType;
  label?: 'owner' | 'toko';
  titleSize: string;
};

const useStyles = makeStyles({
  paperFullWidth: {
    maxWidth: '68%',
  },
});

const DialogTitle = memo((props: DialogTitleProps) => {
  const { children, onClose, TextComponent = Text, titleSize, label } = props;
  return (
    <View style={styles.title}>
      <View style={styles.container}>
        <TextComponent bold size={titleSize as keyof typeof theme.fonts.sizes}>
          {children}
        </TextComponent>
        {label && (
          <View
            style={[
              styles.label,
              label === 'owner' ? styles.ownerColor : styles.tokoColor,
            ]}
          >
            <TextComponent bold style={styles.whiteText}>
              {label === 'owner' ? 'Owner' : 'Toko'}
            </TextComponent>
          </View>
        )}
      </View>
      {onClose ? (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </View>
  );
});

const Modal = memo((props: Props) => {
  let classes = useStyles();
  let {
    title,
    children,
    open,
    style,
    contentStyle,
    onClose,
    label,
    fullWidth,
    actions,
    TextComponent = Text,
    dismissable = false,
    titleSize = 'medium',
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      classes={classes}
      style={StyleSheet.flatten(style) as CSSProperties}
      disableBackdropClick={dismissable}
    >
      {title && label != null ? (
        <DialogTitle
          onClose={onClose}
          TextComponent={TextComponent}
          label={label}
          titleSize={titleSize}
        >
          {title}
        </DialogTitle>
      ) : title ? (
        <DialogTitle
          onClose={onClose}
          TextComponent={TextComponent}
          titleSize={titleSize}
        >
          {title}
        </DialogTitle>
      ) : null}

      <DialogContent
        style={
          StyleSheet.flatten([
            !title && styles.spacing,
            contentStyle,
          ]) as CSSProperties
        }
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          style={StyleSheet.flatten(styles.actions) as CSSProperties}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
});

const styles = StyleSheet.create({
  spacing: {
    padding: spacing.large,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing.small,
    paddingHorizontal: spacing.large,
  },
  actions: {
    paddingRight: spacing.large,
    paddingBottom: spacing.medium,
  },
  label: {
    marginLeft: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 6,
    paddingHorizontal: 12,
    borderRadius: 24,
  },
  whiteText: { color: colors.text.contrast },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ownerColor: {
    backgroundColor: colors.label.primary,
  },
  tokoColor: {
    backgroundColor: colors.label.secondary,
  },
});

export default Modal;
