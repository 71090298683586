import React from 'react';
import { View, Image, StyleSheet } from 'react-native';

import { StepHeader } from '../../components';
import { Text, TextInput, Button } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { emailIcon } from '../../../assets';

import { CompetitionWinner } from './StepSevenCompetition';

type Props = {
  editable: boolean;
  winners: Array<CompetitionWinner>;
  onEmailChange: (text: string, index: number) => void;
  onNotify: (winnerId: string) => void;
};

export default function StepEightCompetition(props: Props) {
  let { editable, winners, onNotify, onEmailChange } = props;

  return (
    <>
      <StepHeader step={8} title={t(['Kabari Pemenang', 'Notify Winner/s'])} />
      <Text style={{ marginVertical: spacing.small }} bold>
        {t(['Pemenang', 'Winner/s'])}
      </Text>
      {winners[0] ? <Text bold>Email</Text> : null}
      {winners.map(({ id, email: winnerEmail, notified }, index) => (
        <View key={index} style={styles.winnerRow}>
          <TextInput
            value={winnerEmail}
            onChangeText={(text) => onEmailChange(text, index)}
            containerStyle={styles.winner}
            disabled={!editable}
          />
          {notified ? (
            <View
              style={[
                styles.emailWrapper,
                {
                  width: 58,
                  height: 36,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: colors.button.secondary.background,
                },
              ]}
            >
              <Text color="contrast">{t(['Terkirim', 'Sent'])}</Text>
            </View>
          ) : (
            <Button
              preset="transparent"
              disabled={!editable || !id}
              title="email"
              icon={
                <Image
                  source={emailIcon}
                  style={[
                    styles.email,
                    { opacity: !editable || !id ? 0.3 : 1 },
                  ]}
                  resizeMode="contain"
                />
              }
              onPress={() => onNotify(id)}
              style={styles.emailWrapper}
            />
          )}
        </View>
      ))}
    </>
  );
}

const styles = StyleSheet.create({
  email: { width: 36, height: 24 },
  winnerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailWrapper: { position: 'absolute', right: 0 },
  winner: { marginVertical: spacing.xsmall },
});
