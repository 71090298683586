import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import { useLazyQuery } from '@apollo/react-hooks';
import { logEvent } from 'expo-firebase-analytics';

import { Table } from '../../core-ui';
import { HeaderNavigationBar, Header, ErrorMessage } from '../../components';
import { spacing } from '../../constants/theme';
import { GET_SUBMIT_FORM_EVENT } from '../../graphql/queries';
import {
  SubmitFormEventAdvanceSearchVariables,
  SubmitFormEventAdvanceSearch,
} from '../../generated/SubmitFormEventAdvanceSearch';
import { routePaths } from '../../constants/routes';
import { pageTitle } from '../../constants/pageTitle';

export default function SubmittedEventForm() {
  const history = useHistory<{
    formId: number;
    name: string;
  }>();
  const { formId, name } = history.location.state;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [getSubmitFormEvent, { data, loading, error, refetch }] = useLazyQuery<
    SubmitFormEventAdvanceSearch,
    SubmitFormEventAdvanceSearchVariables
  >(GET_SUBMIT_FORM_EVENT, {
    variables: { first: rowsPerPage, skip: page * rowsPerPage, formId: formId },
  });
  const onChangePage = useCallback(
    (newPage: number) => {
      refetch?.({
        skip: page * rowsPerPage,
        first: rowsPerPage,
        formId: formId,
      });
      setPage(newPage);
    },
    [page, rowsPerPage, formId, refetch],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.SubmittedEventForm,
      });
    };
    eventLog();
  }, []);

  useEffect(() => {
    getSubmitFormEvent();
  }, [getSubmitFormEvent]);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  const onBackPress = useCallback(() => history.push(routePaths.onlineForms), [
    history,
  ]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar
        backDestinationText={t(['Formulir Online', 'Online Forms'])}
        onBackButtonPress={onBackPress}
      />
      <Header withSeparator title={name} />
      <Table
        error={!!error && !!data}
        data={(data && data.SubmitFormEventAdvanceSearch.row) ?? []}
        loading={loading}
        dataCount={(data && data.SubmitFormEventAdvanceSearch.total) || 0}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        rowsPerPage={rowsPerPage}
        structure={{
          name: {
            headerTitle: t(['nama', 'name']),
          },
          storeName: {
            headerTitle: t(['toko', 'toko']),
          },
          acctNumber: {
            headerTitle: t(['nomor akun', 'account number']),
          },
          attendees: {
            headerTitle: t(['total pengunjung', 'total attendees']),
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          error={error}
          action={t(['mengambil data formulir', 'retrieve the formulir data'])}
          onPress={refetch}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
});
