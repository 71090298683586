import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import { Text } from '../core-ui';
import { colors, fonts } from '../constants/theme';
import { cancel } from '../../assets';

import Modal from './Modal';
type Props = {
  open: boolean;
  onClose: () => void;
  progress: number;
  isFailed: boolean;
};

export default function ProgressBarModal(props: Props) {
  let { onClose, open, progress, isFailed } = props;
  let maxWidth = 480;

  const renderProgress = () => (
    <View style={styles.progressModalContainer}>
      <Text bold style={styles.progressTitle}>
        Exporting Data...
      </Text>
      <View style={styles.progressBackground}>
        <View
          style={[styles.progressFill, { width: (progress * maxWidth) / 100 }]}
        />
      </View>
      <Text style={styles.progressText}>
        Exporting {isNaN(progress) ? 0 : progress.toFixed(2)}% out of 100%
      </Text>
    </View>
  );

  const renderFailed = () => (
    <View style={styles.failedModalContainer}>
      <View style={styles.closeContainer}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </View>
      <Image source={cancel} style={styles.icon} />
      <Text style={styles.failedText} bold>
        Maaf download Anda gagal, silahkan coba kembali
      </Text>
    </View>
  );

  return (
    <Modal
      dismissable={false}
      onClose={onClose}
      open={open}
      contentStyle={styles.content}
      children={isFailed ? renderFailed() : renderProgress()}
    />
  );
}

const styles = StyleSheet.create({
  progressModalContainer: {
    maxWidth: 600,
    width: 600,
    height: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  failedModalContainer: {
    maxWidth: 600,
    width: 600,
    height: 272,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeContainer: {
    alignItems: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  icon: { width: 72, height: 72 },
  progressBackground: {
    maxWidth: 480,
    width: 480,
    height: 10,
    backgroundColor: '#57ABD21A',
    borderRadius: 5,
  },
  progressFill: {
    maxWidth: 480,
    height: 10,
    backgroundColor: colors.progress.value,
    borderRadius: 5,
  },
  progressTitle: {
    color: '#000000',
    alignSelf: 'center',
    fontSize: fonts.sizes.large,
    marginBottom: 16,
  },
  progressText: {
    color: '#00000099',
    alignSelf: 'center',
    marginTop: 16,
    fontSize: fonts.sizes.default,
  },

  failedText: {
    color: colors.text.default,
    alignSelf: 'center',
    marginTop: 16,
    fontSize: fonts.sizes.medium,
  },
  content: { padding: 0 },
});
