import React from 'react';

import { Header } from '../../components';
import { LibraryDocument } from '../../generated/LibraryDocument';

import LibraryTable from './LibraryTable';

type Props = {
  refetchToggle: boolean;
  onSubmit?: () => void;
  setEditDocument: (value: LibraryDocument) => void;
};

const LibraryShowAll = (props: Props) => {
  let { refetchToggle, onSubmit, setEditDocument } = props;
  return (
    <>
      <Header
        title={t(['Tampilkan Semua', 'Show All'])}
        titleColor="link"
        withSeparator
      />
      <LibraryTable
        refetchToggle={refetchToggle}
        onSubmit={onSubmit}
        setEditDocument={setEditDocument}
      />
    </>
  );
};

export default LibraryShowAll;
