import { useState, useCallback, useMemo } from 'react';
import { MutationHookOptions, useMutation } from '@apollo/react-hooks';

import { UPLOAD } from '../graphql/mutations';
import { Upload, UploadVariables } from '../generated/Upload';

export const useUploadWithProgress = <
  TData extends Upload,
  TVars extends UploadVariables
>(
  query: typeof UPLOAD = UPLOAD,
  mutationOptions?: MutationHookOptions<TData, TVars>,
) => {
  // NOTE: need to handle loading because the one from useMutation is always false when returned
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [progress, setProgress] = useState(0);
  const reset = useCallback(() => {
    setLoading(false);
    setDone(false);
    setProgress(0);
  }, []);
  const onLoadStart = useCallback(() => {
    setDone(false);
    setLoading(true);
  }, []);
  const onProgress = useMemo(() => {
    if (setProgress) {
      return (e: ProgressEvent) => {
        setProgress(Math.round((e.loaded / e.total) * 100));
        if (e.loaded === e.total) {
          setLoading(false);
          setDone(true);
        }
      };
    }
  }, [setProgress]);
  const options = useMemo(
    () => ({
      ...mutationOptions,
      context: {
        fetchOptions: {
          useUpload: true,
          onLoadStart,
          onProgress,
        },
      },
    }),
    [mutationOptions, onLoadStart, onProgress],
  );
  const [upload, { data, error }] = useMutation<TData, TVars>(query, options);

  return { upload, data, loading, error, progress, done, reset };
};
