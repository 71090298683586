import gql from 'graphql-tag';

export const UPDATE_LOYALTY_POINT_MISSION = gql`
  mutation UpdateLoyaltyPointMission(
    $activeStatus: Boolean!
    $date: DateFilter!
  ) {
    updateLoyaltyPointMission(activeStatus: $activeStatus, date: $date) {
      success
    }
  }
`;
