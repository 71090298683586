import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { theme } from '../../constants/theme';
import { HeaderNavigationBar } from '../../components';
import { LibraryDocument } from '../../generated/LibraryDocument';
import { pageTitle } from '../../constants/pageTitle';

import LibraryUploadContent from './LibraryUploadContent';
import LibrarySearch from './LibrarySearch';
import LibraryShowAll from './LibraryShowAll';

const LibraryScene = () => {
  let [refetchToggle, setRefetchToggle] = useState(false);
  let [editDocument, setEditDocument] = useState<LibraryDocument | null>(null);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.LibraryScene });
    };
    eventLog();
  }, []);

  const onSubmit = useCallback(() => {
    setRefetchToggle(!refetchToggle);
    setEditDocument(null);
  }, [refetchToggle]);

  return (
    <View style={styles.root}>
      <View style={styles.section}>
        <HeaderNavigationBar />
        <LibraryUploadContent
          onSubmitCompleted={onSubmit}
          editDocument={editDocument}
        />
      </View>
      <View style={styles.section}>
        <LibrarySearch
          refetchToggle={refetchToggle}
          setEditDocument={setEditDocument}
          onSubmit={onSubmit}
        />
      </View>
      <View style={styles.section}>
        <LibraryShowAll
          refetchToggle={refetchToggle}
          setEditDocument={setEditDocument}
          onSubmit={onSubmit}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: theme.spacing.xlarge },
  section: { paddingBottom: theme.spacing.xlarge },
});

export default LibraryScene;
