export const unverifiedDocumentType = [
  { label: 'KTP', value: 'KTP' },
  { label: 'Selfie', value: 'SELFIE' },
  { label: 'Nama Pemilik', value: 'OWNER_NAME' },
  { label: 'Data', value: 'DATA' },
];

export const unverifiedSelfieProblem = [
  {
    label: 'Foto Selfie menggunakan Masker, topi, dan Kaca mata Hitam',
    value: 'Foto Selfie menggunakan Masker, topi, dan Kaca mata Hitam',
  },
  {
    label:
      'Foto Selfie Tidak dapat divalidasi karena Foto Buram karena gemetar.',
    value:
      'Foto Selfie Tidak dapat divalidasi karena Foto Buram karena gemetar.',
  },
  {
    label:
      'Foto Selfie Tidak dapat divalidasi karena Foto tidak sesuai dengan Frame.',
    value:
      'Foto Selfie Tidak dapat divalidasi karena Foto tidak sesuai dengan Frame.',
  },
  {
    label: 'Foto Selfie tidak diambil Langsung',
    value: 'Foto Selfie tidak diambil Langsung',
  },
];

export const unverifiedIdProblem = [
  {
    label: 'Foto KTP Buram',
    value: 'Foto KTP Buram',
  },
  {
    label: 'KTP yang difoto tidak langsung dari KTP Asli',
    value: 'KTP yang difoto tidak langsung dari KTP Asli',
  },
  {
    label: 'Informasi KTP ditutupi',
    value: 'Informasi KTP ditutupi',
  },
  {
    label: 'KTP Yang diupload adalah Fotocopy KTP',
    value: 'KTP Yang diupload adalah Fotocopy KTP',
  },
  {
    label: 'Foto KTP Tidak Sesuai dengan Selfie yang dilakukan.',
    value: 'Foto KTP Tidak Sesuai dengan Selfie yang dilakukan.',
  },
];

export const ownerNameUnverifiedProblem = [
  {
    label: 'Nama pemilik yang diinputkan tidak sesuai dengan nama Pada KTP',
    value: 'Nama pemilik yang diinputkan tidak sesuai dengan nama Pada KTP',
  },
];

export const ownerDataUnverifiedProblem = [
  {
    label: 'Data yang disubmit masih belum sesuai ketentuan DBO',
    value: 'Data yang disubmit masih belum sesuai ketentuan DBO',
  },
];

export const nextAction = [
  {
    problem: unverifiedIdProblem[0].value,
    action: 'Dimintakan Foto KTP kembali',
  },
  {
    problem: unverifiedIdProblem[1].value,
    action: 'Diminta Upload Foto KTP Langsung',
  },
  {
    problem: unverifiedIdProblem[2].value,
    action: 'Dimintakan Foto kembali KTP',
  },
  {
    problem: unverifiedIdProblem[3].value,
    action: 'Dimintakan Foto kembali KTP',
  },
  {
    problem: unverifiedIdProblem[4].value,
    action: 'Di minta foto sesuai dengan KTP yang diinputkan.',
  },
  {
    problem: unverifiedSelfieProblem[0].value,
    action:
      'Dimintakan Foto kembali tanpa menggunakan Masker, kaca mata dan Topi',
  },
  {
    problem: unverifiedSelfieProblem[1].value,
    action:
      'Dimintakan Foto kembali ditempat yang terang dan lebih jelas (tidak buram)',
  },
  {
    problem: unverifiedSelfieProblem[2].value,
    action:
      'Dimintakan Foto kembali sesuai dengan frame pada saat melakukan Selfie.',
  },
  {
    problem: unverifiedSelfieProblem[3].value,
    action: 'Dimintakan Foto kembali wajah pemilik secara langsung.',
  },
  {
    problem: ownerNameUnverifiedProblem[0].value,
    action:
      'Diminta untuk update nama pemilik pada Toko App di menu Akun Saya lalu klik Edit kemudian ubah nama pemilik di kolom Nama Akun lalu klik Simpan.',
  },
  {
    problem: ownerDataUnverifiedProblem[0].value,
    action:
      'Diminta untuk mengajukan ulang proses verifikasi pemilik toko dengan mencetang Kalimat Pernyataan dan klik Lanjut lalu ikuti prosesnya',
  },
];
