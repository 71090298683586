import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { BRANDS } from '../../graphql/queries';
import { Brands, BrandsVariables } from '../../generated/Brands';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Brand', 'Select Brand']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  disabledOptionsById?: Array<string>;
  queryVariables?: BrandsVariables;
  showLocalBrandData?: boolean;
};

const BrandDropdown = memo((props: Props) => {
  let {
    title = defaultTitle,
    disabledOptionsById,
    selectedOption,
    queryVariables,
    showLocalBrandData,
    ...otherProps
  } = props;

  let { data, loading, error, refetch } = useQuery<Brands, BrandsVariables>(
    BRANDS,
    {
      variables: { showAllLocalBrand: showLocalBrandData },
      notifyOnNetworkStatusChange: true,
    },
  );
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch(queryVariables);
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [queryVariables, refetch]);

  useEffect(refetchData, [queryVariables]);

  let brandOptions = useMemo(
    () =>
      data?.brands.map((brand) => {
        return {
          label: brand.name,
          value: brand.brandId,
          subValue: brand.brandId,
        };
      }),
    [data],
  );
  let availableOptions = useMemo(
    () =>
      data?.brands
        .filter((brand) => {
          return (
            selectedOption === brand.id ||
            !disabledOptionsById?.includes(brand.id)
          );
        })
        .map((brand) => {
          return { label: brand.name, value: brand.id };
        }),
    [data, disabledOptionsById, selectedOption],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data brand', 'retrieve the brand data'])}
      onRetryPress={refetchData}
      options={(disabledOptionsById ? availableOptions : brandOptions) ?? []}
      title={title}
      loading={loading}
      selectedOption={selectedOption}
      {...otherProps}
    />
  );
});

export default BrandDropdown;
