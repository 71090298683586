import React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { theme } from '../constants/theme';

type Props = {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
};

export default function Box({ children, style }: Props) {
  return <View style={[styles.box, style]}>{children}</View>;
}

const styles = StyleSheet.create({
  box: {
    borderWidth: 1,
    borderColor: theme.colors.border.primary,
    padding: theme.spacing.small,
  },
});
