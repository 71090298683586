import React from 'react';
import { View, StyleSheet } from 'react-native';
import BaseCollapsible from 'react-collapsible';

import { colors } from '../../constants/theme';

type Props = {
  children: React.ReactChild;
  onPressOpening: () => void;
  onPressClosing: () => void;
  title: string | React.ReactElement;
  titleOpened: string | React.ReactElement;
};

export function Collapsible({
  children,
  onPressClosing,
  onPressOpening,
  titleOpened,
  title,
}: Props) {
  return (
    <BaseCollapsible
      onOpening={() => {
        onPressOpening();
      }}
      onClosing={() => {
        onPressClosing();
      }}
      trigger={title}
      transitionTime={150}
      selected
      triggerWhenOpen={titleOpened}
    >
      <View style={styles.content}>{children}</View>
    </BaseCollapsible>
  );
}

const styles = StyleSheet.create({
  content: {
    backgroundColor: colors.background.lightGrey,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: colors.border.primary,
  },
});
