import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import * as Sentry from '@sentry/react';

import { STAGING_API } from '../constants/api';
import { getToken, customFetch } from '../helpers';
import ErrorLog from '../services/ErrorLog';

const cache = new InMemoryCache();

const authLink = setContext(async (_, { headers }) => {
  let token = await getToken();
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const httpLink = createUploadLink({
  uri: STAGING_API,
  credentials: 'same-origin',
  // NOTE: a workaround from apollo-upload-client issue thread (https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261)
  fetch: customFetch,
});

const sentryLoggerLink = new ApolloLink((operation, forward) => {
  ErrorLog.setLatestApiParams({
    operationName: operation.operationName,
    params: { ...operation.variables },
  });

  return forward(operation).map((data) => {
    ErrorLog.setLatestApiResponse({
      operationName: operation.operationName,
      response: { ...data?.data },
    });
    return data;
  });
});

const errorLink = onError(({ operation, graphQLErrors, response }) => {
  if (operation && graphQLErrors) {
    Sentry.withScope((scope) => {
      const gqlError =
        Array.isArray(graphQLErrors) && graphQLErrors.length > 0
          ? graphQLErrors[0]
          : graphQLErrors;

      // This GQL Error is spamming the sentry
      if (gqlError.message.includes('Not Authorised!')) {
        return;
      }

      scope.setExtra('name', operation.operationName);
      scope.setExtra('query', operation.query);
      scope.setExtra('variables', JSON.stringify(operation.variables));
      scope.setExtra('response', response);
      Sentry.captureException(new Error(`GQL Error: ${gqlError.message}`));
    });
  }
});

const link = ApolloLink.from([errorLink, sentryLoggerLink, authLink, httpLink]);

export const client = new ApolloClient({ link, cache });
