import React, { useEffect } from 'react';
import { Image, View, StyleSheet, Linking } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { logo, appstore, playstore } from '../../assets';
import { Text, Button } from '../core-ui';
import { spacing } from '../constants/theme';
import { appName, appStoreId, playStoreId } from '../constants/app';
import { pageTitle } from '../constants/pageTitle';

const DownloadAppScene = () => {
  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.DownloadAppScene });
    };
    eventLog();
  }, []);

  return (
    <View style={styles.root}>
      <Image source={{ uri: logo }} style={styles.logo} resizeMode="contain" />
      <Text bold size="large" style={styles.text}>
        {t([
          'Unduh aplikasi DBO untuk menggunakan layanan ini',
          'Install DBO App to use this service',
        ])}
      </Text>
      <Text size="medium">
        {t([
          'Tersedia di Google Play dan App Store',
          'Available on Google Play and Apple App Store',
        ])}
      </Text>
      <View style={styles.row}>
        <Button
          preset="transparent"
          title=""
          icon={
            <Image
              source={{ uri: playstore }}
              resizeMode="contain"
              style={styles.imageButton}
            />
          }
          onPress={() => {
            Linking.openURL(
              `https://play.google.com/store/apps/details?id=${playStoreId}`,
            );
          }}
          style={styles.button}
        />
        <Button
          preset="transparent"
          title=""
          icon={
            <Image
              source={{ uri: appstore }}
              resizeMode="contain"
              style={styles.imageButton}
            />
          }
          onPress={() => {
            Linking.openURL(
              `https://itunes.apple.com/id/app/${appName}/id${appStoreId}`,
            );
          }}
          style={styles.button}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 160,
    height: 160,
    marginBottom: spacing.medium,
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    paddingBottom: spacing.xsmall,
  },
  row: {
    flexDirection: 'row',
  },
  button: {
    marginTop: spacing.medium,
    marginLeft: spacing.xsmall,
    marginRight: spacing.xsmall,
  },
  imageButton: {
    width: 164,
    height: 48,
  },
});

export default DownloadAppScene;
