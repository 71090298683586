import gql from 'graphql-tag';

import { PROMOTION_FRAGMENT } from '../fragments';

export const GET_PROMOTION = gql`
  query Promotions(
    $first: Int
    $skip: Int
    $user: String
    $region: String
    $city: [String!]
    $name: String
    $date: DateFilter
    $archived: ArchiveStatus
    $brandId: ID
    $orderBy: PromotionOrderBy
    $promotionCode: String
  ) {
    promotionAdvanceSearch(
      user: $user
      region: $region
      city: $city
      promotionName: $name
      date: $date
      archiveStatus: $archived
      brandId: $brandId
      pagination: { first: $first, skip: $skip }
      orderBy: $orderBy
      promotionCode: $promotionCode
    ) {
      row {
        ...PromotionFragment
      }
      total
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const CHECK_PROMOTION_CODE = gql`
  query CheckPromotionsCode($promotionCode: String!) {
    checkUniquePromotionCode(promotionCode: $promotionCode) {
      exist
    }
  }
`;
