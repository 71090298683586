import React, { useMemo, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { Cities, CitiesVariables } from '../../../../generated/Cities';
import { GET_CITIES } from '../../../../graphql/queries';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultPlaceholder = t(['- Pilih Kota -', '- Select City -']);

type Props = Omit<
  DropdownProps<string>,
  'options' | 'multiple' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (value: string, label: string) => void;
  provinceID?: string;
};

const CityDropdown = (props: Props) => {
  let { placeholder = defaultPlaceholder, provinceID, ...otherProps } = props;

  //TODO: add error handling
  const [getCities, { data }] = useLazyQuery<Cities, CitiesVariables>(
    GET_CITIES,
  );

  const fetchDistricts = useCallback(() => {
    if (provinceID) {
      getCities({ variables: { provincesID: [provinceID] } });
    }
  }, [provinceID, getCities]);

  useEffect(fetchDistricts, [fetchDistricts]);

  const options = useMemo(
    () =>
      provinceID
        ? data?.cityByProvinceId.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          })) ?? []
        : [],
    [provinceID, data],
  );

  return (
    <Dropdown options={options} placeholder={placeholder} {...otherProps} />
  );
};

export default CityDropdown;
