import React from 'react';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import {
  ownerNameUnverifiedProblem,
  unverifiedIdProblem,
  unverifiedSelfieProblem,
  nextAction as nextActionType,
  ownerDataUnverifiedProblem,
} from '../../constants/unverifiedDocument';
import { Text } from '../../../../core-ui';
import { colors } from '../../constants/theme';

import Dropdown from './Dropdown';
type Options = {
  label: string;
  value: string;
};

type Props = {
  setUnverifiedDocument: (value: Array<string>) => void;
  setUnverifiedProblem: (value: Array<string>) => void;
  setNextActionDesc: (value: Array<string>) => void;
  unverifiedDocument: Array<string>;
  unverifiedProblem: Array<string>;
  options: Array<Options>;
  disabledDocumentType: boolean;
  disabledProblem: boolean;
  index: number;
  nextAction: Array<string>;
};

const UnverifiedCategories = (props: Props) => {
  let {
    setUnverifiedDocument,
    setUnverifiedProblem,
    setNextActionDesc,
    unverifiedDocument,
    unverifiedProblem,
    options,
    disabledDocumentType,
    disabledProblem,
    index,
    nextAction,
  } = props;

  return (
    <>
      <View style={index !== 0 ? styles.container : styles.firstContainer}>
        <Dropdown
          onChange={(value: string) => {
            let tempValueDocument = [...unverifiedDocument];
            let tempValueProblem = [...unverifiedProblem];
            let tempNextAction = [...nextAction];
            tempValueDocument[index] = value;
            tempValueProblem[index] = '';
            tempNextAction[index] = '';
            setUnverifiedDocument(tempValueDocument);
            setUnverifiedProblem(tempValueProblem);
            setNextActionDesc(tempNextAction);
          }}
          label={t(['Jenis Dokumen', 'Document Type'])}
          value={unverifiedDocument[index]}
          options={options}
          disabled={disabledDocumentType}
          disableOption={
            index !== 0
              ? {
                  disable: true,
                  nameOption: unverifiedDocument,
                }
              : undefined
          }
          containerStyle={styles.documentDropdownContainer}
        />
        <Dropdown
          onChange={(value: string) => {
            let tempValueProblem = [...unverifiedProblem];
            tempValueProblem[index] = value;
            setUnverifiedProblem(tempValueProblem);
            let nextActionDesc = nextActionType.find(
              (val) => val.problem === value,
            );
            let tempNextAction = [...nextAction];
            tempNextAction[index] = nextActionDesc?.action
              ? nextActionDesc.action
              : '';
            setNextActionDesc(tempNextAction);
          }}
          label={t(['Permasalahan yang Ditemukan', 'Problem Found'])}
          value={unverifiedProblem[index]}
          options={
            unverifiedDocument[index] === 'KTP'
              ? unverifiedIdProblem
              : unverifiedDocument[index] === 'SELFIE'
              ? unverifiedSelfieProblem
              : unverifiedDocument[index] === 'DATA'
              ? ownerDataUnverifiedProblem
              : ownerNameUnverifiedProblem
          }
          disabled={disabledProblem || unverifiedDocument[index] === ''}
          containerStyle={styles.problemDropdownContainer}
        />
        {index !== 0 && (
          <TouchableOpacity
            style={styles.deleteDropdownIcon}
            onPress={() => {
              let tempValueDocument = [...unverifiedDocument];
              let tempValueProblem = [...unverifiedProblem];
              let tempNextAction = [...nextAction];
              tempValueDocument.splice(index, 1);
              tempValueProblem.splice(index, 1);
              tempNextAction.splice(index, 1);
              setUnverifiedDocument(tempValueDocument);
              setUnverifiedProblem(tempValueProblem);
              setNextActionDesc(tempNextAction);
            }}
          >
            <RemoveCircleOutline htmlColor={colors.text.red} />
          </TouchableOpacity>
        )}
      </View>
      <Text style={styles.nextActionText}>
        Next Action : {nextAction[index]}
      </Text>
      {index !== 2 && <View style={styles.divider} />}
      {index !== 2 && unverifiedDocument.length - 1 === index && (
        <TouchableOpacity
          style={styles.addCategoriesContainer}
          onPress={() => {
            setUnverifiedDocument([...unverifiedDocument, '']);
            setUnverifiedProblem([...unverifiedProblem, '']);
            setNextActionDesc([...nextAction, '']);
          }}
        >
          <AddIcon htmlColor={colors.icon.blue} />
          <Text color={'link'} style={styles.addCategoryText}>
            Tambah Kategori
          </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 16,
  },
  firstContainer: {
    flexDirection: 'row',
  },
  documentDropdownContainer: { maxWidth: 108, flex: 1, marginRight: 12 },
  problemDropdownContainer: { marginRight: 12, flex: 1, maxWidth: 316 },
  deleteDropdownIcon: {
    justifyContent: 'center',
    marginTop: 21,
    marginLeft: 3,
  },
  nextActionText: { marginTop: 8 },
  divider: {
    width: '100%',
    borderColor: colors.button.border.primary,
    borderWidth: 1,
    borderStyle: 'solid',
    marginTop: 16,
  },
  addCategoriesContainer: {
    marginTop: 17,
    flexDirection: 'row',
  },
  addCategoryText: {
    marginLeft: 8,
    alignSelf: 'center',
    fontWeight: '900',
  },
});

export default UnverifiedCategories;
