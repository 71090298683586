import gql from 'graphql-tag';

export const EMAIL_ASSIGNED_BU = gql`
  query EmailAssignedBU($buContactId: ID!) {
    sendEmailBUAdd(buContactId: $buContactId) {
      success
    }
  }
`;

export const EMAIL_COMPETITION_TO_BU = gql`
  query EmailCompetitionToBU($competitionId: ID!) {
    sendEmailCompetitionAssign(competitionId: $competitionId) {
      success
    }
  }
`;

export const EMAIL_EVENT_TO_BU = gql`
  query EmailEventToBU($eventId: ID!) {
    sendEmailEventAssign(eventId: $eventId) {
      success
    }
  }
`;

export const EMAIL_NEWS_TO_BU = gql`
  query EmailNewsToBU($newsId: ID!) {
    sendEmailNewsAssign(newsId: $newsId) {
      success
    }
  }
`;

export const EMAIL_PROMOTION_TO_BU = gql`
  query EmailPromotionToBU($promotionId: ID!) {
    sendEmailPromotionAssign(promotionId: $promotionId) {
      success
    }
  }
`;

export const EMAIL_SLIDER_TO_BU = gql`
  query EmailSliderToBU($sliderId: ID!) {
    sendEmailAssignSlider(sliderId: $sliderId) {
      success
    }
  }
`;
