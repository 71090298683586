import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { logEvent } from 'expo-firebase-analytics';

import { Table } from '../../../core-ui';
import { Header, HeaderNavigationBar, ErrorMessage } from '../../../components';
import { spacing } from '../../../constants/theme';
import { GET_ORDER_DETAIL } from '../../verification/graphql/queries/order';
import {
  OrderDetail as order,
  OrderDetailVariables,
} from '../../../generated/OrderDetail';
import { OrderType } from '../../../generated/globalTypes';
import { Text } from '../../verification/core-ui';
import { colors } from '../../verification/constants/theme';
import { pageTitle } from '../../../constants/pageTitle';

export default function OrderDetail() {
  const history = useHistory<{
    storeName?: string;
    orderId: string;
    orderNumber: string;
    orderType: OrderType;
    storeId: string;
  }>();
  const { storeName, orderId, orderType, orderNumber, storeId } =
    history.location.state ?? {};
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { data, loading, error, refetch } = useQuery<
    order,
    OrderDetailVariables
  >(GET_ORDER_DETAIL, {
    variables: {
      orderId: orderId,
      orderNumber: orderNumber,
      type: orderType,
      storeId: storeId,
    },
  });

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.OrderDetail });
    };
    eventLog();
  }, []);

  const paginatedData = useMemo(() => {
    if (!loading && data) {
      let newData =
        data.orderDetail &&
        data.orderDetail.orders.slice(
          page,
          page === 0 ? rowsPerPage : page * rowsPerPage,
        );
      return newData;
    }
  }, [data, loading, page, rowsPerPage]);

  const onRowsChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setRowsPerPage(Number(event.currentTarget.value));
      setPage(0);
    },
    [],
  );

  const onBack = useCallback(() => {
    history.push(history.location.pathname.split('/').slice(0, -1).join('/'));
  }, [history]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar
        backDestinationText={storeName ?? t(['Poin Toko', 'Toko Point'])}
        onBackButtonPress={onBack}
      />
      <Header
        withSeparator
        title={t(['Pesanan #{orderNumber}', 'Order #{orderNumber}'], {
          orderNumber,
        })}
      />
      <View style={styles.purchaseAmount}>
        <Text bold style={styles.purchaseText}>
          {t(['Total Pembelian', 'Purchase Amount'])}
        </Text>
        <Text>{`Rp ${data?.orderDetail?.totalPrice || 0}`}</Text>
      </View>
      <Table
        error={!!error && !!data}
        data={paginatedData || []}
        loading={loading}
        dataCount={data?.orderDetail?.orders.length || 0}
        onChangePage={setPage}
        onChangeRowsPerPage={onRowsChange}
        rowsPerPage={rowsPerPage}
        structure={{
          orderDate: {
            headerTitle: t(['tanggal', 'date']),
            valuePreProcessor: (value) => new Date(value as string),
          },
          brandName: {
            headerTitle: t(['merek', 'brand']),
          },
          productName: {
            headerTitle: t(['barang', 'item']),
          },
          productCode: {
            headerTitle: t(['kode', 'code']),
          },
          quantity: {
            headerTitle: t(['kuantitas', 'qty']),
            valueProcessor: (value: string) => `${value} m2`,
            render: ({ quantity, unit }) => (
              <Text>
                {quantity.toLocaleString('id')} {unit}
              </Text>
            ),
          },
          subTotalPrice: {
            headerTitle: t(['total harga', 'amount']),
            valueProcessor: (value: string) => `Rp. ${value}`,
          },
          agentName: {
            headerTitle: 'distributor',
            render: () => {
              return data && data.orderDetail && data.orderDetail.placedBy;
            },
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          error={error}
          action={t([
            'mengambil data rincian pesanan',
            'retrieve the order detail',
          ])}
          onPress={refetch}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  purchaseText: { marginBottom: spacing.small },
  purchaseAmount: {
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    paddingTop: spacing.medium,
    paddingBottom: spacing.large,
  },
});
