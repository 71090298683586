import gql from 'graphql-tag';

export const SLIDER_FRAGMENT = gql`
  fragment SliderFragment on CardSlider {
    id
    name
    appUser {
      id
      name
    }
    region {
      id
      name
    }
    city {
      id
      name
    }
    promotionLink {
      id
    }
    numberOrder
    externalLink
    brand {
      id
      name
      businessUnitContact {
        id
        name
      }
    }
    image
    liveDate
    endLiveDate
    timeActive
    status
    active
  }
`;
