import gql from 'graphql-tag';

import {
  EVENT_FRAGMENT,
  COMPETITION_FRAGMENT,
  NEWS_FRAGMENT,
} from '../fragments';

export const CREATE_EVENT = gql`
  mutation CreateEvent(
    $brandId: ID!
    $eventName: String!
    $eventDesc: String!
    $eventDescSalesman: String
    $appUser: String!
    $region: String!
    $city: String!
    $venue: String!
    $formLinkId: Int
    $registrationDeadline: DateTime!
    $externalLink: String
    $image: String!
    $dedicatedPageImage: String
    $eventDate: DateTime!
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $externalButton: String
    $urlDeeplinkSalesman: String
  ) {
    createEvent(
      brandId: $brandId
      eventName: $eventName
      eventDesc: $eventDesc
      eventDescSalesman: $eventDescSalesman
      appUser: $appUser
      region: $region
      city: $city
      venue: $venue
      formLinkId: $formLinkId
      registrationDeadline: $registrationDeadline
      externalLink: $externalLink
      image: $image
      dedicatedPageImage: $dedicatedPageImage
      eventDate: $eventDate
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      externalButton: $externalButton
      urlDeeplinkSalesman: $urlDeeplinkSalesman
    ) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $eventId: ID!
    $brandId: ID!
    $eventName: String!
    $eventDesc: String!
    $eventDescSalesman: String
    $appUser: String!
    $region: String!
    $city: String!
    $venue: String!
    $formLinkId: Int
    $registrationDeadline: DateTime!
    $externalLink: String
    $image: String!
    $dedicatedPageImage: String
    $eventDate: DateTime!
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $externalButton: String
    $urlDeeplinkSalesman: String
  ) {
    updateEvent(
      eventId: $eventId
      brandId: $brandId
      eventName: $eventName
      eventDesc: $eventDesc
      eventDescSalesman: $eventDescSalesman
      appUser: $appUser
      region: $region
      city: $city
      venue: $venue
      formLinkId: $formLinkId
      registrationDeadline: $registrationDeadline
      externalLink: $externalLink
      image: $image
      dedicatedPageImage: $dedicatedPageImage
      eventDate: $eventDate
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      externalButton: $externalButton
      urlDeeplinkSalesman: $urlDeeplinkSalesman
    ) {
      ...EventFragment
    }
  }
  ${EVENT_FRAGMENT}
`;

export const ARCHIVE_EVENT = gql`
  mutation ArchiveEvent($id: ID!) {
    archiveEvent(eventId: $id) {
      success
    }
  }
`;

export const CREATE_COMPETITION = gql`
  mutation CreateCompetition(
    $brandId: ID!
    $competitionName: String!
    $competitionDesc: String!
    $competitionDescSalesman: String
    $appUser: String!
    $region: String!
    $city: String!
    $competitionCloseDate: DateTime!
    $mediaSocial: [CompetitionSocialMediaCreateInput!]
    $formLinkId: Int
    $externalLink: String
    $image: String!
    $dedicatedPageImage: String
    $eventDate: DateTime!
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $externalButton: String
    $urlDeeplinkSalesman: String
  ) {
    createCompetition(
      brandId: $brandId
      competitionName: $competitionName
      competitionDesc: $competitionDesc
      competitionDescSalesman: $competitionDescSalesman
      appUser: $appUser
      region: $region
      city: $city
      competitionCloseDate: $competitionCloseDate
      socialMedia: $mediaSocial
      formLinkId: $formLinkId
      externalLink: $externalLink
      image: $image
      dedicatedPageImage: $dedicatedPageImage
      eventDate: $eventDate
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      externalButton: $externalButton
      urlDeeplinkSalesman: $urlDeeplinkSalesman
    ) {
      ...CompetitionFragment
    }
  }
  ${COMPETITION_FRAGMENT}
`;

export const UPDATE_COMPETITION = gql`
  mutation UpdateCompetition(
    $competitionId: ID!
    $brandId: ID!
    $competitionName: String!
    $competitionDesc: String!
    $competitionDescSalesman: String
    $appUser: String!
    $region: String!
    $city: String!
    $competitionCloseDate: DateTime!
    $mediaSocial: CompetitionSocialMediaInput
    $formLinkId: Int
    $externalLink: String
    $image: String!
    $dedicatedPageImage: String
    $eventDate: DateTime!
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $winners: WinnersDataInput
    $externalButton: String
    $urlDeeplinkSalesman: String
  ) {
    updateCompetition(
      competitionId: $competitionId
      brandId: $brandId
      competitionName: $competitionName
      competitionDesc: $competitionDesc
      competitionDescSalesman: $competitionDescSalesman
      appUser: $appUser
      region: $region
      city: $city
      competitionCloseDate: $competitionCloseDate
      mediaSocial: $mediaSocial
      formLinkId: $formLinkId
      externalLink: $externalLink
      image: $image
      dedicatedPageImage: $dedicatedPageImage
      eventDate: $eventDate
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      winners: $winners
      externalButton: $externalButton
      urlDeeplinkSalesman: $urlDeeplinkSalesman
    ) {
      ...CompetitionFragment
    }
  }
  ${COMPETITION_FRAGMENT}
`;
export const ARCHIVE_COMPETITION = gql`
  mutation ArchiveCompetition($id: ID!) {
    archiveCompetition(competitionId: $id) {
      success
    }
  }
`;

export const CREATE_NEWS = gql`
  mutation CreateNews(
    $appUser: String!
    $businessUnitContactId: ID
    $brandId: ID
    $rangeId: ID
    $region: String!
    $city: String!
    $startDate: DateTime!
    $title: String!
    $desc: String!
    $descSalesman: String
    $image: String!
    $dedicatedPageImage: String
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $externalLink: String
    $internalLinkId: String
    $typeLink: NewsTypeLink!
    $externalButton: String
    $urlDeeplinkSalesman: String
  ) {
    createNews(
      appUser: $appUser
      businessUnitContactId: $businessUnitContactId
      brandId: $brandId
      rangeId: $rangeId
      region: $region
      city: $city
      startDate: $startDate
      title: $title
      desc: $desc
      descSalesman: $descSalesman
      image: $image
      dedicatedPageImage: $dedicatedPageImage
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      externalLink: $externalLink
      internalLinkId: $internalLinkId
      typeLink: $typeLink
      externalButton: $externalButton
      urlDeeplinkSalesman: $urlDeeplinkSalesman
    ) {
      ...NewsFragment
    }
  }
  ${NEWS_FRAGMENT}
`;

export const UPDATE_NEWS = gql`
  mutation UpdateNews(
    $newsId: ID!
    $appUser: String!
    $businessUnitContactId: ID
    $brandId: ID
    $rangeId: ID
    $region: String!
    $city: String!
    $startDate: DateTime!
    $title: String!
    $desc: String!
    $descSalesman: String
    $image: String!
    $dedicatedPageImage: String
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $externalLink: String
    $internalLinkId: String
    $typeLink: NewsTypeLink!
    $externalButton: String
    $urlDeeplinkSalesman: String
  ) {
    updateNews(
      newsId: $newsId
      appUser: $appUser
      businessUnitContactId: $businessUnitContactId
      brandId: $brandId
      rangeId: $rangeId
      region: $region
      city: $city
      startDate: $startDate
      title: $title
      desc: $desc
      descSalesman: $descSalesman
      image: $image
      dedicatedPageImage: $dedicatedPageImage
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      externalLink: $externalLink
      internalLinkId: $internalLinkId
      typeLink: $typeLink
      externalButton: $externalButton
      urlDeeplinkSalesman: $urlDeeplinkSalesman
    ) {
      ...NewsFragment
    }
  }
  ${NEWS_FRAGMENT}
`;

export const ARCHIVE_NEWS = gql`
  mutation ArchiveNews($id: ID!) {
    archiveNews(newsId: $id) {
      success
    }
  }
`;
