import React, { useMemo, useCallback, memo } from 'react';
import { StyleSheet } from 'react-native';
import { useQuery } from '@apollo/react-hooks';

import { BRANDS } from '../../graphql/queries';
import { Brands, BrandsVariables } from '../../generated/Brands';

import Dropdown, { Props as DropdownProps } from './Dropdown';

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  isOptional?: boolean;
};

const defaultTitle = t(['Principal', 'Principal']);

const BrandContactDropdown = memo((props: Props) => {
  let { title = defaultTitle, isOptional = false, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<Brands, BrandsVariables>(
    BRANDS,
    {
      variables: { where: { NOT: [{ businessUnitContact: null }] } },
      notifyOnNetworkStatusChange: true,
    },
  );
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  let buOptions = useMemo(() => {
    type Result = {
      label: string;
      subLabel: string;
      value: string;
      subValue: string;
    };
    let result: Array<Result> = [];
    if (data) {
      result = data.brands.map<Result>(({ id, name, businessUnitContact }) => {
        return {
          label: name,
          subLabel: businessUnitContact?.name ?? '',
          value: businessUnitContact?.id ?? '',
          subValue: id,
        };
      });
      let copy = result;

      if (isOptional) {
        copy.unshift({
          label: '-',
          subLabel: '-',
          subValue: '-',
          value: '-',
        });
      }

      result = copy;
    }

    return result;
  }, [data, isOptional]);

  return (
    <Dropdown
      data-cy="principal-dropdown"
      error={error}
      errorAction={t([
        'mengambil data Principal',
        'retrieve the Principal data',
      ])}
      onRetryPress={refetchData}
      type="basic"
      title={title}
      options={buOptions}
      labelStyle={styles.label}
      subLabelStyle={styles.label}
      loading={loading}
      {...otherProps}
    />
  );
});

const styles = StyleSheet.create({
  label: { flex: 1 },
});

export default BrandContactDropdown;
