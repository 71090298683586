import React, { useMemo, memo } from 'react';

import { Role } from '../../generated/globalTypes';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Role', 'Select Role']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
};

const RoleDropdown = memo((props: Props) => {
  let { title = defaultTitle, ...otherProps } = props;

  const options = useMemo(
    () => [
      { label: 'Staff', value: Role.STAFF },
      { label: 'Supervisor', value: Role.SUPERVISOR },
      { label: 'Toko Owner', value: Role.TOKO_OWNER },
    ],
    [],
  );

  return (
    <Dropdown
      data-cy="region-dropdown"
      options={options}
      title={title}
      {...otherProps}
    />
  );
});

export default RoleDropdown;
