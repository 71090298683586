import React, { useState, useCallback, useEffect, useMemo } from 'react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';
import { StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import { Table, Text } from '../../core-ui';
import { ErrorMessage, ErrorModal } from '../../components';
import { theme } from '../../constants/theme';
import { to12Hh } from '../../helpers';
import { useTableSort } from '../../hooks';
import { GET_EVENTS } from '../../graphql/queries';
import { ARCHIVE_EVENT } from '../../graphql/mutations';
import { EventFragment } from '../../generated/EventFragment';
import { Events, EventsVariables } from '../../generated/Events';
import { ArchiveStatus, EventOrderByType } from '../../generated/globalTypes';
import {
  ArchiveEvent,
  ArchiveEventVariables,
} from '../../generated/ArchiveEvent';

type Props = {
  searchByUser?: string;
  searchByRegion?: string;
  searchByCity?: string;
  searchByEvent?: string;
  searchByDateFrom?: Date;
  searchByDateUntil?: Date;
  searchArchived?: boolean;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: EventsVariables;
  setEditEvent?: (value: EventFragment) => void;
};

const DboNewsEventTable = (props: Props) => {
  let {
    searchByUser,
    searchByRegion,
    searchByCity,
    searchByEvent,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
    refetchToggle,
    hideEdit,
    queryVariables,
    setEditEvent,
  } = props;
  const [page, setPage] = useState(0);
  const [archiveID, setArchiveID] = useState<string | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    EventOrderByType
  >();

  let [getEvents, { data, loading, error, refetch }] = useLazyQuery<
    Events,
    EventsVariables
  >(GET_EVENTS, {
    variables: {
      skip: page * rowsPerPage,
      first: rowsPerPage,
      archived: ArchiveStatus.NORMAL,
      orderBy: orderByVariable,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            user: searchByUser,
            region: searchByRegion,
            city: searchByCity,
            name: searchByEvent,
            date:
              searchByDateFrom && searchByDateUntil
                ? {
                    startDate: searchByDateFrom,
                    endDate: searchByDateUntil,
                  }
                : undefined,
            archived: searchArchived ? undefined : ArchiveStatus.NORMAL,
            orderBy: orderByVariable,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [
      searchByUser,
      searchByRegion,
      searchByCity,
      searchByEvent,
      searchByDateFrom,
      searchByDateUntil,
      searchArchived,
      queryVariables,
      refetch,
      rowsPerPage,
      page,
      orderByVariable,
    ],
  );

  useEffect(() => {
    setPage(0);
  }, [
    searchByUser,
    searchByRegion,
    searchByCity,
    searchByEvent,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
  ]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  let [
    archiveEvent,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchiveEvent, ArchiveEventVariables>(ARCHIVE_EVENT, {
    onCompleted: refetchCurrentPage,
    onError: openErrorModal,
  });

  const onArchivePress = useCallback(
    (id: string) => {
      setArchiveID(id);
      archiveEvent({ variables: { id } });
    },
    [archiveEvent],
  );

  useEffect(() => {
    // NOTE: this is triggered when any search props has been changed; page needs to be reset
    getEvents();
  }, [getEvents]);

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onViewPress = useCallback(
    (event: EventFragment) => {
      if (setEditEvent) {
        setEditEvent(event);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditEvent],
  );

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : ({
            action: {
              headerTitle: t(['aksi', 'action']),
              headerTitleColor: 'link',
              displayText: t(['lihat', 'view']),
              onPress: onViewPress,
            },
          } as const),
    [hideEdit, onViewPress],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  return (
    <>
      <Table
        data-cy="event-table"
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        page={page}
        error={!!error}
        data={data?.eventAdvanceSearch.row ?? []}
        loading={loading}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onRowsChange}
        onChangePage={onChangePage}
        dataCount={(data && data.eventAdvanceSearch.total) || 0}
        structure={{
          [EventOrderByType.appUser]: {
            headerTitle: t(['pengguna', 'users']),
            orderable: true,
          },
          [EventOrderByType.city]: {
            headerTitle: t(['kota', 'city']),
            orderable: true,
          },
          [EventOrderByType.timeEvent]: {
            headerTitle: t(['waktu', 'time']),
            render: ({ eventDate }) => (
              <Text>{to12Hh(new Date(eventDate))}</Text>
            ),
            orderable: true,
          },
          [EventOrderByType.eventDate]: {
            headerTitle: t(['tanggal', 'date']),
            valuePreProcessor: (value) => new Date(value as string), // NOTE: value is Date ISO String
            orderable: true,
          },
          [EventOrderByType.brandName]: {
            headerTitle: t(['merek', 'brand']),
            dataPath: 'brand.name',
            orderable: true,
          },
          [EventOrderByType.name]: {
            headerTitle: t(['nama event', 'event name']),
            orderable: true,
          },
          [EventOrderByType.venue]: {
            headerTitle: t(['lokasi', 'location']),
            orderable: true,
          },
          registration: {
            headerTitle: t(['registrasi', 'registration']),
            render: ({ externalLink, formLink }) => {
              if (formLink) {
                return <Text>{`Form ${formLink.id}`}</Text>;
              } else if (externalLink) {
                return <Text>{externalLink}</Text>;
              }
              return <Text>{t(['Tidak ada', 'None'])}</Text>;
            },
          },
          [EventOrderByType.attendees]: {
            headerTitle: t(['Peserta', 'Attendees']),
            render: ({ formLink }) => (
              <Text>{formLink?.totalAttendees ?? '-'}</Text>
            ),
            orderable: true,
          },
          [EventOrderByType.businessUnitContactName]: {
            dataPath: 'brand.businessUnitContact.name',
            headerTitle: t(['Principal', 'Principal']),
            orderable: true,
          },
          ...action,
          archive: {
            headerTitle: t(['arsip', 'archive']),
            headerTitleColor: 'link',
            render: ({ id, status }) =>
              id === archiveID && archiveLoading ? (
                <ActivityIndicator size="small" />
              ) : (
                <TouchableOpacity
                  data-cy="event-archive-toggle"
                  onPress={() => onArchivePress(id)}
                  style={styles.actionSpacing}
                >
                  {status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon data-cy="event-normal" />
                  ) : (
                    <FolderFilledIcon data-cy="event-archived" />
                  )}
                </TouchableOpacity>
              ),
          },
        }}
      />

      {!!error && (
        <ErrorMessage
          error={error}
          action={t(['mengambil data event', 'retrieve the event data'])}
          onPress={refetchCurrentPage}
        />
      )}
      <ErrorModal
        open={errorOpen}
        error={archiveError}
        action={t([
          'mengubah status arsip data event',
          'change archive status of the event data',
        ])}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  actionSpacing: { paddingLeft: theme.spacing.xsmall },
});

export default DboNewsEventTable;
