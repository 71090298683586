import React, { useState, useCallback, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { logEvent } from 'expo-firebase-analytics';

import { spacing } from '../../constants/theme';
import { HeaderNavigationBar, Header, Option } from '../../components';
import { Text } from '../../core-ui';
import { PromotionFragment } from '../../generated/PromotionFragment';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { pageTitle } from '../../constants/pageTitle';

import PromotionForm from './PromotionForm';
import PromotionSearch from './PromotionSearch';

export default function SeeAllPromotionsScene() {
  let [selectedUser, setSelectedUser] = useState<string | null>(null);
  let [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  let [regionName, setRegionName] = useState<string | null>(null);
  let [, setSelectedCity] = useState<string | null>(null);
  let [descriptionSalesmanApp, setDescriptionSalesmanApp] = useState('');
  let [salesmanAppDeepLink, setSalesmanAppDeepLink] = useState('');
  let [cityName, setCityName] = useState<string | null>(null);
  let [heroImage, setHeroImage] = useState<FileWithPreview | string | null>(
    null,
  );
  let [optionalImages, setOptionalImages] = useState<
    Array<FileWithPreview | string | null>
  >([null, null]);
  let [promotionId, setPromotionId] = useState<string | null>(null);
  let [promotionName, setPromotionName] = useState('');
  let [promotionCode, setPromotionCode] = useState<string | null>(null);
  let [description, setDescription] = useState('');
  //NOTE: selectedBrand is used for mutations, brandId is used to fetch range data from manager app
  let [selectedBrand, setSelectedBrand] = useState<string | null>(null);
  let [brandId, setBrandId] = useState<string | null>(null);
  let [selectedRange, setSelectedRange] = useState<string | null>(null);
  let [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  let [quantity, setQuantity] = useState('');
  let [unit, setUnit] = useState<string | null>(null);
  let [unitIndex, setUnitIndex] = useState<string | null>(null);
  let [termAndCondition, setTermAndCondition] = useState('');
  let [brandContact, setBrandContact] = useState<string | null>(null);
  let [liveDate, setLiveDate] = useState(new Date());
  let [endDate, setEndDate] = useState(new Date());
  let [showPromotionInMobile, setShowPromotionInMobile] = useState(true);
  let [sendPushNotification, setSendPushNotification] = useState(true);
  let [editPromotion, setEditPromotion] = useState<PromotionFragment | null>(
    null,
  );
  let [refetchToggle, setRefetchToggle] = useState(false);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.SeeAllPromotionsScene,
      });
    };
    eventLog();
  }, []);

  const createSetSelected = useCallback(
    (
      setter: (value: string | null) => void,
      labelSetter?: (value: string | null) => void,
      ...nullSetter: Array<(value: null) => void>
    ) => (selected: Option | null) => {
      setter(selected?.value ?? null);
      labelSetter?.(selected?.label ?? null);
      for (let setToNull of nullSetter) {
        setToNull(null);
      }
    },
    [],
  );
  const getSelectedRegion = useCallback((values: Array<string>) => {
    setSelectedRegion(values[0]);
  }, []);

  const getSelectedUnit = useCallback((values: Array<string>) => {
    setUnitIndex(values[0]);
  }, []);

  // NOTE: this is necessary to trigger the refetch in Tables after form submission.
  const onSubmitCompleted = useCallback(() => {
    setRefetchToggle(!refetchToggle);
    setEditPromotion(null);
    setPromotionId(null);
    setSelectedUser(null);
    setRegionName(null);
    setCityName(null);
    setHeroImage(null);
    setOptionalImages([]);
    setPromotionName('');
    setPromotionCode(null);
    setDescription('');
    setDescriptionSalesmanApp('');
    setSalesmanAppDeepLink('');
    setSelectedBrand(null);
    setBrandId(null);
    setSelectedRange(null);
    setSelectedProduct(null);
    setQuantity('');
    setUnit(null);
    setTermAndCondition('');
    setBrandContact(null);
    setLiveDate(new Date());
    setEndDate(new Date());
    setShowPromotionInMobile(false);
    setSendPushNotification(false);
  }, [refetchToggle]);

  useEffect(() => {
    if (editPromotion) {
      setPromotionId(editPromotion.id);
      setSelectedUser(editPromotion.appUser);
      setRegionName(editPromotion.region);
      setCityName(editPromotion.city);
      setHeroImage(editPromotion.heroImage);
      setOptionalImages(editPromotion.optionalImages);
      setPromotionName(editPromotion.name);
      setPromotionCode(editPromotion.promotionCode || null);
      setDescription(editPromotion.desc || '');
      setDescriptionSalesmanApp(editPromotion.descSalesman || '');
      setSalesmanAppDeepLink(editPromotion.urlDeeplinkSalesman || '');
      setSelectedBrand(editPromotion.brand.id);
      setBrandId(editPromotion.brand.brandId);
      setSelectedRange(editPromotion.rangeId);
      setSelectedProduct(editPromotion.product?.id.toString() || null);
      setQuantity(editPromotion.productQuantity?.toString() ?? '');
      setUnit(editPromotion.product?.unit || null);
      setTermAndCondition(editPromotion.termAndCondition);
      setBrandContact(editPromotion.businessUnitContactId);
      setLiveDate(editPromotion.liveDate);
      setEndDate(editPromotion.endLiveDate);
      setShowPromotionInMobile(editPromotion.showInMobile);
      setSendPushNotification(editPromotion.sendPushNotifAfterCreate);
    }
  }, [editPromotion]);

  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <Header title={t(['Lihat Semua Promosi', 'See All Promotion Page'])} />
      <Text style={styles.subHeader}>
        {t(['Semua promosi saat ini', 'All current promotions'])}
      </Text>
      <PromotionForm
        descriptionSalesmanApp={descriptionSalesmanApp}
        setDescriptionSalesmanApp={setDescriptionSalesmanApp}
        salesmanAppDeepLink={salesmanAppDeepLink}
        setSalesmanAppDeepLink={setSalesmanAppDeepLink}
        selectedUser={selectedUser}
        selectedRegion={selectedRegion}
        regionName={regionName}
        cityName={cityName}
        promotionId={promotionId ?? undefined}
        heroImage={heroImage}
        optionalImages={optionalImages}
        promotionName={promotionName}
        promotionCode={promotionCode}
        description={description}
        selectedBrand={selectedBrand}
        brandId={brandId}
        selectedRange={selectedRange}
        selectedProduct={selectedProduct}
        quantity={quantity}
        unit={unit}
        unitIndex={unitIndex}
        termAndCondition={termAndCondition}
        brandContact={brandContact}
        liveDate={liveDate}
        endDate={endDate}
        getSelectedRegion={getSelectedRegion}
        setSelectedUser={createSetSelected(setSelectedUser)}
        setRegionName={createSetSelected(setSelectedRegion, setRegionName)}
        setCityName={createSetSelected(setSelectedCity, setCityName)}
        setHeroImage={setHeroImage}
        setOptionalImages={setOptionalImages}
        setPromotionName={setPromotionName}
        setPromotionCode={setPromotionCode}
        setDescription={setDescription}
        setSelectedBrand={createSetSelected(
          setSelectedBrand,
          undefined,
          setSelectedRange,
          setSelectedProduct,
          setUnit,
          setUnitIndex,
        )}
        setBrandId={setBrandId}
        setSelectedRange={createSetSelected(
          setSelectedRange,
          undefined,
          setSelectedProduct,
          setUnit,
          setUnitIndex,
        )}
        setSelectedProduct={createSetSelected(
          setSelectedProduct,
          undefined,
          setUnit,
          setUnitIndex,
        )}
        setQuantity={setQuantity}
        setUnit={createSetSelected(setUnitIndex, setUnit)}
        getSelectedUnit={getSelectedUnit}
        setTermAndCondition={setTermAndCondition}
        setBrandContact={createSetSelected(setBrandContact)}
        setLiveDate={setLiveDate}
        setEndDate={setEndDate}
        showPromotionInMobile={showPromotionInMobile}
        sendPushNotification={sendPushNotification}
        setShowPromotionInMobile={setShowPromotionInMobile}
        setSendPushNotification={setSendPushNotification}
        onSubmitCompleted={onSubmitCompleted}
      />
      <PromotionSearch
        refetchToggle={refetchToggle}
        setEditPromotion={setEditPromotion}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  subHeader: {
    marginTop: spacing.small,
    marginBottom: spacing.xlarge,
  },
});
