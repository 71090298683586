import React, { useState, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  Header,
  SearchHeader,
  Option,
  SearchByDateRange,
  CityDropdown,
  SearchByText,
  RegionDropdown,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import BrandDropdown from '../../components/dropdowns/BrandDropdown';
import { FormType } from '../../components/dropdowns/FormLinkDropdown';
import { CompetitionFormFragment } from '../../generated/CompetitionFormFragment';
import { EventFormFragment } from '../../generated/EventFormFragment';
import { CompetitionOnlineFormsVariables } from '../../generated/CompetitionOnlineForms';
import { EventOnlineFormsVariables } from '../../generated/EventOnlineForms';

import OnlineFormTable from './OnlineFormTable';

type Props<T extends FormType> = {
  type: T | null;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  hideArchiveCheck?: boolean;
  queryVariables?: T extends 'competition'
    ? CompetitionOnlineFormsVariables
    : T extends 'event'
    ? EventOnlineFormsVariables
    : undefined; // TODO: Information Online Form Variables
  onSubmitCompleted?: () => void;
  setEditCompetition?: (value: CompetitionFormFragment) => void;
  setEditEvent?: (value: EventFormFragment) => void;
};

const OnlineFormSearch = <T extends FormType>(props: Props<T>) => {
  let {
    type,
    refetchToggle,
    hideEdit,
    hideArchiveCheck,
    queryVariables,
    onSubmitCompleted,
    setEditCompetition,
    setEditEvent,
  } = props;
  // NOTE: these are used for filter state
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<Option | null>(null);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);
  const [inputName, setInputName] = useState<string | null>(null);

  // NOTE: these are used to filter the queries
  const [searchArchived, setSearchArchived] = useState(false);
  const [searchByName, setSearchByName] = useState<string | null>(null);
  const [searchByRegion, setSearchByRegion] = useState<string | null>(null);
  const [searchByCity, setSearchByCity] = useState<string | null>(null);
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  const [searchById, setSearchById] = useState<number | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);

  const clearSearch = useCallback(() => {
    setIncludeArchived(false);
    setSelectedBrand(null);
    setSelectedRegion(null);
    setSelectedCity(null);
    setInputName(null);
    setDateFrom(null);
    setDateUntil(null);
    setSelectedId(null);
  }, []);

  const onSelectRegion = useCallback((selected: Option) => {
    setSelectedRegion(selected);
    setSelectedCity(null);
  }, []);

  const submitSearch = useCallback(() => {
    setSearchArchived(includeArchived);
    setSearchByRegion(selectedRegion ? selectedRegion.label : null);
    setSearchByCity(selectedCity ? selectedCity.label : null);
    if (dateFrom) {
      setSearchByDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
    } else {
      setSearchByDateFrom(null);
    }
    if (dateUntil) {
      setSearchByDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchByDateUntil(null);
    }
    setSearchByName(inputName);
    setSearchByBrand(selectedBrand?.value ?? null);
    setSearchById(Number(selectedId) ?? null);
  }, [
    includeArchived,
    selectedRegion,
    selectedCity,
    dateFrom,
    dateUntil,
    inputName,
    selectedBrand,
    selectedId,
  ]);

  const [title, label] = useMemo(() => {
    switch (type) {
      case 'competition': {
        return [
          t(['Kompetisi', 'Competition']),
          t(['Cari Kompetisi', 'Search by Competition']),
        ];
      }
      case 'event': {
        return [t(['Event', 'Event']), t(['Cari Event', 'Search by Event'])];
      }
      case 'information': {
        return [
          t(['Permintaan Informasi', 'Information Request']),
          t(['Cari Permintaan Informasi', 'Search by Information Request']),
        ];
      }
      default: {
        return ['', ''];
      }
    }
  }, [type]);

  if (!type || type === 'information') {
    return null;
  }

  return (
    <>
      <SearchHeader
        data-cy="search-olform"
        archivable={!hideArchiveCheck}
        title={title}
        includeArchived={includeArchived}
        onArchivePress={setIncludeArchived}
        onSearchPress={submitSearch}
        onClearPress={clearSearch}
        disabled={searchDisabled}
      >
        <View style={styles.separator}>
          <SearchByText
            noDecimalPoint
            data-cy="search-olform-number"
            label={t(['Cari Nomor Formulir', 'Search By Form#'])}
            value={selectedId || ''}
            type="number"
            setValue={setSelectedId}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-cy="search-olform-name"
            label={label}
            value={inputName || ''}
            setValue={setInputName}
          />
        </View>
        <View style={styles.separator}>
          <BrandDropdown
            data-cy="search-olform-brand"
            type="side"
            title={t(['Cari Merek', 'Search by Brands'])}
            selectedOption={selectedBrand?.value}
            onSelect={setSelectedBrand}
          />
        </View>
        <View style={styles.separator}>
          <SearchByDateRange
            data-cy="search-olform-date"
            from={dateFrom}
            until={dateUntil}
            setFrom={setDateFrom}
            setUntil={setDateUntil}
            setDisabled={setSearchDisabled}
          />
        </View>
        <View style={styles.separator}>
          <RegionDropdown
            data-cy="search-olform-region"
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={selectedRegion?.value}
            onSelect={onSelectRegion}
          />
        </View>
        <View style={styles.separator}>
          <CityDropdown
            data-cy="search-olform-city"
            provincesID={selectedRegion ? [selectedRegion.value] : []}
            type="side"
            title={t(['Cari Kota', 'Search by City'])}
            selectedOption={selectedCity?.value}
            onSelect={setSelectedCity}
          />
        </View>
      </SearchHeader>
      <Header
        withSeparator
        title="Results"
        titleColor="link"
        style={styles.topPadding}
      />
      <OnlineFormTable
        data-cy="olform-search-table"
        type={type}
        refetchToggle={refetchToggle}
        hideEdit={hideEdit}
        queryVariables={queryVariables}
        onSubmitCompleted={onSubmitCompleted}
        setEditCompetition={setEditCompetition}
        setEditEvent={setEditEvent}
        searchByName={searchByName || undefined}
        searchByRegion={searchByRegion || undefined}
        searchByCity={searchByCity || undefined}
        searchByDateFrom={searchByDateFrom || undefined}
        searchByDateUntil={searchByDateUntil || undefined}
        searchArchived={searchArchived || undefined}
        searchByBrand={searchByBrand || undefined}
        searchByID={searchById || undefined}
      />
    </>
  );
};

const styles = StyleSheet.create({
  topPadding: { paddingTop: spacing.xlarge },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xxsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
});

export default OnlineFormSearch;
