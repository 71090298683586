import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

import { invalid } from '../../../assets';

export default function SuccessVerifyEmail() {
  return (
    <View style={styles.container}>
      <Image source={invalid} style={styles.image} resizeMethod={'auto'} />
      <Text style={styles.title}>
        {t(['Link Tidak Valid', 'Invalid Link'])}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 'bold',
  },
  image: {
    width: 120,
    height: 120,
    marginBottom: 24,
  },
});
