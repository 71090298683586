export function checkPhoneNumber(text: string) {
  const phoneRegex = /^\d+$/g;
  return phoneRegex.test(text);
}

export function checkPhoneNumberFormat(phone: string) {
  if (
    (!checkPhoneNumber(phone) || phone.length < 8 || phone.length > 14) &&
    phone !== ''
  ) {
    return true;
  } else {
    return false;
  }
}
