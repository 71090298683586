import React, { useCallback, useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router';

import { Table } from '../../core-ui';
import {
  LoyaltyPointHistories,
  LoyaltyPointHistoriesVariables,
} from '../../generated/LoyaltyPointHistories';
import {
  GET_LOYALTY_POINT_HISTORIES,
  GET_TRANSACTION_HISTORIES,
} from '../../graphql/queries';
import {
  TransactionHistories,
  TransactionHistoriesVariables,
} from '../../generated/TransactionHistories';
import { ErrorMessage } from '../../components';
import { Button } from '../../features/verification/core-ui';
import { LumpSumType } from '../../generated/globalTypes';

type Props = {
  storeId: string;
  storeName?: string;
  selectedHistory: number;
  searchByDateFrom?: Date;
  searchByDateUntil?: Date;
  searchByBrand?: string;
  refetchToggle?: boolean;
};

const HistoryTable = (props: Props) => {
  let {
    storeId,
    storeName,
    selectedHistory,
    searchByDateFrom,
    searchByDateUntil,
    searchByBrand,
    refetchToggle,
  } = props;
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const history = useHistory();

  let {
    data: pointData,
    loading: pointLoading,
    error: pointError,
    refetch: pointRefetch,
  } = useQuery<LoyaltyPointHistories, LoyaltyPointHistoriesVariables>(
    GET_LOYALTY_POINT_HISTORIES,
    {
      variables: {
        storeId,
        skip: page * rowsPerPage,
        first: rowsPerPage,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  let {
    data: transactionData,
    loading: transactionLoading,
    error: transactionError,
    refetch: transactionRefetch,
  } = useQuery<TransactionHistories, TransactionHistoriesVariables>(
    GET_TRANSACTION_HISTORIES,
    {
      variables: {
        storeId,
        skip: page * rowsPerPage,
        first: rowsPerPage,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };
  const refetchData = useCallback(() => {
    const commonVariables = {
      storeId,
      brandName: searchByBrand,
      first: rowsPerPage,
      skip: page * rowsPerPage,
      date:
        searchByDateFrom && searchByDateUntil
          ? {
              startDate: searchByDateFrom,
              endDate: searchByDateUntil,
            }
          : undefined,
    };
    const asyncRefetch = async () => {
      try {
        if (selectedHistory === 0) {
          await pointRefetch({
            ...commonVariables,
          });
        } else {
          await transactionRefetch({
            ...commonVariables,
          });
        }
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [
    storeId,
    searchByBrand,
    rowsPerPage,
    page,
    selectedHistory,
    pointRefetch,
    searchByDateFrom,
    searchByDateUntil,
    transactionRefetch,
  ]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(5);
  }, [selectedHistory]);

  useEffect(() => {
    refetchData();
  }, [refetchToggle, refetchData]);

  return selectedHistory === 0 ? (
    <>
      <Table
        data-cy="point-table"
        data={pointData?.loyaltyPointHistoryAdvance.row ?? []}
        loading={pointLoading}
        error={!!pointError}
        dataCount={pointData?.loyaltyPointHistoryAdvance.total ?? 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        structure={{
          date: {
            headerTitle: t(['Tanggal', 'Date']),
            valuePreProcessor: (value) => new Date(value as string),
          },
          activity: {
            headerTitle: t(['Aktivitas', 'Activity']),
            render: ({
              lumpSumType,
              competition,
              promotion,
              event,
              loyaltyActivity,
              activityName,
            }) => {
              return lumpSumType === LumpSumType.COMPETITION
                ? competition?.name
                : lumpSumType === LumpSumType.EVENT
                ? event?.name
                : lumpSumType === LumpSumType.PROMOTION
                ? promotion?.name
                : lumpSumType === LumpSumType.REWARD
                ? activityName
                : loyaltyActivity?.name ?? activityName;
            },
          },
          type: {
            headerTitle: t(['Tipe', 'Type']),
          },
          brand: {
            render: ({ brand }) => {
              return brand?.name ?? '-';
            },
          },
          point: {
            headerTitle: t(['Poin', 'Points']),
          },
          note: {
            headerTitle: t(['Catatan', 'Notes']),
            render: ({ note }) => {
              return note ? note : '-';
            },
          },
        }}
      />
      {!!pointError && (
        <ErrorMessage
          error={pointError}
          action={t([
            'mengambil data riwayat poin',
            'retrieve the point history data',
          ])}
          onPress={refetchData}
        />
      )}
    </>
  ) : (
    <>
      <Table
        data-cy="transaction-table"
        data={transactionData?.HistoryTransactionCMS.row ?? []}
        loading={transactionLoading}
        error={!!transactionError}
        dataCount={transactionData?.HistoryTransactionCMS.total ?? 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onRowsChange}
        structure={{
          orderDate: {
            headerTitle: t(['Tanggal', 'Date']),
            valuePreProcessor: (value) => new Date(value as string),
          },
          orderNumber: {
            headerTitle: t(['Nomor Pesanan', 'Order Number']),
          },
          brandName: {
            headerTitle: t(['Brand', 'Brand']),
          },
          totalPrice: {
            headerTitle: t(['Jumlah Pembelian', 'Purchase Amount']),
            valueProcessor: (value) => 'Rp. ' + value,
          },
          action: {
            headerTitle: t(['Aksi', 'Action']),
            render: ({ orderId, orderType, orderNumber }) => {
              return (
                <Button
                  preset="transparent"
                  style={styles.action}
                  titleTextProps={{ color: 'blue' }}
                  title={t(['lihat rincian', 'view details'])}
                  onPress={() =>
                    history.push(`/loyalty-point/${storeId}/order-detail`, {
                      storeName,
                      orderNumber,
                      orderId,
                      orderType,
                      storeId,
                    })
                  }
                />
              );
            },
          },
        }}
      />
      {!!transactionError && (
        <ErrorMessage
          error={transactionError}
          action={t([
            'mengambil data riwayat transaksi',
            'retrieve the transaction history data',
          ])}
          onPress={refetchData}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  action: {
    justifyContent: 'flex-start',
    paddingLeft: 0,
  },
});

export default HistoryTable;
