import gql from 'graphql-tag';

export const EVENT_ONLINE_FORM_FRAGMENT = gql`
  fragment EventFormFragment on FormEvent {
    id
    name
    venue
    address
    latitude
    longitude
    link
    timeEvent
    maxParticipant
    status
    eventStatus
    event {
      name
      brand {
        id
        name
        businessUnitContact {
          id
          name
        }
      }
      eventDate
      endLiveDate
      liveDate
      city
    }
    submitFormEvent {
      id
      name
      storeName
      phoneNumber
      attendees
    }
    totalAttendees
  }
`;

export const COMPETITION_ONLINE_FORM_FRAGMENT = gql`
  fragment CompetitionFormFragment on FormCompetition {
    id
    name
    image
    link
    prize
    prizeValue
    terms
    status
    competitionStatus
    competition {
      name
      eventDate
      liveDate
      endLiveDate
      brand {
        id
        name
        businessUnitContact {
          id
          name
        }
      }
      city
      formLink {
        link
      }
    }
    submitFormCompetition {
      id
      name
      phoneNumber
      storeName
    }
  }
`;
