import gql from 'graphql-tag';

export const UPLOAD_TOPUP_REWARD = gql`
  mutation UploadTopupReward($file: Upload!) {
    uploadTopupReward(file: $file) {
      id
      fileName
      link
      invalidLink
      uploadDate
      status
      detail
      adminId
      countValid
      countInvalid
    }
  }
`;

export const CANCEL_MISSION_TOPUP_REWARD = gql`
  mutation CancelMissionTopupReward($missionTopupRewardId: ID!) {
    cancelMissionTopupReward(missionTopupRewardId: $missionTopupRewardId) {
      success
    }
  }
`;

export const SEND_MISSION_POINT_TO_DB = gql`
  mutation SendMissionPointToDB($missionTopupRewardId: ID!) {
    sendMissionPointToDB(missionTopupRewardId: $missionTopupRewardId) {
      success
    }
  }
`;
