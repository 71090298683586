import { AsyncStorage } from 'react-native';

import { tokenKey, expDateKey } from '../constants/storage';

export const getToken = () => {
  return AsyncStorage.getItem(tokenKey);
};

export const setToken = (token: string) => {
  return AsyncStorage.setItem(tokenKey, token);
};

export const removeToken = () => {
  return AsyncStorage.removeItem(tokenKey);
};

export const getExpDate = () => {
  return AsyncStorage.getItem(expDateKey);
};

export const setExpDate = (expDate: string) => {
  return AsyncStorage.setItem(expDateKey, expDate);
};

export const removeExpDate = () => {
  return AsyncStorage.removeItem(expDateKey);
};
