export type OwnerLogChanges = {
  birthDate: boolean;
  email: boolean;
  gender: boolean;
  googleSheetFormNumber: boolean;
  name: boolean;
  phoneNumber: boolean;
  ownerVerified: boolean;
};
export type OwnerLog = {
  phoneNumber: string;
  googleSheetFormNumber: string;
  ownerVerified: boolean;
  status: string;
  profile: {
    update: {
      birthDate: string;
      email: string;
      gender: string;
      name: string;
      reUpload: boolean;
      slug: string;
    };
  };
};

export type EmployeeStoreLogChanges = {
  userId: string;
  name: boolean;
  role: boolean;
  privileges: boolean;
  status: boolean;
};
export type EmployeeStoreLog = {
  userId: string;
  name: string;
  privileges: string;
  role: string;
  status: string;
};

export type StoreLogChangesRespond = {
  storeName: boolean;
  phoneNumber: boolean;
  storeCategoryId: boolean;
  storeEmail: boolean;
  address: boolean;
  districtName: boolean;
  cityName: boolean;
  provinceName: boolean;
  urbanVillageName: boolean;
  postalCode: boolean;
  rt: boolean;
  rw: boolean;
  employees: Array<EmployeeStoreLogChanges>;
};
export type StoreLogChanges = {
  storeName: boolean;
  phoneNumber: boolean;
  storeCategoryId: boolean;
  storeEmail: boolean;
  address: boolean;
  urbanVillageName: boolean;
  districtName: boolean;
  cityName: boolean;
  provinceName: boolean;
  rt: boolean;
  rw: boolean;
  postalCode: boolean;
  employees: Array<EmployeeStoreLogChanges>;
};

export type StoreLogRespond = {
  updateDataStore: {
    storeName: string;
    storePhoneNumber: string;
    storeCategoryId: string;
    storeEmail: string;
    address: string;
    postalCode: string;
    provinceName: string;
    cityName: string;
    districtName: string;
    urbanVillageName: string;
    rt: string;
    rw: string;
  };
  employees: Array<EmployeeStoreLog>;
};
export type StoreLog = {
  storeName: string;
  storePhoneNumber: string;
  storeCategoryId: string;
  storeEmail: string;
  address: string;
  urbanVillageName: string;
  districtName: string;
  cityName: string;
  provinceName: string;
  rt: string;
  rw: string;
  postalCode: string;
  employees: Array<EmployeeStoreLog>;
};

export type UnverifiedCategory = {
  type: string;
  detail: string;
  problem: string;
  customProblem?: string;
  action: string;
};

export enum StoreStatusEnum {
  UNVERIFIED,
  REVALIDATE,
  VERIFIED,
  VERIFIED_MERGE,
}
