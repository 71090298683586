const PRIMARY_BLACK = '#1a1a1a';
const SECONDARY_BLACK = '#474747';
const DARK_GREY = '#a3a3a3';
const GREY = '#d1d1d1';
const LIGHT_GREY = '#e8e8e8';
const LIGHTER_GREY = '#fcfcfc';
const DISABLED_CONTENT = '#f2f2f2';
const WHITE = '#ffffff';
const PRIMARY_BLUE = '#57abd2';
const SECONDARY_BLUE = '#f2f9fc';
const GREEN = '#41b941';
const RED = '#e53c3c';
const LIGHT_RED = '#f05d5d';
const ORANGE = '#fea25f';
const LIGHT_ORANGE = '#fef5ee';
const YELLOW = '#fece5f';
const TRANSPARENT = 'transparent';
const SHADOW_BLACK = '#000';
const ACCEPT_GREEN = '#41b941';
const DECLINE_RED = '#e53c3c';
const SUPER_LIGHT_GREY = '#FAFAFA';
const RED_ALT = '#FF5760';

const FONT_SIZES = {
  xs: 12,
  s: 14,
  m: 16,
  l: 18,
  xl: 24,
} as const;

const LINE_HEIGHTS = {
  xs: 15,
  s: 17,
  m: 20,
  l: 21,
  xl: 29,
} as const;

const theme = {
  colors: {
    background: {
      grey: LIGHTER_GREY,
      shadow: SHADOW_BLACK,
      orange: ORANGE,
      white: WHITE,
      blue: PRIMARY_BLUE,
      lightGrey: SUPER_LIGHT_GREY,
    },
    button: {
      color: {
        primary: 'white',
        secondary: 'lightRed',
      },
      background: {
        primary: PRIMARY_BLUE,
        secondary: WHITE,
        orange: ORANGE,
        disabled: GREY,
        transparent: TRANSPARENT,
      },
      border: {
        primary: LIGHT_GREY,
      },
    },
    segmentedControl: {
      background: LIGHTER_GREY,
    },
    icon: {
      blue: PRIMARY_BLUE,
      green: GREEN,
      grey: GREY,
      acceptGreen: ACCEPT_GREEN,
      declineRed: DECLINE_RED,
    },
    table: {
      row: {
        background: WHITE,
        border: LIGHT_GREY,
        disabled: DISABLED_CONTENT,
        toko: PRIMARY_BLUE,
        owner: ORANGE,
      },
      pagination: {
        background: WHITE,
        border: {
          primary: LIGHT_GREY,
          selected: PRIMARY_BLUE,
        },
      },
    },
    popover: {
      primary: {
        background: WHITE,
        shadow: SHADOW_BLACK,
      },
    },
    text: {
      black: PRIMARY_BLACK,
      secondaryBlack: SECONDARY_BLACK,
      blue: PRIMARY_BLUE,
      green: GREEN,
      grey: DARK_GREY,
      orange: ORANGE,
      placeholder: GREY,
      red: RED,
      white: WHITE,
      lightRed: LIGHT_RED,
      redAlt: RED_ALT,
    },
    textInput: {
      label: PRIMARY_BLACK,
      value: SECONDARY_BLACK,
      background: {
        primary: WHITE,
        disabled: DISABLED_CONTENT,
      },
      border: {
        primary: LIGHT_GREY,
        focus: PRIMARY_BLUE,
        error: RED,
        mismatch: YELLOW, // NOTE: may not be relevant now that we don't have mismatch feature
      },
    },
    datePicker: {
      primary: PRIMARY_BLUE,
      border: LIGHT_GREY,
      text: SECONDARY_BLACK,
    },
    dropdown: {
      background: WHITE,
      border: LIGHT_GREY,
      hover: LIGHTER_GREY,
      focus: DISABLED_CONTENT,
      selected: SECONDARY_BLUE,
    },
    filter: {
      background: WHITE,
      border: LIGHT_GREY,
      icon: PRIMARY_BLUE,
      indicator: RED,
    },
    border: {
      primary: LIGHT_GREY,
    },
    leftPane: {
      badge: PRIMARY_BLUE,
      icon: GREY,
      toko: {
        background: SECONDARY_BLUE,
        leftBorder: PRIMARY_BLUE,
      },
      owner: {
        background: LIGHT_ORANGE,
        leftBorder: ORANGE,
      },
    },
  } as const,
  fonts: {
    sizes: FONT_SIZES,
    lineHeights: LINE_HEIGHTS,
  } as const,
  spacing: {
    xxs: 8,
    xs: 10,
    s: 12,
    m: 16,
    l: 24,
    xl: 40,
    xxl: 60,
  } as const,
};

const { colors, fonts, spacing } = theme;
export { colors, fonts, spacing, theme };
