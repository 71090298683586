import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_DISTRICTS } from '../../features/verification/graphql/queries';
import { Districts, DistrictsVariables } from '../../generated/Districts';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Kecamatan', 'Select District']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  cityID?: string;
};

const DistrictDropdown = memo((props: Props) => {
  let { title = defaultTitle, cityID, ...otherProps } = props;

  const [getDistricts, { data, loading, error }] = useLazyQuery<
    Districts,
    DistrictsVariables
  >(GET_DISTRICTS);
  const fetchDistricts = useCallback(() => {
    if (cityID) {
      getDistricts({ variables: { cityID } });
    }
  }, [cityID, getDistricts]);

  useEffect(fetchDistricts, [cityID, fetchDistricts]);

  const options = useMemo(
    () =>
      cityID
        ? data?.districtByCityId.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          })) ?? []
        : [],
    [cityID, data],
  );

  return (
    <Dropdown
      data-cy="district-dropdown"
      error={error}
      errorAction={t([
        'mengambil data kecamatan',
        'retrieve the district data',
      ])}
      onRetryPress={fetchDistricts}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default DistrictDropdown;
