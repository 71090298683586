import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';

import { HeaderNavigationBar } from '../../components';
import { spacing } from '../../constants/theme';
import { routePaths } from '../../constants/routes';

import HeaderGamification, {
  ActiveContentGamification,
} from './Component/HeaderGamification';
import {
  MissionEditor,
  TopUpReward,
  ActivityLog,
  ActivityHistory,
} from './Component';

export default function GamificationScene() {
  const history = useHistory();
  const { state } = useLocation<{ activeContent: ActiveContentGamification }>();

  const [activeContent, setActiveContent] = useState<ActiveContentGamification>(
    state?.activeContent ?? ActiveContentGamification.EDITOR,
  );
  const content = () => {
    if (activeContent === ActiveContentGamification.EDITOR) {
      return <MissionEditor />;
    } else if (activeContent === ActiveContentGamification.TOP_UP_REWARD) {
      return <TopUpReward />;
    } else if (activeContent === ActiveContentGamification.LOG) {
      return <ActivityLog />;
    } else if (activeContent === ActiveContentGamification.HISTORY) {
      return <ActivityHistory />;
    }
    return null;
  };
  return (
    <View style={styles.root}>
      <HeaderNavigationBar />
      <HeaderGamification
        title="Gamification"
        initActiveTab={state?.activeContent}
        onChangeTab={(value: ActiveContentGamification) => {
          if (state) {
            history.replace(routePaths.gamification);
          }
          setActiveContent(value);
        }}
        containerStyle={styles.containerTab}
      />
      {content()}
    </View>
  );
}

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  containerTab: {
    marginTop: spacing.xsmall,
  },
});
