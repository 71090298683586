import React, { useMemo, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_DISTRICTS } from '../../graphql/queries';
import { Districts, DistrictsVariables } from '../../../../generated/Districts';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultPlaceholder = t(['- Pilih Kecamatan -', '- Select District -']);

type Props = Omit<
  DropdownProps<string>,
  'options' | 'multiple' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (value: string, label: string) => void;
  cityID?: string;
};

const DistrictDropdown = (props: Props) => {
  let { placeholder = defaultPlaceholder, cityID, ...otherProps } = props;

  //TODO: add error handling
  const [getDistricts, { data }] = useLazyQuery<Districts, DistrictsVariables>(
    GET_DISTRICTS,
  );

  const fetchDistricts = useCallback(() => {
    if (cityID) {
      getDistricts({ variables: { cityID } });
    }
  }, [cityID, getDistricts]);

  useEffect(fetchDistricts, [fetchDistricts]);

  const options = useMemo(
    () =>
      cityID
        ? data?.districtByCityId.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          })) ?? []
        : [],
    [cityID, data],
  );

  return (
    <Dropdown options={options} placeholder={placeholder} {...otherProps} />
  );
};

export default DistrictDropdown;
