import gql from 'graphql-tag';

export const GET_ORDER_DETAIL = gql`
  query OrderDetail(
    $orderId: String!
    $type: OrderType!
    $orderNumber: String!
    $storeId: String
  ) {
    orderDetail(
      orderId: $orderId
      type: $type
      orderNumber: $orderNumber
      storeId: $storeId
    ) {
      acctNumber
      totalPrice
      discount
      orders {
        brandId
        rangeId
        orderNumber
        orderDate
        brandName
        productId
        productName
        productCode
        quantity
        lastSentQuantity
        unit
        price
        subTotalPrice
      }
      deliveries {
        deliveryNumber
        deliveryDate
        status
        productName
        productCode
        quantity
        unit
      }
      placedBy
      deliveryAddress
    }
  }
`;
