import gql from 'graphql-tag';

import { LIBRARY_DOCUMENT_FRAGMENT } from '../fragments';

export const ARCHIVE_DOCUMENT = gql`
  mutation ArchiveDocument($id: ID!) {
    archiveDocument(documentId: $id) {
      success
    }
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument(
    $brandId: ID!
    $documentName: String!
    $url: String!
    $thumbnail: String!
    $thumbnailOrientation: Orientation!
    $liveDate: DateTime!
  ) {
    createDocument(
      brandId: $brandId
      documentName: $documentName
      url: $url
      thumbnail: $thumbnail
      thumbnailOrientation: $thumbnailOrientation
      liveDate: $liveDate
    ) {
      ...LibraryDocument
    }
  }
  ${LIBRARY_DOCUMENT_FRAGMENT}
`;
export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument(
    $brandId: ID
    $documentId: ID!
    $documentName: String
    $url: String
    $thumbnail: String
    $thumbnailOrientation: Orientation
    $liveDate: DateTime
  ) {
    updateDocument(
      brandId: $brandId
      documentName: $documentName
      documentId: $documentId
      url: $url
      thumbnail: $thumbnail
      thumbnailOrientation: $thumbnailOrientation
      liveDate: $liveDate
    ) {
      ...LibraryDocument
    }
  }
  ${LIBRARY_DOCUMENT_FRAGMENT}
`;
