import React, { useState, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';

import {
  Header,
  SearchHeader,
  Option,
  SearchByDateRange,
  UsersDropdown,
  RegionDropdown,
  CityDropdown,
  SearchByText,
  BrandDropdown,
} from '../../components';
import { spacing, colors } from '../../constants/theme';
import { PromotionFragment } from '../../generated/PromotionFragment';
import { PromotionsVariables } from '../../generated/Promotions';

import PromotionTable from './PromotionTable';

type Props = {
  refetchToggle?: boolean;
  hideEdit?: boolean;
  hideArchiveCheck?: boolean;
  queryVariables?: PromotionsVariables;
  setEditPromotion?: (value: PromotionFragment) => void;
};

const PromotionSearch = (props: Props) => {
  let {
    refetchToggle,
    hideEdit,
    hideArchiveCheck,
    queryVariables,
    setEditPromotion,
  } = props;

  // NOTE: these are used for filter state
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Option | null>(null);
  const [selectedCity, setSelectedCity] = useState<Option | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<Option | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<Option | null>(null);
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateUntil, setDateUntil] = useState<Date | null>(null);
  const [inputPromotion, setInputPromotion] = useState<string | null>(null);
  const [promotionCode, setPromotionCode] = useState<string | null>(null);

  // NOTE: these are used to filter the queries
  const [searchArchived, setSearchArchived] = useState(false);
  const [searchByUser, setSearchByUser] = useState<string | null>(null);
  const [searchByPromotion, setSearchByPromotion] = useState<string | null>(
    null,
  );
  const [searchByRegion, setSearchByRegion] = useState<string | null>(null);
  const [searchByCity, setSearchByCity] = useState<string | null>(null);
  const [searchByDateFrom, setSearchByDateFrom] = useState<Date | null>(null);
  const [searchByDateUntil, setSearchByDateUntil] = useState<Date | null>(null);
  const [searchByBrand, setSearchByBrand] = useState<string | null>(null);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [searchByPromotionCode, setSearchByPromotionCode] = useState<
    string | null
  >(null);

  const submitSearch = useCallback(() => {
    setSearchArchived(includeArchived);
    setSearchByUser(selectedUser?.value.toString() ?? null);
    setSearchByRegion(selectedRegion?.label ?? null);
    setSearchByCity(selectedCity?.label ?? null);
    if (dateFrom) {
      setSearchByDateFrom(new Date(dateFrom.setHours(0, 0, 0)));
    } else {
      setSearchByDateFrom(null);
    }
    if (dateUntil) {
      setSearchByDateUntil(new Date(dateUntil.setHours(23, 59, 59)));
    } else {
      setSearchByDateUntil(null);
    }
    setSearchByPromotion(inputPromotion);
    setSearchByPromotionCode(promotionCode);
    setSearchByBrand(selectedBrand?.value.toString() ?? null);
  }, [
    includeArchived,
    selectedUser,
    selectedRegion,
    selectedCity,
    dateFrom,
    dateUntil,
    inputPromotion,
    selectedBrand,
    promotionCode,
  ]);

  const clearSearch = useCallback(() => {
    setIncludeArchived(false);
    setSelectedBrand(null);
    setSelectedCity(null);
    setSelectedRegion(null);
    setSelectedUser(null);
    setInputPromotion(null);
    setDateFrom(null);
    setDateUntil(null);
    setPromotionCode(null);
  }, []);

  const clearFilter = useCallback(() => {
    clearSearch();
    submitSearch();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectRegion = useCallback((selected: Option) => {
    setSelectedRegion(selected);
    setSelectedCity(null);
  }, []);

  let leftSide = () => {
    return (
      <>
        <View style={styles.separator}>
          <UsersDropdown
            data-cy="search-user-dropdown"
            type="side"
            title={t(['Cari Pengguna', 'Search by Users'])}
            selectedOption={selectedUser?.value}
            onSelect={setSelectedUser}
          />
        </View>
        <View style={styles.separator}>
          <BrandDropdown
            data-cy="search-brand-dropdown"
            type="side"
            title={t(['Cari Merek', 'Search by Brands'])}
            selectedOption={selectedBrand?.value}
            onSelect={setSelectedBrand}
          />
        </View>
        <View style={styles.separator}>
          <RegionDropdown
            data-cy="search-region-dropdown"
            type="side"
            title={t(['Cari Provinsi', 'Search by Region'])}
            selectedOption={selectedRegion?.value}
            onSelect={onSelectRegion}
          />
        </View>
      </>
    );
  };

  let rightSide = () => {
    return (
      <>
        <View style={styles.separator}>
          <CityDropdown
            data-cy="search-city-dropdown"
            type="side"
            provincesID={selectedRegion ? [selectedRegion.value] : []}
            title={t(['Cari Kota', 'Search by City'])}
            selectedOption={selectedCity?.value}
            onSelect={setSelectedCity}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-cy="search-promotion-name"
            label={t(['Cari Promosi', 'Search by Promotion'])}
            value={inputPromotion ?? ''}
            setValue={setInputPromotion}
          />
        </View>
        <View style={styles.separator}>
          <SearchByText
            data-cy="search-promotion-code"
            label={t(['Cari Kode Promosi', 'Search by Promotion Code'])}
            value={promotionCode?.toUpperCase() ?? ''}
            setValue={setPromotionCode}
          />
        </View>
        <View style={styles.separator}>
          <SearchByDateRange
            data-cy="search-promotion-date"
            from={dateFrom}
            until={dateUntil}
            setFrom={setDateFrom}
            setUntil={setDateUntil}
            setDisabled={setSearchDisabled}
          />
        </View>
      </>
    );
  };

  return (
    <>
      <SearchHeader
        data-cy="search-promotion"
        archivable={!hideArchiveCheck}
        title={t(['Promosi', 'Promotion'])}
        includeArchived={includeArchived}
        onArchivePress={setIncludeArchived}
        onSearchPress={submitSearch}
        onClearPress={clearFilter}
        disabled={searchDisabled}
      >
        {leftSide()}
        {rightSide()}
      </SearchHeader>
      <Header
        withSeparator
        title="Results"
        titleColor="link"
        infotipContent={t([
          'Seluruh data Promosi akan ditampilkan pada daftar berikut. Klik Judul Kolom untuk penyortiran berdasarkan judul kolom yang di kilik. Klik tulisan “Lihat” untuk melihat detail konten judul tersebut. Klik “Icon” Arsip untuk mengirimkan data ke halaman folder arsip. Data yang sudah berada di Folder Arsip tidak akan nampak pada daftar ini, Anda dapat mengembalikan ke daftar ini dengan cara masuk pada menu “Halaman Folder Arsip”.',
          'All Promotion data will be displayed in the following list. Click Column Headers for sorting based on the column headings that are clicked. Click "View" to see the detailed content of the title. Click the Archive “Icon” to send the data to the archive folder page. The data that is already in the Archive Folder will not appear in this list, you can return the data to this list by going to the "Archive Folder Page" menu.',
        ])}
        style={styles.topPadding}
      />
      <PromotionTable
        hideEdit={hideEdit}
        queryVariables={queryVariables}
        searchByUser={searchByUser || undefined}
        searchByRegion={searchByRegion || undefined}
        searchByCity={searchByCity || undefined}
        searchByDateFrom={searchByDateFrom || undefined}
        searchByDateUntil={searchByDateUntil || undefined}
        searchArchived={searchArchived || undefined}
        searchByPromotion={searchByPromotion || undefined}
        searchByBrand={searchByBrand || undefined}
        refetchToggle={refetchToggle}
        setEditPromotion={setEditPromotion}
        searchByPromotionCode={searchByPromotionCode || undefined}
      />
    </>
  );
};

const styles = StyleSheet.create({
  topPadding: { paddingTop: spacing.xlarge },
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xxsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
});

export default PromotionSearch;
