import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

import { notFound } from '../../../assets';

export default function SuccessVerifyEmail() {
  return (
    <View style={styles.container}>
      <Image source={notFound} style={styles.image} resizeMethod={'auto'} />
      <Text style={styles.title}>
        {t(['Halaman Tidak Ditemukan', 'Page Not Found'])}
      </Text>
      <Text style={styles.text}>
        {t([
          `Kami tidak menemukan halaman yang anda cari.\nPastikan anda menggunakan alamat link yang benar`,
          `We can't find the page you're looking for.\nPlease make sure you have the correct link.`,
        ])}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 8,
    fontSize: 20,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 20,
  },
  image: {
    width: 56,
    height: 56,
    marginBottom: 8,
  },
});
