import React, { useMemo } from 'react';

import { Role } from '../../../../generated/globalTypes';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultPlaceholder = t(['- Pilih Role -', '- Select Role -']);

type Props = Omit<
  DropdownProps<string>,
  'options' | 'multiple' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (value: string, label: string) => void;
};

const RoleDropdown = (props: Props) => {
  let { placeholder = defaultPlaceholder, ...otherProps } = props;

  const options = useMemo(
    () => [
      { label: 'Staff', value: Role.STAFF },
      { label: 'Supervisor', value: Role.SUPERVISOR },
      { label: 'Toko Owner', value: Role.TOKO_OWNER },
    ],
    [],
  );

  return (
    <Dropdown options={options} placeholder={placeholder} {...otherProps} />
  );
};

export default RoleDropdown;
