import React, { ReactNode, memo, useState, useRef, useEffect } from 'react';
import { View, StyleSheet, ViewProps, Animated } from 'react-native';
import { Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Button, Checkbox, Text } from '../core-ui';
import { theme } from '../constants/theme';

import Header from './Header';

type Props = {
  'data-cy'?: string;
  archivable?: boolean;
  children?: ReactNode;
  includeArchived?: boolean;
  disabled?: boolean;
  infotipContent?: string;
  title?: string;
  containerStyle?: ViewProps['style'];
  onArchivePress?: (check: boolean) => void;
  onClearPress: () => void;
  onSearchPress: () => void;
  buttonContainerStyle?: ViewProps['style'];
  isCollapsed?: boolean;
};

const SearchHeader = memo((props: Props) => {
  let {
    archivable,
    children,
    includeArchived,
    infotipContent,
    title,
    containerStyle,
    onArchivePress,
    onClearPress,
    onSearchPress,
    disabled,
    buttonContainerStyle,
    isCollapsed,
  } = props;
  let leftSideCount = 1;
  let leftSide = children;
  let rightSide = null as ReactNode;
  let leftSideCollapsed = null as ReactNode;
  let rightSideCollapsed = null as ReactNode;
  if (Array.isArray(children)) {
    let filteredChildren = children.filter((item) => item != null);
    leftSideCount = Math.ceil(filteredChildren.length / 2);
    if (isCollapsed) {
      leftSide = filteredChildren[0];
      rightSide = filteredChildren.slice(leftSideCount)[0];
      leftSideCollapsed = filteredChildren.slice(1, leftSideCount);
      rightSideCollapsed = filteredChildren.slice(leftSideCount + 1);
    } else {
      leftSide = filteredChildren.slice(0, leftSideCount);
      rightSide = filteredChildren.slice(leftSideCount);
    }
  }
  const [expanded, setExpanded] = useState(false);

  let ButtonExpand = () => {
    const transformAnim = useRef(new Animated.Value(0)).current;

    useEffect(() => {
      Animated.timing(transformAnim, {
        toValue: expanded ? 1 : 0,
        duration: 300,
      }).start();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    return (
      <IconButton
        data-cy="expand-button"
        onClick={() => setExpanded(!expanded)}
        disableTouchRipple={false}
        style={{ justifyContent: 'flex-end' }}
      >
        <Text>Advanced Filter</Text>
        <Animated.View
          style={{
            transform: [
              {
                rotate: transformAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '180deg'],
                }),
              },
            ],
          }}
        >
          <ExpandMoreIcon fontSize="default" />
        </Animated.View>
      </IconButton>
    );
  };

  return (
    <View style={containerStyle}>
      {title && (
        <Header
          withSeparator
          title={t(['Cari', 'Search'])}
          subtitle={title}
          infotipContent={infotipContent}
        />
      )}
      <View style={styles.row}>
        <View style={styles.flex} testID="leftSide">
          {leftSide}
          {isCollapsed && (
            <Collapse in={expanded}> {leftSideCollapsed}</Collapse>
          )}
        </View>
        <View style={styles.mediumHorizontalSpacing} />
        <View style={styles.flex} testID="rightSide">
          {rightSide}
          {isCollapsed && (
            <Collapse in={expanded}> {rightSideCollapsed}</Collapse>
          )}
          {isCollapsed && <ButtonExpand />}

          <View style={styles.row}>
            <View style={[styles.flex, styles.checkbox]}>
              {archivable && (
                <Checkbox
                  data-cy={`${props['data-cy']}-archive`}
                  label={t(['Sertakan arsip', 'Include archived items'])}
                  checked={!!includeArchived}
                  onPress={onArchivePress}
                />
              )}
            </View>
            <View style={[styles.row, styles.flex, buttonContainerStyle]}>
              <Button
                disabled={disabled}
                data-cy={`${props['data-cy']}-submit`}
                title={t(['Cari', 'Search'])}
                style={styles.flex}
                onPress={onSearchPress}
              />
              <View style={styles.smallHorizontalSpacing} />
              <Button
                data-cy={`${props['data-cy']}-clear`}
                preset="secondary"
                title={t(['Hapus', 'Clear'])}
                style={styles.flex}
                onPress={onClearPress}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  row: { flexDirection: 'row' },
  flex: { flex: 1 },
  checkbox: { alignItems: 'flex-end' },
  smallHorizontalSpacing: { width: theme.spacing.small },
  mediumHorizontalSpacing: { width: theme.spacing.medium },
});

export default SearchHeader;
