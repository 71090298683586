import gql from 'graphql-tag';

export const GET_TOKO_USERS = gql`
  query TokoUsers(
    $skip: Int
    $first: Int
    $storeName: String
    $storeCode: String
    $profileName: String
    $phoneNumber: String
    $profileEmail: String
    $registrationDate: DateFilter
  ) {
    UserStoreAdvanceSearch(
      pagination: { first: $first, skip: $skip }
      orderBy: { id: asc }
      storeName: $storeName
      storeCode: $storeCode
      profileName: $profileName
      phoneNumber: $phoneNumber
      profileEmail: $profileEmail
      registrationDate: $registrationDate
    ) {
      row {
        id
        role
        profile {
          id
          name
          email
          user {
            createFrom
          }
        }
        accessUser
        status
        accessUser
        phoneNumber
        store {
          managerStoreId
          managerStoreCode
          storeManagerBulk {
            storeName
            address
          }
          CMSStoreStatus
          createdAt
        }
      }
      total
    }
  }
`;

export const GET_AGENT_REQUESTS = gql`
  query AgentRequests(
    $first: Int
    $skip: Int
    $provinceId: String
    $cityId: String
    $storeName: String
    $storeCode: String
    $brandId: ID
    $orderBy: RequestAgentOrderByInput
    $status: CmsRequestStatusFilter
    $newStatus: NewCmsRequestStatusFilter
    $registrationDate: DateFilter
    $requestDate: DateFilter
  ) {
    requestAgentAdvanceSearch(
      pagination: { first: $first, skip: $skip }
      provinceId: $provinceId
      cityId: $cityId
      orderBy: $orderBy
      storeName: $storeName
      storeCode: $storeCode
      brandId: $brandId
      status: $status
      newStatus: $newStatus
      registrationDate: $registrationDate
      requestDate: $requestDate
    ) {
      row {
        id
        agentName
        approvalDeadline {
          deadline
          overSla
        }
        encodedToken
        cmsRequestStatus
        status
        rejectedBy
        user {
          createdAt
        }
        store {
          id
          verifiedDate
          storeVerified
          cityName
          provinceName
          managerStoreId
          managerStoreCode
          storeManager {
            id
            storeName
            statusActive
          }
          createdAt
        }
        businessUnitContact {
          id
          name
          brand {
            id
            name
          }
        }
        salesmanInfo {
          distributorName: agentName
          salesmanName
          salesmanReferralCode
        }
        assignDateAgent
        approveDateAgent
        rejectDateAgent
        createdAt
        requestFrom
        orderId
        totalOrderAmount
        isCancelledInAction
        agentReassignDate
      }
      total
    }
  }
`;

export const GET_STORES = gql`
  query Stores(
    $storeName: String
    $storeRegionId: String
    $storeCityId: String
    $pagination: PaginationInput
    $orderBy: StoreOrderByInput
    $storeCode: String
    $storePhoneNumber: String
    $storeDistrictId: String
    $storeUrbanVillageId: String
  ) {
    storeSearchAdvance(
      storeName: $storeName
      storeRegionId: $storeRegionId
      storeCityId: $storeCityId
      pagination: $pagination
      orderBy: $orderBy
      storeCode: $storeCode
      storePhoneNumber: $storePhoneNumber
      storeDistrictId: $storeDistrictId
      storeUrbanVillageId: $storeUrbanVillageId
    ) {
      row {
        id
        point
        totalPurchase
        storeManager {
          storeName
          province
          city
        }
      }
      total
    }
  }
`;
