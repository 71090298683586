import React, {
  useState,
  useMemo,
  useEffect,
  Fragment,
  useCallback,
} from 'react';
import { StyleSheet, View, Image, ActivityIndicator } from 'react-native';
import {
  TableContainer,
  Table as MTable,
  TableHead as MTableHead,
  TableBody as MTableBody,
  TableRow as MTableRow,
  TableCell,
  makeStyles,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';

import { Header, RadioGroup, ErrorMessage } from '../../components';
import { Text } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { GET_SLIDERS, GET_APP_USERS } from '../../graphql/queries';
import { Sliders, SlidersVariables } from '../../generated/Sliders';
import { ArchiveStatus, Time } from '../../generated/globalTypes';
import { AppUsers } from '../../generated/AppUsers';

const useStyles = makeStyles({
  root: { color: colors.text.link },
  tableRoot: { width: '100%' },
  topCell: {
    padding: '24px 0 0',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    width: 75,
    maxWidth: 75,
  },
  cell: {
    padding: '24px 0 24px',
    verticalAlign: 'top',
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
});

type Props = { refetchToggle: boolean };

export default function ShowAllUserGroup(props: Props) {
  let { refetchToggle } = props;
  const classes = useStyles();
  let [selectedTime, setSelectedTime] = useState<Time>(Time.ALL_DAY);

  const { data, loading, error, refetch } = useQuery<Sliders, SlidersVariables>(
    GET_SLIDERS,
    {
      variables: { archiveStatus: ArchiveStatus.NORMAL },
      notifyOnNetworkStatusChange: true,
    },
  );

  const { data: appUsers } = useQuery<AppUsers>(GET_APP_USERS);
  const appUserList = useMemo(() => {
    if (appUsers) {
      return appUsers.appUserAPI.map(({ name }) => name);
    }
    return [];
  }, [appUsers]);

  const refetchSliders = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);
  useEffect(() => {
    refetchSliders();
  }, [refetchToggle, refetchSliders]);

  const timeOptions = useMemo(
    () => [
      { value: Time.ALL_DAY, label: t(['Sepanjang hari', 'All day']) },
      { value: Time.MORNING, label: t(['Pagi', 'Morning']) },
      { value: Time.DAY, label: t(['Siang', 'Day']) },
      { value: Time.EVENING, label: t(['Malam', 'Evening']) },
    ],
    [],
  );

  const filteredData = useMemo(
    () =>
      data
        ? data.cardSliderAdvanceSearch.row.filter(
            (item) => item.timeActive === selectedTime,
          )
        : [],
    [data, selectedTime],
  );

  let renderActivePromotion = (
    liveDate: string,
    endLiveDate: string,
    month: number,
  ) => {
    let fill = 0;
    let blankLeft = 0;
    let blankRight = 0;

    const liveDateObj = new Date(liveDate);
    const endDateObj = new Date(endLiveDate);
    const endOfMonth = new Date(new Date().getFullYear(), month, -1).getDate();

    const isStart = liveDateObj.getMonth() === month;
    const isEnd = endDateObj.getMonth() === month;

    if (liveDateObj.getMonth() < month && endDateObj.getMonth() > month) {
      // NOTE: month is within start and end dates range, whole month is filled
      fill = 1;
    } else if (isStart && isEnd) {
      // NOTE: start and end dates are in the same month, only certain dates between start and end is filled
      blankLeft = liveDateObj.getDate();
      blankRight = endOfMonth - endDateObj.getDate();
      fill = endOfMonth - blankLeft - blankRight;
    } else if (isStart) {
      // NOTE: month is the start date, only the right part of the month is filled
      blankLeft = liveDateObj.getDate();
      fill = endOfMonth - blankLeft;
    } else if (isEnd) {
      // NOTE: month is the end date, only the left part of the month is filled
      fill = endDateObj.getDate();
      blankRight = endOfMonth - fill;
    }
    return (
      <View style={styles.row}>
        <View style={{ flex: blankLeft }} />
        <View style={[{ flex: fill }, styles.height, styles.activePromotion]} />
        <View style={{ flex: blankRight }} />
      </View>
    );
  };

  const topCellClass = useMemo(() => ({ root: classes.topCell }), [
    classes.topCell,
  ]);
  const cellClass = useMemo(() => ({ root: classes.cell }), [classes.cell]);
  const months = useMemo(
    () => [
      t(['Jan', 'Jan']),
      t(['Feb', 'Feb']),
      t(['Mar', 'Mar']),
      t(['Apr', 'Apr']),
      t(['Mei', 'May']),
      t(['Jun', 'Jun']),
      t(['Jul', 'Jul']),
      t(['Agu', 'Aug']),
      t(['Sep', 'Sep']),
      t(['Okt', 'Oct']),
      t(['Nov', 'Nov']),
      t(['Des', 'Dec']),
    ],
    [],
  );
  const headerContent = useMemo(
    () => (
      <MTableRow>
        {['', ...months].map((
          month,
          index, // NOTE: additional empty first item for the image column
        ) => (
          <TableCell align="center" key={index} classes={cellClass}>
            <Text>{month}</Text>
          </TableCell>
        ))}
      </MTableRow>
    ),
    [cellClass, months],
  );
  const bodyContent = useMemo(
    () =>
      filteredData.map(({ image, liveDate, endLiveDate, appUser }, index) => (
        <Fragment key={index}>
          <MTableRow>
            <TableCell classes={cellClass} rowSpan={2}>
              <Image
                source={{ uri: image || '' }}
                style={styles.image}
                resizeMode="contain"
              />
            </TableCell>
            {months.map((_, monthIndex) => (
              <TableCell classes={topCellClass} key={monthIndex}>
                {renderActivePromotion(liveDate, endLiveDate, monthIndex)}
              </TableCell>
            ))}
          </MTableRow>
          <MTableRow>
            <TableCell classes={cellClass} colSpan={12}>
              {appUserList
                .filter(
                  // NOTE: filter the app user list
                  (appUserName) =>
                    !!appUser.find(({ name }) => name === appUserName),
                )
                .map((
                  appUserName, // NOTE: render as texts with some distance
                ) => (
                  <Text key={appUserName} style={styles.appUser}>
                    {appUserName}
                  </Text>
                ))}
            </TableCell>
          </MTableRow>
        </Fragment>
      )),
    [months, filteredData, appUserList, cellClass, topCellClass],
  );

  return (
    <>
      <Header
        title={t(['Tampilkan Semua Pengguna', 'Show All User Groups'])}
        titleColor="link"
      />
      <RadioGroup
        horizontal
        options={timeOptions}
        selectedOption={selectedTime}
        setSelectedOption={setSelectedTime}
        optionLabelPlacement="start"
        containerStyle={styles.radioGroup}
      />
      {loading && <ActivityIndicator />}
      {!!error && (
        <ErrorMessage
          action={t([
            'mengambil data slider beranda',
            'retrieve the homepage slider data',
          ])}
          error={error}
          onPress={refetchSliders}
        />
      )}
      {!!data && (
        <TableContainer className={classes.tableRoot}>
          <MTable size="small">
            <MTableHead>{headerContent}</MTableHead>
            <MTableBody>{bodyContent}</MTableBody>
          </MTable>
        </TableContainer>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  row: { flexDirection: 'row' },
  appUser: { paddingRight: spacing.medium, paddingBottom: spacing.xsmall },
  image: {
    width: 240,
    height: 124,
  },
  height: {
    height: 16,
  },
  activePromotion: {
    backgroundColor: colors.table.activePromotionBackground,
  },
  radioGroup: {
    paddingTop: spacing.medium,
  },
});
