import React, {
  memo,
  CSSProperties,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import {
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
} from 'react-native';
import {
  Dialog,
  DialogContent,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { colors, spacing } from '../constants/theme';

import Text from './Text';

type Props = {
  open: boolean;
  title: string;
  onClose: () => void;
  style?: StyleProp<ViewStyle>;
  contentStyle?: StyleProp<ViewStyle>;
  image: string;
  bottomContent?: ReactNode;
};

type DialogTitleProps = {
  children: string;
  onClose: () => void;
};

const useStyles = makeStyles({
  paperFullWidth: {
    maxWidth: '60%',
  },
});

const DialogTitle = memo((props: DialogTitleProps) => {
  const { children, onClose } = props;
  return (
    <View style={styles.title}>
      <Text bold size="xl">
        {children}
      </Text>
      {onClose ? (
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </View>
  );
});

const IMAGE_HEIGHT = 480;
const AttachmentModal = memo((props: Props) => {
  let {
    open,
    onClose,
    style,
    title,
    image,
    contentStyle,
    bottomContent = null,
  } = props;

  const [width, setWidth] = useState(-1);
  const onLayout = useCallback(
    (e: LayoutChangeEvent) => setWidth(e.nativeEvent.layout.width),
    [],
  );
  const imageStyle = useMemo(() => ({ width, height: IMAGE_HEIGHT }), [width]);

  let classes = useStyles();
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      classes={classes}
      style={StyleSheet.flatten(style) as CSSProperties}
    >
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      <DialogContent
        style={
          StyleSheet.flatten([
            styles.dialogContent,
            contentStyle,
          ]) as CSSProperties
        }
      >
        <View onLayout={onLayout}>
          <Image
            resizeMode="contain"
            resizeMethod="resize"
            source={image as ImageSourcePropType}
            style={imageStyle}
          />
        </View>
        {bottomContent}
      </DialogContent>
    </Dialog>
  );
});

const styles = StyleSheet.create({
  flex: { flex: 1 },
  title: {
    borderBottomWidth: 1,
    borderBottomColor: colors.button.border.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: spacing.l,
    marginHorizontal: spacing.l,
  },
  dialogContent: {
    padding: spacing.l,
  },
});

export default AttachmentModal;
