import gql from 'graphql-tag';

export const EDIT_REQUEST_AGENT_EMAIL = gql`
  mutation EditRequestAgentEmail($emailRequestAgent: EmailRequestAgentInput!) {
    EditRequestAgentReportEmail(emailRequestAgent: $emailRequestAgent) {
      success
      error {
        message
        requestAgentEmailData {
          id
          email
          brandId
        }
      }
    }
  }
`;

export const SWITCH_REQUEST_AGENT_EMAIL = gql`
  mutation SwitchRequestAgentEmail(
    $id: ID!
    $businessContact: Boolean!
    $statusActive: Boolean!
  ) {
    switchOnOffRequestAgentReportEmail(
      id: $id
      businessContact: $businessContact
      statusActive: $statusActive
    ) {
      success
    }
  }
`;

export const DELETE_REQUEST_AGENT_EMAIL = gql`
  mutation DeleteRequestAgentReportEmail($id: ID!) {
    deleteRequestAgentReportEmail(id: $id) {
      success
    }
  }
`;
