import { ValidVisitation } from '../../../generated/globalTypes';

export const storeValidation = [
  { label: 'Valid', value: 'Valid' },
  { label: 'Valid 1', value: 'Valid 1' },
  { label: 'Valid 2', value: 'Valid 2' },
  { label: 'Valid 3', value: 'Valid 3' },
  { label: 'Invalid', value: 'Invalid' },
  { label: 'Invalid 1', value: 'Invalid 1' },
  { label: 'Invalid 2', value: 'Invalid 2' },
  { label: 'Invalid 3', value: 'Invalid 3' },
  { label: 'Invalid 4', value: 'Invalid 4' },
  { label: 'Invalid 5', value: 'Invalid 5' },
  { label: 'Invalid 6', value: 'Invalid 6' },
  { label: 'Invalid 7', value: 'Invalid 7' },
  { label: 'Others', value: 'Others' },
  { label: 'No Need Validation', value: 'No Need Validation' },
  { label: 'Sampah', value: 'Sampah' },
  { label: 'Double Sampah', value: 'Double Sampah' },
];

export const validVisitation = [
  { label: 'Guaranteed', value: ValidVisitation.GUARANTEED },
  { label: 'Not Guaranteed', value: ValidVisitation.NOT_GUARANTEED },
];
