import gql from 'graphql-tag';

export const GET_BU_CONTACT = gql`
  query BUContacts {
    businessUnitContacts {
      id
      name
      email
      brand {
        id
        name
      }
      phoneNumber
    }
  }
`;

export const IS_BU_CONNECT = gql`
  query BUContactConnect($id: ID!) {
    BUContactConnect(id: $id) {
      isConnect
    }
  }
`;
