import gql from 'graphql-tag';

export const CHANGE_PHONE_NUMBER = gql`
  mutation changePhoneNumber(
    $oldPhoneNumber: String!
    $newPhoneNumber: String!
    $googleSheetFormNumber: String!
  ) {
    changePhoneNumber(
      oldPhoneNumber: $oldPhoneNumber
      newPhoneNumber: $newPhoneNumber
      googleSheetFormNumber: $googleSheetFormNumber
    ) {
      status
      message
    }
  }
`;

export const LOG_PHONE_NUMBER = gql`
  query logChangePhoneNumbers($where: LogChangePhoneNumberWhereInput) {
    logChangePhoneNumbers(where: $where) {
      id
      oldPhoneNumber
      newPhoneNumber
      userId
      user {
        id
      }
      dataNumber
      createdAt
      updatedAt
    }
  }
`;
