import gql from 'graphql-tag';

export const LIBRARY_DOCUMENT_FRAGMENT = gql`
  fragment LibraryDocument on Document {
    id
    brand {
      id
      name
    }
    brandId
    documentName
    url
    thumbnail
    thumbnailOrientation
    liveDate
    status
  }
`;
