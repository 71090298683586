import gql from 'graphql-tag';

export const CREATE_BRAND_PAGE = gql`
  mutation CreateBrandPage(
    $idBrand: ID!
    $pageName: String!
    $idLogoLibrary: ID
    $headerImage: String
    $about: String!
    $rangeName: String!
    $description: String!
    $availableIn: String!
    $rangeImage: String
    $liveDate: DateTime!
    $rangeId: String!
    $status: StatusActive!
  ) {
    createBrandPage(
      idBrand: $idBrand
      pageName: $pageName
      idLogoLibrary: $idLogoLibrary
      headerImage: $headerImage
      about: $about
      rangeName: $rangeName
      description: $description
      availableIn: $availableIn
      rangeImage: $rangeImage
      liveDate: $liveDate
      rangeId: $rangeId
      status: $status
    ) {
      id
    }
  }
`;

export const ADD_BRAND_RANGE = gql`
  mutation AddBrandRange(
    $brandPageID: ID!
    $rangeName: String!
    $description: String!
    $availableIn: String!
    $rangeImage: String
    $liveDate: DateTime!
    $orderNow: String!
  ) {
    addBrandRange(
      idBrandPage: $brandPageID
      rangeName: $rangeName
      description: $description
      availableIn: $availableIn
      rangeImage: $rangeImage
      liveDate: $liveDate
      rangeId: $orderNow
    ) {
      id
    }
  }
`;

export const DELETE_BRAND_RANGE = gql`
  mutation DeleteBrandRange($rangeID: ID!) {
    deleteBrandRange(idBrandRange: $rangeID) {
      success
    }
  }
`;

export const UPDATE_BRAND_PAGE = gql`
  mutation UpdateBrandPage(
    $brandPageID: ID!
    $logoLibraryID: ID
    $headerImage: String
    $about: String
    $rangeID: ID!
    $rangeName: String!
    $description: String!
    $availableIn: String!
    $rangeImage: String
    $liveDate: DateTime!
    $orderNow: String!
    $status: StatusActive!
  ) {
    updateBrandPage(
      idBrandPage: $brandPageID
      idLogoLibrary: $logoLibraryID
      headerImage: $headerImage
      about: $about
      idBrandRange: $rangeID
      rangeName: $rangeName
      description: $description
      availableIn: $availableIn
      rangeImage: $rangeImage
      liveDate: $liveDate
      rangeId: $orderNow
      status: $status
    ) {
      id
    }
  }
`;
