import React, { memo } from 'react';

import { StatsCompetition } from '../../generated/globalTypes';

import Dropdown, { Props as DropdownProps } from './Dropdown';

import { Option } from '.';

const defaultTitle = t(['Cari Statistik', 'Search by Stats']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
};

const CompetitionStatsDropdown = memo((props: Props) => {
  let { title = defaultTitle, ...otherProps } = props;

  let competitionStats: Array<Option> = [
    {
      label: t(['semua pengguna yang masuk', 'all users who entered']),
      value: StatsCompetition.ALL_USERS_WHO_ENTERED,
    },
    {
      label: t(['semua pemenang kompetisi', 'all competition winners']),
      value: StatsCompetition.ALL_COMPETITION_WINNERS,
    },
  ];

  return (
    <Dropdown options={competitionStats ?? []} title={title} {...otherProps} />
  );
});

export default CompetitionStatsDropdown;
