import gql from 'graphql-tag';

import { STORE_FRAGMENT, USER_FRAGMENT } from '../fragments';
import { ADMIN_REQUEST_VERIFICATION_FRAGMENT } from '../../../../graphql/fragments';

export const VERIFICATION_REQUESTS = gql`
  query VerificationRequests(
    $type: TypeVerify
    $registerDateInput: DateFilter
    $confirmDateInput: DateFilter
    $storeFilter: FilterStoreInput
    $userFilter: FilterUserInput
    $pagination: PaginationInput
    $searchName: String
    $adminId: String
    $orderBy: RequestVerificationOrderBy
    $userStatus: [FilterUserStatusVerifyInput!]
    $storeStatus: [FilterStoreStatusVerifyInput!]
    $viewData: FilterViewDataInput
  ) {
    requestVerificationAdvance(
      type: $type
      registerDateInput: $registerDateInput
      confirmDateInput: $confirmDateInput
      storeFilter: $storeFilter
      userFilter: $userFilter
      pagination: $pagination
      searchName: $searchName
      orderBy: $orderBy
      adminId: $adminId
      status: { user: $userStatus, store: $storeStatus }
      viewData: $viewData
    ) {
      row {
        id
        store {
          ...StoreFragment
        }
        storeId
        user {
          ...UserFragment
        }
        userId
        type
        admin {
          ...AdminRequestVerificationFragment
        }
        adminId
        status
        unverifiedReasonData {
          nextAction
          problemReason
          otherReason
          storePhotoCategory
          phoneCategory
        }
        note
        confirmAt
        confirmBy
        createdAt
        validasiToko
        validVisitation
      }
      total
    }
  }
  ${ADMIN_REQUEST_VERIFICATION_FRAGMENT}
  ${STORE_FRAGMENT}
  ${USER_FRAGMENT}
`;
