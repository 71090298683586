import gql from 'graphql-tag';

export const ROLLBACK_VERIFIED_STORE = gql`
  mutation RollbackVerifiedStore($idVerification: ID!) {
    rollBackVerifiedStore(idVerification: $idVerification) {
      success
    }
  }
`;

export const CHANGE_STORE_CODE = gql`
  mutation ChangeVerifiedMergeStoreCode(
    $idVerification: ID!
    $newManagerStoreCode: String!
  ) {
    changeCodeVerifiedMergeStore(
      idVerification: $idVerification
      newManagerStoreCode: $newManagerStoreCode
    ) {
      success
    }
  }
`;
