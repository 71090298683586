import React, {
  ReactNode,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  Dropdown,
  Option,
  DateTimePicker,
  StepHeader,
  CityDropdown,
  RegionDropdown,
  ErrorMessage,
  LiveDateRadioGroup,
} from '../../components';
import { Text, Button } from '../../core-ui';
import { spacing, colors, theme } from '../../constants/theme';
import { idMonths, enMonths, idDays, enDays } from '../../constants/date';
import UsersDropdown from '../../components/dropdowns/UsersDropdown';
import {
  GET_MONTHLY_EVENT,
  GET_MONTHLY_COMPETITION,
  GET_MONTHLY_NEWS,
} from '../../graphql/queries';
import {
  MonthlyEvent,
  MonthlyEventVariables,
} from '../../generated/MonthlyEvent';
import { MonthlyCompetition } from '../../generated/MonthlyCompetition';
import { MonthlyNews, MonthlyNewsVariables } from '../../generated/MonthlyNews';

type Event = {
  city: string;
  appUser: string;
  name: string;
  eventDate: Date;
};

export type NewsCategory = 'competition' | 'event' | 'news';

type Props = {
  selectedEvent: NewsCategory | null;
  selectedAppUser: string | null;
  selectedRegion: string | null;
  regionName: string | null;
  cityName: string | null;
  selectedDate: Date;
  stepSix: ReactNode;
  editable: boolean;
  editorState: EditorState;
  salesmanEditorState: EditorState;
  liveDate?: Date;
  endDate: Date;
  submitDisabled: boolean;
  submitLoading: boolean;
  getRegionID: (values: Array<string>) => void;
  onEventSelected: (value: Option) => void;
  onAppUserSelected: (value: Option) => void;
  onRegionSelected: (value: Option) => void;
  onCitySelected: (value: Option | null) => void;
  onDateSelected: (date: Date) => void;
  setEditorState: (value: EditorState) => void;
  setSalesmanEditorState: (value: EditorState) => void;
  setLiveDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setDescription: (value: string) => void;
  setSalesmanDescription: (value: string) => void;
  onSubmit: () => void;
  onEdit: () => void;
};

type ListRowProps = {
  date: string;
  city: string;
  appUser: string;
  name: string;
  showDate?: boolean;
};

const ListRow = (props: ListRowProps) => {
  let { date, city, appUser, name, showDate } = props;
  return (
    <View>
      {showDate && (
        <Text bold style={styles.xxSmallBottomSpacing}>
          {t(['{idDay} {date}', '{enDay} {date}'], {
            idDay: idDays[new Date(date).getDay()]
              .substring(0, 3)
              .toUpperCase(),
            enDay: enDays[new Date(date).getDay()]
              .substring(0, 3)
              .toUpperCase(),
            date: new Date(date).getDate(),
          })}
        </Text>
      )}
      <View style={[styles.row, styles.flex, styles.xxSmallBottomSpacing]}>
        <Text style={styles.flex}>{city}</Text>
        <Text style={styles.flex}>{appUser}</Text>
        <Text style={styles.flex}>{name}</Text>
      </View>
    </View>
  );
};

export default function StepOneToFive(props: Props) {
  let {
    selectedEvent,
    selectedAppUser,
    selectedRegion,
    regionName,
    cityName,
    selectedDate,
    stepSix,
    editable,
    editorState,
    salesmanEditorState,
    liveDate,
    endDate,
    submitDisabled,
    submitLoading,
    getRegionID,
    onEventSelected,
    onAppUserSelected,
    onRegionSelected,
    onCitySelected,
    onDateSelected,
    setEditorState,
    setSalesmanEditorState,
    setLiveDate,
    setEndDate,
    setDescription,
    setSalesmanDescription,
    onSubmit,
    onEdit,
  } = props;

  const [seeAll, setSeeAll] = useState(false);
  const [monthChangeDate, setMonthChangeDate] = useState<Date | null>(null);

  const categoryToString = useCallback((category: NewsCategory) => {
    switch (category) {
      case 'competition': {
        return t(['Kompetisi', 'Competitions']);
      }
      case 'event': {
        return t(['Event', 'Events']);
      }
      case 'news': {
        return t(['Berita', 'News']);
      }
    }
  }, []);

  const onPressSeeAll = useCallback(() => setSeeAll(!seeAll), [
    seeAll,
    setSeeAll,
  ]);

  const [
    getMonthlyEvent,
    { data: listOfEvents, loading: eventLoading, error: eventError },
  ] = useLazyQuery<MonthlyEvent, MonthlyEventVariables>(GET_MONTHLY_EVENT);

  const [
    getMonthlyCompetition,
    { data: listOfCompetitions, loading: compLoading, error: compError },
  ] = useLazyQuery<MonthlyCompetition, MonthlyEventVariables>(
    GET_MONTHLY_COMPETITION,
  );

  const [
    getMonthlyNews,
    { data: listOfNews, loading: newsLoading, error: newsError },
  ] = useLazyQuery<MonthlyNews, MonthlyNewsVariables>(GET_MONTHLY_NEWS);

  const refetchCalendar = useCallback(() => {
    let currentDate = new Date();
    const commonVariables = {
      dateFrom: monthChangeDate
        ? new Date(monthChangeDate.getFullYear(), monthChangeDate.getMonth())
        : new Date(currentDate.getFullYear(), currentDate.getMonth()),
      dateUntil: monthChangeDate
        ? new Date(
            monthChangeDate.getFullYear(),
            monthChangeDate.getMonth() + 1,
            0,
            23,
            59,
            59,
          )
        : new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0,
            23,
            59,
            59,
          ),
    };

    switch (selectedEvent) {
      case 'event':
        return getMonthlyEvent({ variables: commonVariables });
      case 'competition':
        return getMonthlyCompetition({ variables: commonVariables });
      case 'news':
        return getMonthlyNews({ variables: commonVariables });
      default:
        return null;
    }
  }, [
    getMonthlyEvent,
    getMonthlyCompetition,
    getMonthlyNews,
    selectedEvent,
    monthChangeDate,
  ]);

  useEffect(() => {
    refetchCalendar();
  }, [refetchCalendar]);

  const { markedDates, selectedMonthEventList } = useMemo(() => {
    let markedDates: Array<Date> = [];
    let selectedMonthEventList: ReactNode = null;

    if (selectedEvent === 'competition' && listOfCompetitions) {
      for (const item of listOfCompetitions.competitions) {
        const { appUser, city, eventDate, id, name } = item;
        markedDates.push(new Date(eventDate));
        const itemNode = (
          <ListRow
            key={id}
            date={eventDate}
            city={city}
            appUser={appUser}
            name={name}
          />
        );
        if (Array.isArray(selectedMonthEventList)) {
          selectedMonthEventList.push(itemNode);
        } else {
          selectedMonthEventList = [itemNode];
        }
      }
    } else if (selectedEvent === 'event' && listOfEvents) {
      for (const item of listOfEvents.events) {
        const { appUser, city, eventDate, id, name } = item;
        markedDates.push(new Date(eventDate));
        const itemNode = (
          <ListRow
            key={id}
            date={eventDate}
            city={city}
            appUser={appUser}
            name={name}
          />
        );
        if (Array.isArray(selectedMonthEventList)) {
          selectedMonthEventList.push(itemNode);
        } else {
          selectedMonthEventList = [itemNode];
        }
      }
    } else if (selectedEvent === 'news' && listOfNews) {
      for (const item of listOfNews.news) {
        const { appUser, city, startDate, id, title } = item;
        markedDates.push(new Date(startDate));
        const itemNode = (
          <ListRow
            key={id}
            date={startDate}
            city={city}
            appUser={appUser}
            name={title}
          />
        );
        if (Array.isArray(selectedMonthEventList)) {
          selectedMonthEventList.push(itemNode);
        } else {
          selectedMonthEventList = [itemNode];
        }
      }
    }

    return {
      markedDates,
      selectedMonthEventList,
    };
  }, [selectedEvent, listOfCompetitions, listOfEvents, listOfNews]);

  const selectedDateEventList = useMemo(() => {
    const dateString = selectedDate.toDateString();
    if (selectedEvent === 'competition' && listOfCompetitions) {
      return listOfCompetitions.competitions
        .filter(
          ({ eventDate }) => new Date(eventDate).toDateString() === dateString,
        )
        .map(({ appUser, city, eventDate, id, name }) => {
          return (
            <ListRow
              key={id}
              date={eventDate}
              city={city}
              appUser={appUser}
              name={name}
            />
          );
        });
    } else if (selectedEvent === 'event' && listOfEvents) {
      return listOfEvents.events
        .filter(
          ({ eventDate }) => new Date(eventDate).toDateString() === dateString,
        )
        .map(({ appUser, city, eventDate, id, name }) => {
          return (
            <ListRow
              key={id}
              date={eventDate}
              city={city}
              appUser={appUser}
              name={name}
            />
          );
        });
    } else if (selectedEvent === 'news' && listOfNews) {
      return listOfNews.news
        .filter(
          ({ startDate }) => new Date(startDate).toDateString() === dateString,
        )
        .map(({ appUser, city, startDate, id, title }) => {
          return (
            <ListRow
              key={id}
              date={startDate}
              city={city}
              appUser={appUser}
              name={title}
            />
          );
        });
    }
    return null;
  }, [
    selectedDate,
    selectedEvent,
    listOfCompetitions,
    listOfEvents,
    listOfNews,
  ]);

  const onSelectRegion = useCallback(
    (selected: Option) => {
      onRegionSelected(selected);
      onCitySelected(null);
    },
    [onRegionSelected, onCitySelected],
  );

  let toolbar = {
    options: [
      'inline',
      'blockType',
      'fontSize',
      'list',
      'textAlign',
      'colorPicker',
      'link',
      'emoji',
      'image',
      'remove',
      'history',
    ],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    blockType: {
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48],
    },
    list: { options: ['unordered', 'ordered'] },
    link: { showOpenOptionOnHover: false },
  };

  return (
    <>
      <View style={styles.row}>
        <View
          style={[
            styles.flex,
            styles.paddingRight40,
            selectedEvent && styles.divider,
          ]}
        >
          <View style={styles.smallBottomSpacing}>
            <StepHeader
              title={t(['Pilih Daftar', 'Select Listing'])}
              divider={false}
            />
            <Dropdown
              data-cy="dbo-news-dropdown"
              title={t(['Pilih Daftar', 'Select Listing'])}
              options={[
                {
                  label: t(['Kompetisi', 'Competition']),
                  value: 'competition',
                },
                { label: t(['Event', 'Event']), value: 'event' },
                { label: t(['Berita', 'News']), value: 'news' },
              ]}
              selectedOption={selectedEvent ?? undefined}
              onSelect={onEventSelected}
            />
          </View>
          {selectedEvent && (
            <>
              <View style={styles.smallBottomSpacing}>
                <StepHeader
                  title={t(['Pilih Pengguna Aplikasi', 'Select App Users'])}
                  divider={false}
                />
                <UsersDropdown
                  data-cy="form-user-dropdown"
                  selectedOption={selectedAppUser ?? undefined}
                  onSelect={onAppUserSelected}
                  disabled={!editable}
                />
              </View>
              <View style={styles.smallBottomSpacing}>
                <StepHeader
                  title={t(['Pilih Provinsi', 'Selected Region'])}
                  divider={false}
                />
                <RegionDropdown
                  data-cy="form-region-dropdown"
                  dataKey="label"
                  getSelectedValues={getRegionID}
                  selectedOption={regionName ?? undefined}
                  onSelect={onSelectRegion}
                  disabled={!editable}
                />
              </View>
              <View style={styles.smallBottomSpacing}>
                <StepHeader
                  title={t(['Pilih Kota', 'Select City'])}
                  divider={false}
                />
                <CityDropdown
                  data-cy="form-city-dropdown"
                  dataKey="label"
                  provincesID={selectedRegion ? [selectedRegion] : []}
                  selectedOption={cityName ?? undefined}
                  onSelect={onCitySelected}
                  disabled={!editable}
                />
              </View>
              <StepHeader
                title={t(['Pilih Tanggal', 'Select Event Date'])}
                divider={false}
              />
              <DateTimePicker
                data-cy="form-calendar"
                type="calendar"
                onMonthChange={setMonthChangeDate}
                selectedDate={selectedDate}
                onChange={onDateSelected}
                disabled={!editable}
                markedDates={markedDates ?? undefined}
              />
              {selectedDateEventList?.length !== 0 && (
                <View style={styles.selectedDateEvents}>
                  <Text color="error" style={styles.xSmallBottomSpacing}>
                    {t(
                      [
                        '{idDay} {date} {idMonth} {year}',
                        '{enDay} {date} {enMonth} {year}',
                      ],
                      {
                        idDay: idDays[selectedDate.getDay()]
                          .substring(0, 3)
                          .toUpperCase(),
                        enDay: enDays[selectedDate.getDay()]
                          .substring(0, 3)
                          .toUpperCase(),
                        date: selectedDate.getDate(),
                        idMonth: idMonths[selectedDate.getMonth()],
                        enMonth: enMonths[selectedDate.getMonth()],
                        year: selectedDate.getFullYear(),
                      },
                    )}
                  </Text>
                  {selectedDateEventList}
                </View>
              )}
              <View style={[styles.row, styles.seeAllWrapper]}>
                <Button
                  preset="transparent"
                  title={
                    t(['Lihat Semua', 'See All']) + ` ${seeAll ? '-' : '+'}`
                  }
                  onPress={onPressSeeAll}
                  style={{ marginRight: spacing.small }}
                  disabled={!editable}
                />
                <Text>
                  {t(['{category} {idMonth}', '{enMonth} {category}'], {
                    category: categoryToString(selectedEvent),
                    idMonth: idMonths[selectedDate.getMonth()],
                    enMonth: enMonths[selectedDate.getMonth()],
                  })}
                </Text>
              </View>
              <View>
                {(eventLoading || compLoading || newsLoading) && (
                  <ActivityIndicator />
                )}
                {(!!eventError || !!compError || !!newsError) && (
                  <ErrorMessage
                    error={eventError || compError || newsError}
                    action={t(
                      [
                        'mengambil data {category}',
                        'retireve the {category} data',
                      ],
                      {
                        category: categoryToString(selectedEvent),
                      },
                    )}
                    onPress={refetchCalendar}
                  />
                )}
                {seeAll && selectedMonthEventList}
              </View>
            </>
          )}
        </View>
        <View
          style={[styles.flex, selectedEvent && styles.paddingHorizontal40]}
        >
          {selectedEvent && stepSix}
        </View>
        <View style={styles.flex2}>
          {selectedEvent && (
            <>
              <Text style={styles.xSmallBottomSpacing} bold>
                {t([
                  'Deskripsi - Konten Toko App',
                  'Description - Toko App Content',
                ])}
              </Text>
              <View style={[styles.editorContainer, styles.smallBottomSpacing]}>
                <Editor
                  readOnly={!editable}
                  editorState={editorState}
                  onEditorStateChange={(value) => {
                    setEditorState(value);
                    let newDescription = draftToHtml(
                      convertToRaw(value.getCurrentContent()),
                    );
                    setDescription(newDescription);
                  }}
                  toolbar={toolbar}
                />
              </View>
              <Text style={styles.xSmallBottomSpacing} bold>
                {t([
                  'Deskripsi - Konten Salesman App',
                  'Description - Salesman App Content',
                ])}
              </Text>
              <View style={[styles.editorContainer, styles.smallBottomSpacing]}>
                <Editor
                  readOnly={!editable}
                  editorState={salesmanEditorState}
                  onEditorStateChange={(value) => {
                    setSalesmanEditorState(value);
                    let newSalesmanDescription = draftToHtml(
                      convertToRaw(value.getCurrentContent()),
                    );
                    setSalesmanDescription(newSalesmanDescription);
                  }}
                  toolbar={toolbar}
                />
              </View>
              <Text style={styles.xSmallBottomSpacing} bold>
                {t(['Tayang di Aplikasi', 'Go Live on App'])}
              </Text>
              <View style={styles.liveContainer}>
                <View style={styles.xSmallBottomSpacing}>
                  <LiveDateRadioGroup
                    withEndDate
                    liveDate={liveDate}
                    setLiveDate={setLiveDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    disabled={!editable}
                    horizontal
                  />
                </View>
                <View style={[styles.row, { marginTop: spacing.small }]}>
                  <View style={[styles.flex, styles.buttonSpacing]}>
                    <Button
                      isLoading={submitLoading}
                      title={t(['Simpan', 'Submit'])}
                      onPress={onSubmit}
                      disabled={submitDisabled || !editable}
                    />
                  </View>
                  <View style={styles.flex}>
                    <Button
                      preset="secondary"
                      title={
                        editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])
                      }
                      onPress={onEdit}
                    />
                  </View>
                </View>
              </View>
            </>
          )}
        </View>
      </View>
    </>
  );
}

let styles = StyleSheet.create({
  flex: { flex: 1 },
  flex2: { flex: 2 },
  row: { flexDirection: 'row' },
  seeAllWrapper: { alignItems: 'center', marginTop: spacing.medium },
  smallBottomSpacing: {
    marginBottom: spacing.small,
  },
  selectedDateEvents: {
    marginTop: spacing.medium,
    borderWidth: 1,
    borderColor: colors.border.primary,
    padding: spacing.small,
  },
  xSmallBottomSpacing: {
    paddingBottom: spacing.xsmall,
  },
  xxSmallBottomSpacing: {
    paddingBottom: spacing.xxsmall,
  },
  paddingRight40: {
    paddingRight: 40,
  },
  paddingHorizontal40: {
    paddingHorizontal: 40,
  },
  divider: {
    borderRightWidth: 1,
    borderRightColor: colors.border.light,
  },
  editorContainer: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    padding: 5,
    minHeight: 400,
  },
  liveContainer: {
    borderColor: theme.colors.border.primary,
    borderWidth: 1,
    padding: spacing.medium,
    paddingTop: spacing.xxsmall,
    paddingLeft: spacing.small,
  },
  buttonSpacing: {
    marginLeft: 46,
    marginRight: spacing.small,
  },
});
