import React, { useState, useCallback, useMemo, memo } from 'react';
import { StyleSheet } from 'react-native';
import { ButtonBase, Popover, makeStyles } from '@material-ui/core';

import { Text } from '../core-ui';
import { theme, colors } from '../constants/theme';

const useStyles = makeStyles({
  paper: {
    backgroundColor: colors.popover.secondary.background,
    padding: `${theme.spacing.xsmall}px ${theme.spacing.small}px`,
  },
});

type Props = { label: string; content: string };

const TextPopover = memo((props: Props) => {
  let { label, content } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const onPress = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    [setAnchorEl],
  );

  const anchorOrigin = useMemo(
    () => ({ vertical: 'bottom', horizontal: 'center' } as const),
    [],
  );
  const transformOrigin = useMemo(
    () => ({ vertical: 'top', horizontal: 'center' } as const),
    [],
  );

  return (
    <>
      <ButtonBase disableRipple onClick={onPress}>
        <Text color="link" bold style={styles.leftText}>
          {label}
        </Text>
      </ButtonBase>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={classes}
        elevation={4}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={onClose}
      >
        <Text>{content}</Text>
      </Popover>
    </>
  );
});

const styles = StyleSheet.create({
  leftText: { textAlign: 'left' },
});

export default TextPopover;
