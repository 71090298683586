import gql from 'graphql-tag';

import { PROMOTION_FRAGMENT } from '../fragments';

export const CREATE_PROMOTION = gql`
  mutation CreatePromotion(
    $brandId: ID!
    $productId: String
    $rangeId: String
    $promotionName: String!
    $appUser: String!
    $region: String!
    $city: String!
    $termAndCondition: String!
    $heroImage: String
    $optionalImages: [String!]!
    $description: String!
    $descriptionSalesman: String
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $productQuantity: Int
    $unitIndex: Int
    $businessUnitContactId: ID
    $urlDeeplinkSalesman: String
    $sendPushNotification: Boolean!
    $showPromotionInMobile: Boolean!
    $promotionCode: String
    $isGeneratePromotionCode: Boolean!
  ) {
    createPromotion(
      brandId: $brandId
      productId: $productId
      rangeId: $rangeId
      promotionName: $promotionName
      appUser: $appUser
      region: $region
      city: $city
      termAndCondition: $termAndCondition
      heroImage: $heroImage
      optionalImages: $optionalImages
      description: $description
      descriptionSalesman: $descriptionSalesman
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      productQuantity: $productQuantity
      unitIndex: $unitIndex
      businessUnitContactId: $businessUnitContactId
      urlDeeplinkSalesman: $urlDeeplinkSalesman
      sendPushNotification: $sendPushNotification
      showPromotionInMobile: $showPromotionInMobile
      promotionCode: $promotionCode
      isGeneratePromotionCode: $isGeneratePromotionCode
    ) {
      ...PromotionFragment
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const UPDATE_PROMOTION = gql`
  mutation UpdatePromotion(
    $promotionId: ID!
    $brandId: ID!
    $productId: String
    $rangeId: String
    $promotionName: String!
    $appUser: String!
    $region: String!
    $city: String!
    $termAndCondition: String!
    $heroImage: String
    $optionalImages: [String!]!
    $description: String!
    $descriptionSalesman: String
    $liveDate: DateTime!
    $endLiveDate: DateTime!
    $productQuantity: Int
    $unitIndex: Int
    $businessUnitContactId: ID
    $urlDeeplinkSalesman: String
    $showPromotionInMobile: Boolean!
    $promotionCode: String
    $isGeneratePromotionCode: Boolean!
  ) {
    updatePromotion(
      promotionId: $promotionId
      brandId: $brandId
      productId: $productId
      rangeId: $rangeId
      promotionName: $promotionName
      appUser: $appUser
      region: $region
      city: $city
      termAndCondition: $termAndCondition
      heroImage: $heroImage
      optionalImages: $optionalImages
      description: $description
      descriptionSalesman: $descriptionSalesman
      liveDate: $liveDate
      endLiveDate: $endLiveDate
      productQuantity: $productQuantity
      unitIndex: $unitIndex
      businessUnitContactId: $businessUnitContactId
      urlDeeplinkSalesman: $urlDeeplinkSalesman
      showPromotionInMobile: $showPromotionInMobile
      promotionCode: $promotionCode
      isGeneratePromotionCode: $isGeneratePromotionCode
    ) {
      ...PromotionFragment
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const ARCHIVE_PROMOTION = gql`
  mutation ArchivePromotion($id: ID!) {
    archivePromotion(promotionId: $id) {
      success
    }
  }
`;
