import { useMutation, useQuery } from '@apollo/react-hooks';
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { View, StyleSheet, Image, ActivityIndicator } from 'react-native';

import { deleteIcon } from '../../../assets';
import {
  BrandDropdown,
  ConfirmationModal,
  Option,
  ErrorModal,
} from '../../components';
import { colors } from '../../constants/theme';
import { Text, Button, TextInput, Switch } from '../../core-ui';
import {
  AutomaticReportRequestAgentEmails,
  AutomaticReportRequestAgentEmails_automaticReportRequestAgentEmails,
} from '../../generated/AutomaticReportRequestAgentEmails';
import { Brands, BrandsVariables, Brands_brands } from '../../generated/Brands';
import {
  DeleteRequestAgentReportEmail,
  DeleteRequestAgentReportEmailVariables,
} from '../../generated/DeleteRequestAgentReportEmail';
import {
  EditRequestAgentEmail,
  EditRequestAgentEmailVariables,
} from '../../generated/EditRequestAgentEmail';
import {
  EmailRequestAgentCreateInput,
  EmailRequestAgentUpdateInput,
} from '../../generated/globalTypes';
import {
  SwitchRequestAgentEmail,
  SwitchRequestAgentEmailVariables,
} from '../../generated/SwitchRequestAgentEmail';
import {
  DELETE_REQUEST_AGENT_EMAIL,
  EDIT_REQUEST_AGENT_EMAIL,
  SWITCH_REQUEST_AGENT_EMAIL,
} from '../../graphql/mutations/requestAgentEmail';
import { BRANDS } from '../../graphql/queries';
import { GET_AUTOMATIC_REPORT_REQUEST_AGENT_EMAIL_LIST } from '../../graphql/queries/requestAgentReportEmail';
import validateEmail from '../../helpers/validateEmail';

const blankData = {
  brandId: null as string | null,
  email: '',
};

export default function RequestAgentReportEmail() {
  let [editable, setEditable] = useState(false);
  let [email, setEmail] = useState<Array<string>>([]);
  let [check, setCheck] = useState<Array<boolean>>([]);
  let [brands, setBrands] = useState<Array<Option>>([]);
  let [ids, setIds] = useState<Array<string>>([]);
  let [newData, setNewData] = useState<Array<EmailRequestAgentCreateInput>>([]);
  let [deletedId, setDeletedId] = useState('');
  let [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
  let [showErrorModal, setErrorModal] = useState(false);
  let [errorMessage, setErrorMessage] = useState('');

  const {
    data: brandData,
    loading: brandLoading,
    refetch: brandRefetch,
  } = useQuery<Brands, BrandsVariables>(BRANDS, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: requestAgentEmailData,
    loading: requestAgentEmailLoading,
    refetch: requestAgentEmailRefetch,
  } = useQuery<AutomaticReportRequestAgentEmails>(
    GET_AUTOMATIC_REPORT_REQUEST_AGENT_EMAIL_LIST,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const [editRequestAgent, { loading: editRequestAgentLoading }] = useMutation<
    EditRequestAgentEmail,
    EditRequestAgentEmailVariables
  >(EDIT_REQUEST_AGENT_EMAIL, {
    onCompleted: () => {
      brandRefetch();
      requestAgentEmailRefetch();
      setEditable(false);
      setNewData([]);
    },
  });

  const [
    deleteRequestAgent,
    { loading: deleteRequestAgentLoading },
  ] = useMutation<
    DeleteRequestAgentReportEmail,
    DeleteRequestAgentReportEmailVariables
  >(DELETE_REQUEST_AGENT_EMAIL, {
    onCompleted: () => {
      setConfirmDeleteModal(false);
      setDeletedId('');
      brandRefetch();
      requestAgentEmailRefetch();
    },
  });

  const [
    switchRequestAgent,
    { loading: switchRequestAgentLoading },
  ] = useMutation<SwitchRequestAgentEmail, SwitchRequestAgentEmailVariables>(
    SWITCH_REQUEST_AGENT_EMAIL,
    {
      onCompleted: () => {
        brandRefetch();
        requestAgentEmailRefetch();
      },
    },
  );

  const filteredBrands = useMemo(() => {
    let newBrands: Brands['brands'] = [];
    if (brandData) {
      for (let item of brandData.brands) {
        if (item.businessUnitContact != null) {
          newBrands.push(item);
        }
      }
    }
    return newBrands;
  }, [brandData]);

  const createSetValue = useCallback(
    <T extends unknown>(setter: Dispatch<SetStateAction<Array<T>>>) => (
      index: number,
    ) => (value: T) => {
      setter((prev) =>
        prev.map((prevValue, loopIndex) =>
          loopIndex === index ? value : prevValue,
        ),
      );
    },
    [],
  );

  const setBrandsValue = useMemo(() => createSetValue(setBrands), [
    createSetValue,
  ]);

  const setEmailValue = useCallback(
    (index: number) => (value: string) => {
      createSetValue(setEmail)(index)(value);
    },
    [createSetValue],
  );

  const onSave = useCallback(async () => {
    let errorFlag = false;

    let updatedData: Array<EmailRequestAgentUpdateInput> = [];
    for (let i = 0; i < email.length; i++) {
      if (!validateEmail(email[i])) {
        setErrorMessage(
          t([
            'Mohon masukkan format email yang benar',
            'Please input correct email format',
          ]),
        );
        errorFlag = true;
      }

      updatedData.push({
        email: email[i] || '',
        brandId: brands[i]?.value || '',
        id: ids[i] || '',
      });
    }

    let createdData: Array<EmailRequestAgentCreateInput> = [];
    newData.forEach((data) => {
      if (data.brandId) {
        if (!validateEmail(data.email)) {
          setErrorMessage(
            t([
              'Mohon masukkan format email yang benar',
              'Please input correct email format',
            ]),
          );
          errorFlag = true;
        }

        createdData.push(data);
      } else {
        setErrorMessage(t(['Mohon pilih brand', 'Please choose brand']));
        errorFlag = true;
      }
    });

    if (errorFlag) {
      setErrorModal(true);
      return;
    }

    editRequestAgent({
      variables: {
        emailRequestAgent: {
          update: updatedData,
          create: createdData,
        },
      },
    });
  }, [newData, editRequestAgent, email, brands, ids]);

  const addNewRow = useCallback(() => {
    setNewData([...newData, blankData as EmailRequestAgentCreateInput]);
  }, [newData]);

  const onPressDeleteNewData = (index: number) => {
    let tmpNewData = newData.filter((_, i) => i !== index);
    setNewData(tmpNewData);
  };

  const onChangeEmailNewData = (index: number, newValue: string) => {
    let tmpNewData = newData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          email: newValue,
        };
      }
      return data;
    });

    setNewData(tmpNewData);
  };

  const onChangeBrandNewData = (index: number, newValue: Option) => {
    let tmpNewData = newData.map((data, i) => {
      if (i === index) {
        return {
          ...data,
          brandId: newValue.value,
        };
      }
      return data;
    });

    setNewData(tmpNewData);
  };

  useEffect(() => {
    let initialActive: Array<boolean> = [];

    for (let { businessUnitContact } of filteredBrands) {
      initialActive.push(
        businessUnitContact?.requestAgentReportActiveSendEmail ?? false,
      );
    }

    requestAgentEmailData?.automaticReportRequestAgentEmails.forEach((data) => {
      initialActive.push(data.activeSendEmail);
    });

    setCheck(initialActive);

    if (editable) {
      let initialBrands: Array<Option> = [];
      let initialEmail: Array<string> = [];
      let initialIds: Array<string> = [];

      requestAgentEmailData?.automaticReportRequestAgentEmails.forEach(
        (data) => {
          initialBrands.push({
            label: data.brand?.name ?? '',
            value: data.brandId ?? '',
          });
          initialEmail.push(data?.email ?? '');
          initialIds.push(data.id);
        },
      );

      setBrands(initialBrands || []);
      setEmail(initialEmail || []);
      setIds(initialIds);
    }
  }, [
    setBrands,
    setIds,
    setEmail,
    editable,
    setCheck,
    requestAgentEmailData,
    filteredBrands,
  ]);

  const onCancel = useCallback(() => {
    setEditable(false);
  }, []);

  const closeErrorModal = () => {
    setErrorModal(false);
  };

  return (
    <View data-cy="request-agent-email-item" style={styles.flex}>
      <View style={styles.header}>
        <Text bold>
          {t([
            'EMAIL LAPORAN PERMINTAAN DISTRIBUTOR',
            'REQUEST AGENT REPORT EMAIL',
          ])}
        </Text>
        {editable ? (
          <View style={styles.row}>
            <Button
              data-cy="request-agent-email-save"
              preset="transparent"
              title={t(['Simpan', 'Save'])}
              isLoading={editRequestAgentLoading}
              onPress={onSave}
              // disabled={submitDisabled}
            />
            <View style={styles.separator} />
            <Button
              data-cy="request-agent-email-cancel"
              preset="transparent"
              title={t(['Batal', 'Cancel'])}
              onPress={onCancel}
            />
          </View>
        ) : (
          <Button
            data-cy="request-agent-email-edit-add"
            preset="transparent"
            title={t(['Ubah/Tambah', 'Edit/Add'])}
            onPress={() => {
              setEditable(true);
            }}
          />
        )}
      </View>

      {brandLoading ||
      requestAgentEmailLoading ||
      deleteRequestAgentLoading ||
      switchRequestAgentLoading ? (
        <ActivityIndicator />
      ) : editable ? (
        <View>
          {filteredBrands?.map((brandData: Brands_brands, index: number) => {
            return (
              <View key={index} style={styles.editable}>
                <View style={styles.brand}>
                  <BrandDropdown
                    data-cy={`bu-contact-brand-dropdown`}
                    title={t(['Cari Merek', 'Search by Brands'])}
                    selectedOption={brandData.brandId}
                    onSelect={() => {}}
                    disabled
                  />
                </View>
                <TextInput
                  data-cy="bu-contact-email"
                  value={brandData.businessUnitContact?.email ?? ''}
                  onChangeText={() => {}}
                  disabled
                  containerStyle={styles.input}
                />
                <View style={styles.iconView} />
              </View>
            );
          })}

          {requestAgentEmailData?.automaticReportRequestAgentEmails.map(
            (
              requestAgent: AutomaticReportRequestAgentEmails_automaticReportRequestAgentEmails,
              index: number,
            ) => {
              return (
                <View key={index} style={styles.editable}>
                  <View style={styles.brand}>
                    <BrandDropdown
                      data-cy={`bu-contact-brand-dropdown`}
                      title={t(['Cari Merek', 'Search by Brands'])}
                      selectedOption={brands[index]?.value}
                      onSelect={setBrandsValue(index)}
                    />
                  </View>
                  <TextInput
                    data-cy="bu-contact-email"
                    value={email[index] ?? ''}
                    onChangeText={setEmailValue(index)}
                    containerStyle={styles.input}
                  />
                  <Button
                    data-cy="bu-contact-delete"
                    title={t(['hapus', 'delete'])}
                    preset="transparent"
                    icon={<Image source={deleteIcon} style={styles.delete} />}
                    onPress={() => {
                      setDeletedId(requestAgent.id);
                      setConfirmDeleteModal(true);
                    }}
                  />
                </View>
              );
            },
          )}

          {newData.map((_, index) => {
            return (
              <View key={index} style={styles.editable}>
                <View style={styles.brand}>
                  <BrandDropdown
                    data-cy={`bu-contact-brand-dropdown`}
                    title={t(['Cari Merek', 'Search by Brands'])}
                    selectedOption={newData[index].brandId}
                    onSelect={(item) => onChangeBrandNewData(index, item)}
                  />
                </View>
                <TextInput
                  data-cy="bu-contact-email"
                  value={newData[index].email ?? ''}
                  onChangeText={(value) => onChangeEmailNewData(index, value)}
                  containerStyle={styles.input}
                />
                <Button
                  data-cy="bu-contact-delete"
                  title={t(['hapus', 'delete'])}
                  preset="transparent"
                  icon={<Image source={deleteIcon} style={styles.delete} />}
                  onPress={() => onPressDeleteNewData(index)}
                />
              </View>
            );
          })}

          <Button
            data-cy="request-agent-email-edit-add"
            preset="transparent"
            title={t(['+ Tambah Baris Baru', '+ Add New Line'])}
            onPress={addNewRow}
          />
        </View>
      ) : (
        <View>
          {filteredBrands?.map((item: Brands_brands, index: number) => {
            return (
              <View key={index} style={styles.email}>
                <View style={styles.textWrapper}>
                  <Text size="small" style={styles.text}>
                    {item.name}
                  </Text>
                </View>
                <View style={styles.textWrapper}>
                  <Text
                    data-cy="bu-contact-edited-name"
                    size="small"
                    style={styles.text}
                  >
                    {item.businessUnitContact?.email}
                  </Text>
                </View>
                <Switch
                  checked={check[index]}
                  onPress={() => {
                    switchRequestAgent({
                      variables: {
                        businessContact: true,
                        id: item.businessUnitContact?.id ?? '',
                        statusActive: !check[index],
                      },
                    });
                  }}
                />
              </View>
            );
          })}
          {requestAgentEmailData?.automaticReportRequestAgentEmails.map(
            (
              item: AutomaticReportRequestAgentEmails_automaticReportRequestAgentEmails,
              index: number,
            ) => {
              return (
                <View key={index} style={styles.email}>
                  <View style={styles.textWrapper}>
                    <Text size="small" style={styles.text}>
                      {item.brand?.name}
                    </Text>
                  </View>
                  <View style={styles.textWrapper}>
                    <Text
                      data-cy="bu-contact-edited-name"
                      size="small"
                      style={styles.text}
                    >
                      {item.email}
                    </Text>
                  </View>
                  <Switch
                    checked={check[index + filteredBrands.length]}
                    onPress={() => {
                      switchRequestAgent({
                        variables: {
                          businessContact: false,
                          id: item.id,
                          statusActive: !check[index + filteredBrands.length],
                        },
                      });
                    }}
                  />
                </View>
              );
            },
          )}
        </View>
      )}
      <ConfirmationModal
        data-cy="delete-modal"
        keepOpenAfterConfirm
        loading={false}
        open={showConfirmDeleteModal}
        onClose={() => {
          setConfirmDeleteModal(false);
        }}
        onConfirm={() => {
          deleteRequestAgent({
            variables: {
              id: deletedId,
            },
          });
        }}
        title={t([
          'Apakah anda yakin untuk menghapus? Data yang telah di hapus tidak dapat dikembalikan.',
          'Are you sure you want to delete? This action is irreversible.',
        ])}
      />

      <ErrorModal
        onClose={closeErrorModal}
        open={showErrorModal}
        message={errorMessage}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  email: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
    justifyContent: 'space-around',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  editable: {
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    marginBottom: 6,
  },
  separator: {
    height: 12,
    width: 2,
    backgroundColor: colors.button.primary.background,
    alignSelf: 'center',
    marginRight: 4,
  },
  textWrapper: { flex: 1 },
  input: { flex: 1, alignItems: 'center', marginRight: 8 },
  text: { width: '100%' },
  brand: { flex: 1, marginRight: 8 },
  row: { flexDirection: 'row', justifyContent: 'space-between' },
  delete: { width: 24, height: 24 },
  iconView: { width: 24, height: 24, marginLeft: 40 },
});
