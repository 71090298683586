import { useQuery } from '@apollo/react-hooks';
import React, { useMemo, memo, useCallback } from 'react';

import { LoyaltyMissionPoints } from '../../generated/LoyaltyMissionPoints';
import { GET_LOYALTY_POINT_MISSION } from '../../graphql/queries';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Event', 'Select Event']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
};

const MissionDropdown = memo((props: Props) => {
  let { title = defaultTitle, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<LoyaltyMissionPoints>(
    GET_LOYALTY_POINT_MISSION,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      data?.loyaltyMissionPoints.map(({ name }) => ({
        label: name,
        value: name,
      })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data mission', 'retrieve the mission data'])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default MissionDropdown;
