import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_COMPETITIONS } from '../../graphql/queries';
import {
  Competitions,
  CompetitionsVariables,
} from '../../generated/Competitions';
import { ArchiveStatus } from '../../generated/globalTypes';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Kompetisi', 'Select Competition']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  brandId?: string;
  titleIfEmpty?: string;
};

const CompetitionsDropdown = memo((props: Props) => {
  let { title = defaultTitle, brandId, titleIfEmpty, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<
    Competitions,
    CompetitionsVariables
  >(GET_COMPETITIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      brandId,
      archived: ArchiveStatus.NORMAL,
    },
  });

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      data?.competitionAdvanceSearch.row.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [data],
  );

  const dropdownTitle = useMemo(() => {
    return options.length === 0 && !!titleIfEmpty ? titleIfEmpty : title;
  }, [options, title, titleIfEmpty]);

  return (
    <Dropdown
      disabled={!!titleIfEmpty && options.length === 0}
      error={error}
      errorAction={t([
        'mengambil data kompetisi',
        'retrieve the competition data',
      ])}
      onRetryPress={refetchData}
      options={options}
      title={dropdownTitle}
      loading={loading}
      {...otherProps}
    />
  );
});

export default CompetitionsDropdown;
