export type Order = 'asc' | 'desc';

export const ascendingDateComparator = (a: Date, b: Date) => {
  return a.valueOf() - b.valueOf();
};

export const ascendingComparator = <T extends { [key: string]: unknown }>(
  a: T,
  b: T,
  orderBy: keyof T | string,
) => {
  let itemA = a[orderBy];
  let itemB = b[orderBy];
  if (itemA instanceof Date && itemB instanceof Date) {
    return ascendingDateComparator(itemA, itemB);
  } else {
    if (itemA > itemB) {
      return 1;
    } else if (itemA < itemB) {
      return -1;
    } else {
      return 0;
    }
  }
};

const getComparator = <T extends { [key: string]: unknown }>(
  order: Order,
  orderBy: keyof T | string,
) => {
  if (order === 'asc') {
    return (a: T, b: T) => ascendingComparator(a, b, orderBy);
  } else {
    return (a: T, b: T) => -ascendingComparator(a, b, orderBy);
  }
};

export default getComparator;
