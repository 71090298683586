import React, { useState, useCallback, useMemo } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { useDebounce } from 'use-debounce';
import { useHistory } from 'react-router-dom';

import {
  Text,
  TextInput,
  Filter,
  FilterModal,
  filterModalDefaultValue,
} from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { ErrorMessage } from '../../components';
import { useSnackbar } from '../../context';
import { VERIFICATION_REQUESTS } from '../../graphql/queries';
import { CLAIM_VERIFICATION_REQUEST } from '../../graphql/mutations';
import {
  VerificationRequests,
  VerificationRequestsVariables,
  VerificationRequests_requestVerificationAdvance_row,
} from '../../../../generated/VerificationRequests';
import {
  ClaimVerificationRequest,
  ClaimVerificationRequestVariables,
} from '../../../../generated/ClaimVerificationRequest';
import {
  OrderByArg,
  RequestVerificationOrderByType,
  VerificationClaimType,
} from '../../../../generated/globalTypes';
import { useGetNetworkerror, useTableSort } from '../../../../hooks';

import QueueTable from './QueueTable';

const QueueScene = () => {
  const history = useHistory();
  const { openSnackbar, closeSnackbar } = useSnackbar();
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const [filterPlaceholder, setFilterPlaceholder] = useState('');
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, , callPending] = useDebounce(searchText, 500);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [
    claimingRequest,
    setClaimingRequest,
  ] = useState<VerificationRequests_requestVerificationAdvance_row | null>(
    null,
  );
  const [resetFilterToggle, setResetFilterToggle] = useState(false);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    RequestVerificationOrderByType
  >();

  const [filterVariables, setFilterVariables] = useState<
    VerificationRequestsVariables
  >({});

  let filterMemo = useMemo(
    () => ({
      ...filterVariables,
      searchName: debouncedSearchText,
      pagination: {
        skip: page * rowsPerPage,
        first: rowsPerPage,
      },
      orderBy: orderByVariable ?? {
        order: OrderByArg.desc,
        type: RequestVerificationOrderByType.updatedAt,
      },
    }),
    [page, rowsPerPage, filterVariables, debouncedSearchText, orderByVariable],
  );

  const { data, loading, error, refetch } = useQuery<
    VerificationRequests,
    VerificationRequestsVariables
  >(VERIFICATION_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    variables: filterMemo,
  });

  const refetchData = useCallback(
    (skip?: number) => {
      let filterCopy = filterMemo;

      if (skip) {
        filterCopy.pagination.skip = skip;
      }

      const asyncRefetch = async () => {
        await refetch(filterCopy);
      };
      asyncRefetch();
    },
    [filterMemo, refetch],
  );

  const onClaimCompleted = useCallback(() => {
    if (claimingRequest) {
      const { type, store, user } = claimingRequest;
      refetchData();
      openSnackbar({
        message: (
          <>
            <Text bold color="white">
              {type === 'TOKO'
                ? store?.storeManagerBulk?.storeName
                : user?.profile?.name}
            </Text>
            <Text color="white" data-cy="snackbar-message">
              {' '}
              {t(['dipindahkan ke', 'moved to'])}{' '}
            </Text>
            <Text bold color="white">
              {t(['Sedang Dikerjakan', 'Work in Progress'])}
            </Text>
          </>
        ),
        variant: 'success',
        buttonTitle: t(['Lihat', 'View']),
        onButtonPress: () => {
          closeSnackbar();
          history.push({ pathname: '/wip', state: { data: claimingRequest } });
        },
      });
    }
  }, [claimingRequest, history, openSnackbar, closeSnackbar, refetchData]);

  const onClaimError = useCallback(
    (error: ApolloError) => {
      if (
        error.graphQLErrors[0]?.message ===
        'Request verification is already claimed'
      ) {
        openSnackbar({
          message: t([
            'Permohonan verifikasi telah diklaim admin lain. Mohon pilih permohonan verifikasi lain.',
            'Verification request has been claimed by other admin. Please select other verification request.',
          ]),
          variant: 'error',
        });
      } else {
        openSnackbar({
          message: t([
            'Gagal klaim permohonan verifikasi. Mohon pilih permohonan verifikasi lain.',
            'Failed to claim verification request. Please select other verification request.',
          ]),
          variant: 'error',
        });
      }
    },
    [openSnackbar],
  );

  const [claim, { loading: claimLoading }] = useMutation<
    ClaimVerificationRequest,
    ClaimVerificationRequestVariables
  >(CLAIM_VERIFICATION_REQUEST, {
    onCompleted: onClaimCompleted,
    onError: onClaimError,
  });

  const onClaimPress = useCallback(
    (data: VerificationRequests_requestVerificationAdvance_row) => {
      setClaimingRequest(data);
      claim({ variables: { type: VerificationClaimType.CLAIM, id: data.id } });
    },
    [claim],
  );

  const onFilterChange = useCallback(
    (variables: VerificationRequestsVariables, action: 'set' | 'reset') => {
      setFilterVariables({ ...filterVariables, ...variables });
      if (action === 'set') {
        setSearchText('');
        callPending();
        setPage(0);
      } else if (searchText === '') {
        // NOTE: if reset is done when search text is empty, assume an existing filter is reset so need to reset page too
        setPage(0);
      }
    },
    [searchText, callPending, filterVariables],
  );

  const onSearchChange = useCallback(
    (text: string) => {
      setPage(0);
      setSearchText(text);
      setFilterVariables(filterModalDefaultValue);
      setResetFilterToggle(!resetFilterToggle);
    },
    [resetFilterToggle],
  );

  const rightNode = useMemo(
    () => <SearchIcon htmlColor={colors.icon.grey} />,
    [],
  );
  const openFilterModal = useCallback(() => setFilterModalOpen(true), []);
  const closeFilterModal = useCallback(() => setFilterModalOpen(false), []);

  let isError = useGetNetworkerror({
    error,
  });

  if (isError) {
    return (
      <View style={styles.errorRoot}>
        <ErrorMessage onPress={refetchData} error={error} />
      </View>
    );
  }

  return (
    <ScrollView contentContainerStyle={styles.root}>
      <FilterModal
        open={filterModalOpen}
        setPlaceholder={setFilterPlaceholder}
        setFilter={onFilterChange}
        onClose={closeFilterModal}
        resetToggle={resetFilterToggle}
      />
      <View style={styles.header}>
        <View style={styles.title}>
          <Text bold size="xl">
            {t(['Daftar Data', 'List of Data'])}
          </Text>
          <View style={styles.countPill}>
            <Text color="white">
              {t(['{count} data', '{count} items'], {
                count: data?.requestVerificationAdvance.total ?? 0,
              })}
            </Text>
          </View>
        </View>
        <TextInput
          data-cy="queue-search-name"
          value={searchText}
          onChangeText={onSearchChange}
          placeholder={t(['Cari Nama', 'Search by Name'])}
          rightNode={rightNode}
        />
        <Filter
          data-cy="filter-button"
          placeholder={filterPlaceholder}
          style={styles.filterButton}
          onPress={openFilterModal}
        />
      </View>
      <QueueTable
        loading={loading}
        claimLoading={claimLoading}
        claimingRequestID={claimingRequest?.id ?? null}
        data={data?.requestVerificationAdvance.row ?? []}
        dataCount={data?.requestVerificationAdvance.total ?? 0}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        onClaimPress={onClaimPress}
        refetchData={refetchData}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: colors.background.grey,
    paddingHorizontal: spacing.xxl,
    paddingVertical: spacing.l,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: spacing.m,
  },
  title: { flexDirection: 'row', alignItems: 'center' },
  countPill: {
    backgroundColor: colors.button.background.primary,
    paddingVertical: 2,
    paddingHorizontal: 6,
    borderRadius: 10,
    marginLeft: spacing.xxs,
  },
  filterButton: { width: 200 },
  errorRoot: {
    flex: 1,
    backgroundColor: colors.background.grey,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default QueueScene;
