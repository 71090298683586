import React from 'react';

import { AuthenticatedRoute, UnauthenticatedRoute } from '../routes';
import { useAuth } from '../context/AuthContext';

export default function App() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <AuthenticatedRoute /> : <UnauthenticatedRoute />;
}
