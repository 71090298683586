import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';

import {
  UsersDropdown,
  DateTimePicker,
  RegionDropdown,
  CityDropdown,
  Header,
  Option,
  ErrorModal,
} from '../../components';
import PopUpMessageDropdown from '../../components/dropdowns/PopUpMessageDropdown';
import MultiLevelDropdown from '../../components/dropdowns/MultiLevelDropdown';
import { Text, Button } from '../../core-ui';
import { spacing } from '../../constants/theme';
import { UPDATE_POP_UP } from '../../graphql/mutations';
import { UpdatePopUp, UpdatePopUpVariables } from '../../generated/UpdatePopUp';
import { BRANDS, GET_SCREENS } from '../../graphql/queries';
import { Brands, BrandsVariables } from '../../generated/Brands';
import { GET_RANGES } from '../../graphql/queries/product';
import { Ranges, RangesVariables } from '../../generated/Ranges';
import { Screens, ScreensVariables } from '../../generated/Screens';
import { AccessScreen, LinkTypePopUp } from '../../generated/globalTypes';

type Props = {
  refetchToggle: boolean;
  onSubmitComplete: () => void;
};

export default function AddNewPopUpMessages(props: Props) {
  let { refetchToggle, onSubmitComplete } = props;
  let [selectedUser, setSelectedUser] = useState<string | null>(null);
  let [selectedCity, setSelectedCity] = useState<string | null>(null);
  let [cityName, setCityName] = useState<string | null>(null);
  let [selectedRegion, setSelectedRegion] = useState<string | null>(null); // NOTE: ID of the region, only used to get City dropdown options
  let [regionName, setRegionName] = useState<string | null>(null); // NOTE: name of the region
  let [selectedPage, setSelectedPage] = useState<Option | null>(null);
  let [selectedMessage, setSelectedMessage] = useState<Option | null>(null);
  let [linkType, setLinkType] = useState<LinkTypePopUp | null>(null);
  let [startDate, setStartDate] = useState<Date | null>(null);
  let [endDate, setEndDate] = useState<Date | null>(null);
  let [pageOptions, setPageOptions] = useState<Array<Option>>([]);
  let [pageMultiLevelValue, setPageMultilevelValue] = useState<
    Array<string> | undefined
  >();
  let [selectedMultiLevelOptions, setSelectedMultiLevelOptions] = useState<
    Array<Option>
  >([]);
  const [editable, setEditable] = useState(true);
  const [errorOpen, setErrorOpen] = useState(false);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  const {
    data: screenData,
    loading: screenLoading,
    error: screenError,
    refetch: screenRefetch,
  } = useQuery<Screens, ScreensVariables>(GET_SCREENS, {
    variables: { where: { type: AccessScreen.CONDITION } },
    notifyOnNetworkStatusChange: true,
  });
  const [
    updatePopUp,
    { loading: updateLoading, error: updateError },
  ] = useMutation<UpdatePopUp, UpdatePopUpVariables>(UPDATE_POP_UP, {
    onCompleted: onSubmitComplete,
    onError: openErrorModal,
  });

  const [
    getRange,
    { data: rangeData, loading: rangeLoading, error: rangeError },
  ] = useLazyQuery<Ranges, RangesVariables>(GET_RANGES, {
    variables: {
      brandId: pageMultiLevelValue?.[1] || '',
    },
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: brandData,
    loading: brandLoading,
    error: brandError,
    refetch: brandRefetch,
  } = useQuery<Brands, BrandsVariables>(BRANDS, {
    notifyOnNetworkStatusChange: true,
  });

  let brandOptions = useMemo(
    () =>
      brandData &&
      brandData.brands.map((brand) => {
        return { label: brand.name, value: brand.brandId, multiLevelValue: [] };
      }),
    [brandData],
  );

  useEffect(() => {
    let filteredScreen = [];
    if (screenData) {
      let editedOptions: Array<Option> = [];
      let screenArray = screenData.screens;
      let nullParent = screenArray?.filter(
        (item) => item && item.parent == null,
      );
      filteredScreen.push(nullParent);
      let hasParent = screenArray?.filter(
        (item) => item && item.parent != null,
      );
      filteredScreen.push(hasParent);

      for (let screen of screenArray) {
        if (screen) {
          if (screen.parent == null) {
            let existed = editedOptions.find(
              (item) => screen?.name === item.label,
            );
            if (!existed) {
              editedOptions.push({ value: screen.id, label: screen.name });
            }
          } else {
            let existedAtZeroLevel = editedOptions.find(
              (item) => screen?.parent === item.value,
            );
            let myOrderScene = editedOptions.find((item) =>
              item.label.toLowerCase().includes('order'),
            );
            if (existedAtZeroLevel) {
              existedAtZeroLevel = {
                ...existedAtZeroLevel,
                multiLevelValue: [
                  ...existedAtZeroLevel.multiLevelValue,
                  { label: screen.name, value: screen.id },
                ],
              };
              let newEditedOptions = [];
              for (let opt of editedOptions) {
                if (opt.value !== existedAtZeroLevel.value) {
                  newEditedOptions.push(opt);
                }
              }
              editedOptions = [...newEditedOptions, existedAtZeroLevel];
            } else {
              editedOptions = [
                ...editedOptions,
                {
                  label: screen.parent.replace(/([A-Z]{1,3})/g, ' $1').trim(),
                  value: screen.parent,
                  multiLevelValue: [{ label: screen.name, value: screen.id }],
                },
              ];
            }

            if (myOrderScene) {
              let orderOption = editedOptions.find((item) =>
                item.label.toLowerCase().includes('order'),
              );
              if (orderOption) {
                if (!orderOption.multiLevelValue) {
                  let newEditedOptions = [];
                  for (let opt of editedOptions) {
                    if (opt.value !== orderOption.value) {
                      newEditedOptions.push(opt);
                    }
                  }
                  editedOptions = [
                    ...newEditedOptions,
                    {
                      ...orderOption,
                      multiLevelValue: brandOptions,
                    },
                  ];
                }
              }
            }
          }
        }
      }
      setPageOptions(editedOptions);
    }
  }, [screenData, brandOptions]);

  useEffect(() => {
    let editedOptions = [...pageOptions];
    let orderOption = editedOptions.find(({ value }) => value === '12');
    if (
      pageMultiLevelValue?.[0] === '12' &&
      orderOption?.multiLevelValue &&
      rangeData &&
      !rangeLoading
    ) {
      let editBrandOption = orderOption.multiLevelValue.find(
        ({ value }) => pageMultiLevelValue?.[1] === value,
      );
      if (editBrandOption) {
        if (
          editBrandOption.multiLevelValue &&
          editBrandOption.multiLevelValue.length > 0
        ) {
          return;
        }
        editBrandOption = {
          ...editBrandOption,
          multiLevelValue: rangeData.ranges.map(({ id, name }) => ({
            label: name,
            value: id,
          })),
        };

        let updatedBrandIndex = orderOption.multiLevelValue.findIndex(
          (multiOpt) => editBrandOption?.value === multiOpt.value,
        );
        if (updatedBrandIndex !== -1) {
          orderOption.multiLevelValue.splice(
            updatedBrandIndex,
            1,
            editBrandOption,
          );
        }

        let updatedPageIndex = editedOptions.findIndex(
          (opt) => orderOption?.value === opt.value,
        );
        if (updatedPageIndex !== -1) {
          editedOptions.splice(updatedPageIndex, 1, orderOption);
        }

        setPageOptions(editedOptions);
      }
    }
  }, [pageOptions, rangeData, rangeLoading, pageMultiLevelValue]);

  const onSelectPage = (option: Option, level: number) => {
    let newSelectedMultiLevelOptions = [...selectedMultiLevelOptions];
    if (level === 1) {
      if (newSelectedMultiLevelOptions) {
        if (newSelectedMultiLevelOptions.length < 1) {
          newSelectedMultiLevelOptions.push(option);
        } else {
          newSelectedMultiLevelOptions.splice(0, 1, option);
        }
        setSelectedMultiLevelOptions(newSelectedMultiLevelOptions);
      }
    }
    if (level === 2) {
      if (newSelectedMultiLevelOptions) {
        if (newSelectedMultiLevelOptions.length < 2) {
          newSelectedMultiLevelOptions.push(
            ...newSelectedMultiLevelOptions,
            option,
          );
        } else {
          newSelectedMultiLevelOptions.splice(1, 1, option);
        }
        setSelectedMultiLevelOptions(newSelectedMultiLevelOptions);
      }
    }
    if (
      newSelectedMultiLevelOptions &&
      newSelectedMultiLevelOptions[0].label.toLowerCase() === 'my order' &&
      level === 2
    ) {
      getRange({ variables: { brandId: option.value } });
    }
    setSelectedPage(option);
  };
  const onSelectMessage = (item: Option, linkType: LinkTypePopUp | null) => {
    setSelectedMessage(item);
    setLinkType(linkType);
  };
  const createSetSelected = useCallback(
    (
      setter: (value: string) => void,
      labelSetter?: (value: string) => void,
      ...nullSetters: Array<(value: null) => void>
    ) => (selected: Option) => {
      setter(selected.value);
      labelSetter?.(selected.label);
      for (let setNull of nullSetters) {
        setNull(null);
      }
    },
    [],
  );
  const onSubmit = () => {
    updatePopUp({
      variables: {
        popUpId: (selectedMessage && selectedMessage.value) || '',
        popUpName: (selectedMessage && selectedMessage.label) || '',
        linkTypePopUp: linkType,
        who: selectedUser,
        city: cityName,
        region: regionName,
        pageId: selectedPage && selectedPage.value,
        startDate,
        endDate,
        conditionBrandId:
          pageMultiLevelValue?.[0] === '12' ? pageMultiLevelValue[1] : null,
        conditionRangeId:
          pageMultiLevelValue?.[0] === '12' ? pageMultiLevelValue[2] : null,
      },
    });
  };

  const onEdit = useCallback(() => setEditable(true), []);
  const onCancel = useCallback(() => setEditable(false), []);

  const submitDisabled = useMemo(
    () =>
      !selectedPage ||
      !selectedMessage ||
      !selectedRegion ||
      !selectedUser ||
      !cityName ||
      !startDate ||
      !endDate,
    [
      selectedPage,
      selectedMessage,
      selectedRegion,
      selectedUser,
      cityName,
      startDate,
      endDate,
    ],
  );

  const refetchScreenData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await screenRefetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [screenRefetch]);
  const refetchBrandData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await brandRefetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [brandRefetch]);

  const multiLevelError = useMemo(
    () => [
      screenError,
      selectedMultiLevelOptions[0]?.label.toLowerCase() === 'my order'
        ? brandError
        : undefined,
      selectedMultiLevelOptions[0]?.label.toLowerCase() === 'my order'
        ? rangeError
        : undefined,
    ],
    [screenError, brandError, rangeError, selectedMultiLevelOptions],
  );
  const multiLevelErrorAction = useMemo(
    () => [
      t(['mengambil data halaman toko app', 'retrieve the toko app page data']),
      t(['mengambil data brand', 'retrieve the brand data']),
      t(['mengambil data range', 'retrieve the range data']),
    ],
    [],
  );
  const multiLevelOnRetryPress = useMemo(
    () => [
      refetchScreenData,
      refetchBrandData,
      () =>
        getRange({
          variables: { brandId: selectedMultiLevelOptions[1]?.value },
        }),
    ],
    [refetchScreenData, getRange, refetchBrandData, selectedMultiLevelOptions],
  );

  return (
    <>
      <ErrorModal
        open={errorOpen}
        action={t(['membuat pengaturan pop up', 'create pop up settings'])}
        error={updateError}
        onClose={closeErrorModal}
      />
      <Header
        withSeparator
        title={t(['KONTEN TOKO APP', 'TOKO APP CONTENT'])}
        titleSize="default"
        infotipContent={t([
          'Atur target audience dari PopUp yang sudah di buat diatas. Harap membuat PopUp baru terlebih dahulu sebelum berada di tahap ini.',
          'Set the target audience of PopUp that has been created above. Please create a new PopUp first before being at this stage.',
        ])}
      />
      <View style={styles.container}>
        <View style={styles.flexFive}>
          <View style={styles.row}>
            <View style={styles.flex}>
              <Text bold style={styles.title}>
                {t(['PESAN', 'MESSAGE'])}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text bold style={styles.title}>
                {t(['SIAPA?', 'WHO?'])}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text bold style={styles.title}>
                {t(['PROVINSI', 'REGION'])}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text bold style={styles.title}>
                {t(['KOTA', 'CITIES'])}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text bold style={styles.title}>
                {t(['HALAMAN', 'PAGE'])}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.flex}>
              <PopUpMessageDropdown
                data-cy="popup-assign-name"
                type="basic"
                title={t(['Pilih Pesan', 'Select Message'])}
                selectedOption={selectedMessage?.value}
                refetchToggle={refetchToggle}
                onSelectMessage={onSelectMessage}
                disabled={!editable}
              />
            </View>
            <View style={styles.flex}>
              <UsersDropdown
                data-cy="popup-assign-user"
                type="basic"
                title={t(['Cari Pengguna', 'Search by Users'])}
                selectedOption={selectedUser ?? undefined}
                onSelect={createSetSelected(setSelectedUser)}
                disabled={!editable}
              />
            </View>
            <View style={styles.flex}>
              <RegionDropdown
                data-cy="popup-assign-region"
                dataKey="label"
                getSelectedValues={(ids) => setSelectedRegion(ids[0])}
                selectedOption={regionName ?? undefined}
                type="basic"
                onSelect={createSetSelected(
                  setSelectedRegion,
                  setRegionName,
                  setSelectedCity,
                  setCityName,
                )}
                disabled={!editable}
              />
            </View>
            <View style={styles.flex}>
              <CityDropdown
                data-cy="popup-assign-city"
                type="basic"
                provincesID={selectedRegion ? [selectedRegion] : []}
                title={t(['Cari Kota', 'Search by City'])}
                selectedOption={selectedCity ?? undefined}
                onSelect={createSetSelected(setSelectedCity, setCityName)}
                disabled={!editable}
              />
            </View>
            <View style={styles.flex}>
              <MultiLevelDropdown
                data-cy="popup-assign-screen"
                multiLevelError={multiLevelError}
                multiLevelErrorAction={multiLevelErrorAction}
                multiLevelOnRetryPress={multiLevelOnRetryPress}
                dataKey="label"
                title={t(['Pilih Halaman', 'Select Page'])}
                options={pageOptions}
                selectedMultiLevelValue={pageMultiLevelValue}
                type="basic"
                setSelectedMultiLevelValue={setPageMultilevelValue}
                selectedOption={selectedPage?.label}
                onSelect={onSelectPage}
                loading={screenLoading}
                levelOneLoading={brandLoading}
                levelTwoLoading={rangeLoading}
                disabled={!editable}
              />
            </View>
          </View>
        </View>
        <View style={styles.rightColumn}>
          <Text bold style={styles.title}>
            {t(['TANGGAL MULAI', 'START DATE'])}
          </Text>
          <DateTimePicker
            data-cy="popup-assign-start"
            withoutLabel
            type="datetime"
            selectedDate={startDate}
            onChange={setStartDate}
            disablePast={false}
            disabled={!editable}
          />
          <Text bold style={styles.startDate}>
            {t(['TANGGAL BERAKHIR', 'END DATE'])}
          </Text>
          <DateTimePicker
            data-cy="popup-assign-end"
            withoutLabel
            type="datetime"
            selectedDate={endDate}
            onChange={setEndDate}
            disablePast={false}
            disabled={!editable}
          />
          <View style={styles.buttonWrapper}>
            <Button
              data-cy="popup-assign-submit"
              isLoading={updateLoading}
              disabled={submitDisabled || !editable}
              onPress={onSubmit}
              title={t(['Simpan', 'Submit'])}
              style={styles.button}
            />
            <Button
              data-cy="popup-assign-cancel"
              preset="secondary"
              onPress={editable ? onCancel : onEdit}
              title={editable ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit'])}
              style={styles.button}
            />
          </View>
        </View>
      </View>
    </>
  );
}

let styles = StyleSheet.create({
  flex: { flex: 1, marginRight: spacing.xsmall },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: 26,
  },
  title: { marginBottom: spacing.small },
  row: { flexDirection: 'row', justifyContent: 'space-between' },
  padding: { padding: spacing.small },
  button: { marginTop: spacing.small },
  rightColumn: { flex: 1, alignItems: 'flex-start' },
  startDate: { marginVertical: spacing.small },
  flexFive: { flex: 5 },
});
