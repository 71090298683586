import React, { useCallback, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMediaQuery } from '@material-ui/core';

import { Button, Text, TextInput } from '../../core-ui';
import { spacing, colors } from '../../constants/theme';
import { Option, DistributorDropdown } from '../../components';
import { AgentRequestByToken } from '../../generated/AgentRequestByToken';

import RejectReasonModal from './RejectReasonModal';

export type ReviewAgentApplicationProps = {
  data?: AgentRequestByToken;
  inputValue?: string;
  inputPlaceholder: string;
  suggestions?: Array<Option>; // NOTE: basically, suggestions are dropdown options but under a text input
  showSuggestions?: boolean;
  suggestionsLoading?: boolean;
  selectedDistributor?: Option;
  rejectReason: string;
  submitLoading?: boolean;
  submitDisabled?: boolean;
  onSelectDistributor?: (value: Option) => void;
  onSetRejectReason: (value: string) => void;
  onSelectSuggestion?: (value: Option) => void;
  onInputChange?: (value: string) => void;
  onSubmit: () => void;
  onReject: () => void;
};

const ReviewAgentApplication = (props: ReviewAgentApplicationProps) => {
  let {
    data,
    inputValue,
    inputPlaceholder,
    suggestions,
    showSuggestions,
    suggestionsLoading,
    selectedDistributor,
    rejectReason,
    submitLoading,
    submitDisabled,
    onSelectDistributor,
    onSetRejectReason,
    onSelectSuggestion,
    onInputChange,
    onSubmit,
    onReject,
  } = props;

  const [rejectReasonModalOpen, setRejectReasonModalOpen] = useState(false);

  const openRejectReasonModal = useCallback(
    () => setRejectReasonModalOpen(true),
    [],
  );
  const closeRejectReasonModal = useCallback(
    () => setRejectReasonModalOpen(false),
    [],
  );

  const isDesktop = useMediaQuery('(min-device-width: 1224px)');

  const [isReject, setIsReject] = useState(false);

  //NOTE: commenting this for now as storeCategoryName is not enum type anymore
  /*
  const getStoreCategory = useCallback((category) => {
    switch (category) {
      case 'BUILDING_MATERIAL_STORE':
        return t(['Toko Bahan Bangunan', 'Building Material Store']);
      case 'MODERN_BUILDING_MATERIAL_STORE':
        return t([
          'Toko Bahan Bangunan Modern',
          'Moden Building Material Store',
        ]);
      case 'SEMI_BUILDING_MATERIAL_STORE':
        return t(['Semi Toko Bahan Bangunan', 'Semi Building Material Store']);
      case 'PLUMBING_STORE':
        return t(['Toko Pipa', 'Plumbing Store']);
      case 'CUSTOMER':
        return t(['Pelanggan', 'Customer']);
      case 'CONTRACTOR':
        return t(['Kontraktor', 'Contractor']);
      case 'PROJECT':
        return t(['Proyek', 'Project']);
      case 'DEVELOPER':
        return t(['Pengembang', 'Developer']);
      case 'OTHER':
        return t(['Lainnya', 'Other']);
    }
  }, []);
  */
  return (
    <View style={isDesktop ? styles.root : styles.mobileRoot}>
      {!!data && (
        <>
          <View style={styles.rowCenterJustified}>
            <View style={isDesktop ? styles.content : styles.flex}>
              <View style={styles.column}>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Pengajuan untuk merek', 'Application for brand'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent.brand.name}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Tanggal Pengajuan', 'Application Date'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent.createdAt}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Nama Toko', 'Toko Name'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.storeName}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Alamat Email', 'Email'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.storeEmail}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Nomor Telepon Toko', 'Toko Phone Number'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.storePhoneNumber}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Yang Mengajukan', 'Requested by'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data?.requestAgent?.user?.profile?.name ?? ''}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Nomor Telepon User', 'User Phone Number'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data?.requestAgent?.user?.phoneNumber ?? ''}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['ID Toko', 'Toko ID'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.managerStoreId}
                  </Text>
                </View>
              </View>
              <View style={styles.column}>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Alamat', 'Address'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.address}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Provinsi', 'Province'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.province}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Kota', 'City'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.city}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Kabupaten', 'Kapubaten (district/regency)'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.district}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Kelurahan', 'Kelurahan (urban village)'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.village}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Kode Pos', 'Postal Code'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.postalCode}
                  </Text>
                </View>
                <View style={styles.field}>
                  <Text bold style={styles.label}>
                    {t(['Kategori Toko', 'Store Category'])}
                  </Text>
                  <Text bold color="link" style={styles.value}>
                    {data.requestAgent?.store.storeManager?.storeCategoryName}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.rowCenterJustified, styles.centerAligned]}>
            <View style={styles.footer}>
              <View style={styles.field}>
                <Text style={styles.label}>
                  {t(['Kode dan Nama Salesman', 'Salesman Code and Name'])}
                </Text>
                <Text style={styles.value}>
                  {data.requestAgent.salesmanInfo?.salesmanName} -
                  {data.requestAgent.salesmanInfo?.salesmanReferralCode}
                </Text>
              </View>

              <View style={styles.field}>
                <Text style={styles.label}>
                  {t(['Asal Distributor', 'Origin Distributors'])}
                </Text>
                <Text style={styles.value}>
                  {data.requestAgent.salesmanInfo?.agentName || '-'}
                </Text>
              </View>

              <View style={styles.field}>
                <Text style={styles.label}>
                  {t([
                    'Pengajuan untuk Distributor',
                    'Requested for Distributor',
                  ])}
                </Text>
                <Text bold color="link" style={styles.value}>
                  {/** NOTE: toko may not nominate any agents at all */}
                  {data.requestAgent.agentName ??
                    t(['Ditentukan oleh Principal', 'Determined by Principal'])}
                </Text>
              </View>
              <View
                style={isDesktop ? styles.field : { flexDirection: 'column' }}
              >
                <Text
                  style={[styles.label, !isDesktop && styles.bottomSpacing]}
                >
                  {inputPlaceholder}
                </Text>
                <View
                  style={[styles.value, !isDesktop && styles.bottomSpacing]}
                >
                  {onSelectDistributor && (
                    <DistributorDropdown
                      searchable
                      brandId={data.requestAgent.brand.brandId}
                      selectedOption={selectedDistributor?.value}
                      onSelect={onSelectDistributor}
                      type="basic"
                    />
                  )}
                  {inputValue && onInputChange && (
                    <TextInput
                      value={inputValue}
                      suggestions={suggestions}
                      showSuggestions={showSuggestions}
                      suggestionsLoading={suggestionsLoading}
                      onSelectSuggestion={onSelectSuggestion}
                      placeholder={inputPlaceholder}
                      onChangeText={onInputChange}
                    />
                  )}
                </View>
              </View>
              <View style={isDesktop && styles.spaceBetween}>
                <Text
                  italic
                  size="small"
                  style={!isDesktop && styles.bottomSpacing}
                >
                  {t([
                    'Mohon setujui permohonan distributor ini & teruskan hasil ke',
                    'Please approve this Distributor application & forward results to',
                  ])}{' '}
                  <Text bold size="small">
                    sales@dbo.id{/** TODO: make sure email is constant */}
                  </Text>
                </Text>
                <View style={styles.field}>
                  <View
                    style={isDesktop ? styles.buttonContainer : styles.flex}
                  >
                    <Button
                      title={t(['Kirim', 'Submit'])}
                      onPress={() => {
                        setIsReject(false);
                        onSubmit();
                      }}
                      isLoading={!isReject ? submitLoading : false}
                      disabled={submitDisabled}
                    />
                  </View>
                </View>
              </View>
            </View>
            <Button
              title="Reject"
              style={styles.button}
              onPress={openRejectReasonModal}
              isLoading={isReject ? submitLoading : false}
            />
          </View>
        </>
      )}
      <RejectReasonModal
        open={rejectReasonModalOpen}
        rejectReason={rejectReason}
        onSetRejectReason={onSetRejectReason}
        onClose={closeRejectReasonModal}
        onPressSubmit={() => {
          setIsReject(true);
          onReject();
          closeRejectReasonModal();
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  mobileRoot: { padding: spacing.medium },
  content: { flexDirection: 'row', flex: 1, maxWidth: 1000 },
  column: { flex: 1, maxWidth: 500, paddingBottom: spacing.small },
  flex: { flex: 1 },
  label: { flex: 3, paddingRight: spacing.xsmall, flexWrap: 'nowrap' },
  value: { flex: 5 },
  field: { flexDirection: 'row', paddingBottom: spacing.small },
  footer: {
    flex: 1,
    maxWidth: 600,
    borderWidth: 1,
    borderColor: colors.border.primary,
    padding: spacing.medium,
  },
  bottomSpacing: { paddingBottom: spacing.xsmall },
  buttonContainer: { paddingLeft: spacing.xsmall },
  button: {
    backgroundColor: colors.textInput.errorBorder,
    marginLeft: 100,
  },
  centerAligned: {
    alignItems: 'center',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowCenterJustified: { flexDirection: 'row', justifyContent: 'center' },
});

export default ReviewAgentApplication;
