import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_NEWS } from '../../graphql/queries';
import { News, NewsVariables } from '../../generated/News';
import { ArchiveStatus } from '../../generated/globalTypes';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Berita', 'Select News']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
};

const NewsDropdown = memo((props: Props) => {
  let { title = defaultTitle, ...otherProps } = props;

  const { data, loading, error, refetch } = useQuery<News, NewsVariables>(
    GET_NEWS,
    {
      notifyOnNetworkStatusChange: true,
      variables: { archiveStatus: ArchiveStatus.NORMAL },
    },
  );

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(
    () =>
      data?.newsAdvanceSearch.row.map(({ id, title }) => ({
        label: title,
        value: id,
      })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data berita', 'retrieve the news data'])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default NewsDropdown;
