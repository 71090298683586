import React, { useMemo, useCallback, memo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GetListGift, GetListGiftVariables } from '../../generated/GetListGift';
import { GET_LIST_GIFT } from '../../graphql/queries';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Cat', 'Select Brand']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  categoryId: string | null;
};

const LoyaltySubCategoryDropdown = memo((props: Props) => {
  let {
    title = defaultTitle,
    selectedOption,
    categoryId,
    ...otherProps
  } = props;

  const [getSubCategory, { data, loading, error }] = useLazyQuery<
    GetListGift,
    GetListGiftVariables
  >(GET_LIST_GIFT, {
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(() => {
    if (categoryId) {
      getSubCategory({ variables: { categoryId } });
    }
  }, [categoryId, getSubCategory]);

  useEffect(refetchData, [categoryId, refetchData]);

  const options = useMemo(
    () =>
      data?.listGift.map(({ name, variantId }) => ({
        label: name,
        value: variantId,
      })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t([
        'mengambil data sub kategori loyalty',
        'retrieve the loyalty subcategory data',
      ])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      selectedOption={selectedOption}
      {...otherProps}
    />
  );
});

export default LoyaltySubCategoryDropdown;
