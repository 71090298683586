import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment UserFragment on UserRequestVerificationStoreBulk {
    id
    profile {
      id
      name
      avatar
      idCardNumber
      idCardPhoto
      idCardType
      email
      reUpload
      agreementStatement
      gender
      birthDate
    }
    agreementStatementRegal {
      id
      agreementStatement
      agreementApproveDate
      agreementStatementApprove
    }
    store {
      id
      proofOfBusiness
      storeVerified
      storeManagerBulk {
        id
        storeName
        province
        yearEstablished
        address
        storeCode
        city
      }
    }
    phoneNumber
    role
    accessUser
    createdAt
    whatsAppValidation
  }
`;
