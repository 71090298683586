import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_CITIES } from '../../graphql/queries';
import { Cities, CitiesVariables } from '../../generated/Cities';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Kota', 'Select City']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  provincesID: Array<string>;
};

const CityDropdown = memo((props: Props) => {
  let { title = defaultTitle, provincesID, ...otherProps } = props;

  const [getCities, { data, loading, error }] = useLazyQuery<
    Cities,
    CitiesVariables
  >(GET_CITIES);
  const fetchCities = useCallback(() => {
    if (provincesID.length) {
      getCities({ variables: { provincesID } });
    }
  }, [provincesID, getCities]);

  useEffect(fetchCities, [provincesID, fetchCities]);

  const options = useMemo(
    () =>
      provincesID.length
        ? data?.cityByProvinceId.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          })) ?? []
        : [],
    [provincesID, data],
  );

  return (
    <Dropdown
      data-cy="city-dropdown"
      error={error}
      errorAction={t(['mengambil data kota', 'retrieve the city data'])}
      onRetryPress={fetchCities}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default CityDropdown;
