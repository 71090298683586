import React, { memo } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, Image } from 'react-native';

import { Text, Button, Dropzone, TextInput } from '../core-ui';
import { colors, spacing } from '../constants/theme';
import { FileWithPreview } from '../core-ui/Dropzone';
import { Logo } from '../scenes/logoLibrary/LogoLibraryScene';

type Props = {
  logo: Logo;
  style?: StyleProp<ViewStyle>;
  editable?: boolean;
  setLogoImage?: (value: Logo) => void;
};

type UploadLogoItemProps = Props & {
  'data-cy'?: string;
  saveDisabled: boolean;
  saveLoading: boolean;
  deleteLoading: boolean;
  onChange: (key: keyof Logo, value: string | FileWithPreview) => void;
  onSave: () => void;
  onEdit: () => void;
  onDelete: () => void;
};

const SelectLogoItem = memo((props: Props) => {
  let { logo, setLogoImage, style } = props;
  return (
    <View style={style}>
      <Image
        source={{ uri: logo.imageUrl }}
        style={styles.image}
        resizeMode="contain"
      />
      <Text style={styles.logoName}>{logo.name}</Text>
      <Button
        title={t(['Gunakan sebagai logo', 'Set as logo'])}
        preset="transparent"
        onPress={() => {
          setLogoImage && setLogoImage(logo);
        }}
        style={styles.button}
        disabled={!logo}
      />
    </View>
  );
});

const UploadLogoItem = memo((props: UploadLogoItemProps) => {
  let {
    logo,
    style,
    editable,
    saveDisabled,
    saveLoading,
    deleteLoading,
    onChange,
    onSave,
    onEdit,
    onDelete,
  } = props;

  return (
    <View style={style}>
      <Dropzone
        data-cy={`${props['data-cy']}-dropzone`}
        disabled={!editable}
        imageSize={{ height: 250, width: 500 }}
        type="image"
        containerStyle={{ height: 109, width: 191 }}
        source={logo.imageFile ?? logo.imageUrl}
        getPreview={(value) => onChange('imageFile', value)}
      />
      {editable ? (
        <TextInput
          data-cy={`${props['data-cy']}-input`}
          placeholder={t(['Masukkan Nama', 'Insert Name'])}
          value={logo.name}
          onChangeText={(value) => {
            onChange('name', value);
          }}
          containerStyle={styles.logoName}
        />
      ) : (
        <Text style={styles.logoName} data-cy={`${props['data-cy']}-name`}>
          {logo.name ?? t(['Masukkan Nama', 'Insert Name'])}
        </Text>
      )}
      <View style={styles.actions}>
        <Button
          data-cy={`${props['data-cy']}-save-edit`}
          preset="transparent"
          isLoading={saveLoading}
          disabled={editable && saveDisabled}
          title={editable ? t(['Simpan', 'Save']) : t(['Ubah', 'Edit'])}
          onPress={editable ? onSave : onEdit}
        />
        <Text color="link">|</Text>
        <Button
          data-cy={`${props['data-cy']}-delete`}
          preset="transparent"
          isLoading={deleteLoading}
          title={t(['Hapus', 'Delete'])}
          onPress={onDelete}
          disabled={!logo.imageFile && !logo.imageUrl && !logo.name}
        />
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  button: {
    alignSelf: 'flex-end',
  },
  image: {
    height: 109,
    width: 191,
    borderWidth: 1,
    borderColor: colors.border.primary,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoName: {
    marginTop: spacing.xsmall,
  },
});

export { SelectLogoItem, UploadLogoItem };
