import React, { useEffect, useCallback } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useQuery } from '@apollo/react-hooks';

import { ErrorMessage } from '../../components';
import { PopUps, PopUpsVariables } from '../../generated/PopUps';
import { GET_POP_UPS } from '../../graphql/queries';
import { PopUpFragment } from '../../generated/PopUpFragment';
import { ArchiveStatus } from '../../generated/globalTypes';

import CustomPopUp from './CustomPopUp';

type Props = {
  editPopUp: PopUpFragment | null;
  refetchToggle: boolean;
  onArchive: () => void;
  setEditPopUp: (value: PopUpFragment | null) => void;
};

const CustomPopUpList = (props: Props) => {
  const { editPopUp, refetchToggle, onArchive, setEditPopUp } = props;

  const { data, loading, error, refetch } = useQuery<PopUps, PopUpsVariables>(
    GET_POP_UPS,
    {
      variables: { archiveStatus: ArchiveStatus.NORMAL },
      notifyOnNetworkStatusChange: true,
    },
  );

  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  useEffect(() => {
    refetchData();
  }, [refetchToggle, refetchData]);

  return (
    <View style={styles.flex}>
      {loading && <ActivityIndicator />}
      {data && (
        <View style={[styles.row, styles.flex]}>
          {data.popUpAdvanceSearch.row.map((popUp, index) => (
            <CustomPopUp
              key={index}
              popUp={popUp}
              index={index + 1}
              edit={!!editPopUp && editPopUp.id === popUp.id}
              setEditPopUp={setEditPopUp}
              onArchive={onArchive}
            />
          ))}
        </View>
      )}
      {!!error && (
        <ErrorMessage
          action={t(['mengambil data pop up', 'retrieve the pop up data'])}
          error={error}
          onPress={refetchData}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: 'row', flexWrap: 'wrap' },
});

export default CustomPopUpList;
