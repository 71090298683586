import gql from 'graphql-tag';

import { ADMIN_FRAGMENT } from '../fragments';

export const GET_ADMIN = gql`
  query GetAdmin {
    admin {
      ...AdminFragment
    }
  }
  ${ADMIN_FRAGMENT}
`;
