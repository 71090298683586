const BLUE = '#57abd2';
const LIGHT_BLUE = '#b8dbec';
const GOLD = '#ead28d';
const DARK_GOLD = '#d9af34';
const GREEN = '#41da61';
const DARK_GREEN = '#5E988C';
const LIGHT_GREEN = '#d4dba8';
const LIGHTER_BLUE = '#d9ecf5';
const LIGHTEST_BLUE = '#ecf5f9';
const BLACK = '#000000';
const LIGHT_BLACK = '#0c0c0c';
const DARKER_GRAY = '#0d0d0d';
const DARK_GRAY = '#353535';
const MEDIUM_GRAY = '#7f7f7f';
const GRAY = '#cccccc';
const LIGHT_GRAY = '#e8e8e8';
const LIGHTER_GRAY = '#f2f2f2';
const MEDIUM_LIGHT_GRAY = '#C5C5C5';
const WHITE = '#ffffff';
const DARK_RED = '#fd0d1b';
const RED = '#ef4135';
const RED_ALT = '#DA576D';
const TRANSPARENT = 'transparent';
const YELLOW = '#F9D26A';
const DISABLED_OVERLAY = 'rgba(0,0,0,0.12)'; // NOTE: the same as button's disabled bg color
const GREY88 = '#e0e0e0';
const GREY = '#C0C0C0';
const ORANGE = '#F89143';
const ORANGE2 = '#fea25f';
const LIGHT_GREY = '#D4D4D4';

const YELLOW_PASTEL = '#FFF3D9';

const FONT_SIZES = {
  xsmall: 10,
  small: 12,
  default: 14,
  medium: 18,
  mediumLarge: 16,
  large: 24,
  xLarge: 36,
} as const;

const LINE_HEIGHTS = {
  xsmall: 13,
  small: 16,
  default: 19,
  medium: 24,
  mediumLarge: 24,
  large: 32,
  xLarge: 36,
} as const;

// NOTE: adjust this as necessary, prioritize what you have over this as it is only meant to be a basic building block
const theme = {
  colors: {
    button: {
      primary: {
        background: BLUE,
        text: 'contrast',
      },
      secondary: {
        background: LIGHTER_GRAY,
        text: 'secondary',
      },
      optional: {
        background: ORANGE,
        text: 'contrast',
      },
      warning: {
        background: RED,
        text: 'contrast',
      },
      transparent: {
        background: TRANSPARENT,
        text: 'link',
      },
    },
    checkbox: {
      checked: BLUE,
      unchecked: DARKER_GRAY,
    },
    switch: {
      checked: BLUE,
      unchecked: GRAY,
      thumb: WHITE,
    },
    border: {
      primary: LIGHT_GRAY,
      dark: MEDIUM_GRAY,
      light: MEDIUM_LIGHT_GRAY,
      white: WHITE,
      error: RED,
    },
    dropdown: {
      primary: {
        displayedValue: BLUE,
        hover: LIGHTER_GRAY,
        icon: DARK_GRAY,
        item: BLACK,
        selectedItem: BLUE,
        background: LIGHT_GRAY,
      },
      secondary: {
        displayedValue: LIGHT_BLACK,
        icon: BLACK,
      },
      basic: {
        border: LIGHT_GRAY,
      },
      disabled: DISABLED_OVERLAY,
    },
    requestStatus: {
      accepted: DARK_GREEN,
      rejected: RED_ALT,
      pending: YELLOW,
    },
    dropzone: {
      disabled: DISABLED_OVERLAY,
    },
    homepageSlider: {
      edit: BLUE,
      allDay: BLACK,
      morning: LIGHT_BLUE,
      day: GOLD,
      evening: LIGHT_GREEN,
    },
    icon: {
      primary: BLUE,
      secondary: WHITE,
      dragIndicator: GRAY,
      marker: BLUE,
    },
    popover: {
      primary: { background: BLUE, shadow: GRAY },
      secondary: { background: WHITE, shadow: GRAY },
    },
    popUp: {
      blue: BLUE,
      gold: GOLD,
      red: DARK_RED,
    },
    search: {
      default: DARKER_GRAY,
      placeholder: GRAY,
    },
    separator: LIGHT_GRAY,
    status: {
      pending: GRAY,
      live: GREEN,
      end: DARK_RED,
    },
    table: {
      background: TRANSPARENT,
      backgroundWhite: WHITE,
      head: GREY,
      highlightedRow: YELLOW_PASTEL,
      activePromotionBackground: LIGHTER_BLUE,
      innerBorder: LIGHT_GREY,
    },
    text: {
      archive: DARKER_GRAY,
      contrast: WHITE,
      default: BLACK,
      disabled: GRAY,
      link: BLUE,
      secondary: LIGHT_BLACK,
      error: RED,
      footer: MEDIUM_GRAY,
      accepted: GREEN,
      pending: DARK_GOLD,
      waiting: ORANGE,
    },
    textInput: {
      background: WHITE,
      label: LIGHT_BLACK,
      value: LIGHT_BLACK,
      bottomLabel: BLUE,
      activeBorder: BLUE,
      errorBorder: DARK_RED,
      disabled: DISABLED_OVERLAY,
    },
    dateTimePicker: {
      primary: BLUE,
      selectedDateBorder: RED,
      today: BLUE,
      markedDateBackground: LIGHTEST_BLUE,
      border: LIGHT_GRAY,
    },
    progress: {
      background: LIGHT_GRAY,
      value: BLUE,
    },
    radio: {
      selected: BLUE,
      border: LIGHT_GRAY,
      disabled: DISABLED_OVERLAY,
    },
    segmentedControl: {
      background: LIGHTER_GRAY,
      border: LIGHT_GRAY,
    },
    box: {
      border: GREY88,
    },
    label: {
      primary: ORANGE2,
      secondary: BLUE,
    },
  },
  fonts: {
    sizes: FONT_SIZES,
    lineHeights: LINE_HEIGHTS,
    default: {
      letterSpacing: -0.08,
    },
  },
  style: {
    border: {
      borderColor: LIGHT_GRAY,
      borderWidth: 1,
    },
    separator: {
      borderColor: GRAY,
      borderBottomWidth: 1,
    },
  },
  spacing: {
    xxsmall: 4,
    xsmall: 8,
    small: 16,
    medium: 24,
    large: 32,
    xlarge: 60,
  },
} as const;

const { colors, fonts, spacing } = theme;
export { theme, colors, fonts, spacing };
