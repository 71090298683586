import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_PRODUCTS } from '../../graphql/queries/product';
import { Products, ProductsVariables } from '../../generated/Products';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Satuan', 'Select Unit']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  brandID?: string;
  rangeID?: string;
  productID?: string;
  title?: string;
};

const UnitDropdown = memo((props: Props) => {
  let {
    title = defaultTitle,
    brandID,
    rangeID,
    productID,
    ...otherProps
  } = props;

  const [getProducts, { data, loading, error }] = useLazyQuery<
    Products,
    ProductsVariables
  >(GET_PRODUCTS);

  const refetchData = useCallback(() => {
    if (brandID && rangeID && productID) {
      getProducts({ variables: { brandID, rangeID } });
    }
  }, [brandID, rangeID, productID, getProducts]);

  useEffect(refetchData, [brandID, rangeID, productID, refetchData]);

  const options = useMemo(() => {
    let selectedProduct = data?.productList.dataProducts.find(
      (item) => item.id === Number(productID),
    );
    return (
      selectedProduct?.unit.map((item, index) => ({
        label: item,
        value: index.toString(),
      })) ?? []
    );
  }, [data, productID]);

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data unit', 'retrieve the unit data'])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default UnitDropdown;
