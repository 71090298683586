import React, { useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFoundScene } from '../scenes';
import { useAuth } from '../context';
import { RoleAdmin } from '../generated/globalTypes';

import { adminRoutes, principalRoutes, verificatorRoutes } from './routes';
import ScrollToTop from './ScrollToTop';

export default function AuthenticatedRoute() {
  const { role } = useAuth();
  const routes = useMemo(() => {
    if (role === RoleAdmin.VERIFICATION_ADMIN) {
      return verificatorRoutes;
    } else if (role === RoleAdmin.SUPER_VERIFICATION_ADMIN) {
      return verificatorRoutes;
    } else if (role === RoleAdmin.MASTER_VERIFICATION_ADMIN) {
      return verificatorRoutes;
    } else if (role === RoleAdmin.PRINCIPAL) {
      return principalRoutes;
    } else {
      return adminRoutes;
    }
  }, [role]);
  return (
    <>
      <ScrollToTop />
      <Switch>
        {routes.map((route, index) => {
          return <Route key={index} {...route} />;
        })}
        <Route component={NotFoundScene} />
      </Switch>
    </>
  );
}
