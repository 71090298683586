export let convertToCurrencyRupiah = (value: number): string => {
  let numberValue = Number(value);
  if (!isNaN(numberValue)) {
    return numberValue.toLocaleString('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    });
  }
  return numberValue.toString();
};
