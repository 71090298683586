import gql from 'graphql-tag';

export const DOWNLOAD_TEMPLATE_TOP_UP_REWARD = gql`
  query DownloadTemplateTopUpRewardCSV {
    downloadTemplateTopUpRewardCSV {
      link
    }
  }
`;

export const DOWNLOAD_INVALID_DATA_TOP_UP_REWARD = gql`
  query DownloadInvalidDataTopUpRewardCSV($topUpRewardId: ID!) {
    downloadInvalidDataTopUpRewardCSV(topUpRewardId: $topUpRewardId) {
      link
    }
  }
`;

export const GET_MISSION_TOPUP_REWARD = gql`
  query MissionTopupReward($first: Int, $skip: Int) {
    missionTopupReward(pagination: { first: $first, skip: $skip }) {
      row {
        id
        fileName
        link
        invalidLink
        uploadDate
        status
        detail
        adminId
        countValid
        countInvalid
        createdAt
      }
      total
    }
  }
`;

export const GET_TOPUP_REWARD_DETAIL = gql`
  query MissionTopupRewardDetail(
    $first: Int
    $skip: Int
    $topupRewardId: String!
  ) {
    missionTopupRewardDetail(
      pagination: { first: $first, skip: $skip }
      topupRewardId: $topupRewardId
    ) {
      row {
        id
        createdAt
        updatedAt
        startDatePeriod
        endDatePeriod
        storeCode
        missionId
        invalidData
        no
        note
        valid
        missionTopupRewardId
        missionTopupReward {
          fileName
          status
        }
      }
      total
    }
  }
`;
