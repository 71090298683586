import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_RANGES } from '../../graphql/queries/product';
import { Ranges, RangesVariables } from '../../generated/Ranges';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Range', 'Select Range']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  brandID?: string;
  title?: string;
};

const RangeDropdown = memo((props: Props) => {
  let { title = defaultTitle, brandID, ...otherProps } = props;

  const [getRanges, { data, loading, error }] = useLazyQuery<
    Ranges,
    RangesVariables
  >(GET_RANGES);
  const refetchData = useCallback(() => {
    if (brandID) {
      getRanges({ variables: { brandId: brandID } });
    }
  }, [brandID, getRanges]);

  useEffect(refetchData, [brandID, refetchData]);

  const options = useMemo(
    () =>
      data?.ranges.map(({ id, name }) => ({ label: name, value: id })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data range', 'retrieve the range data'])}
      onRetryPress={refetchData}
      options={options}
      loading={loading}
      title={title}
      {...otherProps}
    />
  );
});

export default RangeDropdown;
