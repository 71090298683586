import gql from 'graphql-tag';

export const GET_CITIES = gql`
  query Cities($provincesID: [ID!]!) {
    cityByProvinceId(provincesId: $provincesID) {
      id
      name
      provinceId
    }
  }
`;
