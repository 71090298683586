import React, { ReactNode, memo } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { Infotip, Text, TextProps } from '../core-ui';
import { theme, colors } from '../constants/theme';

type Props = {
  infotipContent?: ReactNode;
  subtitle?: string;
  subtitleColor?: TextProps['color'];
  style?: StyleProp<ViewStyle>;
  title: string;
  titleColor?: TextProps['color'];
  titleSize?: TextProps['size'];
  withSeparator?: boolean;
};

const Header = memo((props: Props) => {
  let {
    infotipContent,
    subtitle,
    subtitleColor = 'link',
    style,
    title,
    titleColor = 'default',
    titleSize = 'large',
    withSeparator,
  } = props;
  return (
    <View style={style}>
      <View style={styles.row}>
        <Text>
          <Text bold size={titleSize} color={titleColor}>
            {title}
          </Text>
          {!!subtitle && (
            <>
              <Text bold size="large">
                {' '}
                -{' '}
              </Text>
              <Text size="large" color={subtitleColor}>
                {subtitle}
              </Text>
            </>
          )}
        </Text>
        {!!infotipContent && (
          <View testID="infotip">
            <Infotip>{infotipContent}</Infotip>
          </View>
        )}
      </View>
      {withSeparator && <View style={styles.separator} testID="separator" />}
    </View>
  );
});

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: 1,
    borderColor: colors.separator,
    marginVertical: theme.spacing.small,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default Header;
