import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';

import { Table, Text } from '../../core-ui';
import { ErrorMessage, ErrorModal } from '../../components';
import { useTableSort } from '../../hooks';
import { GET_NEWS } from '../../graphql/queries';
import { ArchiveStatus, NewsOrderByType } from '../../generated/globalTypes';
import { colors } from '../../constants/theme';
import { News, NewsVariables } from '../../generated/News';
import { ARCHIVE_NEWS } from '../../graphql/mutations';
import { ArchiveNews, ArchiveNewsVariables } from '../../generated/ArchiveNews';
import { NewsFragment } from '../../generated/NewsFragment';

type Props = {
  searchArchived?: boolean;
  searchByUser?: string;
  searchByBrand?: string;
  searchByRegion?: string;
  searchByCity?: string;
  searchByNewsItem?: string;
  searchDateFrom?: Date;
  searchDateUntil?: Date;
  searchViewersFrom?: number;
  searchViewersUntil?: number;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: NewsVariables;
  setEditNews?: (value: NewsFragment) => void;
};

export default function NewsTable(props: Props) {
  let {
    searchArchived,
    searchByUser,
    searchByBrand,
    searchByRegion,
    searchByCity,
    searchByNewsItem,
    searchDateFrom,
    searchDateUntil,
    searchViewersFrom,
    searchViewersUntil,
    refetchToggle,
    hideEdit,
    queryVariables,
    setEditNews,
  } = props;

  let [archiveId, setArchiveId] = useState<string | null>(null);
  let [page, setPage] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    NewsOrderByType
  >();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  let [getNews, { data, loading, error, refetch }] = useLazyQuery<
    News,
    NewsVariables
  >(GET_NEWS, {
    variables: {
      skip: page * rowsPerPage,
      first: rowsPerPage,
      archiveStatus: ArchiveStatus.NORMAL,
      orderBy: orderByVariable,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            appUser: searchByUser,
            region: searchByRegion,
            city: searchByCity,
            newsItem: searchByNewsItem,
            brandId: searchByBrand,
            date:
              searchDateFrom && searchDateUntil
                ? {
                    startDate: searchDateFrom,
                    endDate: searchDateUntil,
                  }
                : undefined,
            viewers:
              searchViewersFrom && searchViewersUntil
                ? {
                    countFrom: searchViewersFrom,
                    countTo: searchViewersUntil,
                  }
                : null,
            archiveStatus: searchArchived ? undefined : ArchiveStatus.NORMAL,
            orderBy: orderByVariable,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [
      page,
      searchArchived,
      searchByUser,
      searchByBrand,
      searchByRegion,
      searchByCity,
      searchByNewsItem,
      searchDateFrom,
      searchDateUntil,
      searchViewersFrom,
      searchViewersUntil,
      queryVariables,
      refetch,
      rowsPerPage,
      orderByVariable,
    ],
  );

  useEffect(() => {
    setPage(0);
  }, [
    searchArchived,
    searchByUser,
    searchByBrand,
    searchByRegion,
    searchByCity,
    searchByNewsItem,
    searchDateFrom,
    searchDateUntil,
    searchViewersFrom,
    searchViewersUntil,
  ]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  let [
    archiveCompetition,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchiveNews, ArchiveNewsVariables>(ARCHIVE_NEWS, {
    onCompleted: refetchCurrentPage,
    onError: openErrorModal,
  });

  const onArchivePress = useCallback(
    (id: string) => {
      setArchiveId(id);
      archiveCompetition({ variables: { id } });
    },
    [archiveCompetition],
  );

  useEffect(() => {
    // NOTE: this is triggered when any search props has been changed; page needs to be reset
    getNews();
  }, [getNews]);

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onViewPress = useCallback(
    (news: NewsFragment) => {
      if (setEditNews) {
        setEditNews(news);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditNews],
  );

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : ({
            action: {
              headerTitle: t(['berita', 'news']),
              headerTitleColor: 'link',
              displayText: t(['lihat', 'view']),
              onPress: onViewPress,
            },
          } as const),
    [hideEdit, onViewPress],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  return (
    <>
      <Table
        data-cy="news-table"
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        page={page}
        error={!!error}
        loading={loading}
        data={data?.newsAdvanceSearch.row ?? []}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onRowsChange}
        onChangePage={onChangePage}
        dataCount={data?.newsAdvanceSearch.total || -1}
        structure={{
          [NewsOrderByType.appUser]: {
            headerTitle: t(['pengguna', 'users']),
            orderable: true,
          },
          [NewsOrderByType.city]: {
            headerTitle: t(['kota', 'city']),
            orderable: true,
          },
          [NewsOrderByType.liveDate]: {
            headerTitle: t(['tanggal', 'date']),
            valuePreProcessor: (value) => new Date(value as string),
            orderable: true,
          },
          [NewsOrderByType.endLiveDate]: {
            headerTitle: t(['berakhir', 'ends']),
            valuePreProcessor: (value) => new Date(value as string),
            orderable: true,
          },
          [NewsOrderByType.title]: {
            headerTitle: t(['judul', 'listing']),
            orderable: true,
          },
          [NewsOrderByType.brandName]: {
            headerTitle: t(['brand', 'brand']),
            dataPath: 'businessUnitContact.brand.name',
            orderable: true,
          },
          [NewsOrderByType.buContactName]: {
            headerTitle: t(['principal', 'principal']),
            dataPath: 'businessUnitContact.name',
            orderable: true,
          },
          [NewsOrderByType.clickCount]: {
            headerTitle: t(['Dilihat', 'Viewers']),
            orderable: true,
          },
          ...action,
          status: {
            align: 'center',
            render: ({ endLiveDate }) => {
              const live =
                new Date(endLiveDate).valueOf() > new Date().valueOf();
              return (
                <View style={live ? styles.liveIndicator : styles.endIndicator}>
                  <Text color="contrast">{live ? 'LIVE' : 'END'}</Text>
                </View>
              );
            },
          },
          archive: {
            align: 'center',
            headerTitle: t(['arsip', 'archive']),
            render: ({ id, status }) => {
              return archiveId === id && archiveLoading ? (
                <ActivityIndicator size="small" />
              ) : (
                <TouchableOpacity
                  data-cy="news-archive-toggle"
                  onPress={() => onArchivePress(id)}
                >
                  {status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon data-cy="news-normal" />
                  ) : (
                    <FolderFilledIcon data-cy="news-archived" />
                  )}
                </TouchableOpacity>
              );
            },
          },
        }}
      />
      {!!error && (
        <ErrorMessage
          error={error}
          action={t(['mengambil data berita', 'retrieve the news data'])}
          onPress={refetchCurrentPage}
        />
      )}
      <ErrorModal
        open={errorOpen}
        error={archiveError}
        action={t([
          'mengubah status arsip data berita',
          'change archive status of the news data',
        ])}
        onClose={closeErrorModal}
      />
    </>
  );
}

const styles = StyleSheet.create({
  liveIndicator: {
    width: 48,
    height: 36,
    backgroundColor: colors.status.live,
    alignItems: 'center',
    justifyContent: 'center',
  },
  endIndicator: {
    width: 48,
    height: 36,
    backgroundColor: colors.status.end,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
