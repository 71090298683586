import gql from 'graphql-tag';

export const SCREEN_FRAGMENT = gql`
  fragment ScreenFragment on Screen {
    id
    name
    parent
    key
    type
  }
`;
