import { MAPS_API_KEY } from 'react-native-dotenv';

import { Option } from '../components';

export default async function getPlaces(query: string) {
  let proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  let targetUrl = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&components=country:id&key=${MAPS_API_KEY}`;
  let result = await fetch(proxyUrl + targetUrl);
  let json = await result.json();

  if (json.status === 'OK') {
    let mappedData: Array<Option> = json.predictions.map(
      (item: { place_id: string; description: string }): Option => {
        return {
          value: item.place_id,
          label: item.description,
        };
      },
    );
    return {
      success: true,
      places: mappedData,
    };
  } else {
    return { success: false, errorMessage: json.error_message };
  }
}
