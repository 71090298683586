import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { ApolloError } from 'apollo-client';
import { logEvent } from 'expo-firebase-analytics';

import { spacing } from '../../constants/theme';
import { Button } from '../../core-ui';
import {
  Option,
  Header,
  HeaderNavigationBar,
  ErrorModal,
} from '../../components';
import { FileWithPreview } from '../../core-ui/Dropzone';
import {
  CreateBrandPage,
  CreateBrandPageVariables,
} from '../../generated/CreateBrandPage';
import { Upload, UploadVariables } from '../../generated/Upload';
import { CREATE_BRAND_PAGE, UPLOAD } from '../../graphql/mutations';
import { GET_BRAND_LOGOS } from '../../graphql/queries';
import {
  BrandLogos_brand_logos,
  BrandLogosVariables,
  BrandLogos,
} from '../../generated/BrandLogos';
import { StatusActive, FolderType } from '../../generated/globalTypes';
import { routePaths } from '../../constants/routes';
import { pageTitle } from '../../constants/pageTitle';

import {
  StepOne,
  UploadStep,
  StepFour,
  StepFive,
  StepSix,
  StepSeven,
} from './components/NewBrandForm';

type Logo = Omit<BrandLogos_brand_logos, '__typename'>;

export default function AddNewBrandPage() {
  const history = useHistory();
  let [namePage, setNamePage] = useState<Option | null>(null);
  let [about, setAbout] = useState('');
  let [rangeName, setRangeName] = useState<string | null>(null);
  let [description, setDescription] = useState('');
  let [availableIn, setAvailableIn] = useState('');
  let [liveDate, setLiveDate] = useState(new Date());
  let [orderNow, setOrderNow] = useState<Option | null>(null);
  let [isModalOpen, setModalOpen] = useState(false);
  let [logoImage, setLogoImage] = useState<Logo | null>(null);
  let [headerImage, setHeaderImage] = useState<FileWithPreview | string | null>(
    null,
  );
  let [rangeImage, setRangeImage] = useState<FileWithPreview | string | null>(
    null,
  );
  let [logoLibrary, setLogoLibrary] = useState<Array<Logo>>([]);

  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();

  const submitDisabled = useMemo(
    () =>
      !namePage ||
      !rangeName ||
      !orderNow ||
      !about ||
      !description ||
      !headerImage ||
      !rangeImage ||
      !availableIn,
    [
      namePage,
      rangeName,
      orderNow,
      about,
      description,
      headerImage,
      rangeImage,
      availableIn,
    ],
  );

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', { page_title: pageTitle.AddNewBrandPage });
    };
    eventLog();
  }, []);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(
    (action: string) => (error: ApolloError) => {
      setErrorOpen(true);
      setErrorAction(action);
      setErrorInstance(error);
    },
    [],
  );
  const onUploadError = useMemo(() => {
    return openErrorModal(t(['mengunggah gambar', 'upload image']));
  }, [openErrorModal]);
  const onCreateError = useMemo(() => {
    return openErrorModal(
      t(['membuat data halaman brand', 'create brand page data']),
    );
  }, [openErrorModal]);

  let wrappedSetRangeName = useCallback(
    (selected: Option) => {
      setRangeName(selected.label);
    },
    [setRangeName],
  );

  let [getBrandLogos] = useLazyQuery<BrandLogos, BrandLogosVariables>(
    GET_BRAND_LOGOS,
    {
      onCompleted: ({ brand }) => setLogoLibrary(brand.logos),
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    },
  );

  let [uploadImage] = useMutation<Upload, UploadVariables>(UPLOAD, {
    onError: onUploadError,
  });

  const goToBrandPages = useCallback(
    () => history.push(routePaths.brandPages),
    [history],
  );
  let [createBrandPage, { loading }] = useMutation<
    CreateBrandPage,
    CreateBrandPageVariables
  >(CREATE_BRAND_PAGE, {
    onError: onCreateError,
    onCompleted: goToBrandPages,
  });

  useEffect(() => {
    !!namePage &&
      getBrandLogos({
        variables: {
          brandID: namePage.value.toString(),
        },
      });
    setRangeName(null);
    setOrderNow(null);
  }, [namePage, getBrandLogos]);

  let onSubmit = useCallback(async () => {
    if (
      namePage &&
      rangeName &&
      orderNow &&
      about &&
      description &&
      headerImage &&
      rangeImage &&
      availableIn
    ) {
      let headerImageLink = '';
      let rangeImageLink = '';
      if (headerImage && rangeImage) {
        if (typeof headerImage === 'object' && typeof rangeImage === 'object') {
          const headerImagePromise = uploadImage({
            variables: {
              file: headerImage.file,
              folder: FolderType.BRAND_PAGE,
            },
          });
          const rangeImagePromise = uploadImage({
            variables: {
              file: rangeImage.file,
              folder: FolderType.BRAND_RANGE,
            },
          });
          const [
            { data: headerImageData },
            { data: rangeImageData },
          ] = await Promise.all([headerImagePromise, rangeImagePromise]);
          headerImageLink = headerImageData?.upload.link ?? '';
          rangeImageLink = rangeImageData?.upload.link ?? '';
        } else if (
          typeof headerImage === 'string' &&
          typeof rangeImage === 'string'
        ) {
          // NOTE: technically we will never reach this block, but just in case
          headerImageLink = headerImage;
          rangeImageLink = rangeImage;
        }
      }
      createBrandPage({
        variables: {
          idBrand: namePage.value,
          pageName: namePage.label,
          about,
          rangeName: rangeName,
          description,
          availableIn,
          liveDate,
          rangeId: orderNow.value.toString(),
          idLogoLibrary: logoImage?.id,
          headerImage: headerImageLink,
          rangeImage: rangeImageLink,
          status: StatusActive.ACTIVE,
        },
      });
    }
  }, [
    namePage,
    rangeName,
    orderNow,
    about,
    description,
    availableIn,
    headerImage,
    rangeImage,
    liveDate,
    logoImage,
    uploadImage,
    createBrandPage,
  ]);

  let onSetLogo = (image: Logo) => {
    setLogoImage(image);
    setModalOpen(false);
  };

  const brandsQueryVariables = useMemo(
    () => ({ where: { brandPage: null } }),
    [],
  );

  return (
    <View style={styles.root}>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <HeaderNavigationBar
        backDestinationText={t(['Halaman Brand', 'Brand Page'])}
        onBackButtonPress={goToBrandPages}
      />
      <Header
        title="Homepage"
        subtitle="Add New Brand Page"
        subtitleColor="default"
        style={styles.header}
      />
      <StepOne
        brandsQueryVariables={brandsQueryVariables}
        selectedNamePage={namePage}
        onSelectNamePage={setNamePage}
      />
      <UploadStep
        create
        editable={!!namePage}
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        headerImage={headerImage}
        setHeaderImage={setHeaderImage}
        logoImage={logoImage}
        setLogoImage={onSetLogo}
        logoLibrary={logoLibrary}
      />
      <View style={styles.row}>
        <StepFour
          create
          about={about}
          setAbout={setAbout}
          editable={!!namePage}
        />
        <StepFive
          create
          showTitle
          editable={!!namePage}
          brandID={namePage?.subValue ?? undefined}
          selectedRangeName={rangeName}
          onSelectRangeName={wrappedSetRangeName}
          description={description}
          setDescription={setDescription}
          availableIn={availableIn}
          setAvailableIn={setAvailableIn}
        />
        <StepSix
          create
          showTitle
          editable={!!namePage}
          rangeImage={rangeImage}
          setRangeImage={setRangeImage}
        />
        <StepSeven
          create
          showTitle
          editable={!!namePage}
          setLiveDate={setLiveDate}
          brandID={namePage?.subValue ?? undefined}
          selectedRange={orderNow}
          onSelectRange={setOrderNow}
        />
      </View>
      <View style={styles.buttonContainer}>
        <Button
          data-cy="submit-button"
          title={t(['Simpan', 'Submit'])}
          onPress={onSubmit}
          style={styles.button}
          isLoading={loading}
          disabled={submitDisabled}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    padding: spacing.xlarge,
  },
  header: {
    marginBottom: spacing.small,
  },
  row: {
    flexDirection: 'row',
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
  button: { width: 120 },
});
