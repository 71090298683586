const flattenRec = (
  input: ObjectOf<unknown>,
  prepend: Array<string> = [],
): ObjectOf<unknown> => {
  let flattened: ObjectOf<unknown> = {};

  for (let key of Object.keys(input)) {
    let value = input[key];
    if (
      typeof value === 'object' &&
      value != null &&
      !(value instanceof Date)
    ) {
      prepend.push(key);
      flattened = {
        ...flattened,
        ...flattenRec(value as ObjectOf<unknown>, prepend),
      };
      prepend.pop();
    } else {
      prepend.push(key);
      flattened[prepend.join('.')] = input[key];
      prepend.pop();
    }
  }

  return flattened;
};

const flattenObject = (input: ObjectOf<unknown>): ObjectOf<unknown> => {
  return flattenRec(input);
};

export default flattenObject;
