import gql from 'graphql-tag';

import { BULK_POINT_WITHOUT_DETAILS_FRAGMENT } from '../fragments';

export const GET_TEMPORARY_GIVEN_POINT = gql`
  query GetTemporaryGivenPoint($id: ID!, $first: Int, $skip: Int) {
    temporaryGivenPoints(
      bulkPointId: $id
      pagination: { first: $first, skip: $skip }
    ) {
      row {
        id
        no
        typeId
        codeStore
        storeName
        name
        brand
        startDate
        endDate
        totalTransaction
        totalPoint
        action
        type
        typeId
        note
        status
        invalidData
        bulkPoint {
          id
          fileName
          status
        }
        tonase
      }
      total
    }
  }
`;

export const DOWNLOAD_INVALID = gql`
  query DownloadInvalidDataCSV($bulkPointId: ID!) {
    downloadInvalidDataCSV(bulkPointId: $bulkPointId) {
      link
    }
  }
`;

export const GET_BULK_TEMPLATE_LINK = gql`
  query BulkTemplateLink {
    downloadTemplateCSV {
      link
    }
  }
`;

export const GET_BULK_INPUTS = gql`
  query BulkPoints($first: Int!, $skip: Int!) {
    bulkPointAdvanceSearch(
      pagination: { first: $first, skip: $skip }
      orderBy: { createdAt: desc }
    ) {
      row {
        ...BulkPointWithoutDetailsFragment
      }
      total
    }
  }
  ${BULK_POINT_WITHOUT_DETAILS_FRAGMENT}
`;
