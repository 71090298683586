import gql from 'graphql-tag';

export const GET_LOYALTY_POINT_MISSION = gql`
  query LoyaltyMissionPoints {
    loyaltyMissionPoints {
      id
      name
      missionId
      category
      startDate
      endDate
      active
      point
    }
  }
`;

export const GET_ACTIVITY_MISSION_HISTORY = gql`
  query MissionActivityHistories($first: Int, $skip: Int) {
    missionActivityHistories(pagination: { first: $first, skip: $skip }) {
      row {
        id
        admin {
          id
          name
        }
        startDate
        endDate
        recentStatus
      }
      total
    }
  }
`;

export const GET_ACTIVITY_MISSION_LOG = gql`
  query MissionActivityLog(
    $storeCode: String
    $storeName: String
    $role: Role
    $missionName: String
    $finishedDate: DateFilter
    $topUpStatus: Boolean
    $topUpDate: DateFilter
    $verificationStatus: Boolean
    $ownerVerificationStatus: Boolean
    $registerDate: DateFilter
    $verificationStoreDate: DateFilter
    $ownerVerificationDate: DateFilter
    $kycDate: DateFilter
    $first: Int
    $skip: Int
  ) {
    missionActivityLog(
      storeCode: $storeCode
      storeName: $storeName
      role: $role
      missionName: $missionName
      finishedDate: $finishedDate
      registerDate: $registerDate
      verificationStoreDate: $verificationStoreDate
      ownerVerificationDate: $ownerVerificationDate
      kycDate: $kycDate
      verificationStatus: $verificationStatus
      ownerVerificationStatus: $ownerVerificationStatus
      topUpStatus: $topUpStatus
      topUpDate: $topUpDate
      pagination: { first: $first, skip: $skip }
    ) {
      row {
        finishedDate
        id
        loyaltyMissionPoint {
          id
          name
          missionId
          point
        }
        store {
          id
          storeName
          managerStoreCode
          verifiedDate
          storeVerified
        }
        topUpDate
        topUpStatus
        updatedAt
        user {
          id
          role
          createdAt
          profile {
            id
            name
            uploadAt
          }
          ownerVerified
          verifiedDate
        }
      }
      total
    }
  }
`;

export const DOWNLOAD_ACTIVITY_MISSION_LOG = gql`
  query DownloadMissionActivityLog(
    $storeCode: String
    $storeName: String
    $role: Role
    $missionName: String
    $finishedDate: DateFilter
    $topUpStatus: Boolean
    $topUpDate: DateFilter
    $verificationStatus: Boolean
    $ownerVerificationStatus: Boolean
    $registerDate: DateFilter
    $verificationStoreDate: DateFilter
    $ownerVerificationDate: DateFilter
    $kycDate: DateFilter
  ) {
    downloadMissionActivityLog(
      storeCode: $storeCode
      storeName: $storeName
      role: $role
      missionName: $missionName
      finishedDate: $finishedDate
      topUpStatus: $topUpStatus
      topUpDate: $topUpDate
      registerDate: $registerDate
      verificationStoreDate: $verificationStoreDate
      ownerVerificationDate: $ownerVerificationDate
      kycDate: $kycDate
      verificationStatus: $verificationStatus
      ownerVerificationStatus: $ownerVerificationStatus
    ) {
      link
    }
  }
`;
