import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_POP_UPS } from '../../graphql/queries';
import { PopUps, PopUpsVariables } from '../../generated/PopUps';
import { ArchiveStatus, LinkTypePopUp } from '../../generated/globalTypes';

import Dropdown, { Option, Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Pesan', 'Select Message']);

type Props = Omit<DropdownProps, 'options' | 'title' | 'onSelect'> & {
  title?: string;
  refetchToggle?: boolean;
  onSelectMessage: (item: Option, linkType: LinkTypePopUp | null) => void;
};

const PopUpMessageDropdown = memo((props: Props) => {
  let {
    title = defaultTitle,
    refetchToggle,
    onSelectMessage,
    ...otherProps
  } = props;

  const { data, loading, error, refetch } = useQuery<PopUps, PopUpsVariables>(
    GET_POP_UPS,
    {
      variables: {
        archiveStatus: ArchiveStatus.NORMAL,
        isWhoNotNull: false,
      },
      notifyOnNetworkStatusChange: true,
    },
  );
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  useEffect(refetchData, [refetchToggle, refetchData]);

  const onSelectItem = (item: Option) => {
    let popUp = data?.popUpAdvanceSearch.row.find(
      ({ id }) => id === item.value,
    );
    onSelectMessage(item, popUp?.linkType ?? null);
  };

  const options = useMemo(
    () =>
      (data &&
        data.popUpAdvanceSearch.row.map(({ id, name }) => ({
          label: name,
          value: id,
        }))) ??
      [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data pop up', 'retrieve the pop up data'])}
      onRetryPress={refetchData}
      options={options}
      title={title}
      loading={loading}
      onSelect={onSelectItem}
      {...otherProps}
    />
  );
});

export default PopUpMessageDropdown;
