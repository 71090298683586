import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { StoreCategories } from '../../../../generated/StoreCategories';
import { GET_STORE_CATEGORIES } from '../../graphql/queries';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultPlaceholder = t(['- Pilih Kategori -', '- Select Category -']);

type Props = Omit<
  DropdownProps<string>,
  'options' | 'multiple' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (value: string, label: string) => void;
};

const StoreCategoryDropdown = (props: Props) => {
  let { placeholder = defaultPlaceholder, ...otherProps } = props;

  //TODO: add error handling
  const { data } = useQuery<StoreCategories>(GET_STORE_CATEGORIES);

  const options = useMemo(
    () =>
      data
        ? data.storeCategory.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          }))
        : [],
    [data],
  );

  return (
    <Dropdown options={options} placeholder={placeholder} {...otherProps} />
  );
};

export default StoreCategoryDropdown;
