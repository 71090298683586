import React, { useState, useCallback, useEffect, useMemo } from 'react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import FolderFilledIcon from '@material-ui/icons/Folder';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import {
  ArchiveStatus,
  PromotionOrderByType,
} from '../../generated/globalTypes';
import { Table, Text } from '../../core-ui';
import { theme } from '../../constants/theme';
import { to12Hh } from '../../helpers';
import { useTableSort } from '../../hooks';
import { GET_PROMOTION } from '../../graphql/queries';
import { ARCHIVE_PROMOTION } from '../../graphql/mutations';
import { Promotions, PromotionsVariables } from '../../generated/Promotions';
import { PromotionFragment } from '../../generated/PromotionFragment';
import {
  ArchivePromotion,
  ArchivePromotionVariables,
} from '../../generated/ArchivePromotion';
import { ErrorMessage, ErrorModal } from '../../components';

type Props = {
  searchByUser?: string;
  searchByRegion?: string;
  searchByCity?: string;
  searchByDateFrom?: Date;
  searchByDateUntil?: Date;
  searchArchived?: boolean;
  searchByPromotion?: string;
  searchByBrand?: string;
  searchByPromotionCode?: string;
  refetchToggle?: boolean;
  hideEdit?: boolean;
  queryVariables?: PromotionsVariables;
  setEditPromotion?: (value: PromotionFragment) => void;
};

const PromotionTable = (props: Props) => {
  let {
    searchByUser,
    searchByRegion,
    searchByCity,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
    searchByPromotion,
    searchByBrand,
    refetchToggle,
    hideEdit,
    queryVariables,
    setEditPromotion,
    searchByPromotionCode,
  } = props;
  const [page, setPage] = useState(0);
  const [archiveID, setArchiveID] = useState<string | null>(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { order, orderBy, orderByVariable, onRequestSort } = useTableSort<
    PromotionOrderByType
  >();

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  let [getPromotions, { data, loading, error, refetch }] = useLazyQuery<
    Promotions,
    PromotionsVariables
  >(GET_PROMOTION, {
    variables: {
      archived: ArchiveStatus.NORMAL,
      skip: page * rowsPerPage,
      first: rowsPerPage,
      orderBy: orderByVariable,
      ...queryVariables,
    },
    notifyOnNetworkStatusChange: true,
  });

  const refetchData = useCallback(
    (skip?: number, first?: number) => {
      const asyncRefetch = async () => {
        try {
          await refetch?.({
            skip: skip ?? page * rowsPerPage,
            first: first ?? rowsPerPage,
            user: searchByUser,
            region: searchByRegion,
            city: searchByCity ? [searchByCity] : [],
            name: searchByPromotion,
            date:
              searchByDateFrom && searchByDateUntil
                ? {
                    startDate: searchByDateFrom,
                    endDate: searchByDateUntil,
                  }
                : undefined,
            archived: searchArchived ? undefined : ArchiveStatus.NORMAL,
            brandId: searchByBrand,
            orderBy: orderByVariable,
            promotionCode: searchByPromotionCode,
            ...queryVariables,
          });
        } catch (_) {
          // NOTE: error because of token handled by AuthContext
        }
      };
      asyncRefetch();
    },
    [
      page,
      searchByUser,
      searchByRegion,
      searchByCity,
      searchByPromotion,
      searchByDateFrom,
      searchByDateUntil,
      searchArchived,
      searchByBrand,
      searchByPromotionCode,
      queryVariables,
      refetch,
      rowsPerPage,
      orderByVariable,
    ],
  );

  useEffect(() => {
    setPage(0);
  }, [
    searchByUser,
    searchByRegion,
    searchByCity,
    searchByPromotion,
    searchByDateFrom,
    searchByDateUntil,
    searchArchived,
    searchByBrand,
    searchByPromotionCode,
  ]);

  const refetchCurrentPage = useCallback(() => {
    refetchData(page * rowsPerPage);
  }, [refetchData, page, rowsPerPage]);

  useEffect(() => {
    refetchData();
  }, [refetchData, refetchToggle]);

  useEffect(() => {
    // NOTE: this is triggered when any search props has been changed; page needs to be reset
    getPromotions();
  }, [getPromotions]);

  let [
    archivePromotions,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchivePromotion, ArchivePromotionVariables>(
    ARCHIVE_PROMOTION,
    {
      onCompleted: refetchCurrentPage,
      onError: openErrorModal,
    },
  );

  const onArchivePress = useCallback(
    (id: string) => {
      setArchiveID(id);
      archivePromotions({ variables: { id } });
    },
    [archivePromotions],
  );

  const onChangePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const onViewPress = useCallback(
    (promotion: PromotionFragment) => {
      if (setEditPromotion) {
        setEditPromotion(promotion);
        window.scrollTo({ top: 170 });
      }
    },
    [setEditPromotion],
  );

  const action = useMemo(
    () =>
      hideEdit
        ? {}
        : ({
            action: {
              headerTitle: t(['UBAH', 'EDIT']),
              headerTitleColor: 'link',
              displayText: t(['lihat', 'view']),
              onPress: onViewPress,
            },
          } as const),
    [hideEdit, onViewPress],
  );

  const onRowsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(Number(event.currentTarget.value));
    setPage(0);
  };

  return (
    <>
      <Table
        data-cy="promotion-table"
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        page={page}
        data={data?.promotionAdvanceSearch.row ?? []}
        error={!!error}
        loading={loading}
        dataCount={data?.promotionAdvanceSearch.total || 0}
        onChangePage={onChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onRowsChange}
        structure={{
          [PromotionOrderByType.name]: {
            headerTitle: t(['PROMOSI', 'PROMOTIONS']),
            render: ({ name }) => (
              <Text style={{ textDecorationLine: 'underline' }}>{name}</Text>
            ),
            orderable: true,
          },
          [PromotionOrderByType.brandName]: {
            headerTitle: t(['MEREK', 'BRANDS']),
            dataPath: 'brand.name',
            orderable: true,
          },
          [PromotionOrderByType.appUser]: {
            headerTitle: t(['PENGGUNA', 'USERS']),
            orderable: true,
          },
          [PromotionOrderByType.city]: {
            headerTitle: t(['KOTA', 'CITY']),
            orderable: true,
          },
          [PromotionOrderByType.time]: {
            headerTitle: t(['WAKTU', 'TIME']),
            render: ({ liveDate }) => <Text>{to12Hh(new Date(liveDate))}</Text>,
            orderable: true,
            compareFn: (a, b) => {
              let itemA = new Date(a.liveDate).toTimeString();
              let itemB = new Date(b.liveDate).toTimeString();
              return itemA < itemB ? -1 : 1;
            },
          },
          [PromotionOrderByType.liveDate]: {
            headerTitle: t(['MULAI', 'START']),
            valuePreProcessor: (value) => new Date(value as string), // NOTE: value is Date ISO String
            orderable: true,
          },
          [PromotionOrderByType.endLiveDate]: {
            headerTitle: t(['AKHIR', 'END']),
            valuePreProcessor: (value) => new Date(value as string), // NOTE: value is Date ISO String
            orderable: true,
          },
          [PromotionOrderByType.buContactName]: {
            dataPath: 'businessUnitContact.name',
            headerTitle: t(['PRINCIPAL', 'PRINCIPAL']),
            orderable: true,
          },
          ...action,
          archive: {
            headerTitle: t(['ARSIP', 'ARCHIVE']),
            headerTitleColor: 'link',
            render: ({ id, status }) =>
              id === archiveID && archiveLoading ? (
                <ActivityIndicator size="small" />
              ) : (
                <TouchableOpacity
                  data-cy="promotion-archive-toggle"
                  onPress={() => onArchivePress(id)}
                  style={styles.actionSpacing}
                >
                  {status === ArchiveStatus.NORMAL ? (
                    <FolderOutlinedIcon data-cy="promotion-normal" />
                  ) : (
                    <FolderFilledIcon data-cy="promotion-archived" />
                  )}
                </TouchableOpacity>
              ),
          },
        }}
      />
      {!!error && (
        <View style={styles.errorRoot}>
          <ErrorMessage
            error={error}
            action={t([
              'mengambil data promosi',
              'retrieve the promotion data',
            ])}
            onPress={refetchCurrentPage}
          />
        </View>
      )}
      <ErrorModal
        open={errorOpen}
        error={archiveError}
        action={t([
          'mengubah status arsip promosi',
          'change archive status of the promotion data',
        ])}
        onClose={closeErrorModal}
      />
    </>
  );
};

const styles = StyleSheet.create({
  errorRoot: { alignItems: 'center', paddingTop: theme.spacing.medium },
  actionSpacing: { paddingLeft: theme.spacing.xsmall },
});

export default PromotionTable;
