import React, { useMemo, useCallback, memo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { GET_AGENT_BY_BRAND_ID } from '../../graphql/queries';
import {
  AgentByBrandId,
  AgentByBrandIdVariables,
} from '../../generated/AgentByBrandId';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Distributor', 'Select Distributor']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  brandId: string;
  title?: string;
  withEmptyData?: boolean;
};

const DistributorDropdown = memo((props: Props) => {
  let {
    title = defaultTitle,
    brandId,
    selectedOption,
    withEmptyData = false,
    ...otherProps
  } = props;
  const { data, loading, error, refetch } = useQuery<
    AgentByBrandId,
    AgentByBrandIdVariables
  >(GET_AGENT_BY_BRAND_ID, {
    variables: { brandId }, //The Variable will accept brand.brandId
    notifyOnNetworkStatusChange: true,
  });
  const refetchData = useCallback(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error because of token handled by AuthContext
      }
    };
    asyncRefetch();
  }, [refetch]);

  const options = useMemo(() => {
    if (data) {
      let distributorList = data.agentByBrandId;
      let mapOptions = distributorList.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
      return withEmptyData
        ? [{ label: 'Pilih Distributor', value: '' }, ...mapOptions]
        : mapOptions;
    }
    return [];
  }, [data, withEmptyData]);

  return (
    <Dropdown
      error={error}
      errorAction={t([
        'mengambil data distributor',
        'retrieve the distributor data',
      ])}
      onRetryPress={refetchData}
      selectedOption={selectedOption}
      options={options}
      title={title}
      loading={loading}
      {...otherProps}
    />
  );
});

export default DistributorDropdown;
