import gql from 'graphql-tag';

export const GET_SALESMAN_REFERRAL_CODE = gql`
  query LoyaltyPointHistoryRedeem(
    $pagination: PaginationInput
    $filter: FilterLoyaltyHistoryInput
  ) {
    loyaltyPointHistoryRedeem(pagination: $pagination, filter: $filter) {
      row {
        storeCode
        storeName
        redeemDate
        time
        rewardCategory
        rewardName
        handlingFee
        utilityFee
        processingFee
        taxFee
        pointValue
        pointToRpValue
        salesmanCode
        status
        refundReason
        pricePerProduct
        quantity
        assuranceFee
        shippingFee
        oldBalance
        newBalance
        source
        deviceId
        tadaOrderNumber
        phoneNumber
        userName
      }
      total
    }
  }
`;

export const GET_ORDER_REFERRAL_CODE_ONLY = gql`
  query GetOrderReferralCodeOnly(
    $storeName: String
    $storeCode: String
    $salesmanReferralCode: String
    $salesmanCode: String
    $distributorCode: Int
    $distributorName: String
    $orderCode: String
    $statusOrder: OrderStatus
    $orderDateInput: DateFilter
    $pagination: PaginationInput
  ) {
    getOrderReferralCodeOnly(
      storeName: $storeName
      storeCode: $storeCode
      salesmanCode: $salesmanCode
      salesmanReferralCode: $salesmanReferralCode
      distributorCode: $distributorCode
      distributorName: $distributorName
      orderCode: $orderCode
      statusOrder: $statusOrder
      orderDateInput: $orderDateInput
      pagination: $pagination
    ) {
      orders {
        orderNumber
        storeCode
        storeName
        agentId
        agentName
        totalAmount
        orderDate
        salesmanReferralCode
        salesmanCode
        status
      }
      total
    }
  }
`;

export const GET_STORE_OWNER_REFERRAL_CODE_VERIFICATION = gql`
  query StoreOwnerVerificationReferralCodeAdvance(
    $filter: FilterStoreOwnerSalesReferralCodeInput
    $pagination: PaginationInput
  ) {
    storeOwnerVerificationSalesReferralCodeAdvance(
      filter: $filter
      pagination: $pagination
    ) {
      row {
        storeCode
        storeName
        userName
        phoneNumber
        salesmanReferralCode
        verificationStatus
        verifiedDate
        dateOfFiling
      }
      total
    }
  }
`;

export const GET_REFERRAL_STORE_PHOTO_VERIF = gql`
  query ReferralStorePhotoVerifAdvance(
    $requestVerificationFilter: ReferralStorePhotoVerifAdvanceInput
    $pagination: PaginationInput
  ) {
    referralStorePhotoVerifAdvance(
      requestVerificationFilter: $requestVerificationFilter
      pagination: $pagination
    ) {
      row {
        managerStoreCode
        storeName
        phoneNumber
        firstRevalidationSubmitDate
        requestVerificationStatus
        storePhotoSalesmanReferralCode
      }
      total
    }
  }
`;

export const DOWNLOAD_STORE_OWNER_REQUEST_VERIFICATION = gql`
  query DownloadStoreOwnerRequestVerification(
    $filter: FilterStoreOwnerSalesReferralCodeInput
  ) {
    downloadStoreOwnerRequestVerification(filter: $filter) {
      link
    }
  }
`;

export const DOWNLOAD_REFERRAL_STORE_PHOTO_VERIF = gql`
  query DownloadReferralSalesmanVerifStore(
    $requestVerificationFilter: ReferralStorePhotoVerifAdvanceInput
  ) {
    downloadReferralSalesmanVerifStore(
      requestVerificationFilter: $requestVerificationFilter
    ) {
      link
    }
  }
`;

export const DOWNLOAD_POINT_HISTORY_REDEEM = gql`
  query DownloadPointRedeemHistory(
    $storeCode: String
    $storeName: String
    $redeemSalesmanCode: String
    $userName: String
    $date: DateFilter
    $redeemStatus: RedeemStatus
    $source: LoyaltySource
    $phoneNumber: String
  ) {
    downloadLoyaltyPointRedeemHistory(
      storeCode: $storeCode
      storeName: $storeName
      redeemSalesmanCode: $redeemSalesmanCode
      userName: $userName
      date: $date
      redeemStatus: $redeemStatus
      source: $source
      phoneNumber: $phoneNumber
    ) {
      link
    }
  }
`;

export const DOWNLOAD_ORDER_REFERRAL_CODE_ONLY = gql`
  query DownloadOrderReferralCodeOnly(
    $storeName: String
    $storeCode: String
    $salesmanReferralCode: String
    $salesmanCode: String
    $distributorCode: Int
    $distributorName: String
    $orderCode: String
    $statusOrder: OrderStatus
    $orderDateInput: DateFilter
  ) {
    downloadOrderReferralCodeOnly(
      storeName: $storeName
      storeCode: $storeCode
      salesmanReferralCode: $salesmanReferralCode
      salesmanCode: $salesmanCode
      distributorCode: $distributorCode
      distributorName: $distributorName
      orderCode: $orderCode
      statusOrder: $statusOrder
      orderDateInput: $orderDateInput
    ) {
      link
    }
  }
`;
