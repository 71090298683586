function getDays(day: number) {
  let days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
  return days[day];
}

function getMonth(month: number) {
  let months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
  ];
  return months[month];
}

function getFullMonth(month: number) {
  let months = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'December',
  ];
  return months[month];
}

function getFormattedDate(date: Date) {
  let usedDate =
    date.getDate() < 10
      ? `0${date.getDate().toString()}`
      : date.getDate().toString();
  let usedMonth = getMonth(date.getMonth().valueOf());
  let usedYear = date.getFullYear().toString();

  return `${usedDate} ${usedMonth} ${usedYear}`;
}

function getFormattedSentenceDate(date: Date) {
  let usedDate = date.getDate().toString();
  let usedMonth = getFullMonth(date.getMonth().valueOf());
  let usedYear = date.getFullYear().toString();
  let usedDay = getDays(date.getDay());

  return `${usedDay}, ${usedDate} ${usedMonth} ${usedYear}`;
}

export { getFormattedDate, getFormattedSentenceDate };
