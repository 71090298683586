type ApiObject = {
  operationName: string;
};

type ApiParams = ApiObject & Record<'params', Record<string, unknown>>;
type ApiResponse = ApiObject & Record<'response', Record<string, unknown>>;

export default class ErrorLog {
  private static _currentScreenName = '';
  private static _latestApiParams: ApiParams;
  private static _latestApiResponse: ApiResponse;

  static getLogInfo() {
    return {
      currentScreen: this._currentScreenName,
      apiInfo: {
        latestRequestParams: { ...this._latestApiParams },
        latestResponse: { ...this._latestApiResponse },
      },
    };
  }

  static setCurrentScreen(newCurrentScreen: string) {
    this._currentScreenName = newCurrentScreen;
  }

  static getCurrentScreen() {
    return this._currentScreenName;
  }

  static setLatestApiParams(newApiParams: ApiParams) {
    this._latestApiParams = newApiParams;
  }

  static getLatestApiParams() {
    return this._latestApiParams;
  }

  static setLatestApiResponse(newApiResponse: ApiResponse) {
    this._latestApiResponse = newApiResponse;
  }

  static getLatestApiResponse() {
    return this._latestApiResponse;
  }
}
