import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import {
  getToken,
  setToken,
  removeToken,
  getExpDate,
  removeExpDate,
  setExpDate,
} from '../helpers';
import { ErrorModal } from '../components';
import { GET_ADMIN } from '../graphql/queries';
import { GetAdmin } from '../generated/GetAdmin';
import { adminAuthenticatedRoutes } from '../routes/routes';
import { routePaths as verificatorAuthenticatedRoutes } from '../features/verification/constants/routes';

type Auth = {
  isAuthenticated: boolean;
  id: string;
  name: string;
  role: string;
  login: (token: string) => void;
  logout: () => void;
};

const AuthContext = createContext<Auth>({
  isAuthenticated: false,
  id: '',
  name: '',
  role: '',
  login: () => {},
  logout: () => {},
});

type Props = { children?: ReactNode };
export const AuthProvider = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [id, setIdState] = useState('');
  const [name, setNameState] = useState('');
  const [role, setRoleState] = useState('');
  const [open, setOpen] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);

  const openModal = useCallback(() => setOpen(true), []);
  const closeModal = useCallback(() => setOpen(false), []);

  const inAuthenticatedRequiredRoute = useMemo(() => {
    const currentPath = location.pathname.split('/')[1];
    const inVerificatorRoute =
      Object.values(verificatorAuthenticatedRoutes).findIndex(
        (path) => (path as string).split('/')[1] === currentPath,
      ) !== -1;
    if (inVerificatorRoute) {
      return true;
    }
    const inAdminRoute =
      adminAuthenticatedRoutes.findIndex(
        ({ path }) => (path as string).split('/')[1] === currentPath,
      ) !== -1;
    return inAdminRoute;
  }, [location]);

  const { data, loading, refetch } = useQuery<GetAdmin>(GET_ADMIN, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: (error) => {
      const errors = error.graphQLErrors;
      if (
        isAuthenticated &&
        inAuthenticatedRequiredRoute &&
        errors.findIndex(
          ({ message }) =>
            message.includes('Not Authorised!') ||
            message.includes('Token is expire please login again'),
        ) !== -1
      ) {
        openModal();
      }
    },
  });

  useEffect(() => {
    const hasToken = async () => {
      const token = await getToken();
      if (token) {
        // NOTE: hypothetically set authenticated value. the next useEffect which will check whether it's valid
        setAuthenticated(true);
      } else {
        // NOTE: nothing else to check, so set auth loading to false
        setAuthLoading(false);
      }
    };

    hasToken();
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      // NOTE: here we check whether the token in store is valid
      const expDate = await getExpDate();
      const now = new Date();
      const isTokenExpired = expDate
        ? now.getTime() > new Date(expDate).getTime()
        : true;
      const isTokenValid = !!data;

      if (!isTokenExpired && isTokenValid && data) {
        // NOTE: at this point the token in store is proven valid
        setIdState(data.admin.id);
        setNameState(data.admin.name);
        setRoleState(data.admin.role);
        setAuthenticated(true);
      } else {
        // NOTE: the token in store is invalid (i.e. a login action of the same account has been made)
        openModal();
      }
      setAuthLoading(false);
    };

    if (inAuthenticatedRequiredRoute) {
      // NOTE: in a route that requires authentication so do token check
      if (!loading && isAuthenticated) {
        checkToken();
      }
    } else {
      // NOTE: in a route that doesn't require any authentication so skip token check
      setAuthLoading(false);
    }
  }, [
    inAuthenticatedRequiredRoute,
    isAuthenticated,
    data,
    loading,
    location,
    openModal,
  ]);

  useEffect(() => {
    const asyncRefetch = async () => {
      try {
        await refetch();
      } catch (_) {
        // NOTE: error handled by onError
      }
    };
    asyncRefetch();
  }, [location, refetch]);

  const login = useCallback(
    async (token: string) => {
      setAuthLoading(true);
      const today = new Date().setHours(23, 59, 59, 999);
      await setToken(token);
      await setExpDate(new Date(today).toISOString());
      await refetch();
      setAuthenticated(true);
    },
    [refetch],
  );

  const logout = useCallback(async () => {
    await removeToken();
    await removeExpDate();
    setAuthenticated(false);
    closeModal();
    if (history?.location.pathname !== '/') {
      history?.push('/');
    }
  }, [history, closeModal]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, id, name, role, login, logout }}
    >
      <ErrorModal
        open={open}
        onClose={logout}
        buttonTitle={t(['Lanjutkan', 'Continue'])}
        message={t([
          'Sesi anda telah berakhir. Silakan login kembali untuk melanjutkan.',
          'Your session has expired. Please re-login to continue.',
        ])}
      />
      {authLoading ? (
        <View style={styles.root}>
          <ActivityIndicator />
        </View>
      ) : (
        props.children
      )}
    </AuthContext.Provider>
  );
};

const styles = StyleSheet.create({
  root: { flex: 1, alignItems: 'center', justifyContent: 'center' },
});

export const useAuth = () => useContext(AuthContext);
