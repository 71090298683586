import {
  API_URL,
  LINK_MANAGER_STORE,
  SENTRY_ENVIRONMENT,
  LINKING_URL,
} from 'react-native-dotenv';

export const LOCAL_API = 'http://localhost:3000/graphql';
export const STAGING_API = API_URL ? API_URL : 'https://dev.api.dbo.id/graphql';
export const SENTRY_DSN =
  'https://513feeaf458c41ccac149a05eefcaaca@o425879.ingest.sentry.io/5367155';

export const LINK_MANAGER_STORE_VERIFY = LINK_MANAGER_STORE
  ? LINK_MANAGER_STORE
  : 'https://stage-mngr.dbo.id:81/admin/stores';

export const ENVIRONMENT =
  SENTRY_ENVIRONMENT === 'production'
    ? 'production'
    : SENTRY_ENVIRONMENT === 'staging'
    ? 'staging'
    : SENTRY_ENVIRONMENT === 'production-prelive'
    ? 'production-prelive'
    : 'dev';

export const DEEP_LINK_URL = LINKING_URL
  ? LINKING_URL
  : 'https://staging.customer.dbo.co.id/redirect/';
