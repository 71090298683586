import React, { useState, useCallback, ReactNode, useMemo, memo } from 'react';
import { Image, StyleSheet } from 'react-native';
import { ButtonBase, Popover, makeStyles } from '@material-ui/core';

import { theme, colors } from '../constants/theme';
import { infotip } from '../../../../assets';

import Text from './Text';

const useStyles = makeStyles({
  paper: {
    backgroundColor: colors.popover.primary.background,
    padding: `${theme.spacing.m}px`,
  },
  smallButton: { height: 16, width: 16 },
});

type Props = { children?: ReactNode };

const Infotip = memo((props: Props) => {
  let { children } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);
  const onPress = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) =>
      setAnchorEl(event.currentTarget),
    [setAnchorEl],
  );

  const wrappedChildren = useMemo(
    () =>
      typeof children === 'string' ? (
        <Text color="secondaryBlack">{children}</Text>
      ) : (
        children
      ),
    [children],
  );

  return (
    <>
      <ButtonBase
        disableRipple
        onClick={onPress}
        classes={{
          root: classes.smallButton,
        }}
      >
        <Image source={infotip} style={styles.icon} />
      </ButtonBase>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        elevation={2}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: -8 }}
        onClose={onClose}
      >
        {wrappedChildren}
      </Popover>
    </>
  );
});

let styles = StyleSheet.create({
  icon: { width: 16, height: 16 },
});

export default Infotip;
