import gql from 'graphql-tag';

import {
  EVENT_ONLINE_FORM_FRAGMENT,
  COMPETITION_ONLINE_FORM_FRAGMENT,
} from './onlineForm';

export const EVENT_FRAGMENT = gql`
  fragment EventFragment on Event {
    id
    appUser
    region
    city
    eventDate
    name
    desc
    descSalesman
    urlDeeplinkSalesman
    venue
    registrationDeadline
    externalLink
    brand {
      id
      name
      businessUnitContact {
        id
        name
        email
      }
    }
    formLink {
      ...EventFormFragment
    }
    image
    dedicatedPageImage
    liveDate
    endLiveDate
    status
    externalButton
  }

  ${EVENT_ONLINE_FORM_FRAGMENT}
`;

export const NEWS_FRAGMENT = gql`
  fragment NewsFragment on News {
    id
    appUser
    region
    city
    startDate
    title
    desc
    descSalesman
    urlDeeplinkSalesman
    brand {
      id
      brandId
      name
    }
    businessUnitContact {
      id
      name
      brand {
        id
        name
      }
    }
    brandId
    range
    rangeId
    image
    dedicatedPageImage
    liveDate
    endLiveDate
    clickCount
    status
    externalLink
    internalLink {
      id
      name
      type
      key
    }
    typeLink
    externalButton
  }
`;

export const COMPETITION_FRAGMENT = gql`
  fragment CompetitionFragment on Competition {
    id
    appUser
    region
    city
    eventDate
    name
    desc
    descSalesman
    urlDeeplinkSalesman
    closeDate
    medsos {
      id
      socialMediaType
      link
    }
    winners {
      id
      name
      email
      notified
      storeName
    }
    externalLink
    brand {
      id
      name
      businessUnitContact {
        id
        name
        email
      }
    }
    formLink {
      ...CompetitionFormFragment
    }
    image
    dedicatedPageImage
    liveDate
    endLiveDate
    status
    externalButton
  }
  ${COMPETITION_ONLINE_FORM_FRAGMENT}
`;
