export const pageTitle = {
  AddNewBrandPage: 'Add New Brand Page',
  AdminHubScene: 'Admin Hub',
  ArchiveScene: 'Archive',
  DboNewsScene: 'Dbo News',
  LibraryScene: 'Library',
  LoginScene: 'Login',
  BrandPagesScene: 'Brand Page',
  HomepageSliderScene: 'Homepage Slider',
  SeeAllPromotionsScene: 'Promotion',
  OnlineFormScene: 'Online Form',
  LogoLibraryScene: 'Logo Library',
  PopUpScene: 'Pop Up',
  TokoUserScene: 'Toko User',
  AgentRequestStatusScene: 'Distributor Request Status',
  SuccessVerifyEmailScene: 'Success Verify Email',
  SubmittedEventForm: 'Submitted Event Form',
  SubmittedCompetitionForm: 'Submitted Competition Form',
  OnlineFormWebView: 'Online Form Web View',
  BusinessUnitFacing: 'Business Unit Facing',
  LoyaltyPointStoreList: 'Loyalty Point Store List',
  PointHistoryScene: 'Point History',
  DownloadAppScene: 'Download App',
  BulkLoyaltyInputScene: 'Bulk Point Input',
  BulkPointReviewScene: 'Bulk Point Review',
  TokoVerification: 'Toko Verification',
  OrderDetail: 'Order Detail',
  DeepLinkList: 'Deep Link List',
  AutomaticNotificationList: 'Automatic Notification List',
  AutomaticNotificationEdit: 'Edit Automatic Notification',
  // UserTierListScene: 'Data User VIP & Non-VIP',
};
