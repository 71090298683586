import React, { useMemo, useEffect, useCallback, memo } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_BRAND_RANGES } from '../../graphql/queries/product';
import {
  BrandRangeParentAndChild,
  BrandRangeParentAndChildVariables,
} from '../../generated/BrandRangeParentAndChild';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultTitle = t(['Pilih Range', 'Select Range']);

type Props = Omit<DropdownProps, 'options' | 'title'> & {
  brandID?: string;
  title?: string;
};

const BrandRangeDropdown = memo((props: Props) => {
  let { title = defaultTitle, brandID, ...otherProps } = props;

  const [getBrandRanges, { data, loading, error }] = useLazyQuery<
    BrandRangeParentAndChild,
    BrandRangeParentAndChildVariables
  >(GET_BRAND_RANGES);
  const refetchData = useCallback(() => {
    if (brandID) {
      getBrandRanges({ variables: { brandId: brandID } });
    }
  }, [brandID, getBrandRanges]);

  useEffect(refetchData, [brandID, refetchData]);

  const options = useMemo(
    () =>
      data?.brandRangeParentAndChild.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [data],
  );

  return (
    <Dropdown
      error={error}
      errorAction={t(['mengambil data range', 'retrieve the range data'])}
      onRetryPress={refetchData}
      options={options}
      loading={loading}
      title={title}
      {...otherProps}
    />
  );
});

export default BrandRangeDropdown;
