import gql from 'graphql-tag';

import { BRAND_IMAGE_FRAGMENT } from '../fragments';

export const BRANDS = gql`
  query Brands($where: BrandWhereInput, $showAllLocalBrand: Boolean) {
    brands(where: $where, showAllLocalBrand: $showAllLocalBrand) {
      id
      brandId
      name
      businessUnitContact {
        id
        name
        email
        phoneNumber
        requestAgentReportActiveSendEmail
      }
    }
  }
`;

export const GET_BRAND_LOGOS = gql`
  query BrandLogos($brandID: ID!) {
    brand(id: $brandID) {
      id
      logos {
        ...BrandImageFragment
      }
    }
  }
  ${BRAND_IMAGE_FRAGMENT}
`;

export const GET_BRAND_PAGE = gql`
  query BrandPage($brandID: ID!) {
    brand(id: $brandID) {
      id
      logos {
        ...BrandImageFragment
      }
      brandPage {
        id
        headerImage
        name
        logoLibrary {
          ...BrandImageFragment
        }
        about
        brandRange(orderBy: { createdAt: asc }) {
          id
          availableIn
          name
          liveDate
          description
          rangeId
          image
        }
        status
      }
    }
  }
  ${BRAND_IMAGE_FRAGMENT}
`;
