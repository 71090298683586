import React, { useMemo, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import { GET_VILLAGES } from '../../graphql/queries';
import { Villages, VillagesVariables } from '../../../../generated/Villages';

import Dropdown, { Props as DropdownProps } from './Dropdown';

const defaultPlaceholder = t(['- Pilih Kelurahan -', '- Select Village -']);

type Props = Omit<
  DropdownProps<string>,
  'options' | 'multiple' | 'value' | 'onChange'
> & {
  value: string | null;
  onChange: (value: string, label: string) => void;
  districtID?: string;
};

const VillageDropdown = (props: Props) => {
  let { placeholder = defaultPlaceholder, districtID, ...otherProps } = props;

  //TODO: add error handling
  const [getVillages, { data }] = useLazyQuery<Villages, VillagesVariables>(
    GET_VILLAGES,
  );

  const fetchVillages = useCallback(() => {
    if (districtID) {
      getVillages({ variables: { districtID } });
    }
  }, [districtID, getVillages]);

  useEffect(fetchVillages, [fetchVillages]);

  const options = useMemo(
    () =>
      districtID
        ? data?.villageByDistrictId.map(({ id, name }) => ({
            label: name,
            value: id.toString(),
          })) ?? []
        : [],
    [districtID, data],
  );

  return (
    <Dropdown options={options} placeholder={placeholder} {...otherProps} />
  );
};

export default VillageDropdown;
