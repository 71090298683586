import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';

import { Text } from '../../core-ui';
import { colors } from '../../constants/theme';
import { toDdMmYyyy } from '../../../../helpers';

import { OwnerLog, OwnerLogChanges } from './types';

type Props = {
  // data?: GetUpdatedUserHistory;
  currentData: string;
  updatedData: string;
};

export default function CollapsedItem(props: Props) {
  const { currentData, updatedData } = props;

  useEffect(() => {
    processData(currentData, updatedData);
  }, [currentData, updatedData]);

  let [changes, setChanges] = useState<OwnerLogChanges>({
    birthDate: false,
    email: false,
    gender: false,
    googleSheetFormNumber: false,
    name: false,
    phoneNumber: false,
    ownerVerified: false,
  });

  let [data, setData] = useState<OwnerLog>({
    phoneNumber: '',
    googleSheetFormNumber: '',
    ownerVerified: false,
    status: '',
    profile: {
      update: {
        birthDate: '',
        email: '',
        gender: '',
        name: '',
        reUpload: false,
        slug: '',
      },
    },
  });

  let processData = (currentData: string, updatedData: string) => {
    let currentChanges: OwnerLog = currentData && JSON.parse(currentData);
    let updatedChanges: OwnerLogChanges =
      currentData && JSON.parse(updatedData);

    setChanges(updatedChanges);
    setData(currentChanges);
  };

  return (
    <View>
      {/* rows */}
      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View style={changes.name ? styles.bluePoint : styles.blank} />
          <Text
            style={changes.name ? styles.highlightedText : styles.normalText}
          >
            Nama
          </Text>
        </View>
        <Text style={changes.name ? styles.highlightedText : styles.normalText}>
          {data.profile.update.name || '-'}
        </Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}
      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View style={changes.phoneNumber ? styles.bluePoint : styles.blank} />
          <Text
            style={
              changes.phoneNumber ? styles.highlightedText : styles.normalText
            }
          >
            Nomor HP
          </Text>
        </View>
        <Text
          style={
            changes.phoneNumber ? styles.highlightedText : styles.normalText
          }
        >
          {data.phoneNumber || '-'}
        </Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}

      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View style={changes.email ? styles.bluePoint : styles.blank} />
          <Text
            style={changes.email ? styles.highlightedText : styles.normalText}
          >
            Email
          </Text>
        </View>
        <Text
          style={changes.email ? styles.highlightedText : styles.normalText}
        >
          {data.profile.update.email || '-'}
        </Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}

      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View style={changes.gender ? styles.bluePoint : styles.blank} />
          <Text
            style={changes.gender ? styles.highlightedText : styles.normalText}
          >
            Jenis Kelamin
          </Text>
        </View>
        <Text
          style={changes.gender ? styles.highlightedText : styles.normalText}
        >
          {data.profile.update.gender || '-'}
        </Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}

      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View style={changes.birthDate ? styles.bluePoint : styles.blank} />
          <Text
            style={
              changes.birthDate ? styles.highlightedText : styles.normalText
            }
          >
            Tanggal Lahir
          </Text>
        </View>
        <Text
          style={changes.birthDate ? styles.highlightedText : styles.normalText}
        >
          {toDdMmYyyy(new Date(data.profile.update.birthDate))}
        </Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}
      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View
            style={changes.ownerVerified ? styles.bluePoint : styles.blank}
          />
          <Text
            style={
              changes.ownerVerified ? styles.highlightedText : styles.normalText
            }
          >
            Status Verifikasi Owner
          </Text>
        </View>
        <Text
          style={
            changes.ownerVerified ? styles.highlightedText : styles.normalText
          }
        >
          {!!data.ownerVerified ? 'Verified' : 'Unverified'}
        </Text>
      </View>

      {/* ////////////////////////////////////////////////////////////////////////////// */}
      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View style={styles.blank} />
          <Text style={styles.normalText}>Status Owner</Text>
        </View>
        <Text style={styles.normalText}>{data.status || '-'}</Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}

      <View style={styles.containers}>
        <View style={styles.containerPoint}>
          <View
            style={
              changes.googleSheetFormNumber ? styles.bluePoint : styles.blank
            }
          />
          <Text
            style={
              changes.googleSheetFormNumber
                ? styles.highlightedText
                : styles.normalText
            }
          >
            Nomor Data Form
          </Text>
        </View>

        <Text
          style={
            changes.googleSheetFormNumber
              ? styles.highlightedText
              : styles.normalText
          }
        >
          {data.googleSheetFormNumber || '-'}
        </Text>
      </View>
      {/* ////////////////////////////////////////////////////////////////////////////// */}

      {/* seperator */}
      <View style={styles.footNote}>
        <Text style={styles.normalText}>
          *Indikator berwarna biru menandakan adanya perubahan data
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  containers: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    marginTop: 12,
  },
  bluePoint: {
    width: 6,
    marginRight: 8,
    height: 6,
    backgroundColor: colors.background.blue,
    borderRadius: 3,
  },
  blank: {
    width: 6,
    marginRight: 8,
    height: 6,
    backgroundColor: 'transparent',
    borderRadius: 3,
  },
  normalText: { color: colors.text.black, opacity: 0.6 },
  highlightedText: { color: colors.text.black, fontWeight: 'bold' },
  containerPoint: { alignItems: 'center', flexDirection: 'row' },
  footNote: {
    flexDirection: 'row',
    marginTop: 12,
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderTopWidth: 1,
    borderColor: colors.border.primary,
  },
});
