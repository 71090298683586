import gql from 'graphql-tag';

import { BULK_POINT_WITHOUT_DETAILS_FRAGMENT } from '../fragments';

export const SEND_POINT_TO_DATABASE = gql`
  mutation SendPointToDatabase($bulkPointId: ID!) {
    sendGivenPointToDB(bulkPointId: $bulkPointId) {
      success
    }
  }
`;

export const CANCEL_BULK_POINT = gql`
  mutation CancelBulkPoint($bulkPointId: ID!) {
    cancelBulkPoint(bulkPointId: $bulkPointId) {
      success
    }
  }
`;

export const UPLOAD_BULK_POINT = gql`
  mutation UploadBulkPoint($file: Upload!) {
    uploadBulkPoint(file: $file) {
      ...BulkPointWithoutDetailsFragment
    }
  }
  ${BULK_POINT_WITHOUT_DETAILS_FRAGMENT}
`;
