import React, { ReactNode, Fragment, useEffect, useState, useRef } from 'react';
import { AsyncStorage } from 'react-native';

type StringTuple = [string, string];

type ParamsObject = {
  [key: string]: unknown;
};

type Primitive = null | undefined | boolean | number | string;
type Resolver = (s: string) => ReactNode;
type FragParams = {
  [key: string]: Primitive | Resolver;
};

type Listener = () => void;

const STORAGE_KEY = '@locale';
const PLACEHOLDER = /\{(\w+)\}/g;
const FRAGMENT = /<(\w+)>(.*?)<\/\1>/g;

// Here the default locale goes first.
let locales = ['id-ID', 'en-US'] as const;
let currentLocaleIndex: 0 | 1 = 0;
let listeners: Set<Listener> = new Set();

type Locale = typeof locales[number];

export function getLocale() {
  return locales[currentLocaleIndex];
}

export function toggleLocale() {
  let newIndex = Math.abs(currentLocaleIndex - 1);
  setLocale(locales[newIndex]);
}

export function setLocale(locale: Locale) {
  let localeIndex = locales.findIndex((l) => l === locale);
  if (localeIndex === 0 || localeIndex === 1) {
    if (localeIndex !== currentLocaleIndex) {
      currentLocaleIndex = localeIndex;
      // Notify event listeners that the locale has changed.
      for (let listener of listeners) {
        listener();
      }
    }
  }
}

// This allows the app to listen for when the locale changes.
export function onLocaleChange(listener: Listener) {
  listeners.add(listener);
  let unsubscribe = () => {
    listeners.delete(listener);
  };
  return unsubscribe;
}

export function t(tuple: StringTuple, params?: ParamsObject): string {
  let input = tuple[currentLocaleIndex];
  if (params) {
    return input.replace(PLACEHOLDER, (fallback: string, key: string) =>
      key in params ? toString(params[key]) : fallback,
    );
  } else {
    return input;
  }
}

// Process a string with fragments inside.
t.frag = (tuple: StringTuple, params: FragParams): Array<ReactNode> => {
  let input = t(tuple, params);
  let results: Array<ReactNode> = [];
  let key = 0;
  // Wrap an item in a Fragment with a key before pushing to the results.
  let push = (item: ReactNode) => {
    results.push(<Fragment key={++key}>{item}</Fragment>);
  };
  let lastIndex = 0;
  // This next part will replace fragments `<foo>abc</bar>`
  input.replace(
    FRAGMENT,
    (match: string, tagName: string, content: string, index: number) => {
      let textBefore = input.slice(lastIndex, index);
      lastIndex = index + match.length;
      push(textBefore);
      let resolver = params[tagName];
      push(typeof resolver === 'function' ? resolver(content) : match);
      return '';
    },
  );
  let textAfter = input.slice(lastIndex);
  push(textAfter);
  return results;
};

// This simply forces the app to unmount and re-mount on locale change.
export function LocaleProvider(props: {
  onChange?: () => unknown;
  children: ReactNode;
}) {
  let { onChange, children } = props;
  let [loading, setLoading] = useState(true);
  let onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  useEffect(() => {
    (async () => {
      let savedLocale = await AsyncStorage.getItem(STORAGE_KEY);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      savedLocale && setLocale(savedLocale as any);
      onLocaleChange(async () => {
        setLoading(true);
        await AsyncStorage.setItem(STORAGE_KEY, getLocale());
        // This allows the app to clear the Apollo cache or whatever. It
        // can be async, but doesn't need to be.
        await onChangeRef.current?.();
        setLoading(false);
      });
      setLoading(false);
    })();
  }, []);
  return loading ? null : <Fragment>{children}</Fragment>;
}

function toString(value: unknown) {
  if (value == null) {
    return '';
  }
  return isPrimitive(value)
    ? String(value)
    : // This is a bit hacky, but it makes sure we don't accidentally expose some source code.
      Object.prototype.toString.call(value);
}

function isPrimitive(value: unknown) {
  return Object(value) !== value;
}
