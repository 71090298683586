import React, { useMemo, useCallback, useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { useMutation } from '@apollo/react-hooks';

import { Text, Button } from '../../core-ui';
import { ErrorModal } from '../../components';
import { spacing, colors } from '../../constants/theme';
import { PopUpFragment } from '../../generated/PopUpFragment';
import { ARCHIVE_POP_UP } from '../../graphql/mutations';
import {
  ArchivePopUp,
  ArchivePopUpVariables,
} from '../../generated/ArchivePopUp';
import { PopUpColor } from '../../generated/globalTypes';

type Props = {
  index: number;
  edit: boolean;
  popUp: PopUpFragment;
  onArchive: () => void;
  setEditPopUp: (value: PopUpFragment | null) => void;
};

const CustomPopUp = (props: Props) => {
  const { popUp, edit, setEditPopUp } = props;
  const {
    id,
    name,
    image,
    title,
    textLine1,
    textLine2,
    textLine3,
    color,
  } = popUp;

  const [errorOpen, setErrorOpen] = useState(false);
  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const openErrorModal = useCallback(() => setErrorOpen(true), []);

  const [
    archive,
    { loading: archiveLoading, error: archiveError },
  ] = useMutation<ArchivePopUp, ArchivePopUpVariables>(ARCHIVE_POP_UP, {
    onCompleted: props.onArchive,
    onError: openErrorModal,
  });

  const cardBackgroundColor = useMemo(() => {
    switch (color) {
      case PopUpColor.BLUE:
        return styles.bluePopUp;
      case PopUpColor.GOLD:
        return styles.goldPopUp;
      case PopUpColor.RED:
        return styles.redPopUp;
      default:
        return null;
    }
  }, [color]);

  const editTitle = edit ? t(['Batal', 'Cancel']) : t(['Ubah', 'Edit']);

  const onEdit = useCallback(() => {
    if (edit) {
      setEditPopUp(null);
    } else {
      setEditPopUp(popUp);
    }
  }, [popUp, edit, setEditPopUp]);
  const onArchive = useCallback(() => archive({ variables: { popUpId: id } }), [
    archive,
    id,
  ]);

  return (
    <View style={styles.root}>
      <ErrorModal
        open={errorOpen}
        action={t([
          'mengubah status arsip data pop up',
          'change the archive status of the pop up data',
        ])}
        error={archiveError}
        onClose={closeErrorModal}
      />
      <View style={styles.xSmallBottom}>
        <View style={[styles.popUpSize, cardBackgroundColor]}>
          {image ? (
            <Image
              data-cy="popup-list-image"
              source={{ uri: image }}
              style={styles.popUpSize}
              resizeMethod="resize"
              resizeMode="contain"
            />
          ) : (
            <View style={styles.popUpBox}>
              <Text
                data-cy="popup-list-title"
                bold
                color="contrast"
                style={styles.xSmallBottom}
              >
                {title}
              </Text>
              <Text
                data-cy="popup-list-line1"
                color="contrast"
                size="small"
                style={styles.xxSmallBottom}
              >
                {textLine1}
              </Text>
              <Text
                data-cy="popup-list-line2"
                color="contrast"
                size="small"
                style={styles.xxSmallBottom}
              >
                {textLine2}
              </Text>
              <Text
                data-cy="popup-list-line3"
                color="contrast"
                size="small"
                style={styles.xxSmallBottom}
              >
                {textLine3?.split(/\{(\w+)\}/).map((content, index) => {
                  if (index === 1) {
                    return (
                      <Text color="contrast" size="small" bold>
                        {content}
                      </Text>
                    );
                  }
                  return content;
                })}
              </Text>
            </View>
          )}
        </View>
      </View>
      <View>
        <Text data-cy="popup-list-name">{name}</Text>
        <View style={styles.actions}>
          <Button
            data-cy="popup-list-edit"
            preset="transparent"
            title={editTitle}
            onPress={onEdit}
            titleTextProps={{ size: 'small' }}
          />
          <Text color="link">|</Text>
          <Button
            data-cy="popup-list-archive"
            preset="transparent"
            title={t(['Arsip', 'Archive'])}
            onPress={onArchive}
            isLoading={archiveLoading}
            titleTextProps={{ size: 'small' }}
            disabled={!edit}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: { paddingRight: 10, paddingBottom: 10 },
  popUpSize: { height: 150, width: 250 },
  popUpBox: {
    flex: 1,
    paddingHorizontal: spacing.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bluePopUp: { backgroundColor: colors.popUp.blue },
  goldPopUp: { backgroundColor: colors.popUp.gold },
  redPopUp: { backgroundColor: colors.popUp.red },
  actions: { flexDirection: 'row', alignItems: 'center' },
  xSmallBottom: { paddingBottom: spacing.xsmall },
  xxSmallBottom: { paddingBottom: spacing.xxsmall },
});

export default CustomPopUp;
