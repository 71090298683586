import React, { Dispatch, ReactNode, SetStateAction, useCallback } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import { colors, spacing } from '../../../constants/theme';

type Props = {
  children: ReactNode;
  minHeight: number;
  setMinHeight: Dispatch<SetStateAction<number>>;
};

export default function FilterContainer({
  children,
  minHeight,
  setMinHeight,
}: Props) {
  const setMinimumHeight = useCallback(
    ({ nativeEvent }: LayoutChangeEvent) => {
      if (nativeEvent.layout.height > minHeight) {
        setMinHeight(nativeEvent.layout.height);
      }
    },
    [minHeight, setMinHeight],
  );

  return (
    <View style={[styles.separator, { minHeight }]} onLayout={setMinimumHeight}>
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  separator: {
    paddingBottom: spacing.xxsmall,
    marginBottom: spacing.xxsmall,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
});
