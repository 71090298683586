import React, {
  ReactNode,
  useState,
  useCallback,
  useMemo,
  CSSProperties,
} from 'react';
import clsx from 'clsx';
import { View } from 'react-native';
import { Table as MTable, TableContainer, makeStyles } from '@material-ui/core';

import { Order } from '../../helpers';
import { colors, theme } from '../../constants/theme';
import type { Props as TextProps } from '../Text';

import TableHead from './TableHead';
import TableBody from './TableBody';
import TableFooter from './TableFooter';

type StructureElement<T, P = {}> = {
  align?: 'left' | 'center' | 'right' | 'justify';
  'data-cy'?: string;
  disablePadding?: boolean;
  displayText?: string;
  /**
   * dataPath
   * type: string, a dot separated path to traverse the data object
   * type: string array, an array of dot separated path, resolves the first path that yields a value if any
   */
  dataPath?: string | Array<string>;
  headerTitle?: string;
  headerTitleColor?: keyof typeof colors.text;
  noHeaderTitle?: boolean;
  orderable?: boolean;
  withLeftBorder?: boolean;
  textProps?: P | ((data: T) => P);
  getStyle?: (data: T) => CSSProperties | undefined;
  onCellClick?: (data: T) => void;
  disabled?: (data: T) => boolean;
  onPress?: (data: T) => void;
  render?: (data: T) => ReactNode;
  valueProcessor?: (data: string, index: number) => string;
  valuePreProcessor?: (data: unknown) => unknown;
  compareFn?: (a: T, b: T) => number;
};

type TableStructure<T, U, P = {}> = {
  [key in keyof U]?: StructureElement<T, P>;
};

export type LoadMoreParams = {
  skip: number;
  first: number;
};

export type Props<T, U, P = TextProps> = {
  'data-cy'?: string;
  data: Array<T>;
  dataCount?: number;
  error?: boolean;
  loading?: boolean;
  emptyPlaceholder?: string;
  stickyHeader?: boolean;
  structure: TableStructure<T, U, P>;
  disablePadding?: boolean;
  withSeparator?: boolean;
  rowsPerPage?: number;
  page?: number;
  order?: Order;
  separatorType2?: boolean;
  override?: boolean;
  isHighlightedHead?: boolean;
  orderBy?: string;
  noHeaderUpperCase?: boolean;
  rowsPerPageOptions?: Array<number>;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >;
};

const useStyles = makeStyles({
  root: { width: '100%', overflowX: 'scroll' },
  removeBorder: { borderWidth: 0 },
  removeHorizontalPadding: { padding: '6px 0 6px' },
  separator: { borderLeftWidth: 0, borderRightWidth: 0 },
  separatorTypeTwo: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    borderColor: 'white',
    backgroundColor: theme.colors.table.head,
  },
  highlightedHead: {
    backgroundColor: theme.colors.table.head,
    padding: '6px 6px 6px 6px',
  },
});

const Table = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends ObjectOf<any>, // NOTE: the shape of the data, ObjectOf<unknown> doesn't work with interfaces from codegen
  U extends { [key in keyof T | string]: unknown } // NOTE: the shape of the flattened data
>(
  props: Props<T, U>,
) => {
  let {
    data,
    dataCount,
    error,
    loading,
    emptyPlaceholder = t(['Tidak ada data', 'No data available']),
    stickyHeader,
    structure,
    disablePadding = false,
    withSeparator = false,
    rowsPerPage,
    page,
    order: orderProp,
    orderBy: orderByProp,
    onRequestSort,
    noHeaderUpperCase,
    onChangeRowsPerPage,
    onChangePage,
    rowsPerPageOptions,
    isHighlightedHead,
    override,
    separatorType2,
  } = props;
  const {
    root,
    removeBorder,
    removeHorizontalPadding,
    separator,
    separatorTypeTwo,
    highlightedHead,
  } = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const handleRequestSort = useCallback(
    (_event: React.MouseEvent<unknown>, property: string) => {
      if (orderBy === property) {
        // NOTE: currently selected order property

        if (order === 'asc') {
          // NOTE: if currently in ascending, change to descending
          setOrder('desc');
        } else {
          // NOTE: otherwise, reset the order property to remove ordering
          setOrderBy(null);
        }
      } else {
        // NOTE: newly selected order property
        setOrder('asc');
        setOrderBy(property);
      }
    },
    [orderBy, order],
  );

  const cellClasses = useMemo(
    () => ({
      root: clsx(
        withSeparator ? separator : removeBorder,
        disablePadding && removeHorizontalPadding,
        separatorType2 && separatorTypeTwo,
        isHighlightedHead && highlightedHead,
      ),
    }),
    [
      withSeparator,
      isHighlightedHead,
      separatorType2,
      disablePadding,
      separator,
      removeBorder,
      removeHorizontalPadding,
      separatorTypeTwo,
      highlightedHead,
    ],
  );

  return (
    <View>
      <TableContainer classes={{ root }}>
        <MTable
          stickyHeader={stickyHeader}
          size="small"
          data-cy={props['data-cy']}
        >
          <TableHead
            data-cy={`${props['data-cy']}-head`}
            data={data}
            structure={structure}
            order={orderProp ?? order}
            orderBy={orderByProp ?? orderBy}
            cellClasses={cellClasses}
            noUpperCase={noHeaderUpperCase}
            onRequestSort={onRequestSort ?? handleRequestSort}
          />
          <TableBody
            data-cy={`${props['data-cy']}-body`}
            data={data}
            loading={loading}
            error={error}
            structure={structure}
            order={order}
            orderBy={orderBy}
            cellClasses={cellClasses}
            rowClasses={cellClasses}
            override={override}
            emptyPlaceholder={emptyPlaceholder}
          />
        </MTable>
      </TableContainer>
      {onChangePage && (
        <TableContainer>
          <MTable>
            <TableFooter
              rowsPerPageOptions={rowsPerPageOptions}
              page={page}
              data-cy={`${props['data-cy']}-footer`}
              onChangePage={onChangePage}
              count={dataCount}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              disableLoadMore={!onChangePage}
            />
          </MTable>
        </TableContainer>
      )}
    </View>
  );
};

export default Table;
