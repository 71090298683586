import React, { memo, ComponentType } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { Button } from '../core-ui';
import { colors } from '../constants/theme';

type Props = {
  'data-cy'?: string;
  options: Array<string>;
  selected: number;
  setSelected: (value: number) => void;
  containerStyle?: StyleProp<ViewStyle>;
  ButtonComponent?: ComponentType;
  disabled?: boolean;
};

const SegmentedControl = memo((props: Props) => {
  let {
    options,
    selected,
    setSelected,
    containerStyle,
    ButtonComponent = Button,
    disabled,
  } = props;

  return (
    <View style={[styles.root, containerStyle, disabled && styles.disabled]}>
      {options.map((option, index) => (
        <View key={index} style={styles.buttonWrapper}>
          <ButtonComponent
            data-cy={`${props['data-cy']}-${index}`}
            style={selected === index ? styles.selectedTab : {}}
            titleTextProps={{
              color: 'black',
              style: { textTransform: 'none' },
            }}
            title={option}
            onPress={() => setSelected(index)}
            preset={selected === index ? 'secondary' : 'transparent'}
            disabled={disabled}
          />
        </View>
      ))}
    </View>
  );
});

let styles = StyleSheet.create({
  root: {
    alignSelf: 'flex-start', //NOTE: this is to make the root view size fit to the content
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
    backgroundColor: colors.segmentedControl.background,
  },
  buttonWrapper: {
    flex: 1,
    padding: 3,
  },
  selectedTab: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.button.border.primary,
  },
  disabled: {
    backgroundColor: colors.textInput.background.disabled,
  },
});

export default SegmentedControl;
