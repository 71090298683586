import gql from 'graphql-tag';

export const GET_UPDATED_USER_HISTORY = gql`
  query GetUpdatedUserHistory($verificationId: String!) {
    getUpdatedUserHistory(verificationId: $verificationId) {
      currentData
      updatedAttributes
      createdAt
    }
  }
`;

export const GET_UPDATED_STORE_HISTORY = gql`
  query GetUpdatedStoreHistory($verificationId: String!) {
    getUpdatedStoreHistory(verificationId: $verificationId) {
      currentData
      updatedAttributes
      createdAt
    }
  }
`;
