import gql from 'graphql-tag';

import { BRAND_IMAGE_FRAGMENT } from '../fragments';

export const LOGO_LIBRARY = gql`
  query LogoLibrary($brandID: String) {
    brands(where: { id: { equals: $brandID } }) {
      id
      name
      logos(orderBy: { createdAt: asc }) {
        ...BrandImageFragment
      }
    }
  }
  ${BRAND_IMAGE_FRAGMENT}
`;
