import { RouteProps } from 'react-router-dom';

import {
  AddNewBrandPage,
  AdminHubScene,
  ArchiveScene,
  DboNewsScene,
  LibraryScene,
  LoginScene,
  BrandPagesScene,
  HomepageSliderScene,
  SeeAllPromotionsScene,
  OnlineFormScene,
  LogoLibraryScene,
  PopUpScene,
  TokoUserScene,
  AgentRequestStatusScene,
  SuccessVerifyEmailScene,
  InvalidLink,
  PageNotFound,
  SubmittedEventForm,
  SubmittedCompetitionForm,
  OnlineFormWebView,
  BusinessUnitFacing,
  LoyaltyPointStoreList,
  PointHistoryScene,
  DownloadAppScene,
  BulkLoyaltyInputScene,
  BulkPointReviewScene,
  // UserTierListScene,
  // AutomaticNotificationList,
  // AutomaticNotificationEdit,
  GamificationScene,
} from '../scenes';
import { TokoVerification } from '../features/verification/scenes';
import { routePaths } from '../constants/routes';
import OrderDetail from '../features/loyaltyPoint/scenes/OrderDetail';
import { DeepLinkList } from '../scenes/deepLink';
import SalesmanReferralCodeScene from '../scenes/salesmanReferralCode/salesmanReferralCodeScene';
import { InvalidDataHistory } from '../scenes/gamification/Component';

const commonRoutes = [
  // routes that don't care about the user's logged in state; the other routes from unauthenticatedRoutes besides '/'
  {
    path: routePaths.verificationSuccessful,
    exact: true,
    component: SuccessVerifyEmailScene,
  },
  {
    path: routePaths.pageNotFound,
    exact: true,
    component: PageNotFound,
  },
  {
    path: routePaths.invalidLink,
    exact: true,
    component: InvalidLink,
  },
  {
    path: routePaths.assignDistributor,
    exact: true,
    component: BusinessUnitFacing,
  },
  {
    path: routePaths.competitionForm,
    exact: true,
    component: OnlineFormWebView,
  },
  {
    path: routePaths.eventForm,
    exact: true,
    component: OnlineFormWebView,
  },
  {
    path: routePaths.redirect,
    exact: false,
    component: DownloadAppScene,
  },
];

export const unauthenticatedRoutes: Array<RouteProps> = [
  {
    path: '/',
    exact: true,
    component: LoginScene,
  },
  ...commonRoutes,
];

export const adminAuthenticatedRoutes: Array<RouteProps> = [
  {
    path: '/',
    exact: true,
    component: AdminHubScene,
  },
  {
    path: routePaths.brandPages,
    exact: true,
    component: BrandPagesScene,
  },
  {
    path: routePaths.newBrandPage,
    exact: true,
    component: AddNewBrandPage,
  },
  {
    path: routePaths.library,
    exact: true,
    component: LibraryScene,
  },
  {
    path: routePaths.dboNews,
    exact: true,
    component: DboNewsScene,
  },
  {
    path: routePaths.homepageSlider,
    exact: true,
    component: HomepageSliderScene,
  },
  {
    path: routePaths.promotions,
    exact: true,
    component: SeeAllPromotionsScene,
  },
  {
    path: routePaths.onlineForms,
    exact: true,
    component: OnlineFormScene,
  },
  {
    path: routePaths.logoLibrary,
    exact: true,
    component: LogoLibraryScene,
  },
  {
    path: routePaths.popUp,
    exact: true,
    component: PopUpScene,
  },
  {
    path: routePaths.tokoUser,
    exact: true,
    component: TokoUserScene,
  },
  {
    path: routePaths.archive,
    exact: true,
    component: ArchiveScene,
  },
  {
    path: routePaths.agentRequestStatus,
    exact: true,
    component: AgentRequestStatusScene,
  },
  {
    path: routePaths.eventFormSubmissions,
    exact: true,
    component: SubmittedEventForm,
  },
  {
    path: routePaths.competitionFormSubmissions,
    exact: true,
    component: SubmittedCompetitionForm,
  },
  {
    path: routePaths.loyaltyStoreList,
    exact: true,
    component: LoyaltyPointStoreList,
  },
  {
    path: routePaths.pointHistory,
    exact: true,
    component: PointHistoryScene,
  },
  {
    path: routePaths.orderDetail,
    exact: true,
    component: OrderDetail,
  },
  {
    path: routePaths.bulkLoyalty,
    exact: true,
    component: BulkLoyaltyInputScene,
  },
  {
    path: routePaths.bulkPointReview,
    exact: true,
    component: BulkPointReviewScene,
  },
  {
    path: routePaths.deepLinkList,
    exact: true,
    component: DeepLinkList,
  },
  // {
  //   path: routePaths.automaticNotificationList,
  //   exact: true,
  //   component: AutomaticNotificationList,
  // },

  // {
  //   path: routePaths.automaticNotificationEdit,
  //   exact: true,
  //   component: AutomaticNotificationEdit,
  // },
  {
    path: routePaths.salesmanReferralCode,
    exact: true,
    component: SalesmanReferralCodeScene,
  },
  {
    path: routePaths.gamification,
    exact: true,
    component: GamificationScene,
  },
  {
    path: routePaths.invalidDataHistory,
    exact: true,
    component: InvalidDataHistory,
  },
  // {
  //   path: routePaths.userTierList,
  //   exact: true,
  //   component: UserTierListScene,
  // },
];

const verificatorAuthenticatedRoutes: Array<RouteProps> = [
  {
    path: '/',
    component: TokoVerification,
  },
];

const principalAuthenticatedRoutes: Array<RouteProps> = [
  {
    path: '/',
    exact: true,
    component: AgentRequestStatusScene,
  },
];

export const adminRoutes: Array<RouteProps> = [
  ...adminAuthenticatedRoutes,
  ...commonRoutes,
];

export const verificatorRoutes: Array<RouteProps> = [
  ...verificatorAuthenticatedRoutes,
  ...commonRoutes,
];

export const principalRoutes: Array<RouteProps> = [
  ...principalAuthenticatedRoutes,
  ...commonRoutes,
];

export const superAdminRoutes: Array<RouteProps> = [
  ...adminAuthenticatedRoutes,
  ...verificatorAuthenticatedRoutes,
  ...commonRoutes,
];
