import React, { useCallback } from 'react';
import { View, Image, StyleSheet, Clipboard } from 'react-native';

import { DateTimePicker, Option } from '../../components';
import BrandContactDropdown from '../../components/dropdowns/BrandContactDropdown';
import { TextInput, Text, Checkbox, Dropzone, Button } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { facebookLogo, instagramLogo, emailIcon } from '../../../assets';
import { FileWithPreview } from '../../core-ui/Dropzone';

export type EnterOptions = [boolean, boolean, boolean];

type Props = {
  name: string;
  dateTime: Date;
  facebookLink: string;
  instagramLink: string;
  externalLink: string;
  brandBU: string | null;
  emailDisabled: boolean;
  editable: boolean;
  image: FileWithPreview | string | null;
  dedicatedPageImage: FileWithPreview | string | null;
  enterOptions: EnterOptions;
  externalButton: string;
  salesmanAppDeepLink: string;

  setDedicatedPageImage: (image: FileWithPreview) => void;
  setEnterOptions: (value: EnterOptions) => void;
  setImage: (image: FileWithPreview) => void;
  setName: (text: string) => void;
  setFacebookLink: (text: string) => void;
  setInstagramLink: (text: string) => void;
  setExternalLink: (text: string) => void;
  setBrandBU: (value: Option) => void;
  setDateTime: (datetime: Date) => void;
  onSendEmail: () => void;
  setExternalButton: (value: string) => void;
  setSalesmanAppDeepLink: (value: string) => void;
};

export default function StepSixCompetition(props: Props) {
  let {
    name,
    facebookLink,
    instagramLink,
    externalLink,
    dateTime,
    image,
    brandBU,
    editable,
    enterOptions,
    emailDisabled,
    externalButton,
    salesmanAppDeepLink,
    dedicatedPageImage,

    setDedicatedPageImage,
    setEnterOptions,
    setImage,
    setName,
    setFacebookLink,
    setInstagramLink,
    setExternalLink,
    setBrandBU,
    setDateTime,
    onSendEmail,
    setExternalButton,
    setSalesmanAppDeepLink,
  } = props;

  const competitionEnterOptions = ['Facebook', 'Instagram'];
  const competitionEnterLinks = [t(['Link Eksternal', 'External Link'])];

  const onCheckPressed = (index: number) => {
    setEnterOptions(
      enterOptions.map((value, i) =>
        i === index ? !value : value,
      ) as EnterOptions,
    );
  };

  const onCopyText = useCallback(() => {
    Clipboard.setString(salesmanAppDeepLink);
  }, [salesmanAppDeepLink]);

  return (
    <View style={styles.flex}>
      <View>
        <TextInput
          data-cy="form-competition-name"
          label={t(['Nama Kompetisi', 'Competition Name'])}
          value={name}
          onChangeText={setName}
          containerStyle={styles.field}
          disabled={!editable}
        />
        <View style={styles.field}>
          <DateTimePicker
            data-cy="form-competition-date"
            type="datetime"
            selectedDate={dateTime}
            onChange={setDateTime}
            label={t([
              'Tanggal/Waktu Berakhir Kompetisi',
              'Competition Closes',
            ])}
            disabled={!editable}
          />
        </View>
        <Text bold style={styles.fieldLabel}>
          {t(['Cara Ikut Serta', 'How to Enter'])}
        </Text>
        <View
          style={[
            styles.row,
            styles.xSmallBottomSpacing,
            styles.justifyContentBetween,
          ]}
        >
          <View>
            {competitionEnterOptions.map((item: string, index: number) => (
              <Checkbox
                data-cy={`form-competition-social-${index}`}
                key={index}
                label={item}
                checked={enterOptions[index]}
                onPress={() => onCheckPressed(index)}
                disabled={!editable}
              />
            ))}
          </View>
          <View style={styles.alignSelfStart}>
            {competitionEnterLinks.map((item: string, index: number) => (
              <Checkbox
                data-cy={`form-competition-link-${index}`}
                key={index}
                label={item}
                checked={enterOptions[index + 2]}
                onPress={() => onCheckPressed(index + 2)}
                disabled={!editable}
              />
            ))}
          </View>
        </View>
        <Text bold style={styles.xSmallBottomSpacing}>
          {t(['Syarat Ikut Serta', 'Entry Requirement'])}
        </Text>
        <Text style={styles.field}>
          {t([
            'Ikuti DBO di Facebook dan Instagram untuk ikut serta',
            'Follow DBO on both Facebook and Instagram to entry',
          ])}
        </Text>
        {enterOptions[0] === true && (
          <View style={[styles.row, styles.field]}>
            <Image
              source={facebookLogo}
              resizeMode="contain"
              style={styles.logo}
            />
            <TextInput
              data-cy="form-competition-facebook"
              value={facebookLink}
              placeholder={t(['Link Facebook', 'Facebook Link'])}
              onChangeText={setFacebookLink}
              disabled={!editable}
            />
          </View>
        )}
        {enterOptions[1] === true && (
          <View style={[styles.row, styles.field]}>
            <Image
              source={instagramLogo}
              resizeMode="contain"
              style={styles.logo}
            />
            <TextInput
              data-cy="form-competition-instagram"
              value={instagramLink}
              placeholder={t(['Link Instagram', 'Instagram Link'])}
              onChangeText={setInstagramLink}
              disabled={!editable}
            />
          </View>
        )}
        {enterOptions[2] === true && (
          <>
            <TextInput
              data-cy="form-competition-external-link"
              label={t(['Link Eksternal', 'External Link'])}
              placeholder={t(['Link Eksternal', 'External Link'])}
              value={externalLink}
              onChangeText={setExternalLink}
              containerStyle={styles.field}
              disabled={!editable}
            />
            <TextInput
              data-cy="news-external-button"
              value={externalButton}
              onChangeText={setExternalButton}
              label={t(['Tombol Eksternal', 'External Button'])}
              containerStyle={styles.field}
              disabled={!editable}
              maxLength={35}
            />
          </>
        )}
        <View style={[styles.row, styles.field]}>
          <View style={styles.flex}>
            <View style={[styles.row, styles.xSmallBottomSpacing]}>
              <Text bold style={styles.flex}>
                {t(['Brand', 'Brand'])}
              </Text>
              <Text bold style={[styles.flex, styles.brandSubLabel]}>
                {t(['Principal', 'Principal'])}
              </Text>
            </View>
            <View>
              <BrandContactDropdown
                isOptional={true}
                data-cy="form-competition-principal-dropdown"
                selectedOption={brandBU ?? undefined}
                onSelect={setBrandBU}
                disabled={!editable}
              />
            </View>
          </View>
          <Button
            preset="transparent"
            title="mail"
            icon={
              <Image
                source={{ uri: emailIcon }}
                style={[styles.emailIcon, { opacity: emailDisabled ? 0.3 : 1 }]}
                resizeMode="contain"
              />
            }
            onPress={onSendEmail}
            style={styles.emailButton}
            disabled={emailDisabled}
          />
        </View>
        <Text style={styles.xSmallBottomSpacing}>
          <Text bold>{t(['Gambar', 'Image'])}</Text>
        </Text>
        <View style={styles.imageContainer}>
          <View style={styles.paddingRight}>
            <Text style={styles.xSmallBottomSpacing}>
              {t(['HomePage', 'HomePage'])}
            </Text>
            <Text style={styles.sizeHelper}>
              {t([
                'Size: 136 x 84px\n(opsional)',
                'Size: 136 x 84px\n(optional)',
              ])}
            </Text>
            <View style={styles.field}>
              <Dropzone
                data-cy="form-competition-image"
                containerStyle={styles.dropzone}
                withUploadText
                type="image"
                imageSize={styles.imageRes}
                source={image}
                getPreview={setImage}
                disabled={!editable}
              />
            </View>
          </View>
          <View style={styles.paddingLeft}>
            <Text style={styles.xSmallBottomSpacing}>
              {t(['Dedicated Page', 'DedicatedPage'])}
            </Text>
            <Text style={styles.sizeHelper}>
              {t([
                'Size: 375 x 180px\n(opsional)',
                'Size: 375 x 180px\n(optional)',
              ])}
            </Text>
            <View style={styles.field}>
              <Dropzone
                containerStyle={styles.dropzone}
                withUploadText
                type="image"
                imageSize={styles.dedicatedImageRes}
                source={dedicatedPageImage}
                getPreview={setDedicatedPageImage}
                disabled={!editable}
              />
            </View>
          </View>
        </View>
        <TextInput
          label={t([
            'URL Deeplink - Konten Salesman App',
            'URL Deeplink - Salesman App Content',
          ])}
          value={salesmanAppDeepLink}
          onChangeText={setSalesmanAppDeepLink}
          containerStyle={styles.field}
          disabled={true}
        />
        <Button
          onPress={onCopyText}
          style={styles.copyLink}
          title={t(['Salin', 'Copy'])}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  row: { flexDirection: 'row', alignItems: 'center' },
  field: { paddingBottom: spacing.small },
  fieldLabel: { paddingBottom: spacing.xxsmall },
  brandSubLabel: { paddingRight: spacing.medium }, // NOTE: this is === arrow down icon size
  logo: { width: 24, height: 24, marginRight: spacing.xsmall },
  dropzone: {
    width: 140,
    height: 120,
  },
  emailIcon: { width: 38, height: 38 },
  emailButton: { marginTop: 23 },
  justifyContentBetween: { justifyContent: 'space-between' },
  xSmallBottomSpacing: { marginBottom: spacing.xsmall },
  alignSelfStart: { alignSelf: 'flex-start' },
  sizeHelper: { color: colors.text.disabled },
  paddingLeft: { paddingLeft: 10 },
  paddingRight: { paddingRight: 10 },
  imageContainer: { flexDirection: 'row', alignContent: 'stretch' },
  imageRes: { width: 136, height: 84 },
  dedicatedImageRes: { width: 375, height: 180 },
  copyLink: {
    color: colors.text.link,
    marginTop: 4,
  },
});
