import gql from 'graphql-tag';

export const BULK_POINT_WITHOUT_DETAILS_FRAGMENT = gql`
  fragment BulkPointWithoutDetailsFragment on BulkPoint {
    id
    fileName
    link
    status
    countValid
    countInvalid
    createdAt
    details
  }
`;
