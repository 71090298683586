import React, { useEffect, useMemo, useCallback, memo } from 'react';
import { StyleSheet } from 'react-native';
import { useLazyQuery } from '@apollo/react-hooks';

import { spacing } from '../../constants/theme';
import {
  GET_COMPETITION_ONLINE_FORMS,
  GET_EVENT_ONLINE_FORMS,
} from '../../graphql/queries';
import { CompetitionOnlineForms } from '../../generated/CompetitionOnlineForms';
import { EventOnlineForms } from '../../generated/EventOnlineForms';
import { CompetitionFormFragment } from '../../generated/CompetitionFormFragment';
import { EventFormFragment } from '../../generated/EventFormFragment';

import Dropdown, { Props as DropdownProps } from './Dropdown';

export type FormType = 'competition' | 'event' | 'information';
type Props = Omit<DropdownProps, 'options' | 'title'> & {
  title?: string;
  formType: FormType;
};

const defaultTitle = t(['Link Formulir', 'Get Form Link']);

const FormLinkDropdown = memo((props: Props) => {
  let { formType, title = defaultTitle, ...otherProps } = props;

  const [
    getCompetitionForms,
    {
      data: competitionForms,
      loading: competitionLoading,
      error: competitionError,
    },
  ] = useLazyQuery<CompetitionOnlineForms>(GET_COMPETITION_ONLINE_FORMS);

  const [
    getEventForms,
    { data: eventForms, loading: eventLoading, error: eventError },
  ] = useLazyQuery<EventOnlineForms>(GET_EVENT_ONLINE_FORMS);

  useEffect(() => {
    if (formType === 'competition') {
      getCompetitionForms();
    } else if (formType === 'event') {
      getEventForms();
    } else {
      // TODO: invoke Information forms query
    }
  }, [formType, getCompetitionForms, getEventForms]);

  const formOptionMapper = useCallback(
    ({ id, name }: CompetitionFormFragment | EventFormFragment) => ({
      label: id.toString(),
      subLabel: name,
      value: id.toString(),
    }),
    [],
  );

  const options = useMemo(() => {
    switch (formType) {
      case 'competition': {
        return (
          competitionForms?.formCompetitionAdvanceSearch.row.map(
            formOptionMapper,
          ) ?? []
        );
      }
      case 'event': {
        return (
          eventForms?.formEventAdvanceSearch.row.map(formOptionMapper) ?? []
        );
      }
      default: {
        // TODO: map Information forms
        return [];
      }
    }
  }, [formType, competitionForms, eventForms, formOptionMapper]);

  const error = useMemo(
    () => (formType === 'competition' ? competitionError : eventError),
    [formType, competitionError, eventError],
  );
  const errorAction = useMemo(
    () =>
      formType === 'competition'
        ? t([
            'mengambil data formulir online kompetisi',
            'retrieve the competition online form data',
          ])
        : t([
            'mengambil data formulir online event',
            'retrieve the event online form data',
          ]),
    [formType],
  );
  const onRetryPress = useCallback(
    () =>
      formType === 'competition' ? getCompetitionForms() : getEventForms(),
    [formType, getCompetitionForms, getEventForms],
  );

  return (
    <Dropdown
      data-cy="form-dropdown"
      error={error}
      errorAction={errorAction}
      onRetryPress={onRetryPress}
      type="basic"
      title={title}
      options={options}
      labelStyle={styles.label}
      subLabelStyle={styles.subLabel}
      loading={competitionLoading || eventLoading}
      {...otherProps}
    />
  );
});

const styles = StyleSheet.create({
  // NOTE: adjust as necessary later on
  label: { flex: 1, paddingRight: spacing.xxsmall },
  subLabel: { flex: 3 },
});

export default FormLinkDropdown;
