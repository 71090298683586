import { StatusVerify } from '../../../generated/globalTypes';

export const tokoStatus = [
  'Unverified',
  'Revalidate',
  'Verified',
  'Verified Merge',
];
export const verifiedStatus = [
  StatusVerify.UNVERIFIED,
  StatusVerify.REVALIDATION,
  StatusVerify.VERIFIED,
  StatusVerify.VERIFIED_MERGE,
];
