import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { useLocation, Prompt } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

import { routePaths } from '../../constants/routes';
import { useSnackbar } from '../../context';
import { spacing } from '../../constants/theme';
import { CLAIM_VERIFICATION_REQUEST } from '../../graphql/mutations';
import {
  ClaimVerificationRequest,
  ClaimVerificationRequestVariables,
} from '../../../../generated/ClaimVerificationRequest';
import { VerificationRequests_requestVerificationAdvance_row } from '../../../../generated/VerificationRequests';
import {
  TypeVerify,
  VerificationClaimType,
} from '../../../../generated/globalTypes';
// import { GET_AGENT_BY_STORE_ID } from '../../../../graphql/queries';
// import {
//   AgentByStoreId,
//   AgentByStoreIdVariables,
// } from '../../../../generated/AgentByStoreId';

import OwnerDataForm from './OwnerDataForm';
import LeftPane from './LeftPane';
import TokoDataForm from './TokoDataForm';

const WorkInProgressScene = () => {
  const { openSnackbar } = useSnackbar();
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<VerificationRequests_requestVerificationAdvance_row | null>(
    null,
  );
  const [leftPaneRefetch, setLeftPaneRefetch] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const location = useLocation();

  const onUnclaim = useCallback(() => {
    setLeftPaneRefetch(!leftPaneRefetch);
    setSelectedItem(null);
    openSnackbar({
      variant: 'success',
      message: t([
        'Klaim permohonan verifikasi telah dibatalkan.',
        'Verification request has been unclaimed.',
      ]),
    });
  }, [leftPaneRefetch, openSnackbar]);
  const onUpdate = useCallback(() => {
    setLeftPaneRefetch(!leftPaneRefetch);
    setSelectedItem(null);
    openSnackbar({
      variant: 'success',
      message: t(['Data telah diperbarui.', 'Data has been updated.']),
    });
  }, [leftPaneRefetch, openSnackbar]);

  const onUnclaimError = useCallback(() => {
    openSnackbar({
      variant: 'error',
      message: t([
        'Gagal membatalkan klaim permohonan verifikasi. Mohon coba lagi nanti.',
        'Failed to unclaim verification request. Please try again later.',
      ]),
    });
  }, [openSnackbar]);

  const [unclaim, { loading: unclaimLoading }] = useMutation<
    ClaimVerificationRequest,
    ClaimVerificationRequestVariables
  >(CLAIM_VERIFICATION_REQUEST, {
    onCompleted: onUnclaim,
    onError: onUnclaimError,
  });

  const unclaimItem = useCallback(() => {
    if (selectedItem) {
      unclaim({
        variables: { type: VerificationClaimType.UNCLAIM, id: selectedItem.id },
      });
    }
  }, [selectedItem, unclaim]);

  // const [getAgent, { data: getAgenData }] = useLazyQuery<
  //   AgentByStoreId,
  //   AgentByStoreIdVariables
  // >(GET_AGENT_BY_STORE_ID);

  const onSelectLeftPaneItem = useCallback(
    (value: VerificationRequests_requestVerificationAdvance_row | null) => {
      if (selectedItem?.id !== value?.id) {
        // TODO: may need to block this if form is dirty
        setSelectedItem(value);
        setFormDirty(false);
        // getAgent({
        //   variables: {
        //     managerStoreId: value.storeId || '',
        //   },
        // });
      }
    },
    [selectedItem],
  );

  const shouldBlock = useMemo(() => {
    return location.pathname === routePaths.wip && formDirty;
  }, [formDirty, location]);

  useEffect(() => {
    if (shouldBlock) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      // NOTE: cleanup
      window.onbeforeunload = null;
    };
  }, [shouldBlock]);

  const formContent = useMemo(() => {
    if (!selectedItem) {
      return null;
    } else if (selectedItem.type === TypeVerify.TOKO) {
      return (
        <TokoDataForm
          unclaimLoading={unclaimLoading}
          onUnclaim={unclaimItem}
          data={selectedItem}
          // distributors={(getAgenData && getAgenData.agentByStoreId) || []}
          onUpdate={onUpdate}
          setFormDirty={setFormDirty}
        />
      );
    } else {
      return (
        <OwnerDataForm
          data={selectedItem}
          unclaimLoading={unclaimLoading}
          setFormDirty={setFormDirty}
          onUnclaim={unclaimItem}
          onUpdate={onUpdate}
        />
      );
    }
  }, [selectedItem, unclaimLoading, unclaimItem, onUpdate]);

  return (
    <View style={styles.root}>
      <Prompt
        when={shouldBlock}
        message={t([
          'Apakah anda yakin ingin meninggalkan halaman ini?',
          'Are you sure you want to leave this page?',
        ])}
      />
      <View style={styles.left}>
        <LeftPane
          shouldRefetch={leftPaneRefetch}
          selectedItemId={selectedItem?.id ?? null}
          setSelectedItem={onSelectLeftPaneItem}
        />
      </View>
      <View style={styles.right}>
        <ScrollView contentContainerStyle={styles.content}>
          {formContent}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: '100%',
  },
  left: {
    flex: 1,
  },
  right: {
    flex: 3.5,
  },
  content: {
    alignItems: 'center',
    paddingVertical: spacing.l,
  },
});

export default WorkInProgressScene;
