import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import DownloadIcon from '@material-ui/icons/GetApp';
import { useLazyQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { saveAs } from 'file-saver';
import { logEvent } from 'expo-firebase-analytics';

import { ErrorModal, Header, HeaderNavigationBar } from '../../components';
import { Button, Dropzone, Text } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { useUploadWithProgress } from '../../hooks';
import { GET_BULK_TEMPLATE_LINK } from '../../graphql/queries';
import { UPLOAD_BULK_POINT } from '../../graphql/mutations';
import { BulkTemplateLink } from '../../generated/BulkTemplateLink';
import { FileWithPreview } from '../../core-ui/Dropzone';
import { pageTitle } from '../../constants/pageTitle';

import { BulkLoyaltyInputTable } from './BulkLoyaltyInputTable';

export const BulkLoyaltyInputScene = () => {
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorAction, setErrorAction] = useState('');
  const [errorInstance, setErrorInstance] = useState<ApolloError | undefined>();
  const [refetchToggle, setRefetchToggle] = useState(false);
  const [file, getFile] = useState<FileWithPreview>();

  const saveTemplate = useCallback((data: BulkTemplateLink) => {
    saveAs(data.downloadTemplateCSV.link, 'template.csv');
  }, []);

  useEffect(() => {
    const eventLog = async () => {
      await logEvent('page_view', {
        page_title: pageTitle.BulkLoyaltyInputScene,
      });
    };
    eventLog();
  }, []);

  const closeErrorModal = useCallback(() => setErrorOpen(false), []);
  const onUploadError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunggah berkas', 'upload file']));
    setErrorInstance(error);
  }, []);
  const toggleRefetch = useCallback(() => setRefetchToggle(!refetchToggle), [
    refetchToggle,
  ]);
  const onDownloadError = useCallback((error: ApolloError) => {
    setErrorOpen(true);
    setErrorAction(t(['mengunduh template', 'download template']));
    setErrorInstance(error);
  }, []);

  const {
    upload,
    reset,
    loading: uploadLoading,
    progress,
    done,
  } = useUploadWithProgress(UPLOAD_BULK_POINT, {
    onCompleted: toggleRefetch,
    onError: onUploadError,
  });

  const [
    getTemplate,
    { data: templateData, loading: getTemplateLoading },
  ] = useLazyQuery<BulkTemplateLink>(GET_BULK_TEMPLATE_LINK, {
    onCompleted: saveTemplate,
    onError: onDownloadError,
  });

  const onDownloadPress = useCallback(() => {
    if (templateData) {
      saveTemplate(templateData);
    } else {
      getTemplate();
    }
  }, [getTemplate, saveTemplate, templateData]);

  const onFileDropped = useCallback(
    (file: File) => {
      reset();
      upload({ variables: { file } });
    },
    [upload, reset],
  );

  return (
    <View style={styles.root}>
      <ErrorModal
        open={errorOpen}
        action={errorAction}
        error={errorInstance}
        onClose={closeErrorModal}
      />
      <HeaderNavigationBar />
      <Header
        withSeparator
        title={t(['Masukkan Poin Jumlah Besar', 'Bulk Point Input'])}
      />
      <View style={styles.downloadRow}>
        <Text bold style={styles.downloadText}>
          {t([
            'Unduh template CSV dan lengkapi rincian data.',
            'Download CSV template and fill in the details.',
          ])}
        </Text>
        <Button
          iconAndText
          icon={<DownloadIcon htmlColor={colors.icon.secondary} />}
          title={t(['Unduh CSV', 'Download CSV'])}
          isLoading={getTemplateLoading}
          onPress={onDownloadPress}
        />
      </View>
      <View style={styles.uploadBlock}>
        <Text bold style={styles.uploadText}>
          {t(['Unggah Berkas CSV', 'Upload CSV File'])}
        </Text>
        <Dropzone
          data-cy="file-dropzone"
          withUploadText
          type="csv"
          uploadLoading={uploadLoading}
          progress={progress}
          done={done}
          source={file}
          getPreview={getFile}
          onDropSuccess={onFileDropped}
          containerStyle={styles.dropzone}
        />
      </View>
      <BulkLoyaltyInputTable
        refetchToggle={refetchToggle}
        onSubmit={toggleRefetch}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { padding: spacing.xlarge },
  downloadRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: spacing.small,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  downloadText: { paddingRight: spacing.large },
  uploadBlock: { paddingTop: spacing.xsmall, paddingBottom: spacing.medium },
  uploadText: { paddingBottom: spacing.xsmall },
  dropzone: { width: 250, height: 150, borderRadius: 0 },
});
