import { MAPS_API_KEY } from 'react-native-dotenv';

export default async function getCoordinates(id: string) {
  let proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  let targetUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${id}&fields=geometry&key=${MAPS_API_KEY}`;
  let result = await fetch(proxyUrl + targetUrl);
  let json = await result.json();

  if (json.status === 'OK') {
    return {
      success: true,
      coordinates: json.result.geometry.location,
    };
  } else {
    return { success: false, errorMessage: json.error_message };
  }
}
