import gql from 'graphql-tag';

import { POP_UP_FRAGMENT } from '../fragments';

export const GET_POP_UPS = gql`
  query PopUps(
    $isWhoNotNull: Boolean
    $archiveStatus: ArchiveStatus
    $first: Int
    $skip: Int
  ) {
    popUpAdvanceSearch(
      isWhoNotNull: $isWhoNotNull
      archiveStatus: $archiveStatus
      pagination: { first: $first, skip: $skip }
      orderBy: { createdAt: desc }
    ) {
      row {
        ...PopUpFragment
      }
      total
    }
  }
  ${POP_UP_FRAGMENT}
`;
