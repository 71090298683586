import React, { memo } from 'react';
import { View, StyleSheet, ViewProps } from 'react-native';

import { Text, Infotip } from '../core-ui';
import { colors, theme } from '../constants/theme';

type Props = {
  step?: number;
  title?: string;
  infotipContent?: string;
  style?: ViewProps['style'];
  divider?: boolean;
};

const StepHeader = memo((props: Props) => {
  let { step, title, infotipContent, style, divider = true } = props;
  return (
    <View style={[styles.root, divider && styles.divider, style]}>
      <Text testID="title">
        {step && (
          <Text bold>{t(['Tahap {num}', 'Step {num}'], { num: step })}: </Text>
        )}
        <Text bold={!step}>{title}</Text>
      </Text>
      {!!infotipContent && (
        <View testID="infotip">
          <Infotip size="small">{infotipContent}</Infotip>
        </View>
      )}
    </View>
  );
});

const styles = StyleSheet.create({
  root: {
    width: '100%',
    paddingBottom: theme.spacing.xsmall,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    borderBottomWidth: 2,
    borderBottomColor: colors.border.primary,
  },
});

export default StepHeader;
