import gql from 'graphql-tag';

export const GET_AGENT_REQUEST_BY_TOKEN = gql`
  query AgentRequestByToken($token: String!) {
    requestAgent(token: $token) {
      id
      agentName
      isCancelledInAction
      brand {
        id
        name
        brandId
      }
      brandId
      createdAt
      status
      cmsRequestStatus
      user {
        phoneNumber
        profile {
          id
          name
        }
      }
      businessUnitContact {
        id
      }
      salesmanInfo {
        agentName
        salesmanName
        salesmanReferralCode
      }
      store {
        id
        managerStoreId
        storeManager {
          id
          storeName
          storeEmail
          storePhoneNumber
          storeCategoryName
          address
          province
          city
          district
          village
          postalCode
          statusActive
        }
        storeVerified
      }
    }
  }
`;

export const GET_AGENT_BY_BRAND_ID = gql`
  query AgentByBrandId($brandId: ID!) {
    agentByBrandId(brandId: $brandId) {
      id
      name
    }
  }
`;

export const GET_AGENT_BY_STORE_ID = gql`
  query AgentByStoreId($managerStoreId: String!) {
    agentByStoreId(managerStoreId: $managerStoreId) {
      id
      agentName
      city
      region
      brandName
    }
  }
`;

export const CREATE_REQUEST_AGENT_DOWNLOAD_ORDER = gql`
  mutation CreateRequestAgentDownloadOrder(
    $input: RequestAgentDownloadInputType!
  ) {
    createRequestAgentDownloadOrder(input: $input) {
      id
      downloadUrl
      lastProcessedBatch
      processedBatch
      totalBatch
      createdAt
      updatedAt
      attributes
      status
    }
  }
`;

export const GET_RA_DOWNLOAD_PROGRESS = gql`
  query GetRADwonloadProgress($downloadId: String!) {
    getRADownloadProgress(downloadId: $downloadId) {
      id
      downloadUrl
      processedBatch
      totalBatch
      lastProcessedBatch
      status
      attributes
      updatedAt
      attributes
    }
  }
`;

export const DOWNLOAD_AGENT_REQUEST = gql`
  query DownloadRequestAgent(
    $provinceId: String
    $cityId: String
    $storeName: String
    $storeCode: String
    $brandId: ID
    $status: CmsRequestStatusFilter
    $registrationDate: DateFilter
    $newStatus: NewCmsRequestStatusFilter
  ) {
    downloadRequestAgent(
      provinceId: $provinceId
      cityId: $cityId
      storeName: $storeName
      storeCode: $storeCode
      brandId: $brandId
      status: $status
      newStatus: $newStatus
      registrationDate: $registrationDate
    ) {
      link
    }
  }
`;

export const GET_TOTAL_REQUEST_AGENT_STATUS = gql`
  query CalculateRequestAgent($registrationDate: DateFilter) {
    calculateRequestAgent(registrationDate: $registrationDate) {
      approve
      cancel
      overSLA
      reject
      rejectByDistributor
      rejectByPrincipal
      request
      review
    }
  }
`;

export const GET_AGENT_REQUEST_BY_STORE = gql`
  query GetAgentRequestByStore(
    $storeId: String!
    $brandId: String!
    $orderBy: RequestAgentOrderByInput
  ) {
    requestAgents(
      where: {
        AND: [
          { storeId: { equals: $storeId } }
          { brandId: { equals: $brandId } }
        ]
      }
      orderBy: $orderBy
    ) {
      id
      createdAt
      assignDateAgent
      rejectDateAgent
      rejectedBy
      agentName
      cmsRequestStatus
      status
      approveDateAgent
      rejectReason
      store {
        id
        managerStoreCode
        managerStoreId
        storeManager {
          id
          storeName
          storeEmail
          storePhoneNumber
          storeCategoryName
          address
          province
          city
          district
          village
          postalCode
          statusActive
        }
        storeVerified
      }
    }
  }
`;
