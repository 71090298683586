import gql from 'graphql-tag';

export const PROMOTION_FRAGMENT = gql`
  fragment PromotionFragment on Promotion {
    id
    appUser
    region
    city
    heroImage
    optionalImages
    name
    termAndCondition
    desc
    descSalesman
    urlDeeplinkSalesman
    range
    rangeId
    businessUnitContactId
    businessUnitContact {
      id
      name
      email
    }
    brand {
      id
      brandId
      name
      logos {
        id
        imageUrl
      }
      businessUnitContact {
        id
        email
        name
      }
      brandPage {
        id
        name
        status
      }
    }
    liveDate
    endLiveDate
    status
    productQuantity
    product {
      id
      unit
    }
    showInMobile
    sendPushNotifAfterCreate
    promotionCode
  }
`;
