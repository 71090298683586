import React, {
  memo,
  useCallback,
  Dispatch,
  SetStateAction,
  CSSProperties,
  useState,
  useMemo,
  useEffect,
} from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { View, StyleSheet, Image } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import HighlightOff from '@material-ui/icons/HighlightOff';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

import { Table, Text, Button } from '../../core-ui';
import { colors, spacing } from '../../constants/theme';
import { toPascalCase } from '../../helpers';
import { VerificationRequests_requestVerificationAdvance_row } from '../../../../generated/VerificationRequests';
import {
  TypeVerify,
  StatusVerify,
  OrderByArg,
  RoleAdmin,
} from '../../../../generated/globalTypes';
import { Modal } from '../../../../components';
import TokoDataForm from '../workInProgress/TokoDataForm';
import OwnerDataForm from '../workInProgress/OwnerDataForm';
import OwnerEditForm from '../workInProgress/OwnerEditForm';
import { useAuth } from '../../../../context';
import OwnerSuperEditForm from '../workInProgress/OwnerSuperEditForm';
import TokoSuperEditForm from '../workInProgress/TokoSuperEditForm';
import { storeImgAvailable, storeImgUnavailable } from '../../../../../assets';

type Props = {
  data: Array<VerificationRequests_requestVerificationAdvance_row>;
  dataCount: number;
  page: number;
  rowsPerPage: number;
  claimLoading: boolean;
  claimingRequestID: string | null;
  loading?: boolean;
  setPage: Dispatch<SetStateAction<number>>;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  onClaimPress: (
    data: VerificationRequests_requestVerificationAdvance_row,
  ) => void;
  refetchData: () => void;
  order: OrderByArg | undefined;
  orderBy: string | undefined;
  onRequestSort: (_event: React.MouseEvent<unknown>, property: string) => void;
};

const QueueTable = memo((props: Props) => {
  const {
    data,
    loading,
    dataCount,
    page,
    rowsPerPage,
    claimLoading,
    claimingRequestID,
    setPage,
    setRowsPerPage,
    onClaimPress,
    refetchData,
    order,
    orderBy,
    onRequestSort,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { role } = useAuth();
  const [viewData, setViewData] = useState<
    VerificationRequests_requestVerificationAdvance_row
  >();
  const [editOwnerModalOpen, setEditOwnerModalOpen] = useState(false);
  const [superEditOwnerModalOpen, setSuperEditOwnerModalOpen] = useState(false);
  const [superEditTokoModalOpen, setSuperEditTokoModalOpen] = useState(false);
  const openEdit = useCallback(() => {
    setEditOwnerModalOpen(true);
  }, []);
  const openSuperEdit = useCallback(() => {
    setSuperEditOwnerModalOpen(true);
  }, []);
  const closeEdit = useCallback(() => {
    setEditOwnerModalOpen(false);
  }, []);

  const closeSuperEdit = useCallback(() => {
    setSuperEditOwnerModalOpen(false);
  }, []);
  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const openSuperEditToko = useCallback(() => {
    setSuperEditTokoModalOpen(true);
  }, []);
  const closeSuperEditToko = useCallback(() => {
    setSuperEditTokoModalOpen(false);
  }, []);

  const modalTitle = useMemo(() => {
    return viewData?.type === TypeVerify.TOKO
      ? viewData.store?.storeManagerBulk?.storeName
      : viewData?.user?.profile?.name;
  }, [viewData]);

  const typeModal = useMemo(() => {
    return viewData?.type === TypeVerify.TOKO ? 'toko' : 'owner';
  }, [viewData]);

  const onChangeRowsPerPage = useCallback(
    (rows: number) => {
      setRowsPerPage(rows);
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );
  const getStyle = useCallback(
    ({
      type,
      store,
      status,
    }: VerificationRequests_requestVerificationAdvance_row) => {
      let style = {} as CSSProperties;
      if (type === TypeVerify.USER && store?.storeVerified === false) {
        style = { ...style, backgroundColor: colors.table.row.disabled };
      }
      if (
        status === StatusVerify.VERIFIED ||
        status === StatusVerify.VERIFIED_MERGE
      ) {
        style = { ...style, cursor: 'pointer' };
      }
      return style;
    },
    [],
  );

  const getEditStyle = useCallback(
    ({ type, store }: VerificationRequests_requestVerificationAdvance_row) => {
      let style = {} as CSSProperties;
      if (type === TypeVerify.USER && store?.storeVerified === false) {
        style = { ...style, backgroundColor: colors.table.row.disabled };
      }
      if (type === TypeVerify.USER) {
        style = { ...style, cursor: 'pointer' };
      }
      return style;
    },
    [],
  );

  const onCellClick = useCallback(
    (data: VerificationRequests_requestVerificationAdvance_row) => {
      if (
        data.status === StatusVerify.VERIFIED ||
        data.status === StatusVerify.VERIFIED_MERGE
      ) {
        openModal();
        setViewData(data);
      }
    },
    [openModal],
  );

  const onEditCellClick = useCallback(
    (data: VerificationRequests_requestVerificationAdvance_row) => {
      openEdit();
      setViewData(data);
    },
    [openEdit],
  );

  const onSuperEditCellClick = useCallback(
    (data: VerificationRequests_requestVerificationAdvance_row) => {
      if (data.type === TypeVerify.USER) {
        openSuperEdit();
      } else if (data.type === TypeVerify.TOKO) {
        openSuperEditToko();
      }
      setViewData(data);
    },
    [openSuperEdit, openSuperEditToko],
  );

  const onRollbackCompleted = useCallback(() => {
    closeModal();
    refetchData();
  }, [closeModal, refetchData]);

  useEffect(() => {
    if (data && viewData) {
      const newViewData = data.find((newData) => newData.id === viewData.id);
      setViewData(newViewData);
    }
  }, [data, viewData]);

  return (
    <>
      <Table
        data-cy="verification-request-table"
        loading={loading}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        dataCount={dataCount}
        onChangePage={setPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        onRequestSort={onRequestSort}
        structure={{
          name: {
            'data-cy': 'verification-request-name',
            withLeftBorder: true,
            headerTitle: t(['Nama', 'Name']),
            orderable: true,
            getStyle,
            onCellClick,
            render: ({ type, user, store }) => (
              <View>
                <Text
                  color={
                    user?.profile?.reUpload || store?.reUpload ? 'red' : 'black'
                  }
                  bold
                >
                  {type === TypeVerify.TOKO ? (
                    store?.storeManagerBulk?.storeName
                  ) : (
                    <>
                      {user?.profile?.name}
                      <Text color="grey" size="xs">
                        {'\n'}
                        {user?.store?.storeManagerBulk?.storeName} (
                        {user?.store?.storeVerified ? 'Verified' : 'Unverified'}
                        )
                      </Text>
                    </>
                  )}
                </Text>
              </View>
            ),
          },
          region: {
            'data-cy': 'verification-request-province',
            headerTitle: t(['Provinsi', 'Region']),
            orderable: true,
            dataPath: 'store.storeManager.province',
            getStyle,
            onCellClick,
            render: ({ store, user }) => (
              <Text color="grey">
                {store?.storeManagerBulk?.province ??
                  user?.store?.storeManagerBulk?.province}
              </Text>
            ),
          },
          createAt: {
            headerTitle: t([
              'Tanggal Verifikasi Dibuat',
              'Verification Create Date',
            ]),
            orderable: true,
            dataPath: 'createdAt',
            getStyle,
            onCellClick,
            render: ({ createdAt }) => (
              <Text color="grey">
                {new Date(createdAt).toLocaleString('id', {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: false,
                })}
              </Text>
            ),
          },
          confirmAt: {
            headerTitle: t(['Terakhir Diperbarui Pada', 'Last Updated At']),
            orderable: true,
            getStyle,
            onCellClick,
            render: ({ confirmAt }) => (
              <Text color="grey">
                {confirmAt
                  ? new Date(confirmAt).toLocaleString('id', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })
                  : '-'}
              </Text>
            ),
          },
          registerAt: {
            headerTitle: t(['Tanggal Registrasi', 'Registration  Date']),
            orderable: true,
            getStyle,
            onCellClick,
            render: ({ type, user, store }) => (
              <Text color="grey">
                {type === TypeVerify.TOKO
                  ? new Date(store?.createdAt).toLocaleString('id', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })
                  : new Date(user?.createdAt).toLocaleString('id', {
                      day: 'numeric',
                      month: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })}
              </Text>
            ),
          },
          confirmBy: {
            headerTitle: t(['Terakhir Diperbarui Oleh', 'Last Updated By']),
            orderable: true,
            getStyle,
            onCellClick,
            render: ({ confirmBy }) => (
              <Text color="grey">{confirmBy ?? '-'}</Text>
            ),
          },
          type: {
            'data-cy': 'verification-request-type',
            headerTitle: t(['Jenis', 'Type']),
            orderable: true,
            getStyle,
            onCellClick,
            valueProcessor: (value) => {
              if (value === 'USER') {
                return t(['Pemilik', 'Owner']);
              } else {
                return 'Toko';
              }
            },
          },
          status: {
            'data-cy': 'verification-request-status',
            headerTitle: t(['Status', 'Status']),
            orderable: true,
            getStyle,
            onCellClick,
            render: ({ status, type }) => (
              <Text
                bold
                color={
                  status === StatusVerify.WAITING
                    ? 'grey'
                    : status === StatusVerify.UNVERIFIED ||
                      status === StatusVerify.REVALIDATION
                    ? 'red'
                    : 'green'
                }
              >
                {status === StatusVerify.VERIFIED && type === TypeVerify.TOKO
                  ? 'Verified New'
                  : status === StatusVerify.VERIFIED && type === TypeVerify.USER
                  ? 'Verified'
                  : status !== StatusVerify.WAITING
                  ? status.split('_').map(toPascalCase).join(' ')
                  : '-'}
              </Text>
            ),
          },
          storePhoto: {
            'data-cy': 'verification-request-store-photo',
            headerTitle: t(['Data Pendukung', 'Store Photo']),
            getStyle,
            onCellClick,
            render: ({ store }) => (
              <Image
                source={
                  store?.storePhotoFront && store.storePhotoInside
                    ? storeImgAvailable
                    : storeImgUnavailable
                }
                style={styles.storeImg}
              />
            ),
          },
          waValidation: {
            'data-cy': 'verification-request-store-photo',
            headerTitle: t(['Valid WhatsApp', 'Valid WhatsApp']),
            getStyle,
            onCellClick,
            render: ({ user }) => {
              if (user?.whatsAppValidation === true) {
                return <CheckCircleOutline htmlColor={'#41b941'} />;
              } else {
                return <HighlightOff htmlColor={'#E53C3C'} />;
              }
            },
          },

          action: {
            'data-cy': 'verification-request-action',
            noHeaderTitle: true,
            getStyle,
            onCellClick,
            render: (data) => {
              const { id, type, status, admin, user } = data;
              // case 1: status VERIFIED or VERIFIED_MERGE
              if (
                status === StatusVerify.VERIFIED ||
                status === StatusVerify.VERIFIED_MERGE
              ) {
                return (
                  <View style={styles.statusRow}>
                    <CheckIcon fontSize="small" htmlColor={colors.icon.green} />
                    <Text bold color="green">
                      {t(['Selesai', 'Done'])}
                    </Text>
                  </View>
                );
              } else {
                // case 2: no status and not yet claimed: disabled = depends on type, title = Claim
                // case 3: no status and already claimed by other admin: disabled = true, title = Claimed
                // case 4: status UNVERIFIED and not yet claimed: disabled = false, title = Reclaim
                // case 5: status UNVERIFIED and already claimed by other admin: disabled = true, title = Claimed
                const disabled =
                  (type === TypeVerify.USER && !user?.store?.storeVerified) || // for case 2, becomes disabled if type if user and store is not yet verified
                  !!admin; // for case 3 and 5
                let title = t(['Klaim', 'Claim']);
                if (!!admin) {
                  title = t(['Diklaim', 'Claimed']);
                } else if (status === StatusVerify.UNVERIFIED) {
                  title = t(['Klaim', 'Reclaim']);
                }
                return (
                  <Button
                    data-cy="verification-request-claim"
                    disabled={disabled}
                    isLoading={claimLoading && id === claimingRequestID}
                    title={title}
                    preset={type === TypeVerify.TOKO ? 'primary' : 'orange'}
                    onPress={() => onClaimPress(data)}
                  />
                );
              }
            },
          },
          edit: {
            'data-cy': 'verification-request-edit',
            noHeaderTitle: true,
            getStyle: getEditStyle,
            onCellClick: (data) => {
              if (
                data.type === TypeVerify.USER &&
                role === RoleAdmin.SUPER_VERIFICATION_ADMIN
              ) {
                onEditCellClick(data);
              }
            },
            render: (data) => {
              const { type } = data;
              if (
                type === TypeVerify.USER &&
                role === RoleAdmin.SUPER_VERIFICATION_ADMIN
              ) {
                return (
                  <View style={{ flexDirection: 'row' }}>
                    <MaterialCommunityIcons
                      name="pencil"
                      color={colors.icon.blue}
                    />
                    <Text
                      style={{
                        color: colors.icon.blue,
                      }}
                    >
                      Edit
                    </Text>
                  </View>
                );
              }
            },
          },
          edit2: {
            'data-cy': 'verification-request-edit',
            noHeaderTitle: true,
            getStyle: getEditStyle,
            onCellClick: (data) => {
              if (role === RoleAdmin.MASTER_VERIFICATION_ADMIN) {
                onSuperEditCellClick(data);
              }
            },
            render: (data) => {
              if (
                role === RoleAdmin.MASTER_VERIFICATION_ADMIN &&
                data.status !== StatusVerify.WAITING
              ) {
                return (
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                    accessibilityComponentType="button"
                  >
                    <MaterialCommunityIcons
                      name="pencil"
                      color={colors.icon.blue}
                    />
                    <Text
                      style={{
                        color: colors.icon.blue,
                      }}
                    >
                      Master Edit
                    </Text>
                  </View>
                );
              }
            },
          },
        }}
      />
      <Modal
        open={modalOpen}
        onClose={closeModal}
        title={modalTitle}
        titleSize="xl"
        TextComponent={Text}
      >
        <View style={styles.modalContent}>
          {viewData?.type === TypeVerify.TOKO ? (
            <TokoDataForm
              viewMode
              data={viewData}
              onRollbackCompleted={onRollbackCompleted}
            />
          ) : (
            viewData && <OwnerDataForm viewMode data={viewData} />
          )}
        </View>
      </Modal>
      {viewData && (
        <Modal title={modalTitle} open={editOwnerModalOpen} onClose={closeEdit}>
          <OwnerEditForm
            viewMode
            data={viewData}
            onClose={closeEdit}
            refetchProfile={refetchData}
          />
        </Modal>
      )}
      {viewData && (
        <Modal
          title={modalTitle}
          label={typeModal}
          open={superEditOwnerModalOpen}
          onClose={closeSuperEdit}
          style={{ zIndex: 3 }}
        >
          <OwnerSuperEditForm
            onClose={closeSuperEdit}
            viewMode
            data={viewData}
            refetchData={refetchData}
          />
        </Modal>
      )}
      {viewData && (
        <Modal
          title={modalTitle}
          label={typeModal}
          open={superEditTokoModalOpen}
          onClose={closeSuperEditToko}
        >
          <TokoSuperEditForm
            onClose={closeSuperEditToko}
            viewMode
            data={viewData}
            refetchData={refetchData}
          />
        </Modal>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  statusRow: { flexDirection: 'row', alignItems: 'center' },
  modalContent: {
    padding: spacing.xs,
  },
  storeImg: {
    width: 24,
    height: 24,
  },
});

export default QueueTable;
