import gql from 'graphql-tag';

import {
  EVENT_ONLINE_FORM_FRAGMENT,
  COMPETITION_ONLINE_FORM_FRAGMENT,
} from '../fragments';

export const CREATE_EVENT_ONLINE_FORM = gql`
  mutation CreateEventOnlineForm(
    $eventName: String!
    $venue: String!
    $maxParticipant: Int!
    $address: String
    $latitude: String
    $longitude: String
    $eventDate: DateTime!
  ) {
    createOnlineFormEvent(
      eventName: $eventName
      venue: $venue
      maxParticipant: $maxParticipant
      address: $address
      latitude: $latitude
      longitude: $longitude
      timeEvent: $eventDate
    ) {
      ...EventFormFragment
    }
  }
  ${EVENT_ONLINE_FORM_FRAGMENT}
`;

export const UPDATE_EVENT_ONLINE_FORM = gql`
  mutation UpdateEventOnlineForm(
    $formID: Int!
    $eventName: String!
    $venue: String!
    $maxParticipant: Int!
    $address: String
    $latitude: String
    $longitude: String
    $eventDate: DateTime!
    $link: String!
  ) {
    updateOnlineFormEvent(
      formId: $formID
      eventName: $eventName
      venue: $venue
      maxParticipant: $maxParticipant
      address: $address
      latitude: $latitude
      longitude: $longitude
      timeEvent: $eventDate
      link: $link
    ) {
      ...EventFormFragment
    }
  }
  ${EVENT_ONLINE_FORM_FRAGMENT}
`;

export const ARCHIVE_EVENT_ONLINE_FORM = gql`
  mutation ArchiveEventOnlineForm($formID: Int!) {
    archiveOnlineFormEvent(formId: $formID) {
      success
    }
  }
`;

export const SUBMIT_EVENT_ONLINE_FORM = gql`
  mutation SubmitEventOnlineForm(
    $formId: Int!
    $name: String!
    $storeName: String!
    $phoneNumber: String!
    $attendees: Int!
  ) {
    submitFormEvent(
      formId: $formId
      name: $name
      storeName: $storeName
      phoneNumber: $phoneNumber
      attendees: $attendees
    ) {
      id
    }
  }
`;

export const CREATE_COMPETITION_ONLINE_FORM = gql`
  mutation CreateCompetitionOnlineForm(
    $competitionName: String!
    $prizeValue: Int!
    $prize: String!
    $terms: String!
    $image: String
  ) {
    createOnlineFormCompetition(
      competitionName: $competitionName
      prizeValue: $prizeValue
      prize: $prize
      terms: $terms
      image: $image
    ) {
      ...CompetitionFormFragment
    }
  }
  ${COMPETITION_ONLINE_FORM_FRAGMENT}
`;

export const UPDATE_COMPETITION_ONLINE_FORM = gql`
  mutation UpdateCompetitionOnlineForm(
    $formID: Int!
    $competitionName: String!
    $prizeValue: Int!
    $prize: String!
    $terms: String!
    $image: String
    $link: String!
  ) {
    updateOnlineFormCompetition(
      formId: $formID
      competitionName: $competitionName
      prizeValue: $prizeValue
      prize: $prize
      terms: $terms
      image: $image
      link: $link
    ) {
      ...CompetitionFormFragment
    }
  }
  ${COMPETITION_ONLINE_FORM_FRAGMENT}
`;

export const ARCHIVE_COMPETITION_ONLINE_FORM = gql`
  mutation ArchiveCompetitionOnlineForm($formID: Int!) {
    archiveOnlineFormCompetition(formId: $formID) {
      success
    }
  }
`;

export const SUBMIT_COMPETITION_ONLINE_FORM = gql`
  mutation SubmitCompetitionOnlineForm(
    $formId: Int!
    $name: String!
    $storeName: String!
    $phoneNumber: String!
  ) {
    submitFormCompetition(
      formId: $formId
      name: $name
      storeName: $storeName
      phoneNumber: $phoneNumber
    ) {
      id
    }
  }
`;
