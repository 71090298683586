export const toDdMmYy = (date: Date) =>
  date.getDate().toString().padStart(2, '0') +
  '/' +
  (date.getMonth() + 1).toString().padStart(2, '0') +
  '/' +
  date.getFullYear().toString().slice(-2);

export const to12Hh = (date: Date) => {
  let hours = date.getHours();
  // NOTE: 00:00 = 12am, 12:00 = 12pm
  return String(hours % 12 || 12) + (hours > 11 && hours < 24 ? 'pm' : 'am');
};

export const toDdMmYyyy = (date: Date) =>
  date.getDate().toString().padStart(2, '0') +
  '/' +
  (date.getMonth() + 1).toString().padStart(2, '0') +
  '/' +
  date.getFullYear();

export const to24Hh = (date: Date) =>
  date.getHours().toString().padStart(2, '0') +
  ':' +
  date.getMinutes().toString().padStart(2, '0');
