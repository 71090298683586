import gql from 'graphql-tag';

export const SET_AGENT = gql`
  mutation SetAgent(
    $requestToken: String!
    $agentId: ID!
    $rejectReason: String!
  ) {
    setAgent(
      requestToken: $requestToken
      agentId: $agentId
      rejectReason: $rejectReason
    ) {
      success
      isSameAgent
    }
  }
`;
